import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { SelectMenu, Table, Action, Text ,Modal, Button} from 'components';
import { Icons, Images } from 'constant';
import { navigation } from 'helpers';
import { changeModalMode } from 'store/utlis/actions';
import { selectUser } from 'store/user/actions';
import { userListSelector } from 'store/user/selector';
import { Menu } from '@headlessui/react';
import {Fetch, persist} from '../../services/local-storage';

import LimitationMenu from './LimitationSelectMenu';
import { Container } from './style';
import {getAllusers, deactivateUser, deleteUser,findreffereduser} from '../../services/auth';
import { useNavigate } from "react-router-dom";
import ModalDeactivate from './ModalDeactivate/index';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const MAX_TEXT = 80;

const Users = () => {
	const CurrentUser = JSON.parse(Fetch("user"));

	const { user, tableMode } = useSelector(userListSelector);
	const [deletemodal, setDeletemodal] = useState(false);
	const [User, setUser] = useState('');

	const [refferalusers,setrefferalusers]=useState([]);

	const [userId, setUserId] = useState();
	const [refferalmodal, setrefferalmodal] = useState(false);
	const dispatch = useDispatch();
	const navigate = useNavigate();


	const [users, setUsers] = useState(user.user);
	const getusers = async() => {
		const res = await getAllusers();
		
		const nfts = res.data.nfts;
		const newdata= res.data.result;
		const filtered= newdata.filter((item) => item.userType == "user")

		for(let i=0; i < filtered.length; i++ ){
		const filtered_nfts = nfts.filter((item) => filtered[i].walletAddress && filtered[i].walletAddress == item.walletAddress)
		let data = filtered[i]
		data['nfts']=filtered_nfts.length
		}    
		setUsers(filtered);
	}
	useEffect(() => {
		setUsers(user.user)
		getusers();
	},[])

	const navigateToDetail = (item) =>{
		navigate(`/users/${item._id}`)
	};

	const timeconvertdays = (unix_timestamp) => {
		var a = new Date(unix_timestamp);
		var months = [
		  "Jan",
		  "Feb",
		  "Mar",
		  "Apr",
		  "May",
		  "Jun",
		  "Jul",
		  "Aug",
		  "Sep",
		  "Oct",
		  "Nov",
		  "Dec",
		];
		var year = a.getFullYear();
		var month = months[a.getMonth()];
		var date = a.getDate();
		var time = a.toLocaleTimeString()
		var time = date + "/" + month + "/" + year // + ", " + time; //+ ' ' + year + ' ' + hour + ':' + min + ':' + sec;
		return time;
	  };

	const activaterefferalmodal=async(item)=>{
		console.log("activaterefferalmodal",item)
        if(item.refferalcode){
		var data ={
			refferalcode :item.refferalcode
		}
		const referedusers = await findreffereduser(data);
		console.log(referedusers)
		if(referedusers.data.status)
		{
			setrefferalusers(referedusers.data.result)
            setrefferalmodal(true)
        }else{
           toast(referedusers.data.message)
		}
		
	}else{
		toast("Refferalcode not found")
	}
	}

	const onClickAction = useCallback(
		(mode, item) => {
			dispatch(selectUser(item));
			dispatch(changeModalMode(mode));
		},
		[dispatch],
	);
	const deleteUsers = async() =>{
		const res = await deleteUser(User && User._id)
		toast("User Deleted Successfully")
		getusers();
		setDeletemodal(false);
	}

	const columns = onClickAction => {
		return [
			{
				label: 'User Info',
				key: 'info',
				dataIndex: 'info',
				render: item => {
					return (
						<div
							className='flex cursor-pointer justify-content-center align-items-center'
							onClick={ () => navigateToDetail(item) }
						>
							<img src={Images.Profile} alt="profile" />
							<span className='relative ml-3 '>{ item.userName }</span>
						</div>
					);
				},
			},
			{
				label: 'Wallet Address',
				key: 'wallet_address',
				dataIndex: 'wallet_address',
				render: item => {
					return (
						<div onClick={ () => navigateToDetail(item) }>
							<span className='font-bold'>{item.walletAddress ? item.walletAddress.slice(0,5) +"..."+item.walletAddress.slice(-3) : "--" }</span>
						</div>
					);
				},
			},
			{
				label: 'NFT',
				key: 'nft',
				dataIndex: 'nft',
				render: item => {
					return (
						<div onClick={ () => navigateToDetail(item) }>
							<span className='font-bold'>{ `${item.nfts} NFTs` }</span>
						</div>
					);
				},
			},
			{
				label: 'Regstration Date',
				key: 'registration_date',
				dataIndex: 'registration_date',
				render: item => {
					return (
						<div onClick={ () => navigateToDetail(item) }>
							<span className='font-bold'>{  new Date(item?.createdAt).toLocaleDateString("en", {
								year: "numeric",
								month: "2-digit",
								day: "numeric"
    						}) }
							</span>
						</div>
					);
				},

			},
			
			{
				label: ' Referral',
				key: 'referral',
				dataIndex: 'referral',
				render: item => {
					return ( 
						<button className='d-flex justify-content-center align-items-center' style={{cursor:"pointer",background:"rgb(202 203 203)",color:"rgb(20 30 48)",width:"100px",height:"40px",borderRadius:"10px"}} onClick={ () => activaterefferalmodal(item)}>
							<span className='font-bold'>Referred ({item.refferalusedcount ? item.refferalusedcount : 0 })</span>
							{/* <LimitationMenu
								dataUser={ item }
							/> */}
						</button>
					);
				},
			},
			
			{
				label: 'Referral ID',
				key: 'referral_id',
				dataIndex: 'referral_id',
				render: item => {
					{console.log(item)}
					return (
						<div onClick={ () => navigateToDetail(item) }>
							<span className='font-bold'>
								{ item.refferalcode ? item.refferalcode : "---"}
							</span>
						</div>
					);
				},
			},

			// {
			// 	label: ' Limitation',
			// 	key: 'limitation',
			// 	dataIndex: 'limitation',
			// 	render: item => {
			// 		return (
			// 			<div onClick={ () => setUserId(item?.id) }>
			// 				<LimitationMenu
			// 					dataUser={ item }
			// 				/>
			// 			</div>
			// 		);
			// 	},
			// },
			{
				label: 'Action',
				key: 'operation',
				dataIndex: 'action',
				render: item => {
					return (
						<>
						{CurrentUser.userType == "superadmin" ?
						<ul className='flex items-center relative left-[-7px]'>
							{/* <li className='m-0 bg-blueLighter p-1.5 rounded cursor-pointer'
								onClick={ e => {
									e.stopPropagation();
								} }
							>
								<Icons.SearchIcon className='w-5 h-5' />
							</li> */}
							{/* <Action item={ item } onClickAction={ onClickAction } /> */}
							{renderPopMoreIcon(item, setDeletemodal)}
						</ul>
						: null
						}
						</>
					);
				},
			}
		];
	};
	const renderPopMoreIcon = (item,setDeletemodal) => {
	
		return (
		  <>
	
		  <SelectMenu
			MenuButton={<Icons.MoreIcon />}
			wrapperClassName='mr-0'
			menuItemsClassName='z-[9] w-56'
			menuBtnClassName='bg-blueLighter p-3 rounded cursor-pointer scale-[0.75]'
		  >
			
			<div className='z-10'>
			  <Menu.Item>
				{({ active }) => (
				  <Text text='Delete' className='py-3 pl-3 hover:bg-gray-400 cursor-pointer' onClick={() => {setUser(item);setDeletemodal(true)}} />
				)}
			  </Menu.Item>
			</div>
	  
		  </SelectMenu>
		  </>
		)
	}

	return (
		<>
		<Container>
			<Table
				columns={ columns(onClickAction) }
				dataSource={ user.user.length  == 0 ? users : user.user }
				// onClickRow={(item) => navigateToDetail(item)}
			/>
		</Container>
		
		{/* <ModalDeactivate  visible={deletemodal} data={User && User}/> */}
		<Modal
        visible={ deletemodal }
        onClickClose={() => setDeletemodal(false)}
      >
        <div className='modal-input-container'>
          <img src={ Images.Deactivate } className='mx-auto mb-10' />
          <Text
            fontType='h4'
            fontFamily='Lora'
            fontWeight='700'
            fontSize='24px'
            text={`Do you want to Permanently delete  ${User?.userName}?`}
            textAlign='center'
            color='var(--blue)'
            className='mb-3 join-modal-title'
          />

          <Text
            text='WARNING : This action cannot be Undone!'
            color='var(--black200)'
            textAlign='center'
            className='mb-8'
          />
        </div>
        <div className='btn-submit mx-auto flex justify-between'>
          <div>
            <Button
              className=''
              label='Yes'
              height={ '40px' }
              width={ '185px' }
              textColor={ '#003051' }
              backgroundColor={ '#E3E0E0' }
              onClick={()=>deleteUsers()}
            />
          </div>
          <div>
            <Button
              className=''
              label='No'
              height={ '40px' }
              width={ '185px' }
              textColor={ '#003051' }
              backgroundColor={ '#E3E0E0' }
              onClick={() =>setDeletemodal(false)}
            />
          </div>
        </div>
      </Modal>


	  <Modal
        visible={ refferalmodal }
        onClickClose={() => setrefferalmodal(false)}
      >
        <div className='modal-input-container mb-1' style={{width:"750px"}}>
          <Text
            fontType='h4'
            fontFamily='Lora'
            fontWeight='700'
            fontSize='24px'
            text={"Reffered User's List"}
            // textAlign='center'
            color='var(--blue)'
            className='mb-4 join-modal-title'
          />
         
        </div>
        <div className='btn-submit'>
		 <div className="row mb-3">
			<div className="col-1 col-md-1 col-lg-1">
			<span className='font-bold'>No</span>
			</div>
			<div className="col-3 col-md-3 col-lg-3">
			<span className='font-bold'>User Name</span>
			</div>
			<div className="col-5 col-md-5 col-lg-5">
			<span className='font-bold'>User Email</span>
			</div>
			<div className="col-3 col-md-3 col-lg-3">
			<span className='font-bold'>Time</span>
			</div>
		 </div>
		 {console.log("refferalusers",refferalusers)}
        {refferalusers && refferalusers.length > 0 && refferalusers.map((item, i) =>(
			<div className="row mb-1">
			<div className="col-1 col-md-1 col-lg-1">
			<span className='font-bold'>{i+1}</span>
			</div>
			<div className="col-3 col-md-3 col-lg-3">
			<span className='font-bold'>{item.ReffereduserName}</span>
			</div>
			<div className="col-5 col-md-5 col-lg-5">
			<span className='font-bold'>{item.ReffereduserEmail}</span>
			</div>
			<div className="col-3 col-md-3 col-lg-3">
			<span className='font-bold'>{timeconvertdays(Number(item.RefferedTime)*1000)}</span>
			</div>
			</div>

		))
        }
        </div>
      </Modal>
		</>
	);
};

export default React.memo(Users);
