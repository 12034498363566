import React, { useEffect } from 'react';
import { useState, useCallback } from 'react';

const isEmpty = array => {
	return Array.isArray(array) && (array.length == 0 || array.every(isEmpty));
};

const useForm = ({ fields }) => {
	const [form, setForm] = useState(fields);

	const registeredValue = fieldname => {
		const inputObj = form[fieldname];
		const {
			value, label, errorMessage, valid, type, shouldUpdate
		} = inputObj;
		return {
			onChange: onInputChange,
			value,
			valid,
			errorMessage,
			label,
			name: fieldname,
			type,
			shouldUpdate
		};
	};

	const isInputFieldValid = useCallback(
		inputField => {
			for (const rule of inputField.validationRules) {
				if (!rule.validate(inputField.value, form)) {
					inputField.errorMessage = rule.message;
					return false;
				}
			}

			return true;
		},
		[form]
	);
	const isInputListFieldValid = useCallback(
		(validationRules, value) => {
			for (const rule of validationRules) {
				if (!rule.validate(value.value, form)) {
					value.errorMessage = rule.message;
					return false;
				}
			}

			return true;
		},
		[form]
	);

	const onInputChange = useCallback(
		(event, cb) => {
			const {
				name, value, id, parentElement, tabIndex, accessKey
			} = event.target;
			let resultField = { ...form };
			const fieldname = parentElement?.id ? parentElement?.id : name;
			const inputObj = { ...form[fieldname] };
			if (inputObj.type === 'list') {
				inputObj.shouldUpdate = false;
				const values = {
					value: value,
					valid: false,
					errorMessage: '',
					id,
					name: name
				};
				inputObj.value[tabIndex] = values;
				const isValidInputList = isInputListFieldValid(inputObj.validationRules, values);
				if (isValidInputList && !values.valid) {
					values.valid = true;
				} else if (!isValidInputList && values.valid) {
					values.valid = false;
				}
				resultField = {
					...resultField,
					[fieldname]: inputObj
				};
				if (cb) {
					cb(values);
				}
			} else if (inputObj.type === 'list-multi') {
				inputObj.shouldUpdate = false;
				const values = {
					value: value,
					valid: false,
					errorMessage: '',
					id,
					name: name
				};
				inputObj.value[accessKey][tabIndex] = values;
				const isValidInputList = isInputListFieldValid(inputObj.validationRules, values);
				if (isValidInputList && !values.valid) {
					values.valid = true;
				} else if (!isValidInputList && values.valid) {
					values.valid = false;
				}
				resultField = {
					...resultField,
					[fieldname]: inputObj
				};
				if (cb) {
					cb(values);
				}
			}  else {
				inputObj.value = value;

				const isValidInput = isInputFieldValid(inputObj);
				if (isValidInput && !inputObj.valid) {
					inputObj.valid = true;
				} else if (!isValidInput && inputObj.valid) {
					inputObj.valid = false;
				}
				resultField = {
					...resultField,
					[name]: inputObj
				};
			}
			setForm(resultField);
		},
		[
			form,
			isInputFieldValid,
			isInputListFieldValid
		]
	);
	const isFormValid = useCallback(() => {
		let isValid = true;
		const arr = Object.values(form);

		for (let i = 0; i < arr.length; i++) {
			if (arr[i].type === 'list') {
				const itemArr = Object.values(arr[i].value);
				const valid = itemArr.find(item => !item.valid);
				if (valid) {
					isValid = false;
					break;
				}
			} else if (arr[i].type === 'list-multi') {
				const itemArr = Object.values(arr[i].value);
				const findNotValid = itemArr.find(item => item.find(res => !res.valid) || item.length === 1);
				if (isEmpty(itemArr) || findNotValid) {
					isValid = false;
					break;
				}
			} else if ((arr[i].type !== 'list' && arr[i].type !== 'list-multi') && !arr[i].valid) {
				isValid = false;
				break;
			}
		}

		return isValid;
	}, [form]);

	const onSubmit = e => {
		if (e) {
			e.preventDefault();
		}
		const values = Object.fromEntries(
			Object.entries(form)
				.map(([key, val]) => [key, val.value])
		);
		return {
			form,
			values
		};
	};

	const setFieldsValue = useCallback(obj => {
		const newObj = { ...form };
		for (const [key, values] of Object.entries(form)) {
			let shouldUpdate = {};
			const val = values;
			if (val.type === 'list' || val.type === 'list-multi') {
				shouldUpdate = { shouldUpdate: true };
			}
			newObj[key] = {
				...values,
				value: obj[key] || '',
				valid: true,
				...shouldUpdate
			};
		}
		setForm({
			...form,
			...newObj
		});
	}, [form]);

	const addListItem = useCallback(
		(fieldname, obj) => {
			const tempFields = { ...form[fieldname] };
			tempFields.value.push(obj);
			setForm({
				...form,
				[fieldname]: tempFields
			});
		},
		[form],
	);

	const removeListItem = useCallback(
		(fieldname, index) => {
			const tempFields = { ...form[fieldname] };
			tempFields.value = tempFields.value.filter((_, i) => i !== index);
			setForm({
				...form,
				[fieldname]: tempFields
			});
		},
		[form],
	);

	const resetFieldsValue = useCallback(() => {
		setForm(fields);
	}, [fields]);

	useEffect(() => {
		setForm(fields);
	}, [fields]);

	return {
		registeredValue,
		isFormValid,
		onSubmit,
		setFieldsValue,
		resetFieldsValue,
		removeListItem,
		addListItem
	};
};

export default useForm;
