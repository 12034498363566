
import * as UtilsActionTypes from './actionTypes';

const initialState = {
	modalMode: 'INIT',
	search: ''
};

const setModalMode = (state, payload) => ({
	...state,
	modalMode: payload,
});

const setSearch = (state, payload) => ({
	...state,
	search: payload,
});

const resetState = () => ({
	...initialState
});
const utilReducer = (state = initialState, action) => {
	switch (action.type) {
		case UtilsActionTypes.CHANGE_MODAL_MODE:
			return setModalMode(state, action.payload);
		case UtilsActionTypes.CHANGE_SEARCH:
			return setSearch(state, action.payload);
		case UtilsActionTypes.RESET_UTILS:
			return resetState();
		default:
			return state;
	}
};

export default utilReducer;
