
import * as RestaurantActionTypes from './actionTypes';

export const getRestaurantLoading = () => ({
	type: RestaurantActionTypes.GET_RESTAURANT_LIST,
});

export const getRestaurantSuccess = payload => ({
	type: RestaurantActionTypes.GET_RESTAURANT_LIST_SUCCESS,
	payload,
});

export const changeTableMode = payload => ({
	type: RestaurantActionTypes.CHANGE_TABLE_MODE,
	payload,
});

export const addRestaurant = payload => ({
	type: RestaurantActionTypes.ADD_RESTAURANT,
	payload,
});

export const editRestaurant = payload => ({
	type: RestaurantActionTypes.EDIT_RESTAURANT,
	payload,
});

export const deleteRestaurant = () => ({
	type: RestaurantActionTypes.DELETE_RESTAURANT,
});

export const selectRestaurant = payload => ({
	type: RestaurantActionTypes.SELECT_RESTAURANT,
	payload,
});

export const getRestaurant = dispatch => params => {
	// return new Promise(async(resolve, reject) => {
	// 	dispatch(getRestaurantLoading());

	// 	const response = await productServices.getRestaurant(params);

	// 	const { stat_code, data, stat_msg, pagination } = response;

	// 	if (stat_code === 200) {
	// 		resolve(dispatch(getRestaurantSuccess({ data,
	// 			pagination })));
	// 	} else {
	// 		reject(stat_msg);
	// 	}
	// });
};