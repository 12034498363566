import React, { useMemo, useState } from 'react';
import { Fonts, Colors } from 'constant';
import { Bar } from 'react-chartjs-2';

const labels = ['Landing Page', 'NFT Collection', 'Restaurant Collection', 'Wallet', 'Contact Us', 'Notification'];

const BarChart = () => {
	const [hovered, setHovered] = useState();
	const options = useMemo(() => {
		return {
			indexAxis: 'y',
			elements: {
				bar: {
					borderWidth: 2,
				},
			},
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				title: {
					display: false,
				},
				legend: {
					display: false
				},
			},
			onHover: (_, value) => {
				setHovered(value.length ? value[0].index : undefined);
			},
			scales: {
				y: {
					afterFit: scaleInstance => {
						scaleInstance.width = 175;
					},
					grid: {
						offset: false,
						borderDash: [5, 5],
						drawBorder: false,
						tickLength: 30,
						tickColor: Colors.white.default
					},
					ticks: {
						font: ctx => {
							const font = {
								family: Fonts.nunito,
								size: 14,
							};
							if (ctx.index === hovered) {
								font.weight = '700';
							}
							return font;
						},
						color: ctx => {
							if (ctx.index === hovered) {
								return Colors.blue.prussianBlue;
							} else {
								return Colors.grey.dark;
							}
						},
						backdropPadding: {
							left: 100
						}
					},
				},
				x: {
					grid: {
						drawBorder: false,
						display: false,
					},
					ticks: {
						display: false,
					},
				},
			}
		};
	}, [hovered]);
	const data = useMemo(() => {
		return {
			labels,
			datasets: [
				{
					label: 'Platform',
					data: [100, 200, 1000, 400, 900, 600, 700, 100, 200, 1000, 400, 500],
					borderColor: Colors.blue.blueGray,
					backgroundColor: Colors.blue.blueGray,
					borderRadius: 5,
					hoverBackgroundColor: Colors.blue.prussianBlue,
					hoverBorderColor: Colors.blue.prussianBlue,
					borderSkipped: false,
					borderWidth: 0.1,
				},
			],
		};
	}, []);
	return <Bar options={ options } data={ data } />;
};

export default React.memo(BarChart);