import React, { useRef } from 'react';
import { Menu, Transition } from '@headlessui/react';

const Tooltip = ({ title, children }) => {
	const useHover = true;
	const buttonRef = useRef(null);
	const dropdownRef = useRef(null);
	const timeoutDuration = 100;
	let timeout;

	const openMenu = () => {
		buttonRef && buttonRef.current.click();
	};

	const closeMenu = () =>
		dropdownRef &&
    dropdownRef.current?.dispatchEvent(
    	new KeyboardEvent('keydown', {
    		key: 'Escape',
    		bubbles: true,
    		cancelable: true
    	})
    );

	const onMouseEnter = closed => {
		clearTimeout(timeout);
		closed && openMenu();
	};
	const onMouseLeave = open => {
		open && (timeout = setTimeout(() => closeMenu(), timeoutDuration));
	};

	return (
		<ul>
			<li className='relative inline-block text-left mb-0 cursor-pointer'>
				<Menu>
					{ ({ open }) => (
						<>
							<div
								className='flex rounded-md'
								onClick={ openMenu }
								// onMouseEnter={ () => useHover && onMouseEnter(!open) }
								onMouseLeave={ () => useHover && onMouseLeave(open, '1') }
							>
								<Menu.Button
									ref={ buttonRef }
									className='inline-flex justify-center w-full  text-sm font-medium leading-5 transition duration-150 ease-in-out  hover:text-gray-500 focus:outline-none active:bg-gray-50 active:text-gray-800 -z-50'
									as='button'
									href='#'
								>
									<span className='font-bold cursor-pointer'>{ title }</span>
								</Menu.Button>
							</div>

							<Transition
								show={ open }
								enter='transition ease-out duration-100'
								enterFrom='transform opacity-0 scale-95'
								enterTo='transform opacity-100 scale-100'
								leave='transition ease-in duration-75'
								leaveFrom='transform opacity-100 scale-100'
								leaveTo='transform opacity-0 scale-95'
							>
								<Menu.Items
									ref={ dropdownRef }
									onMouseEnter={ () => useHover && onMouseEnter() }
									onMouseLeave={ () => useHover && onMouseLeave(open, '2') }
									static
									className='absolute top-10 right-0 w-56 mt-2 origin-top-right bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg outline-none -translate-y-10 translate-x-[130px]'
								>
									{ children }
								</Menu.Items>
							</Transition>
						</>
					) }
				</Menu>
			</li>
		</ul>
	);
};

export default Tooltip;