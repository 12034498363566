// constants
import Web3 from "web3";
import Web3Modal from "web3modal";
import WalletConnectProvider from "@walletconnect/web3-provider";
import store from "./store";
import  RestaurantBuilder from '../../contractsABI/RestaurantBuilder.json';
import Marketplacecontract from "../../contractsABI/Marketplace.json"
// import nftdata from "../contract/nft.json"
const connectRequest = () => {
  return {
    type: "CONNECTION_REQUEST",
  };
};

export const connectSuccess = (payload) => {
  console.log("payload:",payload)
  return {
    type: "CONNECTION_SUCCESS",
    payload: payload,
  };
};

export const connectFailed = (payload) => {
  console.log("failed:", payload)
  return {
    type: "CONNECTION_FAILED",
    payload: payload,
  };
};

// const updateAccountRequest = (payload) => {
//   return {
//     type: "UPDATE_ADDRESS",
//     payload: payload,
//   };
// };

const getProviderOptions = () => {
    const providerOptions = {
      walletconnect: {
        package: WalletConnectProvider,
        options: {
          //infuraId: process.env.REACT_APP_INFURA_ID
          rpc: {
            80001:'https://matic-mumbai.chainstacklabs.com'
          }
        }
      }
    }

    return providerOptions;
}

export const connectWallet = (walletname) => {
    return async(dispatch) => {
        dispatch(connectRequest());
        try {
          console.log("wallet dispatch called")
            const web3Modal = new Web3Modal({
                cacheProvider: true,
                providerOptions: getProviderOptions() // required
            });
            var provider = ''
            if(walletname === "coinbasewallet"){
             var provider = await web3Modal.connectTo('coinbasewallet')
            }else if(walletname === "walletconnect" ){
             var provider =await web3Modal.connectTo("walletconnect")
            }else if(walletname === "fortmatic" ){
              var provider =await web3Modal.connectTo("fortmatic")
            }else if(walletname === "metamask"){
              const web3Modal = new Web3Modal({
                cacheProvider: true,
                providerOptions: getProviderOptions().walletconnect // required
              });
              var provider =await web3Modal.connect()
            }else{
              const web3Modal = new Web3Modal({
                cacheProvider: true,
                providerOptions: getProviderOptions().walletconnect // required
              });
              var provider = await web3Modal.connect()
            }
            if(window.ethereum && window.ethereum.networkVersion !== '137') {
              // console.log("window.ethereum.networkVersion", window.ethereum.networkVersion)
              await addNetwork(137);
            }
            //const provider = await web3Modal.connect();
            const marketplaceAddress = process.env.REACT_APP_RESTAURANT_BUILDER;
            
            await subscribeProvider(provider);
            
            const web3 = new Web3(provider);
            const accounts = await web3.eth.getAccounts();
            const address = accounts[0];
            console.log("address:", address)
            var marketplace = new web3.eth.Contract(RestaurantBuilder,
                process.env.REACT_APP_RESTAURANT_BUILDER
            );

            var NFT = new web3.eth.Contract(
                Marketplacecontract,
                process.env.REACT_APP_MARKETPLACE
            );

          
            dispatch(
                connectSuccess({
                  address,
                  web3,
                  provider,
                  connected: true,
                  marketplace,
                  // marketplacefund,
                  NFT,
                  web3Modal,
                })
            );
        } catch (e) {
            dispatch(connectFailed(e));
        }
    }
}

const subscribeProvider = async(provider) => {
    if (!provider.on) {
      return;
    }

    provider.on('connect', async(id) => {
      console.log(id);
    });

    provider.on("networkChanged", async (networkId) => {
      if(networkId !== '137') {
        store.dispatch(connectFailed('Please switch to Binance mainnet'));
      } else {
        store.dispatch(connectWallet());
      }
    });
}

export async function addNetwork(id) {
  let networkData;
  switch (id) {
    case 137:
      networkData = [
        {
          chainId: "0x89",
          chainName: "Polygon Mainnet",
          rpcUrls: ["https://polygon-rpc.com/"],
          nativeCurrency: {
            name: "MATIC",
            symbol: "MATIC",
            decimals: 18,
          },
          blockExplorerUrls: ["https://polygonscan.com/"],
        },
      ];
    
      break; 

      case 80001:
        networkData=JSON.stringify(networkData)
        networkData = [
          {
            chainId:'0x13881', //web3.utils.toHex('80001'),
            chainName: "Mumbai Testnet",
            rpcUrls: ["https://rpc-mumbai.maticvigil.com"],
            nativeCurrency: {
              name: "MATIC",
              symbol: "MATIC",
              decimals: 18,
            },
            blockExplorerUrls: ["https://polygonscan.com/"],
          },
        ];
    
          break;   
    default:
      break;
  }
  if(Number(id)==137)
    {
      return window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: networkData,
        });
    }
}





