import colors from 'constant/colors';
import styled from 'styled-components';
import twitterIcon from 'assets/icons/twitter.svg';
import igIcon from 'assets/icons/instagram.svg';
import discordIcon from 'assets/icons/discord.svg';
import linkedinIcon from 'assets/icons/linkedin.svg';

const Aside = styled.aside`
`;

const Layout = styled.div`
`;

const Content = styled.div`
  .vertical-line {
    width: 32px;
    height: 0px;
    left: 314px;
    top: 126px;
    border: 1px solid #F4F4F4;
    transform: rotate(90deg);
  }

`;

const MenuItem = styled.a`
  ${props => props.active && `font-weight: 800; color: ${colors.blue.prussianBlue} ;`}
  .item-icon {
    path {
      fill: ${props => props.active ? colors.blue.prussianBlue : colors.grey.netralGrey} ;
    }
  }
`;

const Wrapper = styled.div`
  TextField::-webkit-outer-spin-button,
  TextField::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  TextField[type=number] {
    -moz-appearance: textfield;
  }
`;

const ModalWrapper = styled.div`
  .input-name {
      min-width: 230px !important;
      height: 40px ;
      padding-left: 9px ;
      &::placeholder {
        padding-left: 0 !important ;
      }
  }
  .input-name-check {
    width: 20px;
    height: 20px;
    alignSelf: flex-start;
    display: flex;
  }
  .input-name1 {
    min-width: 150px !important;
    height: 40px ;
    padding-left: 9px ;
    &::placeholder {
      padding-left: 0 !important ;
    }
  }
  .input-name2 {
    min-width: 150px !important;
    height: 40px ;
    padding-left: 9px ;
    &::placeholder {
      padding-left: 0 !important ;
    }
  }
  .input-name3 {
    min-width: 150px !important;
    height: 40px ;
    padding-left: 9px ;
    &::placeholder {
      padding-left: 0 !important ;
    }
  }
  
    .input-socmed {
      min-width: 190px !important;
      height: 40px ;
      padding-left: 30px ;
      &::placeholder {
        padding-left: 0 !important ;
      }
    }
    .icon-twitter {
      background: url(${twitterIcon}) no-repeat scroll 10px 13px;
    }
    .icon-ig {
      background: url(${igIcon}) no-repeat scroll 10px 13px;
    }
    .icon-linkedin {
      background: url(${linkedinIcon}) no-repeat scroll 10px 13px;
    }
    .icon-discord {
      background: url(${discordIcon}) no-repeat scroll 10px 13px;
    }
    textarea {
      padding-top: 10px ;
      padding-left: 10px ;
    }
  .input-wrapper:focus-within {
    label {
      font-weight: bold ;
      color: ${colors.prussianBlue} ;
    }
    .input-name {
        &:focus {
        outline: 1px solid ${colors.blue.prussianBlue} ;
        border-color: transparent ;
      }
    }
    .input-socmed {
        &:focus {
        outline: 1px solid ${colors.blue.prussianBlue} ;
        border-color: transparent ;
      }
    }
    textarea:focus {
      outline: 1px solid ${colors.blue.prussianBlue} ;
      border-color: transparent ;
    }
  }
`;

const IconWrapper = styled.div`

  svg {
    path {
        opacity: 1;
      }
  }

  ${props => props.active && `
    .collection-icon {
      path {
        opacity: 0.2 !important;
      }
    }
  `}

${props => !props.active && `
  .list-icon {
      path {
        opacity: 0.2 !important;
      }
    }
  `}

` 

export {
  Aside, Layout, Content, MenuItem, Wrapper, ModalWrapper, IconWrapper
};