
import * as CollectionActionTypes from './actionTypes';
import { Images } from 'constant';
import { makeID } from 'helpers';
import moment from 'moment';

const restaurantList =  [];

const nftList =  [
	{
		id: 0,
		name: 'NFT 1'
	},
	{
		id: 1,
		name: 'NFT 2'
	},
	{
		id: 2,
		name: 'NFT 3'
	},
	{
		id: 3,
		name: 'NFT 4'
	},
	{
		id: 4,
		name: 'NFT 5'
	},
	{
		id: 5,
		name: 'NFT 6'
	},
	{
		id: 6,
		name: 'NFT 7'
	},
	{
		id: 7,
		name: 'NFT 8'
	},
	{
		id: 8,
		name: 'NFT 9'
	},
	{
		id: 10,
		name: 'NFT 10'
	}
];
const nftListDetail =  [
	{
		id: 0,
		name: 'NFT 1',
		restoName: 'Resto 1',
		price: '1,234',
		perminted: true
	},
	{
		id: 1,
		name: 'NFT 2',
		restoName: 'Resto 2',
		price: '1,234',
		perminted: true
	},
	{
		id: 2,
		name: 'NFT 3',
		restoName: 'Resto 3',
		price: '1,234',
		perminted: true
	},
	{
		id: 3,
		name: 'NFT 4',
		restoName: 'Resto 4',
		price: '1,234',
		perminted: true
	},
	{
		id: 4,
		name: 'NFT 5',
		restoName: 'Resto 5',
		price: '1,234',
		perminted: true
	},
	{
		id: 5,
		name: 'NFT 6',
		restoName: 'Resto 6',
		price: '1,234',
		perminted: false
	},
];
const restructureData = data => {
	data.releaseDate = moment(data.releaseDate).format();
	data.finishDate = moment(data.finishDate).format();
	data.restaurantList = data.restaurants.map(item => item.value);
	data.nftList = nftList;
	data.tier = data.tier.map(item => {
		return {
			id: makeID(10),
			item: item
		};
	});
	return data;
};

const initialState = {
	collections: [],
	groupcollection:[],
	loading: false,
	selectedCollection: {},
	fieldsEditCollection: {}
};

const reqCollection = (state, payload) => ({
	...state,
	collections: payload,
	loading: true
});

const groupCollection= (state, payload) => ({
	...state,
	groupcollection: payload,
	loading: true
});




const addCollection = (state, payload) => {
	let data = { ...payload };
	data.id = makeID(10);
	data = {
		...data,
		...restructureData(data)
	};
	return {
		...state,
		collections: [data, ...state.collections]
	};
};

const selectCollection = (state, payload) => {
	let selectedCollection = { ...payload };
	let fieldsEditCollection = {};
	if (payload) {
		fieldsEditCollection['photo'] = selectedCollection['photo'];
		fieldsEditCollection['name'] = selectedCollection['name'];
		fieldsEditCollection['description'] = selectedCollection['description'];
		fieldsEditCollection['restaurants'] = selectedCollection.restaurantList.map(item => {
			const id = makeID(10);
			return {
				name: `$restaurants-${id}`,
				id: id,
				errorMessage: '',
				valid: true,
				value: item
			};
		});
		fieldsEditCollection['tier'] = selectedCollection.tier.map(item => {
			if (item) {
				return item.item.map(res => {
					const id = makeID(10);
					return {
						name: `$restaurants-${id}`,
						id: id,
						errorMessage: '',
						valid: true,
						value: res.value
					};
				});
			}
			return item;
		});
		fieldsEditCollection['releaseDate'] = selectedCollection['releaseDate'];
		fieldsEditCollection['finishDate'] = selectedCollection['finishDate'];
	}

	return {
		...state,
		selectedCollection,
		fieldsEditCollection
	};
};
const editCollection = (state, payload) => {
	let data = { ...payload };
	const collections = [...state.collections];
	const findID = collections.findIndex(item => item.id === state.selectedCollection.id);
	data = restructureData(data);
	collections[findID] = { ...data };
	return {
		...state,
		collections: [...collections]
	};
};

const deleteCollection = state => {
	const collections = [...state.collections];
	const filteredCollections = collections.filter(item => item.id !== state.selectedCollection.id);
	return {
		...state,
		collections: [...filteredCollections]
	};
};

const collectionReducer = (state = initialState, action) => {
	switch (action.type) {
		case CollectionActionTypes.GET_COLLECTION_LIST:
			return reqCollection(state, action.payload);
		case CollectionActionTypes.ADD_COLLECTION:
			return addCollection(state, action.payload);
		case CollectionActionTypes.SELECT_COLLECTION:
			return selectCollection(state, action.payload);
		case CollectionActionTypes.EDIT_COLLECTION:
			return editCollection(state, action.payload);
		case CollectionActionTypes.DELETE_COLLECTION:
			return deleteCollection(state);
		default:
			return state;
	}
};

export default collectionReducer;
