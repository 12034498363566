import React, {
  useState, useCallback, ReactNode, useEffect
} from 'react';
import { makeID } from 'helpers';

const firstId = makeID(10);

const FormList = ({
  children, subChild, name, ...props
}) => {
  const [update, setUpdate] = useState(true);
  const [fields, setFields] = useState([
    {
      name: `${name}-${firstId}`,
      id: firstId,
      errorMessage: '',
      valid: false,
      value: ''
    }
  ]);

  const add = useCallback(
    () => {
      const id = makeID(10);
      const newValue = {
        id: id,
        name: `${name}-${id}`,
        errorMessage: '',
        valid: false,
        value: ''
      };
      setFields(prevState => [...prevState, newValue]);
      return newValue;
    },
    [setFields],
  );
  const remove = useCallback(
    (id ) => {
      setFields(prevState => prevState.length === 1 ? [...prevState] : [...prevState.filter(item => item.id !== id)]);
    },
    [setFields],
  );

  const onChange =
    (value, fieldName, id) => {
      const prop = props  ;
      const index = fields.findIndex((item ) => item.id === id);
      const event = {
        target: {
          name: fieldName,
          value: value,
          id,
          parentElement: { id: name },
          tabIndex: index
        }
      };
      prop.onChange({ ...event }, (data ) => {
        setFields(prevState => prevState.map((item ) => {
          if (item.id === id) {
            item = {
              ...item,
              id: item.id,
              ...data,
            };
          }
          return item;
        }));
        
      });
    };

  useEffect(() => {
    const prop = props  ;
    if (prop && prop.shouldUpdate && typeof prop.shouldUpdate === 'boolean' && update) {
      if (prop.value.length) {
        setFields(prop.value);
      }
      setUpdate(false);
    }
      
  }, [props, setFields]);

  return (
    <div>
      { children(fields, {
        onAdd: add,
        onRemove: remove,
        onChange: onChange
      }) }
      { subChild }
    </div>
  );
};

export default React.memo(FormList);