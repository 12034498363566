import colors from 'constant/colors';
import fonts from 'constant/fonts';
import React from 'react';
import { Line as LineChart } from 'react-chartjs-2';

const currentMonth = new Date().getMonth();
export const options = {
	responsive: true,
	maintainAspectRatio: false,
	interaction: {
		mode: 'index',
		intersect: false,
	},
	elements: {
		point: {
			radius: 0
		}
	},
	stacked: false,
	plugins: {
		title: {
			display: false,
		},
		legend: {
			display: false
		}
	},
	scales: {
		y: {
			type: 'linear',
			display: true,
			position: 'left',
			grid: {
				tickLength: 4,
				drawBorder: false
			},
			ticks: {
				stepSize: 400,
				font: {
					family: fonts.nunito,
					size: 14
				},
				color: colors.grey.dark,
				padding: 20
			},
			min: 0,
			max: 1200,
		},
		x: {
			grid: {
				drawBorder: false,
				display: true,
				color: ctx => {
					if (ctx.index === currentMonth) {
						return '#CCCCCC';
					}
				}
			},
			ticks: {
				display: true,
				color: ctx => {
					if (ctx.index === currentMonth) {
						return colors.blue.prussianBlue;
					}
				},
				font: (ctx => {
					const font = {
						family: fonts.nunito,
						weight: 'normal'
					};
					if (ctx.index === currentMonth) {
						font.weight = 'bold';
					}
					return { ...font };
				})

			},
		},
	},
};

const labels = ['Jan', 'Febr', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'];

export const data = {
	labels,
	datasets: [
		{
			label: '',
			data: [100, 200, 1000, 400, 900, 600, 700, 100, 200, 1000, 400, 500],
			borderColor: colors.blue.prussianBlue,
			backgroundColor: context => {
				const ctx = context.chart.ctx;
				const gradient = ctx.createLinearGradient(0, 0, 0, 150);
				gradient.addColorStop(0, 'rgba(0, 48, 81, 0.01');
				gradient.addColorStop(0.2, 'rgba(0, 48, 81, 0.2)');
				gradient.addColorStop(1, 'rgba(255,255,255,1)');
				return gradient;
			},
			fill: true,
			yAxisID: 'y',
		},
	],
};

const Line = () => {
	return <LineChart options={ options } data={ data } />;
};

export default React.memo(Line);
