import React, { useState } from 'react';
import {useNavigate} from 'react-router-dom';

import { Button, Input, Text } from 'components';
import { Images } from 'constant';

import LoginPageStyle from './style';
import {signIn} from '../../services/auth';
import { Formik, Field, Form } from "formik";
import {persist, Fetch} from '../../services/local-storage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useEffect } from 'react';

const LoginPage = () => {
  const navigate = useNavigate();
  const [payload, setPayload] = useState({
    email: '',
    password: ''
  });

  const onChangeText = (value, name) => {
    setPayload({
      ...payload,
      [name]: value
    });
  };

  const onClickLogin = () => {
    navigate('/')
    localStorage.setItem('token', )
    // dispatch(loginUser(payload));
  };
  useEffect(()=> {
    if(Fetch('token')){
      navigate('/')
    }else{
      navigate('/login')
    }
  },[])

  return (
    <LoginPageStyle>
      <div className='left-side'>
        
        <Text
            text={ 'Some Encouraging Words Goes Here' }
            fontWeight={ '700' }
            className={ 'title' }
            fontSize={ '56px' }
            color={ '#FFFBD1' }
          />
          <Text
            text={ 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida ac tempus consequat enim, sed tellus sed.' }
            fontWeight={ '300' }
            className={ 'slogan mt-6' }
            fontSize={ '20px' }
            lineHeight={ '31px' }
          />
          
      </div>
      <div className='right-side'>
        <img src={ Images.LogoCms } className='logo-cms' height={ 100 } width={ 100 } />
        <Formik
          initialValues={{ email: "", password: "" }}
          onSubmit={(values, { setSubmitting }) => {
            console.log("values====>",values)
            if(values.email){
              if(values.password){
            signIn(values)
              .then((res) => {  
                setSubmitting(false);
                if (res.data.response.user.userType == "admin" || res.data.response.user.userType =="superadmin"){
                 
                  persist("token", res.data.response.token)
                  persist("user", JSON.stringify(res.data.response.user))

                  navigate(`/`)
                }else{
                  toast("invalid entry")
                }
              })
              .catch((e) => {
                setSubmitting(false);
                console.log("e.response.data.message", e.response.data.message)
                toast(e.response.data.message)
              });
            }else{
              toast("Enter the password")
            }
            }else{
              toast("Enter Email address")
            }

          }}
        >    

        {({
          values,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          /* and other goodies */
        }) => (
          <form onSubmit={handleSubmit}>
        <Text
          text={ 'Welcome to Admin Panel,' }
          fontWeight={ '700' }
          fontType={ 'h4' }
          className={ 'mb-8' }
          textAlign={ 'center' }
        />
          <div className='login-form'>
            <Input
              label='Email'
              name='email'
              placeholder='Email'
              className='mb-5'
              onChange={ (e) => setFieldValue("email", e.value) }
            />
            <Input
              label='Password'
              placeholder='Password'
              type='password'
              name='password'
              className='mb-5'
              onChange={ (e) => setFieldValue("password", e.value) }

            />
            <a className='cursor-pointer' onClick={ () => {navigate('/forgot-password')} }>
              <Text
                text={ 'Forgot Password?' }
                fontWeight={ '800' }
                fontFamily={ 'Nunito' }
                color={ '#003051' }
                fontSize={ '16px' }
                className={ 'mb-8' }
                textAlign={ 'right' }
              />
            </a>
            <Button
              label='Login'
              width='100%'
              type="submit"
              className='bg-prussianBlue'
            />
          </div>
        </form>
        )}
        </Formik>
        {/* <ToastContainer /> */}
        </div>
          
    </LoginPageStyle>
  );
};

export default React.memo(LoginPage);
