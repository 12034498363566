import React from 'react';
import ButtonStyle from './style';
const Button = ({
  children,
  label = 'Submit',
  theme = 'primary',
  type = 'button',
  width = '200px',
  className,
  disabled = false,
  onClick,
  backgroundColor = '',
  textColor,
  height = '60px',
  bgHover,
  textColorHover
}) => {
  return (
    <ButtonStyle
      theme={ theme }
      className={ className }
      type={ type }
      onClick={ () => onClick && onClick() }
      width={ width }
      disabled={ disabled }
      backgroundColor={ backgroundColor }
      textColor={ textColor }
      height={ height }
      bgHover={ bgHover }
      textColorHover={ textColorHover }
    >{ children || label }</ButtonStyle>
  );
};

export default React.memo(Button);