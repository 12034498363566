import LogoCircle from '../assets/images/logo.svg';
import BurgerMenu from '../assets/images/burger-menu.svg';
import Close from '../assets/images/close.svg';
import CheckMark from '../assets/images/check-mark.svg';
import TextLogo from '../assets/images/OG-Logo.svg';
import Rectangle from '../assets/images/Rectangle.png';
import Profile from '../assets/images/profile.png';
import Setting from '../assets/images/setting.png';
import Share from '../assets/images/share.png';
import TransactionOut from '../assets/images/out.png';
import TransactionIn from '../assets/images/in.png';
import LogoCms from '../assets/images/logo_cms.png';
import LogoRestaurant from '../assets/images/logo_restaurant.png';
import LoginBg from '../assets/images/login.png';
import Verification from '../assets/images/verification.png';
import Sent from '../assets/icons/sent.svg';
import SpringIllu from '../assets/icons/spring.svg';
import SummerIllu from '../assets/icons/summer.svg';
import GordonIllu from '../assets/images/gordon.png';
import FallIllu from '../assets/images/fall.png';
import WinterIllu from '../assets/images/winter.png';
import CollectionIllu from '../assets/icons/collections.svg';
import Deactivate from '../assets/images/deactivate.svg';
import USD from '../assets/images/Us-dollar.svg';
import USDC from '../assets/images/Usdollar.svg';
import USDT from '../assets/images/Usdt.svg';
import MATIC from '../assets/images/matic.svg';

const Images = {
	TextLogo,
	LogoCircle,
	Close,
	BurgerMenu,
	CheckMark,
	Rectangle,
	Profile,
	Setting,
	Share,
	TransactionOut,
	TransactionIn,
	LogoCms,
	LogoRestaurant,
	LoginBg,
	Verification,
	Sent,
	Sent,
	SpringIllu,
	SummerIllu,
	GordonIllu,
	FallIllu,
	WinterIllu,
	CollectionIllu,
	Deactivate,
	USD,
	USDC,
	USDT,
	MATIC
};

export default Images;
