import React, { useState } from 'react';
import {useNavigate} from 'react-router-dom';
import { Button, Input, Text, Modal } from 'components';
import { Images } from 'constant';
import {verifyOtp,sendVerificationCode} from '../../../../services/auth/index';

import { toast } from 'react-toastify';
const ModalInputCode = ({ visible, onClose, email }) => {
	const navigate = useNavigate();
	const [otp, setOtp] = useState('');
	const resetPassword = async() =>{
		const values = {email: email, otp:otp};
		const res = await verifyOtp(values);
        console.log("res===>",res)
		if(res.data.status){
			toast(res.data.message)
			navigate(`/create-new-password/${res.data.result._id}`);
		}else{
			toast(res.data.message)
		}
		
	}

	const handlesubmit = async()=>{
        const values = {email:email}
        const res = await sendVerificationCode(values);
        if(res.data.status){
            toast("OTP Resend Successfully")
        }else{
            toast("User not registered");
        }
    }
	return (
		<>
			<Modal
				visible={ visible }
				onClickClose={ () => onClose() }
			>
				<div className='modal-input-container'>
					<img src={ Images.Verification } className='mx-auto mb-10' />
					<Text
						fontType='h4'
						fontFamily='Lora'
						fontWeight='700'
						fontSize='24px'
						text='Input Verification Code'
						textAlign='center'
						color='var(--blue)'
						className='mb-3 join-modal-title'
					/>

					<Text
						text='Verification code has been sent to your Email.'
						color='var(--black200)'
						textAlign='center'
						className='mb-8'
					/>
					<Input
						name='code'
						// value={ payload.code }
						className='mb-8'
						onChange={ (e)=> setOtp(e.value) }
						autofocus
						placeholder='----'
					/>
				</div>
				<div className='btn-submit mx-auto flex justify-center'>
					<div>
						<Button
							className=''
							label='Resend'
							height={ '40px' }
							width={ '185px' }
							onClick={ ()=> handlesubmit() }
							textColor={ '#003051' }
							backgroundColor={ '#E3E0E0' }
						/>
					</div>
					<div>
						<Button
							className=''
							label='Submit'
							height={ '40px' }
							width={ '185px' }
							textColor={ '#003051' }
							backgroundColor={ '#E3E0E0' }
							onClick={ () => { resetPassword() } }
						/>
					</div>
				</div>
			</Modal>
		</>
	);
};

export default React.memo(ModalInputCode);
