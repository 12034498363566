import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { Icons, Colors } from 'constant';
import { Container, ModalWrapper, StepWrapper } from './style';
import { Menu } from '@headlessui/react';
import {
	Tabs, Dropdown, TextField, Button, Modal, Form, Action, SelectMenu, Text,UploadImage
} from 'components';
import { navigation } from 'helpers';
import NFTCard from 'components/Card/NFTCard';
import { collectionListSelector } from 'store/collection/selector';
import { PlusIcon } from '@heroicons/react/solid';
import moment from 'moment';
import colors from 'constant/colors';
import { useNavigate } from 'react-router-dom';
import {listCollections, getallNfts, deleteNftCollections, addNfts} from '../../services/restaurant';
import {Fetch, persist} from '../../services/local-storage';
import { NFTCollection } from 'pages/MyProfile/Sections';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WalletStyles from './styles';
import group27 from  '../../assets/images/Group27.svg';
import group28 from  '../../assets/images/Group28.svg';
import group29 from  '../../assets/images/Group29.svg';
import loadiing from  '../../assets/images/loading.gif';
import abi from "../../contractsABI/TempleteABI.json";
import {
	Aside, Layout, Content, MenuItem, Wrapper, IconWrapper
  } from './editstyles';
import {EditCollections, getRestaurantsByName} from '../../services/restaurant';
import axios from 'axios';
import { selectRestaurant } from 'store/restaurant/actions';
import {addNetwork} from '../../store/walletconnection/WalletAction';
const options = [
	{
		label: 'Filter 1',
		value: 'filter1'
	},
	{
		label: 'Arlene Mccoy',
		value: 'arlenemerloy'
	},
	{
		label: 'Devon Webb',
		value: 'devonwebb'
	},
	{
		label: 'Tom Cook',
		value: 'tomcook'
	},
	{
		label: 'Tanya Fox',
		value: 'tanyafoox'
	},
	{
		label: 'Hellen Schmidt',
		value: 'hellenschmidt'
	},
];
const DetailCollection = () => {
	const navigates= useNavigate();
	const CurrentUser = JSON.parse(Fetch("user"));
	const search = window.location.search; // could be '?foo=bar'
	const params = new URLSearchParams(search);
	const owner = params.get('restaurant');
	const tier_ = params.get('collection');
	const [Restaurant_, setRestaurant] = useState('');
	const [deletemodal,setDeletemodal] = useState(false);
	const [Collection, setCollection]=useState({});
	const [Nfts, setNfts]=useState([]);
	const [oldNfts, setOldNfts]=useState([]);
	const wallet = useSelector((state)=> state.WalletConnect)
	const [minted, setMinted] = useState('');
	const [pre_minted, setPreMinted] = useState('');
	const [editModal, setEditModal] = useState(false);
	const [maticToUsd, setMatictousd] = useState('');
	const [premintednfts,setPremintedNfts] = useState([]);
	const [mintednfts,setMintedNfts] = useState([]);
	const getmatictoUsd = async() => {
	  const matictousd = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd")
	  
	  setMatictousd(matictousd.data['matic-network'].usd)
	}
	const getlistofcollections = async() => {
		const res = await listCollections();
		const value= res.data.result;
		const filtercollection= value.filter((item) => item.OwnedBy== owner && item.NFTName == tier_);
		

		setCollection(filtercollection[0]);
	}
	
	const getnfts=async()=>{
		const res= await getallNfts();
		const values = res.data.result;
	
		const filtered_nfts = values.filter((item) => item.OwnedBy == owner && item.CollectionName == tier_)
	
		setNfts(filtered_nfts);
		setPremintedNfts(filtered_nfts.filter((item) => item.status !='sold'))
		setMintedNfts(filtered_nfts.filter((item) => item.status =='sold'))
		setOldNfts(filtered_nfts);
		setMinted((filtered_nfts.filter((item) => item.status =='sold')).length)
		setPreMinted((filtered_nfts.filter((item) => item.status !='sold')).length)
	}
	const getRestaurantsByName_ = async() =>{
		const tokens = Fetch('tokens')
		const values = {
			restaurantName: owner,
		}
		const res = await getRestaurantsByName(values, tokens)
		const rest_ = res.data.result;
		setRestaurant(rest_)
	}

	const deleteCollections = async() => {
		
		const values = {
			OwnedBy: Collection.OwnedBy,
			tier:Collection.NFTCollections
		}
		
		try{
			const _delete= await deleteNftCollections(values)
			if(_delete.data.status){
				console.log("delete:", _delete)
				// setDeletemodal(false);
				// getlistofcollections();
			}else[
				toast("Cannot delete this collection")
			]
			
		}catch(err){
			console.log("catcherror:", err)
		}
		
	}
	const handlenftsearch = (e) => {
		
		if (e != '') {
		  const values =oldNfts && oldNfts.filter((res) => res.NFTName.toLowerCase().includes(e))
		
		  setNfts(values);
		}
		else {
		  // setRestaurants(Restaurants1)
		  setNfts(oldNfts);
		}
	
	  }

	const initialValues = [
		[
			{ name: 'name', value: '' },
			{ name: 'image', value: '' },
			{ name: 'tier', value: '' },
			{ name: 'description', value: '' },
		],
		[
			{ name: 'price', value: '' },
			{ name: 'quantity', value: '' }
		],
		[
			{ name: 'releaseDate', value: '' },
			{ name: 'finishDate', value: '' }
		]
	];
	const navigate = navigation();
	const id = navigate.pathname.split('/')[2];
	const [activeTabIndex, setActiveTabIndex] = useState(0);
	const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
	const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
	const [modalVisible, setModalVisible] = useState('INIT');
	const [step, setStep] = useState(0);
	const [filter, setFilter] = useState({
		filter1: '',
		filter2: '',
		search: ''
	});
	const [checked, setChecked] = useState(0);
	const [fields, setFields] = useState([...initialValues]);

	const { collections } = useSelector(collectionListSelector);

	const handleFilter = useCallback(
	  event => {
			const { name, value } = event.target;
			setFilter({
				...filter,
				[name]: value
			});
	  },
	  [filter],
	);

	const factoryCollection = useMemo(() => {
		const result = {
			photo: '',
			name: '',
			restaurantList: [],
			nftList: [],
			releaseDate: '',
			finishDate: '',
			nftListDetail: [],
			nftPerminted: [],
			nftMinted: [],
			activeTabIndex: activeTabIndex

		};
		if (collections.length) {
			const filteredCollection = collections.find(item => String(item.id) === id);
			result.photo = filteredCollection.photo;
			result.name = filteredCollection.name;
			result.restaurantList = filteredCollection.restaurantList;
			result.nftList = filteredCollection.nftList;
			result.releaseDate = filteredCollection.releaseDate;
			result.finishDate = filteredCollection.finishDate;
			result.nftListDetail = filteredCollection.nftListDetail.filter(item => item.name.includes(filter.search));
			result.nftPerminted = result.nftListDetail.filter(item => item.perminted);
			result.nftMinted = result.nftListDetail.filter(item => !item.perminted);
		}

		return result;
	}, [id, collections, filter, activeTabIndex]);

	const modalFactory = useMemo(() => {
		const modalData = {
			title: 'Add',
			onSubmit: data => console.log(data),
			visible: modalVisible !== 'INIT'
		};
		if (modalVisible === 'EDIT') {
			modalData.title = 'Edit';
		}
		return modalData;
	}, [modalVisible]);

	const renderNftCard = useCallback(() => {
		const listNft = factoryCollection.activeTabIndex === 0 ? factoryCollection.nftPerminted : factoryCollection.nftMinted;
		if(activeTabIndex ==0 ){
			return premintednfts.map((item,i) => {
				return (
					<NFTCard
						key={ i }
						title={ item?.NFTName }
						image={item?.NFTImage}
						type={item?.NFTCollections}
						restoName={ item?.OwnedBy }
						price={ (Number(item?.NFTPrice)/ maticToUsd).toFixed(2) }
						wrapperClass='w-full'
						isPerminted={ item.status == "sold" ? false : true}
					/>
				);
			});
		}else{
			return mintednfts.map((item,i) => {
				return (
					<NFTCard
						key={ i }
						title={ item?.NFTName }
						image={item?.NFTImage}
						type={item?.NFTCollections}
						restoName={ item?.OwnedBy }
						price={ (Number(item?.NFTPrice)/ maticToUsd).toFixed(2) }
						wrapperClass='w-full'
						isPerminted={ item.status == "sold" ? false : true}
					/>
				);
			});
		}
		
	}, [navigate, factoryCollection]);

	const onChangeFields = useCallback(
		e => {
			const { name, value } = e.target;
			const newFields = [...fields];
			const currentField = newFields[step];
			const indexField = currentField.findIndex(item => item.name === name);
			currentField[indexField].value = value;
			newFields[step] = currentField;
			setFields(newFields);
		},
		[fields, step, setFields],
	);

	const resetState = () => {
    	setModalVisible('INIT');
    	setFields(initialValues);
    	setStep(0);
    	setChecked(0);
	};

	const [addnftname, setAddNftName] = useState('');
	const [addnftimage, setAddNftImage] = useState('');
	const [addnftDescription, setAddNftDescription] = useState('');
	const [addnftTier, setAddNftTier] = useState('');
	const [addnftPrice, setAddNftPrice] = useState('');
	const [addnftCount, setAddNftCount] = useState('1');
	const [addNftBenefits, setAddNftBenefits] = useState('');
	const [addNftRoyalty, setAddNftRoyalty] = useState('');

	const [addnftOwnedBy, setAddNftOwnedBy] = useState('');
	const [addnftReleaseDate, setAddNftReleaseDate] = useState("");
	const [addnftEndDate, setAddNftEndDate] = useState("");
	const [metamaskStatus, setMetamaskStatus] = useState(false);
	const [contractStatus, setContractStatus] = useState(false);
	const [completionStatus, setCompletionStatus] = useState(false);
	const [openLoader,setOpenLoader] = useState(false);
	const tokens = Fetch('token');

	//edit states

	const [editnftname, setEditNftName] = useState('');
	const [editnftimage, setEditNftImage] = useState('');
	const [editnftDescription, setEditNftDescription] = useState('');
	const [editnftTier, setEditNftTier] = useState('');
	const [editnftPrice, setEditNftPrice] = useState('');
	const [editnftCount, setEditNftCount] = useState('');
	const [editNftBenefits, setEditNftBenefits] = useState('');
	const [editnftRoyalty, setEditNftRoyalty] = useState('');
	const [editnftOwnedBy, setEditNftOwnedBy] = useState('');
	const [editnftReleaseDate, setEditNftReleaseDate] = useState('');
	const [editnftEndDate, setEditNftEndDate] = useState('');

	const handleaddCollectionContract = async() =>{
	
		setModalVisible('INIT');
		const values = {
			OwnedBy: Collection && Collection.OwnedBy,
			NFTImage: addnftimage ? addnftimage : Collection.NFTImage,
			NFTName: addnftname ? addnftname : Collection.NFTName,
			NFTDescription: addnftDescription ? addnftDescription :Collection.NFTDescription ,
			NFTPrice: addnftPrice ? addnftPrice : Collection.NFTPrice,
			NFTCount: addnftCount ? addnftCount : Collection.NFTCount,
			benefits:addNftBenefits ? addNftBenefits : Collection.benefits,
			NFTCollections: Collection && Collection.NFTCollections,
			NFTReleaseDate: Collection.NFTReleaseDate ? Collection.NFTReleaseDate : addnftReleaseDate,
			NFTEndDate: Collection.NFTEndDate ? Collection.NFTEndDate: addnftEndDate,
			// NFTRoyalty: 
		}
		
		const {web3, marketplace, address} = wallet;

		if(wallet.address){
		  if(values.NFTName && values.NFTCount && values.NFTCollections && values.NFTDescription && 
			values.NFTImage && values.NFTReleaseDate && values.NFTEndDate && values.OwnedBy){
	
			const {web3, marketplace, address} = wallet;
			setMetamaskStatus(true);
			setOpenLoader(true)
			
			setMetamaskStatus(false);
			setContractStatus(true);
			
			const getAddress= await  marketplace.methods.GetRestaurantAddress(Collection && Collection.OwnedBy).call();
			
			var nftContracts = new web3.eth.Contract(
			  abi,
			  getAddress
			);
			
			
			// const addcollection_= await nftContracts.methods._increaseShares(Collection && Collection.NFTCollections,addnftCount).send({from:address});
			const addcollection_= await marketplace.methods._increaseShares(getAddress,Collection && Collection.NFTCollections,addnftCount).send({from:address});
			// if(addcollection_.status){
			  setContractStatus(false);
			  handleSubmitAdd()
			  
			// }else{
			//   toast("Failed to add collection")
			// }
		  }else{
			toast("fill all data")
		  }
		}else{
		  toast("Connect wallet")
		}
		
	  }
	const handleSubmitAdd = async() =>{
		const price_ = Number(Collection.NFTPrice).toFixed(2)
		const values = {
			OwnedBy: Collection && Collection.OwnedBy,
			NFTImage: addnftimage ? addnftimage : Collection.NFTImage,
			NFTName: addnftname ? addnftname : Collection.NFTName,
			NFTDescription: addnftDescription ? addnftDescription : Collection.NFTDescription,
			NFTPrice: price_,
			NFTCount: addnftCount,
			benefits: Collection.benefits ? Collection.benefits :addNftBenefits,
			NFTCollections: Collection && Collection.NFTCollections,
			NFTReleaseDate: Collection.NFTReleaseDate ? Collection.NFTReleaseDate : addnftReleaseDate,
			NFTEndDate: Collection.NFTEndDate ? Collection.NFTEndDate: addnftEndDate,
			NFTRoyalty: Collection.NFTRoyalty
		}
		let arr = [];
        
		for (let i = 0; i < values.NFTCount; i++){	
		const nftname= values.NFTName+" - "+ (Nfts.length+(i+1));
		const value= {
			NFTName: nftname,
			NFTDescription : values.NFTDescription,
			NFTPrice: values.NFTPrice,
			NFTCount: "1",
			NFTCollections : values.NFTCollections,
			OwnedBy: values.OwnedBy,
			NFTEndDate: values.NFTEndDate,
			NFTReleaseDate: values.NFTReleaseDate,
			NFTBenefits: values.benefits,
			NFTImage: values.NFTImage,
			CollectionName: values.NFTName,
			NFTRoyalty: values.NFTRoyalty
		}
	
		arr.push(value);
		}
	
		const result = await addNfts(arr, tokens);
		
			setOpenLoader(false);
			getnfts();
			toast("NFt added successfully")
			setModalVisible('INIT');
			setStep(0)
		
		
	}
	const handleNFTImage = async(e) => {
		setAddNftImage(e.target.value)
	}
	const handleEditCollectionContract = async() =>{
		if(window.ethereum.networkVersion !== '137'){
			toast("Change to Polygon network")
		}else{
			const tokens =Fetch('token');
			const price = editnftPrice ? editnftPrice : Collection.NFTPrice;
			const tomatic =  Number(price).toFixed(2) ;
			const values = {
				OwnedBy: Collection && Collection.OwnedBy,
				NFTImage: editnftimage ? editnftimage : Collection.NFTImage ,
				NFTName: editnftname ? editnftname : Collection.NFTName,
				NFTDescription: editnftDescription ? editnftDescription : Collection.NFTDescription,
				NFTPrice: tomatic,
				NFTCount: editnftCount ? editnftCount : Collection.NFTCount,
				benefits:editNftBenefits ? editNftBenefits : Collection.benefits,
				NFTCollections: Collection && Collection.NFTCollections,
				NFTReleaseDate:editnftReleaseDate ? editnftReleaseDate : Collection.NFTReleaseDate,
				NFTEndDate:editnftEndDate ? editnftEndDate : Collection.NFTEndDate,
				NFTRoyalty: Collection.NFTRoyalty
			}
			
		
			if(wallet.address){
				
			if(values.NFTName && values.NFTImage && values.NFTEndDate && values.NFTReleaseDate && 
				values.benefits && values.NFTPrice && values.NFTDescription && values.OwnedBy){
		
				const {web3, marketplace, address} = wallet;
				setMetamaskStatus(true);
				setOpenLoader(true)
				
				setMetamaskStatus(false);
				setContractStatus(true);
					
				const getAddress= await  marketplace.methods.GetRestaurantAddress(values.OwnedBy).call();

				var nftContracts = new web3.eth.Contract(
				abi,
				getAddress
				);

				const getGold=await nftContracts.methods.GoldCollections().call();
				const getSilver=await nftContracts.methods.SilverCollections().call();
				const getPlatinum=await nftContracts.methods.PlatinumCollection().call();

				const Collections = Restaurant_.Collections;
				let collection_1 = 0;
				let collection_2 = 0;
				let collection_3 = 0;
				for(let i=0; i<Collections.length; i++){
					if(Collections[i].NFTCollections == "Gold" ){
					  collection_2 = collection_2 + Number(Collections[i].NFTCount);
					}else if(Collections[i].NFTCollections == "Silver" ){
					  collection_1 = collection_1 + Number(Collections[i].NFTCount);
					}else if(Collections[i].NFTCollections == "Platinum" ){
					  collection_3 = collection_3 + Number(Collections[i].NFTCount);
					}
				  }
				if(values.NFTCollections == "Gold"){
					try{
						const addcollection_= await marketplace.methods.editCollections(getAddress,values.OwnedBy,getSilver,collection_2,getPlatinum).send({from:address});
						if(addcollection_.status){
							setContractStatus(false);
							EditCollections_();
						}else{
							setContractStatus(false);
							setOpenLoader(false)
							toast("Connecting with contract failed")
						}
					}catch(err){
						toast("Buying NFT was cancelled")
					}
				}else if(values.NFTCollections == "Silver"){
					// const addcollection_= await marketplace.methods.editCollections(getAddress,values.OwnedBy,values.NFTCount,getGold,getPlatinum).send({from:address});
					try{
						const addcollection_= await marketplace.methods.editCollections(getAddress,values.OwnedBy,getSilver,collection_1,getPlatinum).send({from:address});
						if(addcollection_.status){
							setContractStatus(false);
							EditCollections_();
						}else{
							setContractStatus(false);
							setOpenLoader(false)
							toast("Connecting with contract failed")
						}
					}catch(err){
						toast("Buying NFT was cancelled")
					}
				}else if(values.NFTCollections == "Platinum"){
					// const addcollection_= await marketplace.methods.editCollections(getAddress,values.OwnedBy,getSilver,getGold,values.NFTCount).send({from:address});
					try{
						const addcollection_= await marketplace.methods.editCollections(getAddress,values.OwnedBy,getSilver,getGold,collection_3).send({from:address});
						if(addcollection_.status){
							setContractStatus(false);
							EditCollections_();
						}else{
							setContractStatus(false);
							setOpenLoader(false)
							toast("Connecting with contract failed")
						}
					}catch(err){
						toast("Buying NFT was cancelled")
					}
				}
				else{
					toast("failed")
				}
				}else{
					toast("Fill all the Fields")
				}
			}else{
				toast("connect wallet")
			}
		}
	}
	
	const EditCollections_ = async()=>{
		setCompletionStatus(true);
		const tokens =Fetch('token');
		const price = editnftPrice ? editnftPrice : Collection.NFTPrice;
		const tomatic =  Number(price).toFixed(2) ;
		const values = {
			OwnedBy: Collection && Collection.OwnedBy,
			NFTImage: editnftimage ? editnftimage : Collection && Collection.NFTImage ,
			NFTName: editnftname ? editnftname : Collection && Collection.NFTName,
			NFTDescription: editnftDescription ? editnftDescription : Collection && Collection.NFTDescription,
			NFTPrice: tomatic,
			NFTCount: editnftCount ? editnftCount :  Collection.NFTCount,
			benefits:editNftBenefits ? editNftBenefits : Collection && Collection.benefits,
			NFTCollections: Collection && Collection.NFTCollections,
			NFTReleaseDate:editnftReleaseDate ? editnftReleaseDate.toLocaleString() : Collection && Collection.NFTReleaseDate,
			NFTEndDate:editnftEndDate ? editnftEndDate.toLocaleString() : Collection && Collection.NFTEndDate,
			CollectionName:editnftname ? editnftname : Collection && Collection.NFTName,
			NFTRoyalty: editnftRoyalty ? editnftRoyalty : Collection.NFTRoyalty,
		}
	
		let arr = [];
    
		for (let i = 0; i < values.NFTCount; i++){
		const nftname= values.NFTName+" - "+ (i+1);
		const value= {
			NFTName: nftname,
			NFTDescription : values.NFTDescription,
			NFTPrice: values.NFTPrice,
			NFTCount: "1",
			NFTCollections : values.NFTCollections,
			OwnedBy: values.OwnedBy,
			NFTEndDate: values.NFTEndDate,
			NFTReleaseDate: values.NFTReleaseDate,
			NFTBenefits: values.benefits,
			NFTImage: values.NFTImage,
			CollectionName: values.CollectionName,
		}
		
		arr.push(value);
		}
	
		const data1 = {array:arr, newcollection:values}
		const res = await EditCollections(data1, tokens)
		if(res.data.status){
			setCompletionStatus(false);
			getlistofcollections();
			getnfts();
			setOpenLoader(false);
			setEditModal(false)
			toast("Editing collection success")
		}else{
			toast("Editing collection Failed")
		}
	}
 
	const renderContent = useMemo(() => {
		return (
			<div className='px-5 sm:px-0'>
				<div className='grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-x-[20px] gap-y-[20px] mt-5'>
					{ renderNftCard() }
				</div>
			</div>
		);
	}, [renderNftCard]);

	const renderFilter = useMemo(() => {
		return (
			<div className='border rounded-md'>
				<div id='filter' className='border-b p-[20px] items-center grid grid-cols-2'>
					<div className='flex items-center'>
						{/* <div className='flex items-center'>
							<Dropdown className='mr-[20px] w-[200px] h-[44px]' menuClassname='w-[200px] z-[99]' options={ options } />
							<Dropdown className='w-[200px] h-[44px]' menuClassname='w-[200px] z-[99]' options={ options } />
						</div> */}
					</div>
					<div className='flex items-center'>
						<TextField
							className='search-input w-[360px] border mr-[20px] h-[44px] rounded-lg focus:outline-none focus:border-prussianBlue'
							placeholder={ 'Search NFT' }
							name='search'
							// value={ filter.search }
							onChange={ (e) => handlenftsearch (e.target.value)}
							 />
						{CurrentUser?.userType == "superadmin" ? 
						<Button theme='primary' width='200px' height='44px' className='bg-prussianBlue' onClick={ () =>{
							if(wallet.address){
								setModalVisible('ADD') 
							}else{
								toast("Connect wallet")
							}
							}}>
							<div className='flex items-center space-x-2 justify-center'>
								<PlusIcon className='h-4 w-4 m-0 inline' aria-hidden='true' />
								<span>Add NFT</span>
							</div>
						</Button>
						:null
						}
					</div>
				</div>
				{/* <ToastContainer /> */}
			</div>
		);
	}, [filter, handleFilter, handlenftsearch]);

	const renderStep = useMemo(() => {
		const isEmpty = index => fields[index].find(item => item.value === '');
		const fontStyle = index => step === index ?  'font-extrabold text-prussianBlue' : 'text-darkGrey';
		const titleBold = index => isEmpty(index) ? '' : 'font-extrabold';
		const circleStyle =  index => step === index ? {
			title: 'text-prussianBlue',
			circle: 'border-prussianBlue bg-blueLighter'
		} : isEmpty(index) ? {
			title: 'text-darkGrey',
			circle: 'bg-white'
		} : { title: 'text-white', circle: 'bg-prussianBlue' };
		const arr = ['NFT Content', 'Financial Criteria', 'Release Date'];
		return (
			<StepWrapper className='flex justify-center mt-5'>
				<div className='flex items-center pb-10'>
					{
						arr.map((item, index) => {
							return (
								<React.Fragment key={ index }>
									{ index === 2 &&
                    <div>
                    	<hr className={ `w-[106px] border-dashed ${step === initialValues.length - 1 ? 'border-prussianBlue' : ''}` } />
                    </div>
									}
									<div className='relative'>
										<div className={ `border w-[40px] h-[40px] rounded-full flex justify-center items-center ${circleStyle(index).circle}` } key={ item }>
											<span className={ `font-bold ${circleStyle(index).title}` }>{ index + 1 }</span>
										</div>
										<div className={ `absolute w-[134px] min-w-[100px] left-[-30px] bottom-[-30px] ${item.replace(/\s/g, '')}` }>
											<p className={ `${fontStyle(index)} ${titleBold(index)}` }>{ item }</p>
										</div>
									</div>

									{ index === 0 &&
                    <div>
                    	<hr className={ `w-[106px] border-dashed ${step > 0  ? 'border-prussianBlue' : ''}` } />
                    </div>
									}
								</React.Fragment>
							);
						})
					}
				</div>
			</StepWrapper>
		);
	}, [step, fields]);

	const renderFields = useMemo(() => {
		const pickvalue = name =>  fields[step].find(item => item.name === name);
		if (step === 0) {
			return (
				<React.Fragment>
					<Form.FormGroup className='input-wrapper'>
						<Form.Label htmlFor='name' className='mb-2 text-darkGrey text-sm'>NFT Name</Form.Label>
						<Form.TextField
							id='name'
							name='name'
							placeholder='Name'
							disabled={true}
							className='input-name border rounded-md outline-prussianBlue'
							noInlineError
							onChange={ (e) => setAddNftName(e.target.value) }
							defaultValue={Collection.NFTName}
						/>
					</Form.FormGroup>
					<Form.FormGroup className='mt-2 input-wrapper'>
						<Form.Label htmlFor='image' className='mb-2 text-darkGrey text-sm'>Image</Form.Label>
						<img
							id='image'
							name='image'
							placeholder='Name'
							className='input-name border rounded-md outline-prussianBlue'
							title='Image'
							style={{width:'100%',height:'220px'}}
							src={Collection ?  `${process.env.REACT_APP_S3_URL}${Collection.NFTImage}` : ""}
							// source={ pickvalue('image').value Collection.NFTImage}
							onChange={ (e) => handleNFTImage(e) }
						/>
					</Form.FormGroup>
					<Form.FormGroup className='mt-3 input-wrapper'>
						<Form.Label htmlFor='tier' className='mb-2 text-darkGrey text-sm'>Tier</Form.Label>
						<Form.TextField
							id='tier'
							name='tier'
							value={Collection && Collection.NFTCollections}
							className='input-name border rounded-md outline-prussianBlue'
							// options={ options }
							disabled={true}
							menuClassname='h-[90px] z-20'
							// onChange={ onChangeFields }
						/>
					</Form.FormGroup>
					<Form.FormGroup className='mt-3 input-wrapper'>
						<Form.Label htmlFor='description' className='mb-2 text-darkGrey text-sm'>Description</Form.Label>
						<Form.TextArea
							id='description'
							name='description'
							placeholder='Description'
							className='h-[80px] border rounded-md resize-none p-2.5'
							disabled={true}
							defaultValue={ Collection ?  Collection.NFTDescription :""}
							onChange={ (e) => {setAddNftDescription(e.target.value); Collection.NFTDescription = e.target.value}}

						/>
					</Form.FormGroup>
				</React.Fragment>
			);
		} else if (step === 1) {
			return (
				<React.Fragment>
						<TextField
							id='benefits'
							name='benefits'
							placeholder='benefits'
							disabled={true}
							className='input-name border rounded-md outline-prussianBlue w-full'
							noInlineError
							onChange={ (e) => setAddNftBenefits(e.target.value) }
							defaultValue={ Collection ?  Collection.benefits :""}
						/>
					<Form.FormGroup className='input-wrapper'>
						<Form.Label htmlFor='price' className='mb-2 text-darkGrey text-sm'>Price</Form.Label>
						<Form.TextField
							id='price'
							name='price'
							type="number"
							disabled={true}
							placeholder='Price in Dollar/NFT'
							className='input-amount border rounded-md outline-prussianBlue'
							noInlineError
							defaultValue={ Collection ?  Collection.NFTPrice :""}
							onChange={ (e) => setAddNftPrice(e.target.value) }

						/>
					</Form.FormGroup>
					<div className='mt-3'>
						<span className='mb-2 text-darkGrey text-sm'>Quantity</span>
						<div className='flex w-60 justify-between items-center mt-3'>
							<label htmlFor='one' className='cursor-pointer flex items-center container-label'>
								<input
									name='one'
									id='one'
									type='radio'
									className='w-5 h-5 rounded-full border mr-2.5'
									checked={ checked === 0 }
								/>
								<span className='text-base'>1</span>
								<span className='checkmark' onClick={ () => {
									const event = {
										target: {
											name: 'quantity',
											value: 1
										}
									};
									setAddNftCount('1');
									setChecked(0);
								} } />
							</label>
							<label htmlFor='multi' className='cursor-pointer flex items-center container-label'>
								<input
									name='multi'
									id='one'
									type='radio'
									className='w-5 h-5 rounded-full border mr-2.5'
									checked={ checked === 1 }
								/>
								<span className='text-base'>Multiple</span>
								<span className='checkmark'onClick={ () => {
									const event = {
										target: {
											name: 'quantity',
											value: 100
										}
									};
									onChangeFields(event);
									setChecked(1);
								} } />
							</label>
						</div>
					</div>
					<Form.FormGroup className='mt-3 input-wrapper' >
						<Form.TextField
							id='quantity'
							name='quantity'
							className='input-name border rounded-md outline-prussianBlue'
							noInlineError
							disabled={ checked === 0 }
							// value={ pickvalue('quantity').value }
							onChange={ (e) => setAddNftCount(e.target.value) }
						/>
					</Form.FormGroup>
				</React.Fragment>
			);
		}
		return (
			<div className='grid grid-cols-2 gap-3'>
				<Form.FormGroup className='input-wrapper mt-4'>
					<Form.Label className='text-darkGrey'>Release Date</Form.Label>
					<input
						id='releaseDate'
						type="date"
						name='releaseDate'
						disabled={true}
						defaultValue={Collection.NFTReleaseDate} 
						className='input-datepicker border rounded-md mt-2'
						onChange={ (e) => {setAddNftReleaseDate(e.target.value) }}
					/>
				</Form.FormGroup>
				<Form.FormGroup className='input-wrapper mt-4'>
					<Form.Label className='text-darkGrey'>Finish Date</Form.Label>
					<input
						id='finishDate'
						name='finishDate'
						type="datetime-local"
						disabled={true}
						defaultValue={Collection.NFTEndDate} 
						className='input-datepicker border rounded-md mt-2'
						onChange={ (e) => setAddNftEndDate(e.target.value) }
					/>
				</Form.FormGroup>
			</div>
		);
    
	}, [fields, step, onChangeFields, checked, Collection]);

	const renderButton = useMemo(() => {
		return (
			<div className='flex justify-between items-center space-x-2 mt-10' >
				{ step > 0 ? <Button
					label='Previous'
					width='126px'
					height='44px'
					backgroundColor={ colors.grey.lighter }
					textColor={ colors.blue.prussianBlue }
					onClick={ () => {
						if (step > 0) {
							setStep(step - 1);
						}
					} }
				/> : <div/> }
				<Button
					label={step < 2 ? 'Next' : "Submit"}
					width='126px'
					height='44px'
					backgroundColor={ colors.grey.lighter }
					textColor={ colors.blue.prussianBlue }
					onClick={ () => {
						if (step < initialValues.length - 1) {
							if(step == 0){
								setStep(step + 1);
								
							}else{
								setStep(step + 1);
							}
							
						}else if(step == 2 ){
							handleaddCollectionContract();
						}
					} }
				/>
			</div>
		);
	}, [step]);

	const renderModalContent = useMemo(() => {
		return (
			<div>
				{ renderStep }
				<Form>
					<ModalWrapper className='mt-3'>
						{ renderFields }
					</ModalWrapper>
					{ renderButton }
				</Form>
			</div>
		);
	}, [renderStep, renderFields, renderButton,]);

	const tabsData = useMemo(() => {
		return [`Preminted (${ pre_minted && pre_minted})`, `Minted (${minted && minted})`];
	}, [pre_minted, minted]);
	const renderPopMoreIcon = (setDeletemodal) => {
	
		return (
		  <>
		  
		  <SelectMenu
			MenuButton={<Icons.MoreIcon />}
			wrapperClassName='mr-0'
			menuItemsClassName='z-[9] w-56'
			menuBtnClassName='bg-blueLighter p-3 rounded cursor-pointer scale-[0.75]'
		  >
			<div className='z-10'>
			  <Menu.Item>
				{( { active }) => (
				  <Text text="Edit" className='py-3 pl-3 hover:bg-gray-400 cursor-pointer' onClick = {() =>{
					if(wallet.address){
						setEditModal(true)
					}else{
						toast("connect wallet")
					}
					}}/>
				)}
			  </Menu.Item>
			</div>
			<div className='z-10'>
			  <Menu.Item>
				{({ active }) => (
				  <Text text='Delete' className='py-3 pl-3 hover:bg-gray-400 cursor-pointer' onClick={() => {setDeletemodal(true)}} />
				)}
			  </Menu.Item>
			</div>
	  
		  </SelectMenu>
		  </>
		)
	  }
	useEffect(() => {
		if (filter.search) {
			const perminted = factoryCollection.nftPerminted.length;
			const minted = factoryCollection.nftMinted.length;
			if (!perminted) {
				setActiveTabIndex(1);
			} else if (!minted) {
				setActiveTabIndex(0);
			}
		}
	}, [factoryCollection, filter]);
	useEffect(() => {
		getmatictoUsd();
		getlistofcollections();
		getnfts();
		getRestaurantsByName_();
	},[])

	return (
		<Container>
			<div className='h-[100px] rounded-md border px-5 pt-3 flex items-center'>
				<div className='flex items-center w-[73%]'>
					<ul className='flex items-center w-[100%] justify-between' >
						<li className='flex items-center'>
							<div className='flex w-[60px] h-[60px] rounded-full bg-blueLighter relative overflow-hidden' >
								<img src={`${process.env.REACT_APP_S3_URL}${Collection && Collection.NFTImage}`} alt='collection' className='object-cover' />
							</div>
							<div className='ml-5'>
								<p className='text-darkGrey text-sm'>Name</p>
								<p className='mt-2.5'>{Collection &&  Collection.NFTName }</p>
							</div>
						</li>
						<li>
							<p className='text-darkGrey text-sm'>Restaurant</p>
							<p className='mt-2.5 font-bold'>{Collection &&  Collection.OwnedBy }</p>
						</li>
						<li>
							<p className='text-darkGrey text-sm'>NFT</p>
							<p className='mt-2.5'>{ Collection &&  Collection.NFTCount} NFTs</p>
						</li>
						<li>
							<p className='text-darkGrey text-sm'>Release Date</p>
							<p className='mt-2.5'>{ moment(Collection.NFTReleaseDate).format('DD/MM/YYYY') }</p>
						</li>
						<li>
							<p className='text-darkGrey text-sm'>Finish Date</p>
							<p className='mt-2.5'>{ moment(Collection.NFTEndDate).format('DD/MM/YYYY') }</p>
						</li>
					</ul>
				</div>
				{CurrentUser?.userType == "superadmin" ? 
				<div className='flex items-center justify-end w-[27%]'>
					<ul className='flex items-center relative'>
						{/* <li className='m-0 bg-blueLighter p-3.5 rounded cursor-pointer scale-[0.75] mr-2'
							onClick={ e => {
								e.stopPropagation();
							} }
						>
							<Icons.PencilIcon className='w-5 h-5' />
						</li> */}
						{renderPopMoreIcon( setDeletemodal)}
					</ul>
				</div>
				:null
				}
			</div>
			<div className='mt-5 mb-10 w-full px-5 xl:w-defaultContent xl:px-0'>
				<Tabs
					activeTabIndex={ activeTabIndex }
					setActiveTabIndex={ setActiveTabIndex }
					tabUnderlineWidth={ tabUnderlineWidth }
					setTabUnderlineWidth={ setTabUnderlineWidth }
					tabUnderlineLeft={ tabUnderlineLeft }
					setTabUnderlineLeft={ setTabUnderlineLeft }
					tabsData={ tabsData }
				/>
				{ renderFilter }
				{ renderContent }
			</div>
			<Modal
				visible={ modalFactory.visible }
				headerTitle={ `${modalFactory.title} NFT` }
				onClickClose={ resetState }
				position='top'
			>
				{ renderModalContent }
			</Modal>
			<Modal visible={deletemodal} onClickClose={() =>setDeletemodal(false)}>
			<div>
		
        <Text text='Are you sure you want to delete this Collection?' className='py-5' fontSize='18px' />
        <div className='flex justify-end items-center space-x-2'>
          <Button 
            label='Yes' 
            width='80px' 
            height='35px'
			backgroundColor='white'
            textColor={ Colors.prussianBlue }
            className='border-solid border-2 border-prussianBlue'
            onClick={() => {
				deleteCollections().then(() =>{
					setDeletemodal(false);
					navigates('/collections')
				})
				
              // modalDeleteFactory.onSubmit()
            //   onChangeModalMode('INIT')
            }}
            />
          <Button 
            label='No' 
            width='80px' 
            height='35px'
            backgroundColor='white'
            textColor={ Colors.prussianBlue }
            className='border-solid border-2 border-prussianBlue'
            onClick={() => {
              setDeletemodal(false)
            }}
          />
        </div>
		{/* </Modal> */}
      </div>
		</Modal>
		{openLoader && 
      <Modal visible={true} onClickClose={() =>setOpenLoader(false)}>
        <WalletStyles>
            <Text
                text={ 'Wallet Action' }
                fontType={ 'h5' }
                fontWeight={ '700' }
                fontSize={ '28px' }
                color={ '#FAFAFA' }
                fontFamily={ 'Lora' }
                className='title'
            />
            
            {/* { list.map((item, i) => ( */}
                <div
                    className='card'  
                >
                    <div className='logo'>
                        <img src={ group29 } width={ 50 } height={ 50 } />
                    </div>
                    <div className='menu'>
                        <Text
                            text={"Request to send wallet"}
                            fontWeight={ '700' }
                            color={ '#FAFAFA' }
                            fontType={ 'p' }
                            fontSize={ '20px' }
                            fontFamily={ 'Lora' }
                        />
                    </div>
                    <div className='arrow'>
                      {metamaskStatus &&
                        <img src={ loadiing } width={ 40 } height={ 40 } />
                      }
                        </div>
                    
                    
                </div>
                <div
                    className='card'  
                >
                    <div className='logo'>
                        <img src={ group28 } width={ 50 } height={ 50 } />
                    </div>
                    <div className='menu'>
                        <Text
                            text={"Transaction Underway"}
                            fontWeight={ '700' }
                            color={ '#FAFAFA' }
                            fontType={ 'p' }
                            fontSize={ '20px' }
                            fontFamily={ 'Lora' }
                        />
                    </div>
                    <div className='arrow'>
                      {contractStatus && 
                        <img src={ loadiing } width={ 40 } height={ 40 } />
                      }
                        </div>
                    
                    
                </div>
                <div
                    className='card'  
                >
                    <div className='logo'>
                        <img src={ group27 } width={ 50 } height={ 50 } />
                    </div>
                    <div className='menu'>
                        <Text
                            text={"Waiting for Confirmation"}
                            fontWeight={ '700' }
                            color={ '#FAFAFA' }
                            fontType={ 'p' }
                            fontSize={ '20px' }
                            fontFamily={ 'Lora' }
                        />
                    </div>
                    <div className='arrow'>
                      {completionStatus && 
                        <img src={ loadiing } width={ 40 } height={ 40 } />
                      }
                      </div>
                    
                    
                </div>
            {/* )) } */}
            {/* <Button
                label='CHECK IN SCAN'
                className='connect-wallet'
                // onClick={() => {window.open(``, "_blank")}}
                
                // onClick={ () => handleShowModalContactUs && handleShowModalContactUs() }
            /> */}
            {/* <ToastContainer /> */}
        </WalletStyles>
      </Modal>
      }
	  {editModal && 
	  <Modal visible={true} onClickClose={() => setEditModal(false)}>
            <Form className='my-5' autoComplete='off'>
			<Wrapper className='flex justify-center' >
				<UploadImage
					width='120px'
					height='120px'
					title='Icons'
					rounded='rounded-full'
					// onChange={(e) => onChangeAddNFTImage(e)}
					imageurl={Collection ? Collection.NFTImage : ""}
					// { ...registeredValue('photo') }
				/>
			</Wrapper>
			<div>
      		<Form.FormGroup className='input-wrapper mt-4'>
				<Form.Label htmlFor='restaurants' className='mb-2 text-darkGrey'>Tier</Form.Label>
				<TextField
				className='h-[40px] w-[360px] mr-5'
				menuClassname='h-[100px] w-[360px] z-20'
				// options={ resarray }
				name="NFTCollections"
				// id={ item.id }
				value={Collection.NFTCollections}
				// onChange={ e => {handleCollectioncheck(e.target.value);setAddNftOwnedBy(e.target.value) }}
				placeholder='Choose Restaurant'
				// { ...item }
				/>
			</Form.FormGroup>
				<Form.FormGroup className='input-wrapper'>
					<Form.Label htmlFor='name' className='mb-2 text-darkGrey'>Name</Form.Label>
					<Form.TextField
						id='name'
						placeholder='Name'
						defaultValue={Collection.NFTName}
						className='input-name border rounded-md outline-prussianBlue h-10 '
            			onChange={(e)=> setEditNftName(e.target.value)}
						noInlineError
						// { ...registeredValue('name') } 
            />
				</Form.FormGroup>
			</div>
      <div>
				<Form.FormGroup className='input-wrapper mt-2'>
					{/* <Form.Label htmlFor='benefits' className='mb-2 text-darkGrey'>N</Form.Label> */}
					<Form.TextField
						id='Number of nfts'
						placeholder='Number of Nfts'
            			type="number"
						defaultValue={Collection.NFTCount}
						className='input-name border rounded-md outline-prussianBlue h-10 '
            			onChange={(e)=> setEditNftCount(e.target.value)}
						noInlineError
						// { ...registeredValue('name') } 
            />
				</Form.FormGroup>
			</div>
      		<div>
				<Form.FormGroup className='input-wrapper mt-2'>
					<Form.Label htmlFor='price' className='mb-2 text-darkGrey'>Price</Form.Label>
					<Form.TextField
						id='price'
						placeholder='price (in dollar)'
						defaultValue={Collection.NFTPrice}
						className='input-name border rounded-md outline-prussianBlue h-10 '
						onChange={(e) =>{
						if(e.target.value > 0){
							setEditNftPrice(e.target.value)
						}else{
							setEditNftPrice('')
						}
						}}
						noInlineError
						// { ...registeredValue('name') } 
            		/>
				</Form.FormGroup>
			</div>
			<div>
	
				<Form.FormGroup className='input-wrapper mt-2'>
					<Form.Label htmlFor='royalty' className='mb-2 text-darkGrey'>Royalty</Form.Label>
					<Form.TextField
						id='royalty'
						placeholder='Royalty (in %)'
						defaultValue={Collection.NFTRoyalty}
						className='input-name border rounded-md outline-prussianBlue h-10 '
						onChange={(e) =>{
						if(e.target.value > 0){
							setEditNftRoyalty(e.target.value)
						}else{
							setEditNftRoyalty('')
						}
						}}
						noInlineError
						// { ...registeredValue('name') } 
            		/>
				</Form.FormGroup>
				{editnftRoyalty && editnftPrice ?
				<span>$ {editnftRoyalty ? ((editnftRoyalty*editnftPrice) / 100).toFixed(2) :null}</span>
				:null
				}
				{!editnftRoyalty && !editnftPrice ?
				<span>$ {Collection.NFTRoyalty ? ((Collection.NFTRoyalty*Collection.NFTPrice) / 100).toFixed(2) :null}</span>
				:null
				}
				{!editnftRoyalty && editnftPrice ?
				<span>$ {Collection.NFTRoyalty ? ((Collection.NFTRoyalty*editnftPrice) / 100).toFixed(2) :null}</span>
				:null
				}
				{editnftRoyalty && !editnftPrice ?
				<span>$ {editnftRoyalty ? ((editnftRoyalty*editnftPrice) / 100).toFixed(2) :null}</span>
				:null
				}
			</div>
			
			<Form.FormGroup className='input-wrapper mt-4'>
				<Form.Label htmlFor='description' className='mb-2 text-darkGrey'>Description</Form.Label>
				<Form.TextArea
					id='description'
					placeholder='Description'
					className='h-[80px] border rounded-md resize-none h-10 '
					defaultValue={Collection.NFTDescription}
          			onChange={(e)=>setEditNftDescription(e.target.value)}
					noInlineError
					// { ...registeredValue('description') } 
          />
			</Form.FormGroup>
			
			{/* <Form.FormGroup className='input-wrapper mt-4'>
				<Form.Label htmlFor='tier' className='mb-2 text-darkGrey'>Tier(s)</Form.Label>
				<TextField
				className='h-[40px] w-[360px] mr-5'
				menuClassname='h-[100px] w-[360px] z-20'
				// options={ tiers_ && tiers_}
				name="NFTCollections"
				defaultValue={Collection.NFTCollections}
				// value={Collections[index].NFTCollections}
				// onChange={ e => setAddNftTier(e.target.value) }
				placeholder='Choose Tier'
				// { ...item }
			/>
			</Form.FormGroup> */}
      
      <div>
				<Form.FormGroup className='input-wrapper mt-3'>
					<Form.Label htmlFor='benefits' className='mb-2 text-darkGrey'>Benefits</Form.Label>
					<Form.TextField
						id='benefits'
						placeholder='Benefits'
						className='input-name border rounded-md outline-prussianBlue h-10 '
						defaultValue={Collection.benefits}
            			onChange={(e)=>setEditNftBenefits(e.target.value)}
						noInlineError
						// { ...registeredValue('name') } 
            />
				</Form.FormGroup>
			</div>
			<Wrapper className='grid grid-cols-2 gap-3'>
				<Form.FormGroup className='input-wrapper mt-4'>
					<Form.Label className='text-darkGrey'>Release Date</Form.Label>
					{/* <Form.DatePicker
						className='input-datepicker border rounded-md mt-2 icon-date h-10 '
						selected={Collection.NFTReleaseDate}
            			onChange={(e)=>setEditNftReleaseDate(e.target.value)}
						// { ...registeredValue('releaseDate') }
					/> */}
					<input 
					type="date"
					name="" 
					id="" 
					defaultValue={Collection.NFTReleaseDate} 
					className='input-datepicker border rounded-md mt-2 icon-date h-10 '
					onChange={(e)=>setEditNftReleaseDate(e.target.value)}
					/>
				</Form.FormGroup>
				<Form.FormGroup className='input-wrapper mt-4'>
					<Form.Label className='text-darkGrey'>Finish Date</Form.Label>
					{/* <Form.DatePicker
						selected={Collection.NFTEndDate}
						className='input-datepicker border rounded-md mt-2 icon-date h-10 '
            			onChange={(e)=>setEditNftEndDate(e.target.value)}

						// { ...registeredValue('finishDate') }
					/> */}
					<input 
					type="datetime-local"
					name="" 
					id="" 
					// format='YYYY-MM-DDTHH:mm:ss'
					defaultValue={Collection.NFTEndDate} 
					min={editnftReleaseDate ? editnftReleaseDate +"T00:00" : Collection.NFTReleaseDate +"T00:00" }
					className='input-datepicker border rounded-md mt-2 icon-date h-10 '
					onChange={(e)=>setEditNftEndDate(e.target.value)}
				/>
				</Form.FormGroup>
				
				
				
				
			</Wrapper>
			<div className='flex justify-center mt-4'>
					<Button
						label={"Submit"}
						width='126px'
						height='44px'
						className='bg-prussianBlue'
						backgroundColor={ colors.prussianBlue }
						textColor="white"
						onClick={ () => handleEditCollectionContract() }
					/>
				</div>
		</Form>
		</Modal>
		}
		</Container>
	);
};

export default React.memo(DetailCollection);