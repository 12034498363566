import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Icons, Images } from 'constant';
import { Container, ModalWrapper, StepWrapper,Div} from './style';
import WalletStyles from './styles';
import { Menu } from '@headlessui/react';
import {
	Tabs, Dropdown, TextField, Button, Modal, Form, Action, SelectMenu, Text,UploadImage,Label,Input
} from 'components';
import FormGroup from "components/FormGroup";
import { navigation } from 'helpers';
import NFTCard from 'components/Card/NFTCard';
import { collectionListSelector } from 'store/collection/selector';
import { ColorSwatchIcon, PlusIcon } from '@heroicons/react/solid';
import moment from 'moment';
import colors from 'constant/colors';
import { useNavigate } from 'react-router-dom';
import {listCollections, getallNfts, addNfts,activeevents,getEventsdetails,editevents,editcollection,addnewcollections,editnftdetails,addnftsgroupedit,editnftsgroupedit,deletenft,deleteCollections,activeeventscollection} from '../../services/restaurant';
import {Fetch, persist} from '../../services/local-storage';
import { NFTCollection } from 'pages/MyProfile/Sections';
import * as XLSX from "xlsx/xlsx.mjs"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import loadiing from  '../../assets/images/loading.gif';
import abi from "../../contractsABI/TempleteABI.json";
import { GlobalOutlined } from "@ant-design/icons";
import { RiFacebookCircleLine } from "react-icons/ri";
import { BsInstagram } from "react-icons/bs";
import { RxDiscordLogo } from "react-icons/rx";
import { AiFillCloseCircle } from "react-icons/ai";
import S3 from "react-aws-s3";
import {BsFileEarmarkZipFill} from "react-icons/bs";
import axios from 'axios';



const EventDetail = () => {
	const navigates= useNavigate();
	const CurrentUser = JSON.parse(Fetch("user"));
	const search = window.location.search; // could be '?foo=bar'
	const params = new URLSearchParams(search);
	const owner = params.get('restaurant');
  const wallet = useSelector((state)=> state.WalletConnect)
	const [saveeditcollection,setsaveeditcollection]=useState(false)


  const [matictoUsd,setmatictoUsd]=useState(0)
  const [usdttoUsd,setusdttoUsd]=useState(0)
  const [usdctoUsd,setusdctoUsd]=useState(0)
  const [modal,setmodal]=useState(false)


	
	const getmatictoUsd = async() => {


	  const matictousd = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd")
	 
	  setmatictoUsd(matictousd.data['matic-network'].usd)

    const usdttoUsds = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=tether&vs_currencies=usd")
	  
	  setusdttoUsd(usdttoUsds.data['tether'].usd)

    const usdctoUsds = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=usd-coin&vs_currencies=usd")
	 
	  setusdctoUsd(usdctoUsds.data['tether'].usd)

  
	}

	
	const removeCollection =async(value ,i)=>{
		if(value === "col"){
     
      
      const soldcheck = Collection[i]?.nfts ?   Collection[i]?.nfts.filter(item=>item.status) : 0 ;
    
      if(soldcheck.length > 0){
        toast("Collection NFT Minted")
        setcollectionloader(false)
      }else{
      const obj = Collection[i]
		  const values = [...Collection];
      console.log("values",values)
		  if(values.length>1){
      if(obj._id){
        console.log("i",obj)
      const removeCollections = await deleteCollections(obj._id);
      if(removeCollections.data.status){  
			values.splice(values.findIndex(a => a._id === obj._id) , 1);
			setCollection(values)
      toast(removeCollections.data.message)
      }else{
        toast(removeCollections.data.message)
      }
      }else{
        // toast("Collection not found")
      values.splice(values.findIndex(a => a._id === obj._id) , 1);
			setCollection(values)
      }
		  }
    }
		}
	  }
	
	
	const addCollection =(value)=>{
		if(value === "col"){
		  if(Collection.length > 2){
			toast("Collection Reach The Max Limit")
		  }else{
		  setCollection([...Collection,
		 {NFTCollections:"",
		  _id:"",
		  NFTcolletionbutstate:false,
		  NFTtradedate:"",
		  NFTCollectionsimage:"",
		  NFTTier:"",
		  NFTCollectionRoyality:"",
		  Description:"",
		  NFTCollectionTradestatus:"",
		  Collectionnftgroup:[{nftgroupname:"",
							   nftsupply:"",
							   nftzip:"", 
							   nftzipname:"",
							   nftcvs:"",
							   nftcsvname:"",
							   nfttradedate:"",
							   pinataid:"",
							   nftgroupstatus:false,
							   firstgroupsave:false
							  }]}])
		}
		}
	  }


	const initialValues = [
		[
			{ name: 'name', value: '' },
			{ name: 'image', value: '' },
			{ name: 'tier', value: '' },
			{ name: 'description', value: '' },
		],
		[
			{ name: 'price', value: '' },
			{ name: 'quantity', value: '' }
		],
		[
			{ name: 'releaseDate', value: '' },
			{ name: 'finishDate', value: '' }
		]
	];
	const countryOption = [
		{
		  label: "Singapore",
		  value: "singapore",
		},
		{
		  label: "Indonesia",
		  value: "indonesia",
		},
		{
		  label: "India",
		  value: "india",
		},
		{
		  label: "China",
		  value: "china",
		},
		{
		  label: "United States",
		  value: "us",
		},
		{
		  label: "Ukraine",
		  value: "ukranine",
		},
	  ];

	  const tiers_ = [
		{ label: "Silver", value: "Silver" },
		{ label: "Platinum", value: "Platinum" },
		{ label: "Gold", value: "Gold" },
	  ];

  
  const [collectionloader,setcollectionloader]=useState(false)
  const [ziploader,setziploader]=useState(false)
  const [colimgloader,setcolimgloader]=useState(false)
  const [nftimgloader,setnftimgloader]=useState(false)
  const [csvloader,setcsvloader]=useState(false)
  

	
	const navigate = navigation();
	const id = navigate.pathname.split('/')[2];
	const [activeTabIndex, setActiveTabIndex] = useState(0);
	const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
	const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);
	const [modalVisible, setModalVisible] = useState('INIT');
	const [step, setStep] = useState(0);
	const [filter, setFilter] = useState({
		filter1: '',
		filter2: '',
		search: ''
	});
	const [checked, setChecked] = useState(0);
	const [fields, setFields] = useState([...initialValues]);

	const { collections } = useSelector(collectionListSelector);

  const [token,settoken] = useState(0)


	//values
  const [maticvalue,setmaticvalue]=useState(0)
  const [usdtvalue,setusdtvalue]=useState(0)
  const [usdcvalue,setusdcvalue]=useState(0)

  const [totalfundvalue,settotalfundvalue]=useState(0)

  const [withdrawvalue,setwithdrawvalue]=useState(0)

	//edit states

	const [eventloader,seteventloader]=useState(false)
	const [saveeditevent,setsaveeditevent]=useState(true)
	const [Collection,setCollection]=useState([])

	const [events,setevents]=useState({
		eventname:"",
		eventcountry:"",
		eventcity:"",
		eventaddress:"",
		website:"",
		facebook:"",
		instagram:"",
		linkedin:"",
		discord:"",
		eventimg:"",
		eventimgname:"",
		eventid:"",
	  })
	const [reload,setreload]=useState(false)

	useEffect(() => {
    // getmatictoUsd()
		if(id && !reload){
         geteventdatas();
		}
 
	},[id,wallet.address])

  const geteventbalamce=async(eventname)=>{
    const { web3,address,NFT,marketplace } = wallet;
    if(wallet.address){
    
      try{
      const re = await marketplace.methods.GetEventAddress(eventname).call()
      try{
      const maticvalue = await NFT.methods.totalMaticFundsPerEvent(re).call();
      const valuesmatic = web3.utils.fromWei(maticvalue,'ether')
      setmaticvalue(Number(valuesmatic))
      
      const USDTvalue = await NFT.methods.totalUSDTFundsPerEvent(re).call();
      const usdt = Number(USDTvalue)/1000000
      setusdtvalue(usdt)

      const USDCvalue = await NFT.methods.totalUSDCFundsPerEvent(re).call();
      const usdc=Number(USDCvalue)/1000000
      setusdcvalue(usdc)

      const matictousd = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd")
      setmatictoUsd(Number(valuesmatic)*(matictousd.data['matic-network'].usd))
      
      const usdttoUsds = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=tether&vs_currencies=usd")
      setusdttoUsd(usdt*Number(usdttoUsds.data['tether'].usd))
  
      const usdctoUsds = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=usd-coin&vs_currencies=usd")
      setusdctoUsd(usdc*Number(usdctoUsds.data['usd-coin'].usd))

      }catch(err){
        console.log("err===>value",err)
      }
      
      }catch(err){
       console.log("err===>",err)
      }
    }
  }


  const withdrawfund=async(eventname)=>{
  
    const { web3,address,NFT,marketplace } = wallet;
    if(wallet.address){ 
      const eveaddress = await marketplace.methods.GetEventAddress(events.eventname).call()
    if(Number(token) == 0){
      toast("Please select a token type")
    }else if(Number(token) == 1){
      if(Number(withdrawvalue) == 0 || withdrawvalue == ""){
        toast("Enter Withdrawal Amount")
      }else{
        try{
        if(Number(withdrawvalue) > Number(maticvalue)){ 
          toast("Insufficient Funds") 
        }else{
          const value = web3.utils.toWei(withdrawvalue.toString(),'ether')
          const withdramatic = await NFT.methods.withdrawFunds(eveaddress,value,1).send({from:address})
          setmodal(false)
        }
        }catch(err){
          setmodal(false)
          consol.log("err===>",err)
          toast("Something Went Wrong")
        }
      }  
    }else if(Number(token) == 2){
      if(Number(withdrawvalue) == 0 || withdrawvalue == ""){
        toast("Enter Withdrawal Amount")
      }else{
        try{
        if(Number(withdrawvalue) > Number(usdtvalue)){  
          toast("Insufficient Funds")
        }else{
          const value = (Number(withdrawvalue)*1000000).toString()
          const withdramatic = await NFT.methods.withdrawFunds(eveaddress,value,3).send({from:address}) 
          setmodal(false)
        }
        }catch(err){
          setmodal(false)
          consol.log("err===>",err)
          toast("Something Went Wrong")
        }
      }  
    }else if(Number(token) == 3){
      if(Number(withdrawvalue) == 0 || withdrawvalue == ""){
        toast("Enter Withdrawal Amount")
      }else{
        try{
          if(Number(withdrawvalue) > Number(usdcvalue)){
            toast("Insufficient Funds") 
            }else{
              const value = (Number(withdrawvalue)*1000000).toString()
              const withdramatic = await NFT.methods.withdrawFunds(eveaddress,value,2).send({from:address})
              setmodal(false)
            }
      }catch(err){
        setmodal(false)
        consol.log("err===>",err)
        toast("Something Went Wrong")
      }
    }
  }
 } else{
    setmodal(false)
    toast("Please connect to wallet")
  }

  }



  const setnftGroupvalues = (index,i,e)=>{
    const { name, value } = e ;
    const list = [...Collection];
    list[index].Collectionnftgroup[i][name] = value;
    setCollection(list); 
  }


  const timeconvertdays = (unix_timestamp) => {
    var a = new Date(unix_timestamp);
    var months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    var time = a.toLocaleTimeString()
    var time = date + "/" + month + "/" + year // + ", " + time; //+ ' ' + year + ' ' + hour + ':' + min + ':' + sec;
    return time;
  };


	const changecollection = async (e,index) => {
    setcolimgloader(true)
		var file = e.target.files[0];
		const config = {
		  bucketName: process.env.REACT_APP_BUCKET_NAME,
		  dirName: process.env.REACT_APP_DIRECTORYNAME,
		  region: process.env.REACT_APP_REGION,
		  accessKeyId: process.env.REACT_APP_AWS_ACCESSKEY_ID,
		  secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
		};
		const ReactS3Client = new S3(config);
	
		const newFileName = Date.now()+ "_" +file.name  ;
	
		ReactS3Client.uploadFile(file, newFileName).then((data) => {
		const list = [...Collection];
		list[index]["NFTCollectionsimage"] = newFileName;
		setCollection(list);
    setcolimgloader(false)
		})
	  };
   

    const submitevents =async()=>{
      const { web3,address,NFT,marketplace } = wallet;
      if(address){
        const checkadmins = await marketplace.methods.isOwner(address).call();
        console.log("checkadmin",checkadmins)
      if(checkadmins){
          try{
            var collection_1 = 0;
            var collection_2 = 0;
            var collection_3 = 0;

            var goldtradestatus =""
            var silvertradestatus =""
            var platinumtradestatus = ""

            var goldtradedaate = ""
            var slivetradedaate = ""
            var platinamtradedaate = ""
          
          await Promise.all(Collection.map(async(item)=>{
              if(item.NFTTier == "Gold"){
                const goldvalue = item.Collectionnftgroup?.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue.nftsupply),0); 
                const gol = item.nfts?.length
                collection_1 = Number(goldvalue) + Number(gol?gol:0)
                goldtradestatus = item.NFTCollectionTradestatus
                goldtradedaate = item.nfts?.reduce((accumulator, current) => {
                  return Number(accumulator.NFTTradedate) > Number(current.NFTTradedate) ? Number(accumulator.NFTTradedate) : Number(current.NFTTradedate);
                });

                const tradedates = item.Collectionnftgroup.length > 0 ? item.Collectionnftgroup[0].nfttradedate ?item.Collectionnftgroup[0].nfttradedate : 0 : 0
                const datevalue = goldtradedaate > tradedates ? goldtradedaate : tradedates 
                goldtradedaate = datevalue
              }else if(item.NFTTier == "Silver"){
                const silvervalue = item.Collectionnftgroup?.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue.nftsupply),0);
                const silv = item.nfts?.length
                collection_2 = Number(silvervalue) + Number(silv ?silv :0 )
                silvertradestatus = item.NFTCollectionTradestatus
                slivetradedaate = item.nfts?.reduce((accumulator, current) => {
                  return Number(accumulator.NFTTradedate) > Number(current.NFTTradedate) ? Number(accumulator.NFTTradedate) : Number(current.NFTTradedate);
                });

                const tradedates = item.Collectionnftgroup.length > 0 ? item.Collectionnftgroup[0].nfttradedate ?item.Collectionnftgroup[0].nfttradedate : 0 : 0
                const datevalue = slivetradedaate > tradedates ? slivetradedaate : tradedates 
                slivetradedaate = datevalue
      
              }else if(item.NFTTier == "Platinum"){  
                const platinumvalue = item.Collectionnftgroup?.reduce((accumulator, currentValue) => Number(accumulator) + Number(currentValue.nftsupply),0);
                const plat = item.nfts?.length
                collection_3 = Number(platinumvalue) + Number(plat ? plat :0)
                platinumtradestatus= item.NFTCollectionTradestatus
                platinamtradedaate = item.nfts?.reduce((accumulator, current) => {
                  return Number(accumulator.NFTTradedate) > Number(current.NFTTradedate) ? Number(accumulator.NFTTradedate) : Number(current.NFTTradedate);
                });
                const tradedates = item.Collectionnftgroup.length > 0 ? item.Collectionnftgroup[0].nfttradedate ?item.Collectionnftgroup[0].nfttradedate : 0 : 0
                const datevalue = platinamtradedaate > tradedates ? platinamtradedaate : tradedates 
                platinamtradedaate = datevalue
              }
            }))

          
            const re = await marketplace.methods.GetEventAddress(events.eventname).call()
            console.log("events.eventname,re,collection_2 ?collection_2:0,collection_1?collection_1:0,collection_3?collection_3:0==>",re,events.eventname,collection_2 ?collection_2:0,collection_1?collection_1:0,collection_3?collection_3:0,[silvertradestatus == "trade"?true :false,goldtradestatus == "trade"?true :false,platinumtradestatus == "trade"?true :false],[slivetradedaate? slivetradedaate:0,goldtradedaate ?goldtradedaate:0,platinamtradedaate ?platinamtradedaate:0 ])
            const createevents = await marketplace.methods.editCollections(re,events.eventname,collection_2 ?collection_2:0,collection_1?collection_1:0,collection_3?collection_3:0,[silvertradestatus == "trade"?true :false,goldtradestatus == "trade"?true :false,platinumtradestatus == "trade"?true :false],[slivetradedaate? slivetradedaate:0,goldtradedaate ?goldtradedaate:0,platinamtradedaate ?platinamtradedaate:0 ]).send({ from: address })         
            try {
              const data={
                Eventid:events.eventid,
                Collection:Collection,
              }
              const res = await activeeventscollection(data);
              console.log("res==>",res)
              toast(res.data.message)
              if(res.data.result){
                navigate("/events")
              }
            } catch (er) {
          
              toast("something went wrong")
              console.log(er);
            }
          }catch (er) {
            console.log(er);
          }
      }else{
        toast("SuperAdmin wallet Only Edit Events")
      }
      }else{
        toast("Please connect to wallet")
      }

    }

	
	  const handelcollection1=async (e, index) => {
		const { value } = e;
		  const list = [...Collection];
		  list[index]["NFTTier"] = value;
		  setCollection(list);
	  };

const onchangecollection=async(index)=>{
  const { web3,address,NFT,marketplace } = wallet;
  if(address){
    const checkadmins = await marketplace.methods.isOwner(address).call();
    console.log("checkadmin",checkadmins)
  if(checkadmins){ 
	setcollectionloader(true)
	if(Collection[index].NFTcolletionbutstate){
    
     const soldcheck = Collection[index]?.nfts?.filter(item=>item.status)
      if(soldcheck.length > 0){
        toast("Collection NFT Minted")
        setcollectionloader(false)
      }else{
	  const value = false
	  const list = [...Collection];
	  list[index]["NFTcolletionbutstate"] = value;
	  setCollection(list)
	  setcollectionloader(false)
	  setsaveeditcollection(false)
      }
	}else{
	  if(Collection[index]._id && Collection[index]._id !=""){
		if(Collection[index].NFTCollections && Collection[index].NFTTier  && Collection[index].NFTCollectionTradestatus && Collection[index].Description && Collection[index].NFTCollectionsimage  && Collection[index].NFTCollectionRoyality && events.eventid ){
		const data ={
		  Eventid:events.eventid,
		  Eventname:events.eventname,
		  NFTCollectionid:Collection[index]._id,
		  NFTCollectionname:Collection[index].NFTCollections,
		  Collectiontier:Collection[index].NFTTier,
		  Collectiontrade:Collection[index].NFTCollectionTradestatus,
		  CollectionDescription:Collection[index].Description,
		  NFTcollectionimg:Collection[index].NFTCollectionsimage,
		  NFTRoyality:Collection[index].NFTCollectionRoyality
		}
		const editevent = await editcollection(data)
		if(editevent.data.result){
		  const value = true
		  const list = [...Collection];
		  list[index]["NFTcolletionbutstate"] = value;
		  setsaveeditcollection(true)
		  setcollectionloader(false)
		  toast(editevent.data.message)
		}else{
		  setcollectionloader(false)
		  toast(editevent.data.message)
		}
	  }else{
		setcollectionloader(false)
		toast("Fill All Input")
	  }
	  }else{
		if(Collection[index].NFTCollections && Collection[index].NFTTier  && Collection[index].NFTCollectionTradestatus && Collection[index].Description && Collection[index].NFTCollectionsimage  && Collection[index].NFTCollectionRoyality && events.eventid ){
		  const data ={
			Eventid:events.eventid,
			Eventname:events.eventname,
			NFTCollectionname:Collection[index].NFTCollections,
			Collectiontier:Collection[index].NFTTier,
			Collectiontrade:Collection[index].NFTCollectionTradestatus,
			CollectionDescription:Collection[index].Description,
			NFTcollectionimg:Collection[index].NFTCollectionsimage,
			NFTRoyality:Collection[index].NFTCollectionRoyality
		  }
		  const addnewcol = await addnewcollections(data)
		  if(addnewcol.data.result){
			const value = true
			const list = [...Collection];
			list[index]["NFTcolletionbutstate"] = value;
			list[index]["_id"] = addnewcol.data.result._id;
			setsaveeditcollection(true)
			setcollectionloader(false)
			toast(addnewcol.data.message)
		  }else{
			setcollectionloader(false)
			toast(addnewcol.data.message)
		  }
		}else{
		  setcollectionloader(false)
		  toast("Fill All Collection Data")
		}
	  }
  
	}
  
}else{
  toast("Only Superadmin add NFT's")
}
}else{
  toast("Connect Your Wallet")
}
  
  }

	const onChangeAddNFTImage = async (e) => {
		setevents({...events,eventimgname:e.target.value})
	};

  const uploadFiles=async(index,i,e,nftzip,nftzipname)=>{
 
    setziploader(true)
    const file = e.target.files[0]
    if(file){
    let extension = "." + file.name.split(".").pop();
    if(extension == ".zip"){
    const fileName = e.target.files[0].name
    const formdata = new FormData();
    formdata.append("file", file);
    formdata.append("fileName", fileName);
    formdata.append("colName", Collection[index].NFTCollections);
    formdata.append("groupName", Collection[index].Collectionnftgroup[i].nftgroupname);
   
    var requestOptions = {
      method: 'POST',
      body: formdata,
      redirect: 'follow'
    };
try{
fetch("https://okunftbackend.click/auth/uploadnftzip", requestOptions).then(response =>response.json()).then(res => {

if(res.CID && res.Supply){
        if(Number(res.Supply) == Number(Collection[index].Collectionnftgroup[i].nftsupply)){
          const list = [...Collection];
          list[index].Collectionnftgroup[i][nftzip] =file;
          list[index].Collectionnftgroup[i][nftzipname] =fileName;
          list[index].Collectionnftgroup[i]["pinataid"]=res.CID;
          setCollection(list)
          setziploader(false)
          toast(res.message)
        }else{
          setziploader(false)
           toast("Total Supply and Images are Missmatch ")
        }
      }else{
        setziploader(false)
        toast(res.message)
      }
}).catch((err)=>{
  setziploader(false)
  toast("Something Went Worng")
  console.log('error', err)
})
 

}catch(error){
    setziploader(false)
    console.log('error', error)
  };
  }else{
    setziploader(false)
    toast("Upload Zip File")
  }
  }else{
    setziploader(false)
    toast("Select Zip File")
  }
    
  }

  const bannerImageChange = (index,i,e,name) => {
    setcsvloader(true)
    var file = e.target.files[0];
    if(file){
      let extension = "." + file.name.split(".").pop();
    if(extension == ".xlsx" || extension == ".csv" || extension == ".xls" ){
   
    const reader = new FileReader();
    reader.fileName = file.name // file came from a input file element. file = el.files[0];
    let newData;
    reader.onload = async (e) => {
        const data1 = e.target.result;
        const workbook = XLSX.read(data1, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const json = XLSX.utils.sheet_to_json(worksheet,{raw:false});
        if (json) {
          if(Number(json.length) == Number(Collection[index].Collectionnftgroup[i].nftsupply)){
          const list = [...Collection];
          list[index].Collectionnftgroup[i][name] = json;
          const maxs = Math.max(...Collection[index].Collectionnftgroup[i].nftcvs.map(o => timeconvert(o.SaleEndDate.replaceAll("/","-"))))
          const finaletradeday = Math.round(Number(maxs)/1000)+86400
          list[index].Collectionnftgroup[i]["nfttradedate"] = finaletradeday;
          list[index].Collectionnftgroup[i]["nftcsvname"] = e.target.fileName;
          setCollection(list)
          setcsvloader(false)
          }else{
            setcsvloader(false)
            toast("Total Supply and CSV are Missmatch")
          }
        }else{
          setcsvloader(false)
        }
        // setAirdropDetails({ ...airdropDetails, xdcairdrop_json: json });
      };
    reader.readAsArrayBuffer(e.target.files[0]);
  }else{
    setcsvloader(false)
    toast("Upload XLSX or CSV File")
  }
  }else{
    setcsvloader(false)
    toast("Select XLSX or CSV File")
  }
  };

	function onChangeValue(event) {
		const { id,value } = event.target;
		const list = [...Collection];
		list[id]["NFTCollectionTradestatus"] = value;
		setCollection(list);
	  }

    function isValidHttpUrl(string) {
      try {
        const newUrl = new URL(string);
        return true;
      } catch (err) {
        return false;
      }
    }

	const evetschange=async()=>{
		seteventloader(true)
		if(saveeditevent){
		  setsaveeditevent(false)
		  seteventloader(false)
		}else{
		  if(events.eventid && events.eventid !=""){
			if(events.eventname && events.eventcountry && events.eventcity && events.website && events.instagram && events.facebook && events.discord && events.eventimgname && events.eventid){
        if(!isValidHttpUrl(events.website)){
          seteventloader(false)
          toast("Please Enter Valid Website URL")
          return
         }else if(!isValidHttpUrl(events.instagram)){
          seteventloader(false)
           toast("Please Enter Valid Instagram URL")
           return
         }else if(!isValidHttpUrl(events.discord)){
           toast("Please Enter Valid Discord URL")
           seteventloader(false)
           return
         }else if(!isValidHttpUrl(events.facebook)){
          seteventloader(false)
          toast("Please Enter Valid Facebook URL")
           return
         }
			  const data = {
				eventid:events.eventid,
				eventname: events.eventname,
				website: events.website,
				eventcountry: events.eventcountry,
				eventcity: events.eventcity,
				instagram:events.instagram,
				discord:events.discord,
				facebook:events.facebook,
				eventimgname:events.eventimgname,
			  }
			  const editevent = await editevents(data)
			  if(editevent.data.result){
				setsaveeditevent(true)
				seteventloader(false)
				toast(editevent.data.message)
			  }else{
				seteventloader(false)
				toast(editevent.data.message)
			  }
			}else{
			  seteventloader(false)
			  toast("Fill All Inputs")
			}
		  }else{
			toast("Event ID Missing")
		  }
		  
		}
	  }


	const geteventdatas=async()=>{
		setreload(true)
		const req = await getEventsdetails(id)
		if(req.data.result){
			const eventdetails = req.data.result[0]
			let newarray=[]  
   
			await eventdetails.letnftcollection.filter(item => item.status == "active").map(async(data)=>{
        const data1 = {...data,Collectionnftgroup:[{nftgroupname:"",nftsupply:"",nftzip:"", nftzipname:"",nftcvs:"",nftcsvname:"",nfttradedate:"",pinataid:"",nftgroupstatus:false,firstgroupsave:false}]}
        const unique = [...new Set(data.nfts.map(item => item.NFTCollections))];
			  let nftgroup=[]  
			  Promise.all( unique.map((item)=>{
				  const Group = data.nfts.filter(eve=>eve.NFTCollections == item)
				  if(Group.length > 0){
					nftgroup.push({ Nftgroupname:Group[0].NFTCollections,NFTcount:Group[0].NFTCount,NFTgroupimg:Group[0].NFTImage,CollectionName:Group[0].CollectionName,NFTS:Group})
					}
				}))
			  newarray.push({...data1,nftgroup,NFTcolletionbutstate:true})
			  })
      
			setCollection(newarray)
			setevents({...events,
			eventname:eventdetails.restaurantName ? eventdetails.restaurantName : "",
			eventcountry:eventdetails.Country ? eventdetails.Country :"",
			eventcity:eventdetails.City ?eventdetails.City :"",
			website:eventdetails.website ? eventdetails.website :"",
			facebook:eventdetails.Facebook ? eventdetails.Facebook:"",
			instagram:eventdetails.Instagram ? eventdetails.Instagram:"",
			discord:eventdetails.Discord ?eventdetails.Discord :"",
			eventimg:eventdetails.restaurantProfile ? eventdetails.restaurantProfile :"",
			eventimgname:eventdetails.restaurantProfile ? eventdetails.restaurantProfile:"",
			eventid:eventdetails._id ? eventdetails._id:"",})

      geteventbalamce(eventdetails.restaurantName ? eventdetails.restaurantName : "")
			setreload(false)
		}
	}

	const handelcollection= async (e, index) => {
		const { name, value } = e;
		if(name == "NFTTier"){
		const tirecheck = Collection.filter(item => item.NFTTier == value)
		if(tirecheck.length > 0){
		  toast("Tier Already Selected ")
		}else{
		  const list = [...Collection];
		  list[index]["NFTTier"] = value;
		  setCollection(list);
		}
		}else{
		  const list = [...Collection];
		  list[index][name] = value;
		  setCollection(list);
		}
	  };


	  const editnfts =async(index,ixe,i)=>{
	
    if(Collection[index].nftgroup[ixe].NFTS[i].status){ 
      toast("NFT Minted")
    }else{
		if(Collection[index].nftgroup[ixe].NFTS[i].edit){

        const nftdetails = Collection[index].nftgroup[ixe].NFTS[i]

		const data = {
			NFTId:nftdetails._id,
			NFTName:nftdetails.NFTName,
			NFTPrice:nftdetails.NFTPrice,
			NFTStartDate:nftdetails.NFTStartDate,
      NFTEndDate:nftdetails.NFTEndDate,
      NFTImage:nftdetails.NFTImage,
			NFTDescription:nftdetails.NFTDescription,
			benefits:nftdetails.benefits,
		}
		const editdata = await editnftdetails(data)
		if(editdata.data.result){
			toast(editdata.data.message)
		}else{
			toast(editdata.data.message)
		}
   
		const list = [...Collection];
		const value = false
		list[index]["nftgroup"][ixe]["NFTS"][i]["edit"]=value

		setCollection(list)
		}else{
		const list = [...Collection];
		list[index]["nftgroup"][ixe]["NFTS"][i]={...list[index]["nftgroup"][ixe]["NFTS"][i],edit:true}
		
		setCollection(list)
		}
  }
		
	  }

    const timeconvert =(date)=>{
      const dates = new Date(date).getTime()
      return dates;
    }

	  const dateformat=(date)=>{
		const input = date
	  const [ month, day, year] =  input.split('/')
		if(month.length <3){
		const findate = `${year}-${month.length == 1 ? "0"+month : month}-${day.length == 1 ? "0"+day : day}`
		return findate
		}
	  }

    const disablePastDate = (time) => {
      console.log("time: " + time)
      const today = new Date();
      console.log("today: " + today)
      const dd = String(today.getDate() + 0).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      const yyyy = today.getFullYear();
      console.log(yyyy + "-" + mm + "-" + dd)
      return yyyy + "-" + mm + "-" + dd;
      };

    const addnftgroup = async(value,index)=>{
      if(value === "col"){
      await Collection.map((row,eve)=>{
          if(eve == index){
            row.Collectionnftgroup.push({nftgroupname:"",
            nftsupply:"",
            nftzip:"", 
            nftzipname:"",
            nftcvs:"",
            nftcsvname:"",
            nfttradedate:"",
            pinataid:"",
            nftgroupstatus:false,
            firstgroupsave:false
           })
          }
        })
        setCollection([...Collection])      
      }
    }

    const removenftgroup =(value,i,index)=>{
      if(value === "col"){
        const list= [...Collection];
        const values =list[index].Collectionnftgroup
        if(values.length>1){
          values.splice(i,1);
          list[index].Collectionnftgroup = values
          setCollection(list)
        }
      }
    }

    const removenfts =async(index,ixe,i)=>{

      if(Collection[index].nftgroup[ixe].NFTS[i].status){ 
        toast("NFT Minted")
      }else{
   
      const obj = Collection[index].nftgroup[ixe].NFTS[i]
      if(obj){
      const deletenfts = await deletenft(obj._id) 
     
      if(deletenfts.data.status){ 
      const list = [...Collection];
      list[index]["nftgroup"][ixe]["NFTS"].splice(list[index]["nftgroup"][ixe]["NFTS"].findIndex(a => a._id === obj._id) , 1);
     
      setCollection(list)
      toast(deletenfts.data.message)
      }else{
        toast(deletenfts.data.message)
      }
      }else{
        toast("Invalid Object identifier")
      }
    }
    }

	  const nftvalueedit=(e,name,index,ixe,i)=>{
		const { value } = e;
		if(name =="NFTEndDate" || name =="NFTStartDate" ){
	    const list = [...Collection];	
		const input = value
        const [year, month, day] =  input.split('-')
		const date =`${month}/${day}/${year}`
		list[index]["nftgroup"][ixe]["NFTS"][i][name]=date
		setCollection(list)
		}else{
		const list = [...Collection];
		list[index]["nftgroup"][ixe]["NFTS"][i][name]=value
		setCollection(list)
		}
	  }

    const changesnft =async(e,name,index,ixe,i)=>{
      setnftimgloader(true)
       const collectionname = Collection[index].NFTCollections
       const groupname = Collection[index].nftgroup[ixe].Nftgroupname
       const file = e.target.files[0]
    
       const url = 'https://api.pinata.cloud/pinning/pinFileToIPFS';
       const data = new FormData();
       data.append('file', file);
       data.append('pinataMetadata', JSON.stringify({ name: file.name }));
       data.append('pinataOptions', JSON.stringify({ cidVersion: 0 }));
      
       let res= await axios
       .post(url, data, {
           headers: {
               'Content-Type': `multipart/form-data`,
               pinata_api_key: 'b59485d69cc440231a45',
               pinata_secret_api_key: '3ea46d088dbd804db43cd6108209f95762759e45a604773e18846b8e73405970'
           }
       })
       if(res){
        const hashPrefix = 'https://gateway.pinata.cloud/ipfs/'
        const imagehash = hashPrefix+res.data.IpfsHash;
        console.log("imagehash==>",imagehash)
        const list = [...Collection];
        list[index]["nftgroup"][ixe]["NFTS"][i][name]=imagehash
        setCollection(list)
        setnftimgloader(false)
       }
      }

    const editsavenftgroup=async(index,i)=>{

      const { web3,address,NFT,marketplace } = wallet;
      if(address){
        const checkadmins = await marketplace.methods.isOwner(address).call();
        console.log("checkadmin",checkadmins)
      if(checkadmins){
      if(Collection[index].Collectionnftgroup[i].nftgroupstatus){
        const value = false
        const list = [...Collection];
        list[index].Collectionnftgroup[i]["nftgroupstatus"] = value;
        setsaveeditevent(false)
      }else{
        if(!Collection[index].Collectionnftgroup[i].firstgroupsave){
         
        if(!Collection[index].Collectionnftgroup[i].nftcsvname){
          toast("Upload a NFT Group XLSX")
          return
        }else if(!Collection[index].Collectionnftgroup[i].nftzipname){
          toast("Upload a NFT Group Image ZIP")
          return
        }else if(!Collection[index].Collectionnftgroup[i].nftgroupname){
          toast("Enter a NFT Group Name")
          return

        }else if(!Collection[index].Collectionnftgroup[i].nftsupply){
          toast("Enter a NFT Group Supply Number")
          return
        }
        const data={
           EventName:events.eventname,
           Eventid:events.eventid,
           Collecctions:Collection[index],
           Nftgroup:Collection[index].Collectionnftgroup[i]
        }
        const addnftgroupnew = await addnftsgroupedit(data)
        if(addnftgroupnew.data.result){
          toast(addnftgroupnew.data.message)
          const value = true
          const list = [...Collection];
          list[index].Collectionnftgroup[i]["nftgroupstatus"] = value;
          list[index].Collectionnftgroup[i]["firstgroupsave"] = value;
          setCollection(list)
          setsaveeditevent(true)
        }else{
          toast(addnftgroupnew.data.message)
        }

      }else{
    
       

          if(!Collection[index].Collectionnftgroup[i].nftcsvname){
            toast("Upload a NFT Group XLSX")
            return
          }else if(!Collection[index].Collectionnftgroup[i].nftzipname){
            toast("Upload a NFT Group Image ZIP")
            return
          }else if(!Collection[index].Collectionnftgroup[i].nftgroupname){
            toast("Enter a NFT Group Name")
            return
  
          }else if(!Collection[index].Collectionnftgroup[i].nftsupply){
            toast("Enter a NFT Group Supply Number")
            return
          }

          const data={
            EventName:events.eventname,
            Eventid:events.eventid,
            Collecctions:Collection[index],
            Nftgroup:Collection[index].Collectionnftgroup[i]
         }
         const editnftgroupnew = await editnftsgroupedit(data)
         if(editnftgroupnew.data.result){
          toast(editnftgroupnew.data.message)
          const value = true
          const list = [...Collection];
          list[index].Collectionnftgroup[i]["nftgroupstatus"] = value;
          setCollection(list)
          setsaveeditevent(true)
        }else{
          toast(editnftgroupnew.data.message)
        }
      }
    }
  
}else{
  toast("Only Superadmin add NFT's")
}
}else{
  toast("Connect Your Wallet")
}
    }
	  
	return (
	
		<div>
      <WalletStyles>
			<div class="container-fluid">
			<div className="row">
			<div
            className="col"
            style={{
              backgroundColor: "#c6c6c6d6",
              padding: "20px",
              borderRadius: "10px",
            }}
          >
            <div>
			  <div className="mt-2" style={{fontWeight:"700",color:"#003051",fontSize:"20px"}}>Event View & Edit</div>
            </div>
			</div>
			<Div>
		{saveeditevent ?  
              <div className="row  mt-5 d-flex align-items-center">
                <div className="col-xl-3 col-lg-3 col-md-3 col-xs-6 d-flex justify-content-center">
                <img src={`${process.env.REACT_APP_S3_URL}${events.eventimgname}`}  width="200px"
                height="200px"
                />
                </div>
                <div className="col-lg-9 col-9">
                  <div className="row">
                    <div className="col-xl-4 col-md-4 col-xs-6 d-flex ">
                      <FormGroup className="w-100">
                        <Label htmlFor="Name" className="mb-2 text-dark">
                          Name
                        </Label>
                        <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{events.eventname}</div>
                      </FormGroup>
                    </div>
                    <div className="col-xl-4 col-md-4 col-xs-6 d-flex justify-content-center ">
                      <FormGroup className="w-100">
                        <Label htmlFor="Country" className="mb-2 text-dark">
                          Country
                        </Label>
                        <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{events.eventcountry}</div>
                      </FormGroup>
                    </div>
                    <div className="col-xl-4 col-md-4 col-xs-6 d-flex  justify-content-end ">
                      <FormGroup className="w-100">
                        <Label htmlFor="City" className="mb-2 text-dark">
                          City
                        </Label>
                        <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{events.eventcity}</div>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex ">
                      <div className="w-100">
                        <Label htmlFor="Name" className="mb-2 text-dark">
                          Website
                        </Label>
                        <div className="d-flex ">
                        <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{events.website}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex  justify-content-end">
                      <div className="w-100">
                        <Label htmlFor="Name" className="mb-2 text-dark">
                          Instagram
                        </Label>
                        <div className="d-flex ">
                        <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{events.instagram}</div>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex justify-content-center">
                      <div className="w-100">
                        <Label htmlFor="Name" className="mb-2 text-dark">
                          {" "}
                          Facebook
                        </Label>
                        <div className="d-flex ">
                        <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{events.facebook}</div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex  justify-content-end">
                      <div className="w-100">
                        <Label htmlFor="Name" className="mb-2 text-dark">
                          Discord
                        </Label>
                        <div className="d-flex ">
                          <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{events.discord}</div>
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-4">
                {eventloader? 
                 <button className="btn buttonhover  text-light bg-prussianBlue">
               <img src={loadiing} alt='loader' style={{width:"40px", height:"40px",alignSelf:"center"}}/>
               </button>
				 : 
				 <button onClick={()=>evetschange()} className="btn buttonhover text-light bg-prussianBlue">
         Edit
       </button>
	  } 
                  
                </div>
              </div> :  
              <div className="row  mt-5 d-flex align-items-center">
                <div className="col-xl-3 col-lg-3 col-md-3 col-xs-6 d-flex justify-content-center">
                  <UploadImage
                    width="200px"
                    height="200px"
                    title="Icons"
                    style={{ backgroundColor: "gainsboro" }}
                    onChange={(e) => onChangeAddNFTImage(e)}
                    imageurl={events.eventimgname ? events.eventimgname : ""}
                    // { ...registeredValue('photo') }
                  />
                </div>
                <div className="col-lg-9 col-9">
                  <div className="row">
                    <div className="col-xl-4 col-md-4 col-xs-6 d-flex ">
                      <FormGroup className="w-100">
                        <Label htmlFor="Name" className="mb-2 text-dark">
                          Name
                        </Label>
                        <TextField
                          id="name"
                          value={events.eventname}
                        //   onChange={(e) => setevents({...events,eventname:e.target.value})}
                          placeholder="Name"
                          className="input-name border rounded-md"
                          style={{ minWidth: "250px", height: "40px" }}
                          noInlineError
                        />
                      </FormGroup>
                    </div>
                    <div className="col-xl-4 col-md-4 col-xs-6 d-flex justify-content-center ">
                      <FormGroup className="w-100">
                        <Label htmlFor="Country" className="mb-2 text-dark">
                          Country
                        </Label>
                        <Dropdown
                          className="h-[40px]"
                          menuClassname="h-[100px]"
                          value={events.eventcountry}
                          options={countryOption}
                          onChange={(e) => {
                            setevents({...events,eventcountry:e.target.value})
                           
                          }}
                          placeholder="Choose Country"
                          // options={countries}
                          style={{ width: "350px" }}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-xl-4 col-md-4 col-xs-6 d-flex  justify-content-end ">
                      <FormGroup className="w-100">
                        <Label htmlFor="City" className="mb-2 text-dark">
                          City
                        </Label>
                        <TextField
                          id="city"
                          value={events.eventcity}
                          onChange={(e) => setevents({...events,eventcity:e.target.value})}
                          placeholder="City"
                          className="input-name border rounded-md"
                          style={{ minWidth: "250px", height: "40px" }}
                          noInlineError
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex ">
                      <div className="w-100">
                        <Label htmlFor="Name" className="mb-2 text-dark">
                          Website
                        </Label>
                        <div className="d-flex ">
                          <GlobalOutlined className="icon_pos" />
                          <input
                            id="website"
                            value={events.website}
                            onChange={(e) => setevents({...events,website:e.target.value})}
                            placeholder="Website link"
                            className="input-name  border w-100 rounded-md"
                            style={{
                              padding: " 0px 0px 0px 40px",
                              height: "40px",
                            }}
                            noInlineError
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex  justify-content-end">
                      <div className="w-100">
                        <Label htmlFor="Name" className="mb-2 text-dark">
                          Instagram
                        </Label>
                        <div className="d-flex ">
                          <BsInstagram className="icon_pos" />
                          <input
                            id="instagram"
                            value={events.instagram}
                            onChange={(e) => setevents({...events,instagram:e.target.value})}
                            placeholder="Instagram link"
                            className="input-name w-100 border rounded-md"
                            style={{
                              padding: " 0px 0px 0px 40px",
                              height: "40px",
                            }}
                            noInlineError
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex justify-content-center">
                      <div className="w-100">
                        <Label htmlFor="Name" className="mb-2 text-dark">
                          {" "}
                          Facebook
                        </Label>
                        <div className="d-flex ">
                          <RiFacebookCircleLine className="icon_pos" />
                          <input
                            id="facebook"
                            value={events.facebook}
                            onChange={(e) => setevents({...events,facebook:e.target.value})}
                            placeholder="FaccBook link"
                            className="input-name  border w-100 rounded-md"
                            style={{
                              padding: " 0px 0px 0px 40px",
                              height: "40px",
                            }}
                            noInlineError
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex  justify-content-end">
                      <div className="w-100">
                        <Label htmlFor="Name" className="mb-2 text-dark">
                          Discord
                        </Label>
                        <div className="d-flex ">
                          <RxDiscordLogo className="icon_pos" />
                          <input
                            id="discord"
                            value={events.discord}
                            onChange={(e) => setevents({...events,discord:e.target.value})}
                            placeholder="Discord link"
                            className="input-name w-100 border rounded-md"
                            style={{
                              padding: " 0px 0px 0px 40px",
                              height: "40px",
                            }}
                            noInlineError
                          />
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-4">
                {eventloader? 
                 <button className="btn buttonhover text-light bg-prussianBlue">
               <img src={loadiing} alt='loader' style={{width:"40px", height:"40px",alignSelf:"center"}}/>
               </button>
				 :
				  <button onClick={()=>evetschange()} className="btn buttonhover text-light bg-prussianBlue">
             Save
           </button>
		   }   
                </div>              
              </div> }
              <div className='row d-flex justify-contend-center mt-3'>
              <div className='col col-lg-3 col-md-3'></div>
                  <div className='col col-lg-6 col-md-6'>
          <div
          className='row'
            style={{
              backgroundColor: "rgb(0 48 81)",
              padding: "20px",
              width:"400px",
              borderRadius: "10px",
            }}
          >  
          <div className='col col-lg-7 col-md-7'>

			  <div className="" style={{fontWeight:"700",color:"rgb(230 234 237)",fontSize:"20px"}}>Available Balance</div>
        <div className="mt-3" style={{fontWeight:"700",color:"rgb(114 254 127)",fontSize:"20px"}}>${(Number(matictoUsd)+Number(usdttoUsd)+Number(usdctoUsd)).toFixed(2)}</div>
        </div> 

          <div className='col col-lg-5 col-md-5 d-flex-justify-contend-center align-items-center'>
              <button className='ml-3' onClick={()=>setmodal(true)} style={{background:"rgb(114 254 127)" ,color:"rgb(0 48 81)",fontWeight:"700",borderRadius:"20px",width:"100px",height:"50px"}}>Withdraw</button>
            </div>
        </div>

           

                  </div>      
              </div>

			  {Collection && Collection.map((row,index)=>{
              return(
                <>
                <div
                className="col mt-5 d-flex justify-content-between align-items-center"
                style={{
                  backgroundColor: "#c6c6c6d6",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <div>
				<div className="mt-2" style={{fontWeight:"700",color:"#003051",fontSize:"20px"}}>Collections-{index+1}</div>
                </div>
                <div>
                  <button className="border p-3 rounded border-dark " disabled={ events.eventid =="" } onClick={()=>addCollection("col")}>                    
                    + Add Collection
                  </button>
                </div>
              </div>
           
                <div className="row mt-5">
            { row.NFTcolletionbutstate ?
                <>
                <div className="col-xl-3 col-lg-3 col-md-3 col-xs-6 d-flex justify-center">
                <div className="row d-flex justify-center">
                { Collection[index].NFTCollectionsimage ?
                <img src={`${process.env.REACT_APP_S3_URL}${Collection[index].NFTCollectionsimage}`}  width="200px"
                height="200px"
                />: null
                }
                  </div>
                </div>
                <div className="col-lg-9 col-9">
                  <div className="row">
                    <div className="col-xl-4 col-md-4 col-xs-6 d-flex ">
                      <FormGroup className="w-100">
                        <Label htmlFor="Collection" className="mb-2 text-dark">
                          Collection
                        </Label>
                        <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{Collection[index].NFTCollections}</div>
                      </FormGroup>
                    </div>
                    <div className="col-xl-4 col-md-4 col-xs-6 d-flex justify-content-center ">
                      <FormGroup className="w-100">
                        <Form.Label htmlFor="tier" className="mb-2 text-dark">
                          Tier
                        </Form.Label>
                        <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{Collection[index].NFTTier}</div>
                      </FormGroup>
                    </div>
                    <div className="col-xl-4 col-md-4 col-xs-6 d-flex  justify-content-end ">
                      <FormGroup className="w-100">
                        <Label htmlFor="City" className="mb-2 text-dark">
                          Royality
                        </Label>
                        <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{Collection[index].NFTCollectionRoyality}</div>
                        </FormGroup>
                    </div>
                  </div>
                 
                  <div className="row mt-4">
                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex justify-content-center">
                      <div className="w-100">
                        <Form.FormGroup className="input-wrapper mt-2">
                          <Form.Label
                            htmlFor="description"
                            className="mb-2 text-dark"
                          >
                            Description
                          </Form.Label>
                          
                          <Form.TextArea
                            id="description"
                            placeholder="Description"
                            name="CollectionDescription"
                            value={Collection[index].Description}
                            className="h-[80px] rounded-md resize-none p-2"
                            noInlineError
                          />
                        </Form.FormGroup>
                      </div>
                    </div>

                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex  justify-content-end">
                      <div className="w-100">
                      <Form.FormGroup disabled={ events.eventid =="" } className="input-wrapper mt-2">
                      <Form.Label
                            htmlFor="description"
                            className="mb-2 text-dark"
                          >
                            Trade
                          </Form.Label>
                          {Collection[index].NFTCollectionTradestatus === "trade"?
                        <label class="container mt-1">
                          NFTs shall be traded outside of OKU
                          <input type="radio" value="trade" id={index} name={"Collectiontrade"+index} checked={Collection[index].NFTCollectionTradestatus === "trade"} />
                          <span class="checkmark"></span>
                        </label>
                        :
                        <label class="container mt-2">
                          NFTs shall not be traded outside of OKU
                          <input type="radio" value="nontrade" id={index} name={"Collectiontrade"+index} checked={Collection[index].NFTCollectionTradestatus === "nontrade"} />
                          <span class="checkmark"></span>
                        </label>
                        }
                        </Form.FormGroup>
                      </div>
                    </div>
                    <div className="row mt-4">
                    <div className="col-xl-12 col-md-12 col-xs-12 d-flex justify-content-end"> 
                    <div className="w-100"> 
                    <div className="d-flex justify-content-end">
                    {collectionloader ? 
                    <button className="btn buttonhover  text-light bg-prussianBlue">
                  <img src={loadiing} alt='loader' style={{width:"20px", height:"20px",alignSelf:"center"}}/>
                  </button>
                    : 
                    <button className="btn buttonhover mx-5 text-light bg-prussianBlue" onClick={()=>onchangecollection(index)}> Edit</button> 
                  }

                  { Collection && Collection.length > 1 ? <button className="btn btn-outline-danger" onClick={()=>removeCollection("col",index)}> Remove </button> : null }
                    

                     </div>
                    </div>
                        </div>
                        </div>
                  </div>
                </div>
				
				
				</> :
                 <>
                 <div className="col-xl-3 col-lg-3 col-md-3 col-xs-6 d-flex justify-center">
                <div className="row d-flex justify-center">
                { Collection[index].NFTCollectionsimage ?
                <img src={`${process.env.REACT_APP_S3_URL}${Collection[index].NFTCollectionsimage}`}  width="200px"
                height="200px"
                />: null
                } 
                {colimgloader ?
                <label className="btn buttonhover text-light bg-prussianBlue mt-2" style={{display:"flex",alignItems:"center",justifyContent:"center",width:"80px",height:"45px"}} htmlFor={"contained-button-file"+index}>
                 <img src={loadiing} alt='loader' style={{width:"20px", height:"20px",alignSelf:"center"}}/>
                </label>
                :
                <label className="btn buttonhover text-light bg-prussianBlue mt-2" style={{display:"flex",alignItems:"center",width:"80px",height:"45px"}} htmlFor={"contained-button-file"+index}>
                Upload
                </label>
                }
                <input
                    type="file"
                    id={"contained-button-file"+index}
                    accept={"image/png, image/jpeg, image/jpg"}
                    style={{ display: "none" }}
                    disabled={ events.eventid =="" }
                    onChange={(e) => changecollection(e,index)}
                  />
                  </div>
                </div>
                <div className="col-lg-9 col-9">
                  <div className="row">
                    <div className="col-xl-4 col-md-4 col-xs-6 d-flex ">
                      <FormGroup className="w-100">
                        <Label htmlFor="Collection" className="mb-2 text-dark">
                          Collection
                        </Label>
                        <TextField
                          name="NFTCollections"
                          value={Collection[index].NFTCollections}
                          disabled={ events.eventid =="" }
                          onChange={(e) => handelcollection(e.target,index)}
                          placeholder="Collection Name"
                          className="input-name border rounded-md"
                          style={{ minWidth: "250px", height: "40px" }}
                          noInlineError
                        />
                      </FormGroup>
                    </div>
                    <div className="col-xl-4 col-md-4 col-xs-6 d-flex justify-content-center ">
                      <FormGroup className="w-100">
                        <Form.Label htmlFor="tier" className="mb-2 text-dark">
                          Tier
                        </Form.Label>
                        { console.log("tierss",tiers_.filter((object1) => !Collection.some((object2) => object1.value === object2.NFTTier)))}
                        {tiers_.filter((object1) => !Collection.some((object2) => object1.value === object2.NFTTier)).length > 0 ? 
                        <Dropdown
                          className="h-[40px]  mr-5"
                          menuClassname="h-[100px]  z-20"
                          options={tiers_ && tiers_.filter((object1) => !Collection.some((object2) => object1.value === object2.NFTTier)) }
                          disabled={ events.eventid =="" || tiers_.length === 0}
                          name={"NFTTier"+index}
                          id={ index }
                          value={Collection[index].NFTTier}
                          onChange={(e) => handelcollection1(e.target,index)}
                          placeholder="Choose Tier"
                        /> : 
                        <TextField
                        type="text"
                        value={Collection[index].NFTTier}
                        className="input-name border rounded-md"
                        style={{ minWidth: "250px", height: "40px" }}
                        noInlineError
                      />
                        }
                      </FormGroup>
                    </div>
                    <div className="col-xl-4 col-md-4 col-xs-6 d-flex  justify-content-end ">
                      <FormGroup className="w-100">
                        <Label htmlFor="City" className="mb-2 text-dark">
                          Royality
                        </Label>
                        <TextField
                          name="NFTCollectionRoyality"
                          type="number"
                          value={Collection[index].NFTCollectionRoyality}
                          onChange={(e) => handelcollection(e.target,index)}
                          placeholder="Royality in %"
                          disabled={ events.eventid =="" }
                          className="input-name border rounded-md"
                          style={{ minWidth: "250px", height: "40px" }}
                          noInlineError
                        />
                      </FormGroup>
                    </div>
                  </div>
                 
                  <div className="row mt-4">
                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex justify-content-center">
                      <div className="w-100">
                        <Form.FormGroup className="input-wrapper mt-2">
                          <Form.Label
                            htmlFor="description"
                            className="mb-2 text-dark"
                          >
                            Description
                          </Form.Label>
                          <Form.TextArea
                            id="description"
                            placeholder="Description"
                            name="Description"
                            disabled={  events.eventid =="" }
                            value={Collection[index].Description}
                            className="h-[80px] border rounded-md resize-none p-2"
                            onChange={(e) => handelcollection(e.target,index)}
                            noInlineError
                            // { ...registeredValue('description') }
                          />
                        </Form.FormGroup>
                      </div>
                    </div>

                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex  justify-content-end">
                      <div className="w-100">
                      <Form.FormGroup onChange={onChangeValue} disabled={ events.eventid =="" } className="input-wrapper mt-2">
                      <Form.Label
                            htmlFor="description"
                            className="mb-2 text-dark"
                          >
                            Trade
                          </Form.Label>
                        <label class="container mt-1">
                          NFTs shall be traded outside of OKU
                          <input type="radio" value="trade" id={index} name={"NFTCollectionTradestatus"+index} checked={Collection[index].NFTCollectionTradestatus === "trade"} />
                          <span class="checkmark"></span>
                        </label>
                        <label class="container mt-2">
                          NFTs shall not be traded outside of OKU
                          <input type="radio" value="nontrade" id={index} name={"NFTCollectionTradestatus"+index}  checked={Collection[index].NFTCollectionTradestatus === "nontrade"} />
                          <span class="checkmark"></span>
                        </label>
                        </Form.FormGroup>
                      </div>
                    </div>
                    <div className="row mt-4">
                    <div className="col-xl-12 col-md-12 col-xs-12 d-flex justify-content-end"> 
                    <div className="w-100"> 
                    <div className="d-flex justify-content-end">
                    {collectionloader ? 
                 <button className="btn buttonhover text-light bg-prussianBlue">
               <img src={loadiing} alt='loader' style={{width:"40px", height:"40px",alignSelf:"center"}}/>
               </button>
				 : 
         <button className="btn buttonhover mx-5 text-light bg-prussianBlue" onClick={()=>onchangecollection(index)}> Save</button> 
           }
            { Collection && Collection.length > 1 ? <button className="btn btn-outline-danger " onClick={()=>removeCollection("col",index)}> Remove </button> : null} 
                     </div>
                    </div>
                        </div>
                        </div>
                  </div>
                </div>
				</> }
              </div>
      
{row?.nftgroup?.map((item,ixe)=>{
			return(
		<div class="container-fluid mt-4">
			<div className="row">
				<div className="col" style={{ backgroundColor: "#c6c6c6d6",padding: "10px",borderRadius: "10px",}}>
            <div className='row d-flex justify-center'>
            <div className="col col-lg-4 mt-2" style={{fontWeight:"500",color:"#003051",fontSize:"20px"}}>NFT Group : {item.Nftgroupname}</div>
            <div className="col col-lg-4 mt-2" style={{fontWeight:"500",color:"#003051",fontSize:"20px"}}>Total Supply : {item.NFTS ? item.NFTS.length : 0 }</div>
            <div className="col col-lg-4 mt-2" style={{fontWeight:"500",color:"#003051",fontSize:"20px"}}>Trade Date : {item.NFTS? timeconvertdays(Number(item.NFTS[0].NFTTradedate)*1000) : 0}</div>
            </div>
			</div>
              {item?.NFTS?.map((evs,i)=>(
				<did className="row border-bottom py-2 mt-2"> 
			{evs.edit ? <> 
				<div className="col-xl-3 col-lg-3 col-md-3 col-xs-6 d-flex justify-center">
                <div className="row d-flex justify-center">
                { evs.NFTImage ?
                <img src={`${evs.NFTImage}`}  width="200px"
                height="200px"
                />: null
                }
                {nftimgloader ?
                <label className="btn buttonhover text-light bg-prussianBlue mt-2" style={{display:"flex",alignItems:"center",width:"80px",height:"45px"}} htmlFor={"contained-button-file"+index+i}>
                <img src={loadiing} alt='loader' style={{width:"20px", height:"20px",alignSelf:"center"}}/>
                </label> 
                 :
                <label className="btn buttonhover text-light bg-prussianBlue mt-2" style={{display:"flex",alignItems:"center",width:"80px",height:"45px"}} htmlFor={"contained-button-file"+index+i}>
                Upload
                </label>
               }
                <input
                    type="file"
                    id={"contained-button-file"+index+i}
                    accept={"image/png, image/jpeg, image/jpg"}
                    style={{ display: "none" }}
                    onChange={(e) => changesnft(e,"NFTImage",index,ixe,i)}
                  /> 
                  </div>
                </div>
                <div className="col-lg-9 col-9">
                  <div className="row">
                    <div className="col-xl-3 col-md-3 col-xs-6 d-flex ">
                      <FormGroup className="w-100">
                        <Label htmlFor="Collection" className="mb-2 text-dark">
                          NFT Name
                        </Label>
                        <TextField
                          name={"NFTName"+index+ixe+i}
                          value={evs.NFTName}
                          onChange={(e) => nftvalueedit(e.target,"NFTName",index,ixe,i)}
                          placeholder="NFT Name"
                          className="input-name border rounded-md"
                          style={{ minWidth: "150px", height: "40px" }}
                          noInlineError
                        />
                      </FormGroup>
                    </div>
                    <div className="col-xl-3 col-md-3 col-xs-6 d-flex justify-content-center ">
					<FormGroup className="w-100">
                        <Label htmlFor="Collection" className="mb-2 text-dark">
                          NFT Price
                        </Label>
                        <TextField
                          name="NFTPrice"
                          value={evs.NFTPrice}
                          onChange={(e) => nftvalueedit(e.target,"NFTPrice",index,ixe,i)}
                          placeholder="NFTPrice"
                          className="input-name border rounded-md"
                          style={{ minWidth: "150px", height: "40px" }}
                          noInlineError
                        />
                      </FormGroup>
                    </div>
                    <div className="col-xl-3 col-md-3 col-xs-6 d-flex  justify-content-end ">

                      <FormGroup className="w-100">
                        <Label htmlFor="City" className="mb-2 text-dark">
                          Sale Start Date
                        </Label>
                        <input
                          name="NFTStartDate"
					            	  type="date"
                          max={dateformat(evs.NFTEndDate)}
                          value={dateformat(evs.NFTStartDate)}
                          onChange={(e) => nftvalueedit(e.target,"NFTStartDate",index,ixe,i)}
                          placeholder=""
                          className="input-name border rounded-md"
                          style={{ minWidth: "150px", height: "40px" }}
                          noInlineError
                        />
                      </FormGroup>
                    </div>
					<div className="col-xl-3 col-md-3 col-xs-6 d-flex  justify-content-end ">
                      <FormGroup className="w-100">
                        <Label htmlFor="City" className="mb-2 text-dark">
						            Sale End Date
                        </Label>
                        <input
                          name="NFTEndDate"
                          type="date"
                          min={dateformat(evs.NFTStartDate)}
                          defaultValue={dateformat(evs.NFTEndDate)}
                          onChange={(e) => nftvalueedit(e.target,"NFTEndDate",index,ixe,i)}
                          className="input-name border rounded-md"
                          style={{ minWidth: "150px", height: "40px" }}
                          noInlineError
                        />
                      </FormGroup>
                    </div>

                  </div>
                 
                  <div className="row mt-4">
                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex justify-content-center">
                      <div className="w-100">
                        <Form.FormGroup className="input-wrapper mt-2">
                          <Form.Label
                            htmlFor="description"
                            className="mb-2 text-dark"
                          >
                            Description
                          </Form.Label>
                          <Form.TextArea
                            id="description"
                            placeholder="Description"
                            name="Description"
                            value={evs.NFTDescription}
                            className="h-[80px] border rounded-md resize-none p-2"
                            onChange={(e) => nftvalueedit(e.target,"NFTDescription",index,ixe,i)}
                            noInlineError
                            // { ...registeredValue('description') }
                          />
                        </Form.FormGroup>
                      </div>
                    </div>

                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex  justify-content-end">
                      <div className="w-100">
					  <Form.FormGroup className="input-wrapper mt-2">
                          <Form.Label
                            htmlFor="description"
                            className="mb-2 text-dark"
                          >
                            Benefits
                          </Form.Label>
                          <Form.TextArea
                            id="benefits"
                            placeholder="Benefits"
                            name="Benefits"
                            value={evs.benefits}
                            className="h-[80px] border rounded-md resize-none p-2"
                            onChange={(e) => nftvalueedit(e.target,"benefits",index,ixe,i)}
                            noInlineError
                          />
                        </Form.FormGroup>
                      </div>
                    </div>
                    <div className="row mt-4">
                    <div className="col-xl-12 col-md-12 col-xs-12 d-flex justify-content-end"> 
                    <div className="w-100"> 
                    <div className="d-flex justify-content-end">
                  
         <button className="btn buttonhover mx-5 text-light bg-prussianBlue" onClick={()=>editnfts(index,ixe,i)}> Save</button> 
        
        {/* <button className="btn btn-outline-danger " onClick={()=>removenfts(index,ixe,i)}> Remove </button>  */}
                     </div>
                    </div>
                        </div>
                        </div>
                  </div>
                </div> 
			</> :
			<>
             <div className="col-xl-3 col-lg-3 col-md-3 col-xs-6 d-flex justify-center">
                <div className="row d-flex justify-center">
                { evs.NFTImage ?
                <img src={evs.NFTImage}  width="100px" height="100px"/>
		         : null
                 } 
                  </div>
                </div>
                <div className="col-lg-9 col-9">
                  <div className="row">
                    <div className="col-xl-3 col-md-3 col-xs-6 d-flex ">
                      <FormGroup className="w-100">
                        <Label htmlFor="Collection" className="mb-2 text-dark">
                          NFT Name
                        </Label>
                        <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{evs.NFTName}</div>
                      </FormGroup>
                    </div>
                    <div className="col-xl-3 col-md-3 col-xs-6 d-flex justify-content-center ">
                      <FormGroup className="w-100">
                        <Form.Label htmlFor="tier" className="mb-2 text-dark">
                          Price
                        </Form.Label>
                        <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{evs.NFTPrice}</div>
                      </FormGroup>
                    </div>
                    <div className="col-xl-3 col-md-3 col-xs-3 d-flex  justify-content-end ">
                      <FormGroup className="w-100">
                        <Label htmlFor="City" className="mb-2 text-dark">
                          Sale Start Date
                        </Label>
                        <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{evs.NFTStartDate}</div>
                        </FormGroup>
                    </div>
					<div className="col-xl-3 col-md-3 col-xs-3 d-flex  justify-content-end ">
                      <FormGroup className="w-100">
                        <Label htmlFor="City" className="mb-2 text-dark">
                          Sale End Date
                        </Label>
                        <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{evs.NFTEndDate}</div>
                        </FormGroup>
                    </div>
                  </div>
                 
                  <div className="row mt-4">
                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex justify-content-center">
                      <div className="w-100">
                        <Form.FormGroup className="input-wrapper mt-2">
                          <Form.Label
                            htmlFor="description"
                            className="mb-2 text-dark"
                          >
                            Description
                          </Form.Label>
                          
                          <Form.TextArea
                            id="description"
                            placeholder="Description"
                            name="CollectionDescription"
                            value={evs.NFTDescription}
                            className="h-[80px] rounded-md resize-none p-2"
                            noInlineError
                          />
                        </Form.FormGroup>
                      </div>
                    </div>

                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex  justify-content-end">
                      <div className="w-100">
					  <Form.FormGroup className="input-wrapper mt-2">
                          <Form.Label
                            htmlFor="description"
                            className="mb-2 text-dark"
                          >
                            Benefits
                          </Form.Label>
                          
                          <Form.TextArea
                            id="description"
                            placeholder="Description"
                            name="CollectionDescription"
                            value={evs.benefits}
                            className="h-[80px] rounded-md resize-none p-2"
                            noInlineError
                          />
                        </Form.FormGroup>
                      </div>
                    </div>
                    <div className="row mt-3">
                    <div className="col-xl-12 col-md-12 col-xs-12 d-flex justify-content-end"> 
                    <div className="w-100"> 
                    <div className="d-flex justify-content-end">
                    <button className="btn buttonhover mx-5 text-light bg-prussianBlue" onClick={()=>editnfts(index,ixe,i)}> Edit</button> 
                    <button className="btn btn-outline-danger " onClick={()=>removenfts(index,ixe,i)}> Remove </button>
                     </div>
                    </div>
                        </div>
                        </div>
                  </div>
                </div>
				</>
                }
				</did>
			  ))
              }
				</div>
				</div>
			)
		 })}
	


    {row?.Collectionnftgroup.map((data,i)=>{
              return(
                <>
                 <div className="col mt-5 d-flex justify-content-between align-items-center py-3 border-bottom">
             <div>
               <h1 className="text-blackLighter">NFT Group-{i+1}</h1>
             </div>
             { row.Collectionnftgroup && row.Collectionnftgroup.length > 0 ?
             <div className="d-flex">
               <AiFillCloseCircle className="text-danger" />{" "}
               <button className="text-danger mx-2 " onClick={()=>{removenftgroup("col",i,index)}}>Remove</button>
             </div> : null
              }
             </div>

         {data.nftgroupstatus ? <>
		 <div className="row mt-5">
			<div className="col-lg-12 col-12">
			<div className="row d-flex justify-center">
				<div className="col-xl-4 col-md-4 col-xs-6 d-flex">
				<FormGroup className="w-100">
					<Label htmlFor="Collection" className="mb-2 text-dark">
					Name
					</Label>
					{/* <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{Collection[index].Collectionnftgroup[i].nftgroupname}</div> */}
				</FormGroup>
				</div>
				<div className="col-xl-4 col-md-4 col-xs-6 d-flex">
				<FormGroup className="w-100">
					<Label htmlFor="City" className="mb-2 text-dark">
					Supply
					</Label>
					{/* <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{Collection[index].Collectionnftgroup[i].nftsupply}</div> */}
				</FormGroup>
				</div>
			</div>


			</div>
			</div>

			<div className="row mt-4">
			<div className="col-lg-12 col-12 d-flex justify-center">
			<span className="" style={{fontWeight:"800",fontSize:"20px"}}>Upload Image Folder</span>
			</div>
			</div>

			<div className="row mt-3">
			<div className="col-lg-12 col-12 d-flex justify-center">
			<div className="row d-flex justify-center">
				<div  className="col mt-3"
			style={{
			backgroundColor: "#c6c6c6d6",
			width:"850px",
			borderRadius:"1em",
			height:"100px"
			}}> 
			<div className="row mt-3">
			<div className="col col-lg-2 col-2 mt-1 d-flex justify-center">
			<BsFileEarmarkZipFill size={50} className=""/>
			</div> 
			<div className="col col-lg-7 col-7 mt-1">
			<span className="" style={{fontWeight:"800"}}>Upload Image Folder</span><br/>
			<span> Upload Folder with all the NFT images. Make sure images matches the total supply number</span>
			</div> 
			<div className="col col-lg-3 col-3 mt-1 d-flex justify-center ">
			<div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{Collection[index].Collectionnftgroup[i].nftzipname}</div>
			</div>
			</div>
				</div>
			</div>
			</div>
			</div>

			<div className="row mt-4">
			<div className="col-lg-12 col-12 d-flex justify-center">
			<span className="" style={{fontWeight:"800",fontSize:"20px"}}>Upload CSV File</span>
			</div>
			</div>

			<div className="row mt-3">
			<div className="col-lg-12 col-12 d-flex justify-center">
			<div className="row d-flex justify-center">
				<div  className="col mt-3"
			style={{
			backgroundColor: "#c6c6c6d6",
			width:"850px",
			borderRadius:"1em",
			height:"170px"
			}}> 
			<div className="row mt-3">
			<div className="col col-lg-2 col-2 mt-2 d-flex justify-center">
			<BsFileEarmarkZipFill size={50} className=""/>
			</div> 
			<div className="col col-lg-7 col-7 mt-1">
			<span className="" style={{fontWeight:"800"}}>Select a CSV File to Upload</span><br/>
			<span> Download the CSV file template to bring in NFT metadata,fill in all fields in CSV file which should match with total supply and NFT name should match with file name of images that was uploaded in previous step.</span>
			</div> 
			<div className="col col-lg-3 col-3 mt-2 d-flex justify-center">
			<div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{Collection[index].Collectionnftgroup[i].nftcsvname}</div>
			</div>     
			</div>
				</div>
			</div>
			</div>
			</div>
			<div className="d-flex justify-content-end mt-4">
			<button className="btn mx-5 text-dark border border-dark " onClick={()=>addnftgroup("col",index)}>
			+Add Nft
			</button>

			<button className="btn buttonhover text-light bg-prussianBlue" onClick={()=>editsavenftgroup(index,i)}>
			Edit
			</button>
			</div></> : <><div className="row mt-5">

			<div className="col-lg-12 col-12">
			<div className="row d-flex justify-center">
			<div className="col-xl-4 col-md-4 col-xs-6 d-flex">
				<FormGroup className="w-100">
				<Label htmlFor="Collection" className="mb-2 text-dark">
					Name
				</Label>
				<TextField
					id="website"
					name="nftgroupname"
				  value={Collection[index].Collectionnftgroup[i].nftgroupname}
					disabled={row.NFTCollectionid ==""}
				  onChange={(e) => {Collection[index].Collectionnftgroup[i].firstgroupsave ? toast("Unable to edit") : setnftGroupvalues(index,i,e.target)}}
			
					placeholder=" Name"
					className="input-name border rounded-md"
					style={{ minWidth: "250px", height: "40px" }}
					noInlineError
				/>
				</FormGroup>
			</div>
			<div className="col-xl-4 col-md-4 col-xs-6 d-flex">
				<FormGroup className="w-100">
				<Label htmlFor="City" className="mb-2 text-dark">
					Supply
				</Label>
				<input
					id="website"
					name="nftsupply"
					value={Collection[index].Collectionnftgroup[i].nftsupply}
					disabled={row.NFTCollectionid ==""}
					onChange={(e) =>setnftGroupvalues(index,i,e.target)}
					placeholder="Supply"
					className="input-name border rounded-md"
					style={{ minWidth: "250px", height: "40px" }}
					noInlineError
				/>
				</FormGroup>
			</div>
			</div>


			</div>
			</div>

			<div className="row mt-4">
			<div className="col-lg-12 col-12 d-flex justify-center">
			<span className="" style={{fontWeight:"800",fontSize:"20px"}}>Upload Image Folder</span>
			</div>
			</div>

			<div className="row mt-3">
			<div className="col-lg-12 col-12 d-flex justify-center">
			<div className="row d-flex justify-center">
				<div  className="col mt-3"
			style={{
			backgroundColor: "#c6c6c6d6",
			width:"850px",
			borderRadius:"1em",
			height:"100px"
			}}> 
			<div className="row mt-3">
			<div className="col col-lg-2 col-2 mt-1 d-flex justify-center">
			<BsFileEarmarkZipFill size={50} className=""/>
			</div> 
			<div className="col col-lg-7 col-7 mt-1">
			<span className="" style={{fontWeight:"800"}}>Upload Image Folder</span><br/>
			<span> Upload Folder with all the NFT images. Make sure images matches the total supply number</span>
			</div> 
			<div className="col col-lg-3 col-3 mt-1 d-flex justify-center ">
			{Collection[index]?.Collectionnftgroup[i]?.nftzipname ? <div className="row d-flex justify-center align-items-center"> 
			<div className="row d-flex justify-center mb-2">{Collection[index].Collectionnftgroup[i].nftzipname ? Collection[index].Collectionnftgroup[i].nftzipname :""}</div> 
			{ziploader ?<>
			<label className="btn buttonhover text-light bg-prussianBlue" style={{display:"flex",alignItems:"center" ,justifyContent:"center",width:"95px",height:"45px"}} htmlFor={"contained-button_"+index+i}>
			<img src={loadiing} alt='loader' style={{width:"20px", height:"20px",alignSelf:"center"}}/>
			</label>
			<input
					type="file"
					id={"contained-button_"+index+i}
					style={{display:"none"}}
					accept=".zip"
					// disabled={row.NFTCollectionid ==""}
				  onChange={(e)=>uploadFiles(index,i,e,"nftzip","nftzipname")}
				/>   </> : <><label className="btn buttonhover text-light bg-prussianBlue" style={{display:"flex",alignItems:"center",justifyContent:"center",width:"100px",height:"45px"}} htmlFor={"contained-button_"+index+i}>
				Uploaded
			</label>
			<input
					type="file"
					id={"contained-button_"+index+i}
					style={{display:"none"}}
					accept=".zip"
					// disabled={row.NFTCollectionid ==""}
					onChange={(e)=>uploadFiles(index,i,e,"nftzip","nftzipname")}
				/> </>}    
				</div> 
				
						
				:
				
			<>
			{ziploader ? <><label className="btn buttonhover text-light bg-prussianBlue" style={{display:"flex",alignItems:"center",justifyContent:"center",width:"80px",height:"45px"}} htmlFor={"contained-button_"+i}>
			<img src={loadiing} alt='loader' style={{width:"20px", height:"20px",alignSelf:"center"}}/>
			</label>
			<input
					type="file"
					id={"contained-button_"+i}
					style={{display:"none"}}
					// disabled={row.NFTCollectionid ==""}
					accept=".zip"
				//  onChange={(e)=>{console.log("upload-check",index,i);uploadFiles(index,i,e,"nftzip","nftzipname")}}
				/></>  :  <>
				<label className="btn buttonhover text-light bg-prussianBlue" style={{display:"flex",alignItems:"center",width:"80px",height:"45px"}} htmlFor={"contained-button_"+index+i}>
			Upload
			</label>
			<input
					type="file"
					id={"contained-button_"+index+i}
					accept=".zip"
					style={{display:"none"}}
					// disabled={row.NFTCollectionid ==""}
					onChange={(e)=>{uploadFiles(index,i,e,"nftzip","nftzipname")}}
				/>
				
				</> }
				</>
			}
			</div>
				
			</div>
				</div>
			</div>
			</div>
			</div>

			<div className="row mt-4">
			<div className="col-lg-12 col-12 d-flex justify-center">
			<span className="" style={{fontWeight:"800",fontSize:"20px"}}>Upload CSV File</span>
			</div>
			</div>

			<div className="row mt-3">
			<div className="col-lg-12 col-12 d-flex justify-center">
			<div className="row d-flex justify-center">
				<div  className="col mt-3"
			style={{
			backgroundColor: "#c6c6c6d6",
			width:"850px",
			borderRadius:"1em",
			height:"170px"
			}}> 
			<div className="row mt-3">
			<div className="col col-lg-2 col-2 mt-2 d-flex justify-center">
			<BsFileEarmarkZipFill size={50} className=""/>
			</div> 
			<div className="col col-lg-7 col-7 mt-1">
			<span className="" style={{fontWeight:"800"}}>Select a CSV File to Upload</span><br/>
			<span> Download the CSV file template to bring in NFT metadata,fill in all fields in CSV file which should match with total supply and NFT name should match with file name of images that was uploaded in previous step.</span>
			</div> 
			<div className="col col-lg-3 col-3 mt-2 d-flex justify-center">
			{Collection[index].Collectionnftgroup[i].nftcsvname ?  <div className="row d-flex justify-center align-items-center"> 
			<div className="row d-flex justify-center mb-2">{Collection[index].Collectionnftgroup[i].nftcsvname ? Collection[index].Collectionnftgroup[i].nftcsvname : jsonFilename}</div>
     {csvloader ?
      <label className="btn buttonhover text-light bg-prussianBlue" style={{display:"flex",alignItems:"center",width:"80px",height:"45px"}} htmlFor={"contained_file"+index+i}>
			 <img src={loadiing} alt='loader' style={{width:"20px", height:"20px",alignSelf:"center"}}/>
			</label>
      :
			<label className="btn buttonhover text-light bg-prussianBlue" style={{display:"flex",alignItems:"center",width:"80px",height:"45px"}} htmlFor={"contained_file"+index+i}>
			Uploaded
			</label>}
			<input
			type="file"
			accept=".xlsx, .xls, .csv"
			id={"contained_file"+index+i}
			style={{display:"none"}}
			placeholder="Upload"
			// disabled={row.NFTCollectionid ==""}
		  onChange={(e)=>bannerImageChange(index,i,e,"nftcvs")}
			/>
			</div>  
			: <>
     {csvloader ?
      <label className="btn buttonhover text-light bg-prussianBlue" style={{display:"flex",alignItems:"center",justifyContent:"center",width:"80px",height:"45px"}} htmlFor={"contained_file"+index+i}>
		  <img src={loadiing} alt='loader' style={{width:"20px", height:"20px",alignSelf:"center"}}/>
			</label>
      :
			<label className="btn buttonhover text-light bg-prussianBlue" style={{display:"flex",alignItems:"center",width:"100px",justifyContent:"center",height:"45px"}} htmlFor={"contained_file"+index+i}>
			Upload
			</label>}
			<input
			type="file"
			accept=".xlsx, .xls, .csv"
			id={"contained_file"+index+i}
			style={{display:"none"}}
			placeholder="Upload"
			// disabled={row.NFTCollectionid ==""}
      // Collection[index].Collectionnftgroup[i].nftzipname ? 
      // : toast("upload Image folder")
			onChange={(e)=> bannerImageChange(index,i,e,"nftcvs") }
			/>
			</>   
			}


			</div>

			<div className="row d-flex justify-center align-items-center mt-3">
			<a  className="d-flex justify-center" href="https://sunrisetechs.s3-ap-southeast-2.amazonaws.com/oku/MetadataTemplate.xlsx" download>
			<label className="btn buttonhover text-light bg-prussianBlue" style={{display:"flex",alignItems:"center",width:"174px",height:"45px"}} >
			Download Template
			</label>
			</a>
			</div>

			</div>
				</div>
			</div>

			</div>
			</div>

			<div className="d-flex justify-content-end mt-4">
			<button className="btn buttonhover text-light bg-prussianBlue mr-10" onClick={()=>editsavenftgroup(index,i)}>
			Save
			</button>
			</div></>
			}   
			
			      
           <div className="row justify-content-center ">
             <div className="col-4 d-flex justify-content-center mt-4">
               <label class="container d-flex justify-content-center align-items-center">
                 <div class="mt-1">NFTs shall be traded only after {Collection[index].Collectionnftgroup[i].nfttradedate ? timeconvertdays(Number(Collection[index].Collectionnftgroup[i].nfttradedate) * 1000) : "Sale End Date"}</div>
                 <input type="radio" checked={true} name={"radio"+index+i} />
                 <span class="checkmark"></span>
               </label>
             </div>
           </div>
           </> )
               })}


              </> )
            })}


<div className="row justify-content-center align-item-center">

             <div className="col-4 d-flex justify-content-center mt-4 ">
                 <button className="btn buttonhover text-light bg-prussianBlue w-75" onClick={()=>submitevents()} >Submit</button>
             </div>
  </div>         

			</Div>
			</div>
			</div>
      
      {modal &&
      <Modal
          visible={ modal }
          onClickClose={()=>setmodal(false) }
        >
          <div className='modal-input-container'>
            <Text
              fontType='h2'
              text='Withdraw Funds'
              color='var(--blue)'
              className='mr-30 mb-5 join-modal-title'
            />
            <div className='row d-flex justify-content-space-between'>
              <div className='col col-lg-4 col-md-4'>
              <button className='d-flex justify-content-space-between align-items-center' onClick={()=>settoken(1)} style={  token == 1 ?{color:"rgb(114 254 127)" ,background:"rgb(0 48 81)",fontWeight:"700",borderRadius:"20px",width:"150px",height:"50px"} :{color:"rgb(0 48 81)" ,background:"rgb(114 254 127)",fontWeight:"700",borderRadius:"20px",width:"150px",height:"50px"} }> <img className='ml-2' src={Images.MATIC}/> <div className='ml-2'>Matic</div> <div className='ml-6'>{maticvalue}</div></button>
              </div>
              <div className='col col-lg-4 col-md-4'>
              <button className='d-flex justify-content-space-between align-items-center' onClick={()=>settoken(2)} style={ token == 2 ?{color:"rgb(114 254 127)" ,background:"rgb(0 48 81)",fontWeight:"700",borderRadius:"20px",width:"150px",height:"50px"} :{color:"rgb(0 48 81)" ,background:"rgb(114 254 127)",fontWeight:"700",borderRadius:"20px",width:"150px",height:"50px"}}><img className='ml-2' src={Images.USDT}/> <div className='ml-2'>USDT</div> <div className='ml-6'>{usdtvalue}</div></button>
              </div>
              <div className='col col-lg-4 col-md-4'>
              <button className='d-flex justify-content-space-between align-items-center' onClick={()=>settoken(3)} style={token == 3 ?{color:"rgb(114 254 127)" ,background:"rgb(0 48 81)",fontWeight:"700",borderRadius:"20px",width:"150px",height:"50px"} :{color:"rgb(0 48 81)" ,background:"rgb(114 254 127)",fontWeight:"700",borderRadius:"20px",width:"150px",height:"50px"} }><img className='ml-2' src={Images.USDC}/><div className='ml-2'>USDC</div><div className='ml-6'>{usdcvalue}</div></button>
              </div>
            </div>
            <div className='row mt-4 input-name border rounded-md mt-3'>
            <div className='col col-lg-8 col-md-8'>
            <input
              id="website"
              name="nftsupply"
              value={withdrawvalue}
              onChange={(e) =>setwithdrawvalue(e.target.value)}
              placeholder="Enter withdraw amount"
              // className="input-name border rounded-md"
              style={{ minWidth: "250px", height: "60px",border:"none",outline:"unset" }}
              noInlineError
            />
            </div>
            <div className='col col-lg-4 col-md-4 d-flex justify-content-center align-items-center'>
             {token == 0 ? null :<img width={30} height={30} src={token == 1 ?Images.MATIC : token == 2 ? Images.USDT : token == 3 ? Images.USDC : ""}/>}
             <div className='ml-2' style={{fontWeight:"800",fontSize:"20px"}}>{token == 1 ?"MATIC" : token == 2 ? "USDT" : token == 3 ? "USDC" : ""}</div>
            </div>
           
            </div>
            <div className='row mt-5 d-flex justify-content-center'>
              <WalletStyles className='row d-flex justify-content-center'>
              <button onClick={()=>withdrawfund()} className='btn buttonhover text-light bg-prussianBlue w-75 h-30' >
                Withdraw
              </button>
              </WalletStyles>
            </div>
          </div>
        </Modal>
}
</WalletStyles>
		</div>
	);
};

export default React.memo(EventDetail);