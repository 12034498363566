import { post , get , put } from "../http";

export const signUp = async (data) => {
  try {
    const result = await post(`auth/signup`, data);
    return result;

  } catch (error) {
    throw error;
  }
};

export const signIn = async (user) => {
  let data;
  try {
    data = await post(`auth/login`, user);
  } catch (error) {
    throw error;
  }
  return data;
};


export const getAllusers = async () => {
  try {
   const  data = await get(`auth/getAllusers`);
    return data;
  } catch (error) {
    throw error;
  }
};


export const deactivateUser = async (id) => {
  try {
   const  data = await put(`auth/deactivateUser/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const deleteUser = async (id) => {
  try {
   const  data = await put(`auth/deleteUser/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const getadminrefferalcode = async ()=>{
  try {
    const  data = await get(`auth/getadminrefferalcode`);
     return data;
   } catch (error) {
     throw error;
   }
}

export const deletereferalcode = async (id)=>{
  try {
    const  data = await post(`auth/deletereferalcode/${id}`);
     return data;
   } catch (error) {
     throw error;
   }
}

export const findreffereduser = async (id) => {
  try {
   const  data = await post(`auth/findreffereduser`,id);
    return data;
  } catch (error) {
    throw error;
  }
};



export const adminupdatemembership = async(id,values)=>{
  try {
    const  data = await put(`auth/adminupdatemembership/${id}`,values);
     return data;
   } catch (error) {
     throw error;
   }
}

export const activateUser = async (id) => {
  try {
   const  data = await put(`auth/activateUser/${id}`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const addAdmins = async (values,tokens) => {
  try {
   const  data = await post(`auth/addAdmins`, values, tokens);
    return data;
  } catch (error) {
    throw error;
  }
};


export const EditAdmins = async (id,values,tokens) => {
  try {
   const  data = await put(`auth/EditAdmins/${id}`, values, tokens);
    return data;
  } catch (error) {
    throw error;
  }
};
export const sendVerificationCode = async (values) => {
  try {
   const  data = await put(`auth/sendVerificationCode`, values);
    return data;
  } catch (error) {
    throw error;
  }
};

export const verifyOtp = async (values) => {
  try {
   const  data = await put(`auth/verifyOtp`, values);
    return data;
  } catch (error) {
    throw error;
  }
};

export const resetPassword = async (id,values) => {
  try {
    const data = await post(`auth/resetPasswords/${id}`, values);
    return data;
  } catch (error) {
    throw error;
  }
};


export const ListallUsers = async () => {
  try {
   const  data = await get(`auth/listallUsers`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const listallWallets = async () => {
  try {
   const  data = await get(`auth/listallWallets`);
    return data;
  } catch (error) {
    throw error;
  }
};

export const generateadmincode = async (value)=>{
  try {
   const  data = await post(`auth/generateadmincode`,value);
    return data;
  } catch (error) {
    throw error;
  }
}

