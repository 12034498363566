import React, { useEffect, useCallback, useState } from 'react';
import { SelectMenu, Table, Text } from 'components';
import { Container } from './style';
import { Icons } from 'constant';
import { useDispatch, useSelector } from 'react-redux'
import Tooltip from 'components/Tooltip';
import { Menu } from '@headlessui/react';
import { restaurantListSelector } from 'store/restaurant/selector'
import { selectRestaurant } from 'store/restaurant/actions'
import { changeModalMode } from 'store/utlis/actions'
import { RiFacebookCircleLine } from "react-icons/ri";
import { RxDiscordLogo } from "react-icons/rx";
import { getRestaurants } from '../../services/restaurant';
import { setNewTab } from 'hooks';
import selectors from 'selectors';
import {Fetch, persist} from '../../services/local-storage'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {useNavigate} from 'react-router-dom';



const MAX_TEXT = 60

const renderPopMoreIcon = (item, onClickAction, handletoast,navigateToDetail, wallet,Ownerwallet) => {
  return (
    <>
    <SelectMenu
      MenuButton={ <Icons.MoreIcon />}
      wrapperClassName='mr-0'
      menuItemsClassName='z-[9] w-56'
      menuBtnClassName='bg-blueLighter p-3 rounded cursor-pointer scale-[0.75]'
    >
      <div className='z-10'>
        <Menu.Item>
          {( { active }) => (
            <Text text='Edit'  className='py-3 pl-3 hover:bg-gray-400 cursor-pointer' onClick={() =>{
              if(wallet.address){ 
                // if(wallet.address == Ownerwallet){
                 navigateToDetail(item)
                // onClickAction('EDIT', item)
                // }else{
                //   toast("Owner Only Edit Event")
                // }  
              }else{
                handletoast()
              }
            
            }} />
          )}
        </Menu.Item>
      </div>
      <div className='z-10'>
        <Menu.Item>
          {({ active }) => (
            <Text text='Delete' className='py-3 pl-3 hover:bg-gray-400 cursor-pointer' onClick={() => 
          {
            // if(wallet.address == Ownerwallet){
              onClickAction('DELETE', item)
            // }else{
            //   toast("Owner Only Delete Event")
            // } 
             } } />
          )}
        </Menu.Item>
      </div>
    </SelectMenu>
    
    </>
  )
}

const renderIcon = (socmed) => {

  if (socmed.name === 'twitter') {
    return <li onClick={() => window.open(socmed.value, "_blank")} className='m-0 bg-blueLighter p-3 rounded-full cursor-pointer mr-3' key={socmed.name}> <Icons.TwitterIcon /></li>
  } else if (socmed.name === 'instagram') {
    return <li onClick={() => window.open(socmed.value, "_blank")} className='m-0 bg-blueLighter p-3 rounded-full cursor-pointer mr-3' key={socmed.name}> <Icons.InstagramIcon /></li>
  } else if (socmed.name === 'linkedin') {
    return <li onClick={() => window.open(socmed.value, "_blank")} className='m-0 bg-blueLighter p-3 rounded-full cursor-pointer mr-3' key={socmed.name}> <Icons.LinkedinIcon /></li>
  } else if (socmed.name === 'discord') {
    return <li onClick={() => window.open(socmed.value, "_blank")} className='m-0 bg-blueLighter p-3 rounded-full cursor-pointer mr-3' key={socmed.name}> <Icons.DiscordIcon /></li>
  }
  return null
}

const columns = (onClickAction, CurrentUser, wallet, handletoast,navigateToDetail,Ownerwallet) => {
 
  return [
    
    {
      label: 'Logo',
      key: 'logo',
      dataIndex: 'logo',
      render: (item) => {
        return (
          <img src={`${process.env.REACT_APP_S3_URL}${item.restaurantProfile}`} className='mx-auto pt-[10px] cursor-pointer' alt='oku-group' />
        );
      }
    },
    {
      label: 'Name',
      key: 'Name',
      dataIndex: 'name',
      render: (item) => {
        return (
          <span className='leading-5'>{item.restaurantName}</span>
        );
      }
    },
    {
      label: 'Locations',
      key: 'locations',
      dataIndex: 'locations',
      render: (item) => {
       
        return (
          <span className='leading-5'>{item.Address ? item.Address: item.City}</span>
        );
      }
    },
    {
      label: 'Status',
      key: 'status',
      dataIndex: 'status',
      render: (item) => {
         {console.log("item====>",item)}
        return (
          <span className='leading-5'>{item?.status =="active" ? "Active" : "Inactive" }</span>
        );
      }
    },
    {
      label: 'Collection',
      key: 'collection',
      dataIndex: 'collection',
      render: (item) => {
        return (
          <Tooltip title={`Collections`}>

            {item?.status != 'inactive' && item?.Collections && item?.Collections?.map((item)=>
            <>
            {item.status != 'inactive' ? 
            <div className="py-1">
              
                <Menu.Item>
                  {({ active }) => (
                    <div className='flex flex-1 justify-between cursor-pointer items-center px-5 py-2 hover:bg-gray-300'>
                      <div className='flex items-center space-x-3'>
                        <img src={`${process.env.REACT_APP_S3_URL}${item.NFTImage}`} className="w-10 h-10 nft-image"alt="" />
                        <Text text={item.NFTName} className='py-3 pl-3 ' />
                      </div>
                      <Icons.RightArrow />
                    </div>
                  )}
                </Menu.Item>
            </div>
            :
            <div className="py-1">
              
                <Menu.Item>
                  {({ active }) => (
                    <div className='flex flex-1 justify-between cursor-pointer items-center px-5 py-2 hover:bg-gray-300'>
                      <div className='flex items-center space-x-3'>
                        {/* <img src={`${process.env.REACT_APP_S3_URL}${item.NFTImage}`} className="w-10 h-10 nft-image"alt="" /> */}
                        <Text text={"Deleted"} className='py-3 pl-3 ' />
                      </div>
                      {/* <Icons.RightArrow /> */}
                    </div>
                  )}
                </Menu.Item>
            </div>
            }

            {/* <div className="py-1">
              <Menu.Item>
                {({ active }) => (
                  <div className='flex flex-1 justify-between cursor-pointer items-center px-5 py-2 hover:bg-gray-300'>
                    <div className='flex items-center space-x-3'>
                      <img src={Icons.Spring} alt="" />
                      <Text text='Springggg' className='py-3 pl-3 ' />
                    </div>
                    <Icons.RightArrow />
                  </div>
                )}
              </Menu.Item>
            </div> */}
            </>)}
          </Tooltip>
        );
      }
    },
    // {
    //   label: 'Financial Criteria',
    //   key: 'financialCriteria',
    //   dataIndex: 'financialCriteria',
    //   render: (item) => {
    //     return (
    //       <span className='leading-5'>{item.FinancialCriteria}</span>
    //     );
    //   }
    // },
    {
      label: 'Social Media',
      key: 'socialMedia',
      dataIndex: 'SocialMedia',
      render: (item) => {

        return (
          <ul key={item} className='flex items-center relative left-[-3px]'>
            <li onClick={() => setNewTab(item.Instagram)} className='bg-blueLighter p-3 rounded-full cursor-pointer mr-3' value={item.Instagram}> <Icons.InstagramIcon /></li>
            <li onClick={() => setNewTab(item.LinkedIn)} className='bg-blueLighter p-3 rounded-full cursor-pointer mr-3' value={item.LinkedIn}> <RiFacebookCircleLine className="icon_pos" /></li>
            <li onClick={() => setNewTab(item.Discord)} className='bg-blueLighter p-3 rounded-full cursor-pointer mr-3' value={item.Discord}> <RxDiscordLogo className="icon_pos"/></li>
          </ul>
        );
      }
    },
    {
      label: 'Action',
      key: 'operation',
      dataIndex: 'action',
      render: (item) => {
        return (
          <>
          {CurrentUser?.userType == "superadmin" ?
          <ul className='flex items-center justify-between relative left-[-7px]'>
            {item.website &&
              <li
                className='m-0 bg-blueLighter p-3 rounded cursor-pointer scale-[0.75] mr-2'
                onClick={() => setNewTab(item.website)}>
                <Icons.LinkIcon />
              </li>
            }
           {  renderPopMoreIcon(item, onClickAction, handletoast,navigateToDetail, wallet,Ownerwallet) }
          </ul>
          :null}
          </>
        );
      },
    }
  ];
};



const Restaurant = () => {
  const CurrentUser = JSON.parse(Fetch("user"));
  const wallet = useSelector((state)=> state.WalletConnect)

  const navigate = useNavigate();

  const { restaurants, tableMode } = useSelector(restaurantListSelector)
  const dispatch = useDispatch()
  const rests = useSelector(selectors.restaurants);
  
  const onClickAction = useCallback(
    (mode, item) => {
      dispatch(selectRestaurant(item))
      dispatch(changeModalMode(mode))
    },
    [dispatch],
  )
  const handletoast = () =>{
    toast("connect wallet")
  }
  const [Restaurants, setRestaurants] = useState([]);
  const [Ownerwallet,setOwnerwallet]= useState("");

  const getrestaurant = async () => {
    const result = await getRestaurants();
    setRestaurants(result.data.result)
  }
  const checkowner = async() =>{
		const {web3, marketplace, address} = wallet;
    try{
		const re = await marketplace.methods.owner().call()
		setOwnerwallet(re)
    }catch(err){
      console.log("err",err)
    }
	}
  
  useEffect(() => {
    getrestaurant();
    checkowner()
  }, [wallet])

  
  const renderCard = useCallback((item) => {
    
    return ( 
          <div key={item.id} className='border p-5 rounded-md h-[371px] shadow-lg'>
            <div id='card-header' className='flex items-start justify-between'>
              <div>
                <img src={`${process.env.REACT_APP_S3_URL}${item.restaurantProfile} `}className='mx-auto cursor-pointer w-[40px] h-[40px]' alt='oku-group' />
              </div>
              <div className='cursor-pointer'>
                {renderPopMoreIcon(item, onClickAction, handletoast,navigateToDetail, wallet)}
              </div>
            </div>
            <div id='card-content' className='mt-5'>
              <h5 className='font-extrabold text-base'>{item.restaurantName.length >= 20 ? item.restaurantName.slice(0,25)+"..." : item.restaurantName }</h5>
              <h5 className='text-darkGrey mt-2.5'>{item.FinancialCriteria}</h5>
              {/* <h5 className='mt-2.5 text-sm h-[62px]'>
                {item?.Description?.slice(0, MAX_TEXT)}
                {item?.Description.length >= MAX_TEXT && <span className='text-base font-bold cursor-pointer'>&nbsp;...&nbsp;Read More</span>}
              </h5> */}
              <div onClick={() => setNewTab(item?.website)} className='flex items-center mt-2'>
                <div className='m-0 cursor-pointer scale-[0.7] mr-1 relative left-[-4px]'><Icons.LinkIcon className='link-icon' /></div>
                <h5 className='text-sm font-extrabold text-prussianBlue cursor-pointer '>{"Go to Website"}</h5>
              </div>
              <div className='flex items-center mt-2.5'>
                <div className='m-0 cursor-pointer mr-4'><Icons.MarkupIcon /></div>
                <h5 className='text-sm font-extrabold text-prussianBlue leading-[23px] cursor-pointer '>{item?.Address?.length >= 30 ? item?.Address?.slice(0,30)+"..." : item?.Address}</h5>
              </div>
            </div>
            <div id='card-footer' className='mt-4'>
              <ul className='flex items-center w-44'>
                {
                  [{name: item.Twitter ? "twitter" : "", value: item.Twitter}, {name: item.Instagram ?"instagram" : "", value: item.Instagram},
                   {name: item.LinkedIn ?"linkedin" : "", value: item.LinkedIn}, {name: item.Discord ?"discord" : "",  value: item.Discord}]
                  .map(item => renderIcon(item))
                }
              </ul>
            </div>
          </div>
 );
}, [onClickAction]);

// {console.log("Restaurants",Restaurants.filter((iteem) => iteem.status == "active"))}


const navigateToDetail = (item) =>{

  console.log("navigateToDetailrestarant=>",item)
  navigate(`/preview/${item._id}`);
  // navigate(`/preview/${item.restaurantName}?restaurant=${item._id}`);
}


  return (
    <Container>
     

      {rests &&
      <Table
        columns={columns(onClickAction, CurrentUser,wallet, handletoast,navigateToDetail,Ownerwallet)}
        dataSource={ rests.restaurants ? rests.restaurants : Restaurants}
        tableRowClassname='cursor-pointer'
        // onClickRow={ item => navigateToDetail(item) }
        customCell={tableMode === 'CARD' ? data => {
          return (
            <div className='grid grid-cols-4 p-5 gap-4'>
              {
                data.filter((value) => value.status != 'inactive').map((item) => {
                  return renderCard(item);
                })
              }
            </div>
          );
        } : null}
      />
      }
      {/* <ToastContainer /> */}
    </Container>
  );
};

export default React.memo(Restaurant);