import React, { useState, useMemo } from 'react';
import { Text } from 'components';
import Pagination from './pagination';
import { Container } from './style';

const Table = ({
	columns, dataSource, pageSize = 8, pagination, customCell, className, tableClassName, tableRowClassname, onClickRow
}) => {
	const [currentPage, setCurrentPage] = useState(1);

	const currentDataSource = useMemo(() => {
		const firstPageIndex = (currentPage - 1) * pageSize;
		const lastPageIndex = firstPageIndex + pageSize;
		return dataSource?.slice(firstPageIndex, lastPageIndex);
	}, [currentPage, pageSize, dataSource]);

	const renderTableHead = useMemo(() => {
		return columns.map(item => {
			return (
				<th key={ item.dataIndex } className={ `th-${item.dataIndex} text-blackLighter` }>{ item.label }</th>
			);
		});
	}, [columns]);

	const renderTableColumn = useMemo(() => {
		if (currentDataSource.length) {
			return currentDataSource.map(item => {
				return (
					<tr key={ item.id || '' } className={ `border-b ${tableRowClassname}` } onClick={ () => onClickRow && onClickRow(item) }>
						{
							columns.map(column => (
								<td key={ column.dataIndex }>
									{ column.render ? column.render(item) : item[column.dataIndex] }
								</td>
							))
						}
					</tr>
				);
			});
		}
	}, [currentDataSource, columns]);

	const renderPagination = useMemo(() => {
		if (!pagination && pagination !== null) {
			return (
				<div className='grid grid-cols-3 gap-4 items-center pt-[20px] px-[20px] pb-[30px]'>
					<div>
						<span className='text-darkGrey'>Showing { currentPage }-8 of { dataSource.length } Results</span>
					</div>
					<Pagination
						className='pagination-bar'
						currentPage={ currentPage }
						totalCount={ dataSource.length }
						pageSize={ pageSize }
						onPageChange={ page => setCurrentPage(page) }
					/>
				</div>
			);
		}
		return pagination;
	}, [
		dataSource,
		pageSize,
		setCurrentPage,
		currentPage,
		pagination
	]);

	const renderTable = useMemo(() => {
		if (customCell) {
			return customCell(currentDataSource);
		}
		return (
			<table className={ `table-auto ${tableClassName, tableRowClassname}` }>
				<thead>
					<tr>
						{ renderTableHead }
					</tr>
				</thead>
				<tbody>
					{ renderTableColumn }
				</tbody>
			</table>
		);
	}, [customCell, tableClassName, tableRowClassname, renderTableHead, renderTableColumn, currentDataSource]);

	const renderEmptyItem = useMemo(() => {
		if (!currentDataSource.length) {
			return (
				<div className='p-5 font-bold text-lg'>
					<span>Data Not Found</span>
				</div>
			);
		}
	}, [currentDataSource]);
	return (
		<Container className={ className } isEmpty={ currentDataSource.length === 0 } >
			<div className='min-h-[60vh]'>
				{ renderTable }
				{ renderEmptyItem }
			</div>
			{ renderPagination }
		</Container>
	);
};

export default React.memo(Table);
