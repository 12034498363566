import { Colors, Icons, Images } from 'constant';
import React, {
  PropsWithChildren, useMemo, useEffect, useState
} from 'react';
import {
  Aside, Layout, Content, MenuItem, Wrapper, ModalWrapper, IconWrapper
} from './style';
import useSidebar from './useSidebar';
import {
  Dropdown, Button, TextField, Modal, UploadImage, NftImage, Form, Text, ProfilePopup, Label,
} from 'components';
import TextArea from '../TextArea'
import FormList from '../FormList'
import {
  PlusIcon, PlusCircleIcon, MinusCircleIcon
} from '@heroicons/react/solid';
import { restaurantField } from './Components/Form/formData';
import FormGroup from 'components/FormGroup';
import _ from 'lodash';
import S3 from "react-aws-s3";
import { addRestaurant, editRestaurants, deleteRestaurants, getRestaurants, getCountries, listCollections, getNewCollections, NFTgroupcollection, AddCollections } from '../../services/restaurant';
import { getAllusers, addAdmins, ListallUsers, listallWallets,generateadmincode,getadminrefferalcode } from '../../services/auth';
import { Fetch, persist } from '../../services/local-storage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ethers } from 'ethers';
import { useLocation, useNavigate } from "react-router-dom";
import axios from 'axios';
import { useSelector, useDispatch } from 'react-redux'
import { connectWallet, connectFailed } from '../../redux/WalletAction';
import WalletStyles from './styles';
import group27 from '../../assets/images/Group27.svg';
import group28 from '../../assets/images/Group28.svg';
import group29 from '../../assets/images/Group29.svg';
import loadiing from '../../assets/images/loading.gif';
import {
  adminsField, collectionField, faqField, ModalSuccess, FormAddAdmin, FormCollection, FormAddFaq
} from './Components';
import { email, passwordMatch } from '../../helpers/validation';
import abi from "../../contractsABI/TempleteABI.json";
import Web3 from 'web3';

// import {SET_RESTAURANTS} from '../../stores/User/userActionTypes';
// const list = [
//   {
//       id: 1,
//       image: metamask,
//       title: "Metamask"
//   },
//   {
//       id: 2,
//       image: coinbase,
//       title: "coinbasewallet"
//   },
//   {
//       id: 3,
//       image: walletconnect,
//       title: "walletconnect"
//   },

// ]
const FinancialCriteriaList = [
  {
    label: 'Earnings per share',
    value: 'Earnings per share'
  },
  {
    label: 'stockholder return',
    value: 'stockholder return'
  },
];

const Adminsfun = [
  {
    label: 'SuperAdmin',
    value: 'SuperAdmin'
  },
  {
    label: 'Admin',
    value: 'Admin'
  }
]

const referalfunction = [
  {
    label: 'Used Referal Code',
    value: 'used'
  },
  {
    label: 'Not Used Referal Code',
    value: 'notused'
  }
]
const tier = [
  {
    label: 'Gold',
    value: 'Gold'
  },
  {
    label: 'Silver',
    value: 'Silver'
  },
  {
    label: 'Platinum',
    value: 'Platinum'
  },
];

const Countries = [
  {
    label: 'singapore',
    value: 'singapore'
  },
  {
    label: 'India',
    value: 'India'
  },
];

const options = [
  {
    label: 'Earnings per share',
    value: 'Earnings per share'
  },
  {
    label: 'stockholder return',
    value: 'stockholder return'
  },

  // {
  //   label: 'Devon Webb',
  //   value: 'devonwebb'
  // },
  // {
  //   label: 'Tom Cook',
  //   value: 'tomcook'
  // },
  // {
  //   label: 'Tanya Fox',
  //   value: 'tanyafoox'
  // },
  // {
  //   label: 'Hellen Schmidt',
  //   value: 'hellenschmidt'
  // },
];
const accessOption = [
  {
    label: 'Super Admin',
    value: 'superadmin'
  },
  {
    label: 'Admin',
    value: 'admin'
  },
];

const referalOption = [
  {
    label: 'Used',
    value: 'used'
  },
  {
    label: 'Not Used',
    value: 'admin'
  },
];



const collectionType = [
  {
    label: "Gold",
    value: "Gold"
  },
  {
    label: "Silver",
    value: "Silver"
  },
  {
    label: "Platinum",
    value: "Platinum"
  },

]

const countryOption = [
  {
    label: 'Singapore',
    value: 'singapore'
  },
  {
    label: 'Indonesia',
    value: 'indonesia'
  },
  {
    label: 'India',
    value: 'india'
  },
  {
    label: 'China',
    value: 'china'
  },
  {
    label: 'United States',
    value: 'us'
  },
  {
    label: 'Ukraine',
    value: 'ukranine'
  },
];

const Sidebar = ({ children }) => {
  const [showModalSuccess, setShowModalSuccess] = useState(false);
  const [ownerwallet, setOwnerwallet] = useState('');
  const [ListWallets, setListWallets] = useState([]);

  const CurrentUser = JSON.parse(Fetch("user"));
  
  const wallet = useSelector((state) => state.WalletConnect)
 
  const location = useLocation();
  const dispatch = useDispatch();
  const [matictousd, setMatictousd] = useState('');
  const [restaurantName, setRestaurantName] = useState('');
  const [website, setWebsite] = useState('');
  const [cities, setCities] = useState([]);
  const [Description, setDescription] = useState('');
  const [restaurantProfile, setRestaurantProfile] = useState('');
  const [Country, setCountry] = useState('');
  const [city, setCity] = useState('');
  const [Address, setAddress] = useState('')
  const [Collections, setCollections] = useState([{ NFTCollections: "", NFTName: "", NFTCount: '', NFTPrice: "", NFTReleaseDate: "", NFTEndDate: "", NFTEndTime: "", NFTDescription: "", NFTImage: null, benefits: "", NFTRoyalty: '', OwnedBy: restaurantName && restaurantName }])
  const [Instagram, SetInstagram] = useState('');
  const [Twitter, setTwitter] = useState('');
  const [LinkedIn, setLinkedIn] = useState('');
  const [Discord, setDiscord] = useState('');
  const [FinancialCriteria, setFinancialCriteria] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [id, setId] = useState("");
  const navigate = useNavigate();
  const [oldCollections, setOldCollections] = useState([])
  const [restaurantCollections, setrestaurantCollections] = useState([])
  const [adminAtoZ, setAdminAtoZ] = useState("A - Z");
  const [adminrefAtoZ, setAdminrefAtoZ] = useState("A - Z");
  const [metamaskStatus, setMetamaskStatus] = useState(false);
  const [contractStatus, setContractStatus] = useState(false);
  const [completionStatus, setCompletionStatus] = useState(false);
  const [ListUsers, setListUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [oldusers, setOldUsers] = useState([]);
  const [admins, setAdmins] = useState([]);
  const [oldadmins, setOldAdmins] = useState([]);
  const [openLoader, setOpenLoader] = useState(false);

  const [refferalname,setrefferalname]= useState("");
  const [refferalcount,setrefferalcount]= useState("");
  const [referalids,setreferalids]= useState("");
  const handleInputChange = (e, i) => {
    
    const { name, value } = e
    const list = [...Collections];
  
    list[i][name] = value;
    setCollections(list);
    

  };
  const [countries, setCountries] = useState([]);
  // declare the async data fetching function
  const fetchCountryData = async () => {

    // get the data from the api
    const res = await axios.get('https://countriesnow.space/api/v0.1/countries');
    // convert the data to json
    let countries_ = res.data.data;

    const a = countries_.map((item) => {
      item["value"] = item.country;
      item['label'] = item.country;
      return item;
    })
    setCountries(countries_);
  }
  const handleSetCities = (value) => {
    const country_ = countries && countries.filter((item) => item.country == value)
    const cities_ = country_[0].cities
    let _city = [];
    const cities1 = cities_.map((item) => {
      _city.push({ label: item, value: item })
    })
    setCities(_city)
  }

  const listallWalletsAddress = async () => {
    const res = await listallWallets();
    if (res.data.status) {
      setListWallets(res.data.result)
    }
  }


  const generateadmincodes = async () => {
   if(CurrentUser.id && CurrentUser.userName && CurrentUser.email){
    if(refferalname && refferalcount){
    if(refferalname.length > 3 ){
    var data ={
      refferalname:refferalname,
      refferalcount:refferalcount,
      refferedbyId:CurrentUser.id,
      refferedbyName:CurrentUser.userName,
      refferedbyEmail:CurrentUser.email,
    }
    console.log("CurrentUser",CurrentUser)
    const res = await generateadmincode(data);
    if (res.data.status) {
      toast(res.data.message)
      getadminrefferal()
      setmodal(false)     
    }else{
      toast(res.data.message)
    }
    }else{
      toast("Name Must Be Above 3 letters")
    }
    }else{
      toast("Fill Above Inputs")
    }
  }else{
    toast("Admin Datas Missing,check login profile")
  }
  }

  const handleAddbenefits = (e, index, i) => {
    const { name, value } = e
    const list = [...Collections];
    list[i].benefits[index][name] = value;
    setCollections(list);
  }

  const getmatictoUsd = async () => {
    const matictousd = await axios.get("https://api.coingecko.com/api/v3/simple/price?ids=matic-network&vs_currencies=usd")
    setMatictousd(matictousd.data['matic-network'].usd)
  }
 
  const handleNftImage = async (e, i) => {
   
    let file = e[0]
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: process.env.REACT_APP_DIRECTORYNAME,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_AWS_ACCESSKEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    };

    const ReactS3Client = new S3(config);
    const newFileName = Date.now()+ "_" +file.name  ;
    ReactS3Client.uploadFile(file, newFileName).then((data) => {
      const list = [...Collections];
      list[i]["NFTImage"] = newFileName;
      setCollections(list);
   
    }
    );
  }
  const handleAddClick = () => {
    setCollections([...Collections, {}]);
  }
  const handleRemove = async (index) => {
    var rows = [...Collections];
    rows.splice(index, 1);
    const newrows = rows
    setCollections(newrows);


  };
  const submitcheck = () => {
    let collection_1 = 0;
    let collection_2 = 0;
    let collection_3 = 0;
    for (let i = 0; i < Collections.length; i++) {
      if (Collections[i].NFTCollections == "Gold") {
        collection_2 = collection_2 + Number(Collections[i].NFTCount)
      } else if (Collections[i].NFTCollections == "Silver") {
        collection_1 = collection_1 + Number(Collections[i].NFTCount)
      } else if (Collections[i].NFTCollections == "Platinum") {
        collection_3 = collection_3 + Number(Collections[i].NFTCount)
      }
    }
  
  }

  const handleSubmit = async () => {
    setCompletionStatus(true);
    let newcollections_ = []
    const oldcollections = [...Collections]
    oldcollections.map((item, index) => {
      for (let i = 0; i < Number(oldcollections[index].NFTCount); i++) {
        let item1 = { ...item };
        const num = Number(item1.NFTPrice);
        const Price = Number.isInteger(num) ? num : (num).toFixed(2);
        item1.NFTName = oldcollections[index].NFTName + " - " + `${i + 1}`
        item1.NFTPrice = Price;
        item1.NFTCount = "1";
        item1.CollectionName = oldcollections[index].NFTName;
        item1.OwnedBy = restaurantName;
        newcollections_.splice(i, 0, item1)
      }
    })
    let newarray_ = []
    Collections.map((item) => {
      let item1 = { ...item }
      item1.OwnedBy = restaurantName
      newarray_.push(item1)
    })

    const values = {
      restaurantName: restaurantName,
      restaurantProfile: restaurantProfile,
      website: website,
      Description: Description,
      Country: Country,
      City: city,
      Address: Address,
      walletAddress: walletAddress,
      Collections: newarray_,
      newCollections: newcollections_,
      totalNfts: newcollections_.length,
      Instagram: Instagram,
      Twitter: Twitter,
      LinkedIn: LinkedIn,
      Discord: Discord,
      status: "active",
      FinancialCriteria: FinancialCriteria,
    }
    if (values.restaurantName && values.restaurantProfile && values.website && values.Description && values.Description
      && values.Country && values.City && values.Address && values.Collections && values.FinancialCriteria) {


      await addRestaurant(values).then(res => {
        setCompletionStatus(false);
        onChangeModalMode('INIT')
        getrestaurant();
        setOpenLoader(false)
        toast("Successfully created a restaurant");
        setRestaurantName('');
        setWebsite('');
        setDescription('');
        setRestaurantProfile('');
        setCountry('');
        setCity('');
        setAddress('')
        setCollections([{ NFTCollections: "", NFTName: "", NFTCount: '', NFTPrice: "", NFTEndDate: "", NFTEndTime: "", NFTDescription: "", NFTImage: null, benefits: "", OwnedBy: "" }])
        SetInstagram('');
        setTwitter('');
        setLinkedIn('');
        setDiscord('');
        setFinancialCriteria('');
        setWalletAddress('');
      })
    } else {
      toast("Fill all the data!");
    }

  }
  const editContract = async () => {

    const values = {
      id: id,
      restaurantName: restaurantName,
      restaurantProfile: restaurantProfile ? restaurantProfile : fieldsEditRestaurant.restaurantProfile,
      website: website,
      Description: Description,
      Country: Country,
      City: city,
      Address: Address,
      walletAddress: walletAddress,
      Collections: Collections,
      // newCollections:newcollections_,
      Instagram: Instagram,
      Twitter: Twitter,
      LinkedIn: LinkedIn,
      Discord: Discord,
      FinancialCriteria: FinancialCriteria,
    }
  

    let arrcheck = []
    const check = Collections.map((item) => {
      if (item.NFTCollections && item.NFTCount && item.NFTDescription &&
        item.NFTEndDate && item.NFTName && item.NFTImage && item.benefits && item.NFTPrice && item.NFTRoyalty) {
        // return true;
        arrcheck.push(true)
      } else {
        arrcheck.push(false)
        // return false;
      }
    })
    const checked_ = arrcheck.includes(false)
    if (checked_) {
      toast("Fill all the data in collections")
    } else {
      setOpenLoader(true)
      onChangeModalMode('INIT')
      setMetamaskStatus(true);
      setContractStatus(true)
      try {
        if (values.restaurantName && values.restaurantProfile && values.website && values.Description && values.Description
          && values.Country && values.City && values.Address && values.Collections && values.FinancialCriteria) {
          const { web3, marketplace, address } = wallet;
         
          const getAddress = await marketplace.methods.GetRestaurantAddress(fieldsEditRestaurant.restaurantName).call();
        
          setMetamaskStatus(false);
          var nftContracts = new web3.eth.Contract(
            abi,
            getAddress
          );

          let collection_1 = 0;
          let collection_2 = 0;
          let collection_3 = 0;
          for (let i = 0; i < Collections.length; i++) {
            if (Collections[i].NFTCollections == "Gold") {
              collection_2 = collection_2 + Number(Collections[i].NFTCount)
            } else if (Collections[i].NFTCollections == "Silver") {
              collection_1 = collection_1 + Number(Collections[i].NFTCount)
            } else if (Collections[i].NFTCollections == "Platinum") {
              collection_3 = collection_3 + Number(Collections[i].NFTCount)
            }
          }

          const addnfts = await marketplace.methods.editCollections(getAddress, values.restaurantName, collection_1 ? collection_1 : 0, collection_2 ? collection_2 : 0, collection_3 ? collection_3 : 0).send({ from: address });
        
          setMetamaskStatus(false);
          setContractStatus(false);
          if (addnfts.status) {
            Editrestaurants()
          } else {
            toast("Failed to connect with contract.")
          }
        } else {
          toast("Fill all the data")
        }
      } catch (err) {
        setOpenLoader(false)
        toast("Something went wrong try again Later")
      }
    }
  }
  const checkowner = async () => {
    const { web3, marketplace, address } = wallet;
    try{
    const re = await marketplace.methods.owner().call();
    setOwnerwallet(re)
    }catch(err){
      console.log("err",err);
    }
  }

  const Editrestaurants = async () => {
    try {
      setContractStatus(false);
      setCompletionStatus(true)
      const data = {
        id: id,
      }

      let collectionNew = []
      for (let i = 0; i < Number(Collections.length); i++) {
        let item1 = Collections[i];
        const num = Number(item1.NFTPrice);
        const Price = Number.isInteger(num) ? num : (num).toFixed(2);
        // item1.NFTName = oldcolleCollectionsctions[index].NFTName +" - " +`${i+1}`
        // item1.NFTPrice = Price;
        // item1.NFTCount= "1";

        item1.OwnedBy = restaurantName ? restaurantName : Collections[i].OwnedBy;
        collectionNew.splice(i, 0, item1)
      }
      let newcollections_ = []
      const oldcollections = [...collectionNew]
      oldcollections.map((item, index) => {
        for (let i = 0; i < Number(oldcollections[index].NFTCount); i++) {
          let item1 = { ...item };
          const num = Number(item1.NFTPrice);
          const Price = Number.isInteger(num) ? num : (num).toFixed(2);
          item1.NFTName = oldcollections[index].NFTName + " - " + `${i + 1}`
          item1.NFTPrice = Price;
          item1.NFTCount = "1";
          item1.CollectionName = oldcollections[index].NFTName;
          item1.OwnedBy = restaurantName ? restaurantName : oldcollections[index].OwnedBy;
          newcollections_.splice(i, 0, item1)
        }
      })
   

      const datas = {
        id: id,
        restaurantName: restaurantName,
        restaurantProfile: restaurantProfile ? restaurantProfile : fieldsEditRestaurant.restaurantProfile,
        website: website,
        Description: Description,
        Country: Country,
        City: city,
        Address: Address,
        walletAddress: walletAddress,
        Collections: Collections,
        newCollections: newcollections_,
        Instagram: Instagram,
        Twitter: Twitter,
        LinkedIn: LinkedIn,
        Discord: Discord,
        FinancialCriteria: FinancialCriteria,
      }

      if (datas.id && datas.restaurantName && datas.restaurantProfile && datas.website && datas.Description && datas.Description
        && datas.Country && datas.City && datas.Address && datas.Collections && datas.FinancialCriteria) {
      

        const res1 = await editRestaurants(datas);
        setOpenLoader(false);
        setCompletionStatus(false);

        if (res1.data.status) {
          onChangeModalMode('INIT')
          getrestaurant();
          toast("Editing Successfull");
          setRestaurantName('');
          setWebsite('');
          setDescription('');
          setRestaurantProfile('');
          setCountry('');
          setCity('');
          setAddress('')
          setCollections([{ NFTCollections: "", NFTName: "", NFTCount: '', NFTPrice: "", NFTEndDate: "", NFTEndTime: "", NFTDescription: "", NFTImage: null, benefits: "", OwnedBy: "" }])
          SetInstagram('');
          setTwitter('');
          setLinkedIn('');
          setDiscord('');
          setFinancialCriteria('');
          setWalletAddress('');
          // window.location.reload(false);
        }


      } else {
        toast("Fill all the data!");
      }
    }
    catch (err) {
      console.log("error in update", err)
    }
  }

  const handleCloseEditModal = () => {
    setRestaurantName('');
    setWebsite('');
    setDescription('');
    setRestaurantProfile('');
    setCountry('');
    setCity('');
    setAddress('')
    setCollections([{ NFTCollections: "", NFTName: "", NFTCount: '', NFTPrice: "", NFTEndDate: "", NFTEndTime: "", NFTDescription: "", NFTImage: null, benefits: "", OwnedBy: "" }])
    SetInstagram('');
    setTwitter('');
    setLinkedIn('');
    setDiscord('');
    setFinancialCriteria('');
    setWalletAddress('');
  }
  const getlistofcollections = async () => {
    // const res = await listCollections();
    const res = await getNewCollections()
    // setCollections(res.data.result)
    setOldCollections(res.data.result)

    setrestaurantCollections(res.data.result)
    dispatch({
      type: "GET_COLLECTION_LIST",
      payload: res.data.result,
    })

  }

  const getnftgroup =async()=>{
    const res = await NFTgroupcollection()
    dispatch({
      type: "GET_GROUPCOLLECTION_LIST",
      payload: res.data.result,
    })
  }

  const getadminrefferal=async()=>{
    const res = await getadminrefferalcode()
    dispatch({
      type: "GET_REFERAL_LIST",
      payload: res.data.result,
    })
    setreferalids(res.data.result)
  }

  const Deleterestaurants = async () => {
    try {
      const datas = {
        id: fieldsEditRestaurant._id,
        status: "inactive",
      }
      console.log("datas",datas)
      if (datas.id && datas.status) {
        await deleteRestaurants(datas).then(res => {
          if (res.data.status) {
            onChangeModalMode('INIT')
            toast("Successfully deleted a restaurant");
            window.location.reload(false)
          } else {
            onChangeModalMode('INIT');
            toast("Cannot delete this restaurant");
          }
        })
      }
      else {
        toast("something went wrong!");
      }
    }
    catch (err) {
      console.log("error in delete", err)
    }
  }
  const [resarray, setResarray] = useState([]);
  const [restaurantNameList, setRestaurantNamelist] = useState([]);
  const [Restaurants, setRestaurants] = useState([]);
  const [Restaurants1, setRestaurants1] = useState([]);
  const getrestaurant = async () => {
    const result = await getRestaurants();
    setRestaurants(result.data.result)
    setRestaurants1(result.data.result)
    const value1 = result.data.result;
    let arr = [];
    let arr1 = []
    const filteredres = value1.map((item) => {
      arr.push({ label: item.restaurantName, value: item.restaurantName })
      arr1.push(item.restaurantName)
    })
    setResarray(arr);
    setRestaurantNamelist(arr1);

    dispatch({
      type: "GET_RESTAURANT_LIST_SUCCESS",
      payload: result.data.result,
    })
    // renderContent()
  }
  const search = (e) => {
   
    if (e != '') {
      const values = Restaurants1.filter((res) => res.restaurantName.toLowerCase().includes(e))
      setRestaurants(values)
      dispatch({
        type: "GET_RESTAURANT_LIST_SUCCESS",
        payload: values,
      })
    }
    else {
      setRestaurants(Restaurants1)
      dispatch({
        type: "GET_RESTAURANT_LIST_SUCCESS",
        payload: Restaurants1,
      })
    }
  }
  const allrestaurant =()=>{
   
    if(Restaurants1){
     
      const values = Restaurants1
      setRestaurants(values)
      dispatch({
        type: "GET_RESTAURANT_LIST_SUCCESS",
        payload: values,
      })
    }
    // // window.location.reload(false)
  }

  const allrestaurantcollection=()=>{
    window.location.reload(false)
  }
  const handleFilter = (value) => {
   
    const values = Restaurants1.filter((res) => res.Country.toLowerCase() == value.toLowerCase())

    setRestaurants(values)
    dispatch({
      type: "GET_RESTAURANT_LIST_SUCCESS",
      payload: values,
    })
  }
  const [resfiltername, setResFilterName] = useState('');
  const [collectiontier, setCollectionTier] = useState('');

  const handleFilterrestaurant = (value) => {
    setResFilterName(value)
   
    if (collectiontier) {
    
      const values = restaurantCollections.filter((res) => res.EventName && res.EventName.toLowerCase() == value.toLowerCase() && collectiontier == res.NFTTier)
  
      dispatch({
        type: "GET_COLLECTION_LIST",
        payload: values,
      })
    } else {
      const values = oldCollections.filter((res) => res.EventName && res.EventName.toLowerCase() == value.toLowerCase())
      
      dispatch({
        type: "GET_COLLECTION_LIST",
        payload: values,
      })

    }
  }


  const handleCollectionsfilter = (value) => {
    setCollectionTier(value)
   
    if (resfiltername) {
     
      const values = restaurantCollections.filter((res) => res.NFTTier.toLowerCase() == value.toLowerCase() && resfiltername == res.EventName)
      // setrestaurantCollections(values)
      dispatch({
        type: "GET_COLLECTION_LIST",
        payload: values,
      })
    } else {
      const values = restaurantCollections.filter((res) => res.NFTTier.toLowerCase() == value.toLowerCase())
   
      dispatch({
        type: "GET_COLLECTION_LIST",
        payload: values,
      })
    }

  }

  const handleCollectionsearch = (e) => {
    if (e != '') {
      const values = restaurantCollections.filter((res) => res.NFTCollections.toLowerCase().includes(e))
    
      dispatch({
        type: "GET_COLLECTION_LIST",
        payload: values,
      })
    }
    else {
      // setRestaurants(Restaurants1)
      dispatch({
        type: "GET_COLLECTION_LIST",
        payload: restaurantCollections,
      })
    }

  }

  


  const handleFilterFinalcialCriteria = (value) => {
   
    const values = Restaurants1.filter((res) => res.FinancialCriteria == value.toString())
   
    setRestaurants(values)
    dispatch({
      type: "GET_RESTAURANT_LIST_SUCCESS",
      payload: values,
    })
  }
  const sumArray = (array) => {
    let sum = 0;

    for (let i = 0; i < Collections.length; i++) {
      sum += Number(Collections[i].NFTCount);
    }

    return sum;
  }

  const createRestaurant = async () => {
    const values = {
      restaurantName: restaurantName,
      restaurantProfile: restaurantProfile,
      website: website,
      Description: Description,
      Country: Country,
      City: city,
      Address: Address,
      walletAddress: walletAddress,
      Collections: Collections,
      // newCollections:newcollections_,
      // totalNfts: newcollections_.length,
      Instagram: Instagram,
      Twitter: Twitter,
      LinkedIn: LinkedIn,
      Discord: Discord,
      status: "active",
      FinancialCriteria: FinancialCriteria,
    }

    const { web3, marketplace, address } = wallet;
    const collect = sumArray();
    let arrcheck = []
    const check = Collections.map((item) => {
      if (item.NFTCollections && item.NFTCount && item.NFTDescription &&
        item.NFTEndDate && item.NFTRoyalty && item.NFTName && item.NFTImage && item.benefits && item.NFTPrice) {
        // return true;
        arrcheck.push(true)
      } else {
        arrcheck.push(false)
        // return false;
      }
    })
    const checked_ = arrcheck.includes(false)
    if (checked_) {
      toast("Fill all the data in collections")
    } else {
      try {
        if (values.restaurantName && values.restaurantProfile && values.website && values.Description && values.Description
          && values.Country && values.City && values.Address && values.Collections && values.FinancialCriteria
          && !restaurantNameList.includes(restaurantName) && Web3.utils.isAddress(walletAddress)) {
          onChangeModalMode('INIT')
          setMetamaskStatus(true);
          setOpenLoader(true)
          
          setMetamaskStatus(false);
          setContractStatus(true);
          let collection_1 = 0;
          let collection_2 = 0;
          let collection_3 = 0;
          for (let i = 0; i < Collections.length; i++) {
            if (Collections[i].NFTCollections == "Gold") {
              collection_2 = collection_2 + Number(Collections[i].NFTCount)
            } else if (Collections[i].NFTCollections == "Silver") {
              collection_1 = collection_1 + Number(Collections[i].NFTCount)
            } else if (Collections[i].NFTCollections == "Platinum") {
              collection_3 = collection_3 + Number(Collections[i].NFTCount)
            }
          }

          const createRestaurants = await marketplace.methods.buildRestaurants(restaurantName, collect, walletAddress,
            process.env.REACT_APP_MARKETPLACE, collection_1 ? collection_1 : 0, collection_2 ? collection_2 : 0, collection_3 ? collection_3 : 0).send({ from: address })
         
          setContractStatus(false);
          handleSubmit();
        }
        else {
          toast("Fill all the Data")
        }
      }

      catch (e) {
        console.log("error", e)
      }
    }


  };
  const handleCloseImage = (index) => {
    let obj = [...Collections];
    obj[index].NFTImage = ""
    setCollections(obj);


  }

  // profile to s3
  const onChangeProfile = (e) => {

    setRestaurantProfile(e.target.value)
  };
  const {
    routes, getSelectedMenu, onClickMenu, menuFactory, tableMode, onChangeTableMode,
    modalFactory, onChangeModalMode, fieldsEditRestaurant, modalDeleteFactory
  } = useSidebar();
  const {
    registeredValue, isFormValid, onSubmit, removeListItem, setFieldsValue, addListItem, resetFieldsValue
  } = Form.useForm({ fields: restaurantField });

  const mapping = () => {
  
    setId(fieldsEditRestaurant._id)
    setRestaurantProfile(fieldsEditRestaurant.restaurantProfile)
    setRestaurantName(fieldsEditRestaurant.restaurantName)
    setWebsite(fieldsEditRestaurant.website)
    setDescription(fieldsEditRestaurant.Description)
    setCountry(fieldsEditRestaurant.Country)
    
    setCity(fieldsEditRestaurant.City)
    setCities([{ value: fieldsEditRestaurant.City, label: fieldsEditRestaurant.City }])
    setAddress(fieldsEditRestaurant.Address)
    SetInstagram(fieldsEditRestaurant.Instagram)
    setTwitter(fieldsEditRestaurant.Twitter)
    setLinkedIn(fieldsEditRestaurant.LinkedIn)
    setDiscord(fieldsEditRestaurant.Discord)
    setCollections(fieldsEditRestaurant.Collections)
    setFinancialCriteria(fieldsEditRestaurant.FinancialCriteria)
    setWalletAddress(fieldsEditRestaurant.walletAddress)
  }
  const [userAtoZ, setUserAtoZ] = useState("A - Z");
 

  const [existing_countries, setExisting_countries] = useState([]);
  const getcountries = async () => {
    const res = await getCountries();
    setExisting_countries(res.data.result)

  }
  useEffect(() => {
    // listUsers();
    getmatictoUsd();
    getcountries();
    getlistofcollections();
    getnftgroup()
    getrestaurant();
    getadmins();
    getusers();
    getadminrefferal()
    fetchCountryData()
    if (modalFactory.modalTitle == "Edit") {
      mapping();
    }
    checkowner();
    listallWalletsAddress();
 
  }, [fieldsEditRestaurant, wallet]);

  useEffect(() => {
  
    setOldAdmins(oldadmins)
   
    setOldUsers(oldusers)

  }, [oldadmins, admins, users, oldusers, resarray])
  const admin = JSON.parse(Fetch('user'))

  useEffect(() => {
    const { web3Modal, address } = wallet;
      if (web3Modal.cachedProvider) {
      let web3;
      if (window.ethereum) {
        web3 = new Web3(window.ethereum);
      } else if (window.web3) {
        web3 = new Web3(window.web3.currentProvider);
      };
      web3.eth.getAccounts()
        .then(async (res) => {
          if (res[0].toLowerCase() == (admin.walletAddress).toLowerCase()) {
            dispatch(connectWallet());
          }
        });
      // alert("called")

    }
  }, [])
  const disconnect = () => {
    dispatch(connectFailed())
  }
  const getusers = async () => {
    const res = await getAllusers();
    
    const nfts = res.data.nfts;
    const newdata = res.data.result;
    const filtered = newdata.filter((item) => item.userType == "user")

    for (let i = 0; i < filtered.length; i++) {
      const filtered_nfts = nfts.filter((item) => filtered[i].walletAddress && filtered[i].walletAddress == item.walletAddress)
      let data = filtered[i]
      data['nfts'] = filtered_nfts.length
    }
    setUsers(filtered);
    setOldUsers(filtered);
  }
  const getadmins = async () => {
    const res = await getAllusers();
    const nfts = res.data.nfts;
    const newdata = res.data.result;
    const filtered = newdata.filter((item) => item.userType == "admin" || item.userType == "superadmin")
  
    dispatch({
      type: "GET_ADMINS_LIST",
      payload: filtered
    })
    setAdmins(filtered);
    setOldAdmins(filtered);
    

  }

  const handleUserAtoZ = () => {
    if (userAtoZ == "A - Z") {
      setUserAtoZ("Z - A")
      const mappeduser = oldusers.map((item) => item.userName).sort();
     
      let arr = [];
      const newArr = mappeduser.map((item) => oldusers.map((i) => item == i.userName ? arr.push(i) : ""))
      const newArray = [...new Set(arr)]
      const strAscending = newArray.sort((a, b) =>a.userName.toLowerCase() > b.userName.toLowerCase() ? 1 : -1,);
    
      setUsers(newArray.reverse());
      dispatch({
        type: "GET_USER_LIST",
        payload: newArray
      })
    } else {
      setUserAtoZ("A - Z")
      const mappeduser = oldusers.map((item) => item.userName).sort().reverse();
     
      let arr = [];
      const newArr = mappeduser.map((item) => oldusers.map((i) => item == i.userName ? arr.push(i) : ""))
      const newArray = [...new Set(arr)]
      const strDescending = newArray.sort((a, b) =>a.userName.toLowerCase() > b.userName.toLowerCase() ? -1 : 1,);
     
      setUsers(newArray);
      const reversed = newArray.reverse()
      dispatch({
        type: "GET_USER_LIST",
        payload: reversed
      })
    }
  }
  const alladmins=async()=>{
    window.location.reload(false)
  }

  const allreferal=async()=>{
    dispatch({
      type: "GET_REFERAL_LIST",
      payload: referalids
    })
  }

  const adminsuperadmin = async(values)=>{

    const filtered_admin = admins.filter((item) => item.userType.toLowerCase() == values.toLowerCase())
    dispatch({
      type: "GET_ADMINS_LIST",
      payload: filtered_admin
    })

  }

  const referaladmin = async(values)=>{

    const filtered_admin = referalids.filter((item) => item.RefferalStatus.toLowerCase() == values.toLowerCase())
    dispatch({
      type: "GET_REFERAL_LIST",
      payload: filtered_admin
    })

  }

  const handleAdminReferalAtoZ = () => {
   
    if (adminrefAtoZ == "A - Z") {
      setAdminrefAtoZ("Z - A")
      const mappedrefname = referalids.map((item) => item.RefferalName).sort();
      let arr = [];
      const newArr = mappedrefname.map((item) => referalids.map((i) => item == i.RefferalName ? arr.push(i) : ""))
      const newArray = [...new Set(arr)]
      const strAscending = newArray.sort((a, b) =>a.RefferalName.toLowerCase() > b.RefferalName.toLowerCase() ? 1 : -1,);
      setreferalids(newArray.reverse());
      dispatch({
        type: "GET_REFERAL_LIST",
        payload: newArray
      })
    }else{
      setAdminrefAtoZ("A - Z")
      const mappeduser = referalids.map((item) => item.RefferalName).sort().reverse();
      let arr = [];
      const newArr = mappeduser.map((item) => referalids.map((i) => item == i.RefferalName ? arr.push(i) : ""))
      const newArray = [...new Set(arr)]
      const strDescending = newArray.sort((a, b) =>a.RefferalName.toLowerCase() > b.RefferalName.toLowerCase() ? -1 : 1,);
      setreferalids(newArray);
      const reversed = newArray.reverse()
      dispatch({
        type: "GET_REFERAL_LIST",
        payload: reversed
      })
    
    }


  }

  const handleAdminAtoZ = () => {
    if (adminAtoZ == "A - Z") {
      setAdminAtoZ("Z - A")
      const mappeduser = oldadmins.map((item) => item.userName).sort();
      let arr = [];
      const newArr = mappeduser.map((item) => oldadmins.map((i) => item == i.userName ? arr.push(i) : ""))
      const newArray = [...new Set(arr)]
      const strAscending = newArray.sort((a, b) =>a.userName.toLowerCase() > b.userName.toLowerCase() ? 1 : -1,);
      setAdmins(newArray.reverse());
      dispatch({
        type: "GET_ADMINS_LIST",
        payload: newArray
      })
    } else {
      setAdminAtoZ("A - Z")
      const mappeduser = oldadmins.map((item) => item.userName).sort().reverse();
      let arr = [];
      const newArr = mappeduser.map((item) => oldadmins.map((i) => item == i.userName ? arr.push(i) : ""))
      const newArray = [...new Set(arr)]
      const strDescending = newArray.sort((a, b) =>a.userName.toLowerCase() > b.userName.toLowerCase() ? -1 : 1,);
      setAdmins(newArray);
      const reversed = newArray.reverse()
      dispatch({
        type: "GET_ADMINS_LIST",
        payload: reversed
      })
    }
  }
  const handleSearch = (value) => {
    if (value) {
    
      const filtered_user = oldusers.filter((item) => item.userName.toLowerCase().includes(value))
     
      dispatch({
        type: "GET_USER_LIST",
        payload: filtered_user
      })
    } else {
      dispatch({
        type: "GET_USER_LIST",
        payload: oldusers
      })
    }

  }

  const handlesearchAdmin = (value) => {
    if (value) {
      const filtered_user = oldadmins.filter((item) => item.userName.toLowerCase().includes(value) || item.email.toLowerCase().includes(value))
      dispatch({
        type: "GET_ADMINS_LIST",
        payload: filtered_user
      })
    } else {
      dispatch({
        type: "GET_ADMINS_LIST",
        payload: oldadmins
      })
    }
  }

  const handlesearchAdminreferal = (value) => {
   
    if (value) {
      const filtered_user = referalids.filter((item) => item.RefferalName.toLowerCase().includes(value))
      dispatch({
        type: "GET_REFERAL_LIST",
        payload: filtered_user
      })
    } else {
      dispatch({
        type: "GET_REFERAL_LIST",
        payload: referalids
      })
    }
  }


  // const listUsers = async () => {
  //   try{
  //   const res = await ListallUsers();
  //   if (res.data.status) {
  //     setListUsers(res.data.result)
  //     setListUsers([])
  //   }
  // }catch(err){
  //   console.log(err)
  // }
  // }

  const disablePastDate = () => {
    const today = new Date();
    
    const dd = String(today.getDate() + 1).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    const tt = "T00:00"
    return yyyy + "-" + mm + "-" + dd + tt;
};

  const [adminName, setAdminname] = useState('');
  const [adminPhone, setAdminPhone] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [adminwallet, setAdminWallet] = useState('');
  const [password, setPassword] = useState('');
  const [confirmpasswod, setConfirmPassword] = useState('');
  const [usertype, setUserType] = useState('');
  const [passwordtype, setPasswordtype] = useState("password");
  const isadminSubmitEnabled = email(adminEmail) && password.length >= 8 && confirmpasswod.length >= 8 && passwordMatch(password, confirmpasswod) && (usertype != "admin" ? Web3.utils.isAddress(adminwallet) : true) && ListUsers
    && (usertype != "admin" ? !ListUsers.includes(adminEmail) : true)  && (usertype != "admin" ? !ListWallets.includes(adminwallet) : true) ;

  const admincontract = async () => {
    const { web3, marketplace, address } = wallet;
    setOpenLoader(true);
    setMetamaskStatus(true)
    onChangeModalMode('INIT')
    setMetamaskStatus(false);
    setContractStatus(true);

    const addcollection_ = await marketplace.methods.SetOwner(adminwallet, true).send({ from: address });
    if (addcollection_.status) {
      setContractStatus(false);
      handleSubmitadmins()
    } else {
      setOpenLoader(false);
      toast("Adding admin failed")
    }
  }
  const handleSubmitadmins = async () => {
    setCompletionStatus(true);
    const values = {
      userName: adminName,
      phone: adminPhone,
      email: adminEmail,
      walletAddress: adminwallet,
      password: confirmpasswod,
      userType: usertype,
      status: "active",
    }
  
    const tokens = Fetch('token')
    try {
      const res = await addAdmins(values, tokens)
      if (res.data.status) {
        getadmins();
        onChangeModalMode('INIT');
        setCompletionStatus(false);
        setOpenLoader(false);
        toast("Admin added successfullty")
      }
    } catch (err) {
      toast(err.data.response.message)
    }
    // const res = await addAdmins(values,tokens) 
  }
  const handlePasswordType = () => {
    if (passwordtype == "password") {
      setPasswordtype("text")
    } else {
      setPasswordtype("password")
    }
  }



  //add collection section
  const [addCollectionName, setAddCollectionName] = useState('');
  const [addCollectionCount, setAddCollectionCount] = useState('');
  const [addNftPrice, setAddNftPrice] = useState('');
  const [addNftDescription, setAddNftDescription] = useState('');
  const [addNftTier, setAddNftTier] = useState('');
  const [addNftRoyalty, setAddNftRoyalty] = useState('');

  const [addNftOwnedBy, setAddNftOwnedBy] = useState('');
  const [addNftBenefits, setAddNftBenefits] = useState('');
  const [addNftEnddate, setAddNftEnddate] = useState('');
  const [addNft_release_date, setAddNftReleaseDate] = useState('');
  const [NftImage, setNftImage] = useState('');
  // const [tiers_, setTiers] = useState([]);
  const tiers_ = [{ label: "Silver", value: "Silver" }, { label: "Platinum", value: "Platinum" }, { label: "Gold", value: "Gold" }]
  const onChangeAddNFTImage = async (e) => {
  
    setNftImage(e.target.value);
  }
  const handleaddCollectionContract = async () => {
    if (wallet.address) {
      if (addCollectionName && addCollectionCount && addNftPrice && addNftDescription && addNftTier && addNftOwnedBy &&
        addNftBenefits && addNftEnddate && addNft_release_date && NftImage && addNftRoyalty) {

        const { web3, marketplace, address } = wallet;
        setMetamaskStatus(true);
        setOpenLoader(true)
        onChangeModalMode('INIT')
       
        setMetamaskStatus(false);
        setContractStatus(true);
    
        const getAddress = await marketplace.methods.GetRestaurantAddress(addNftOwnedBy).call();
      
        const addcollection_ = await marketplace.methods._increaseShares(getAddress, addNftTier, addCollectionCount).send({ from: address });
      
        if (addcollection_.status) {
          setContractStatus(false);
          handleSubmitCollections()
        } else {
          toast("Failed to add collection")
        }
      } else {
        toast("fill all data")
      }
    } else {
      toast("Connect wallet")
    }

  }



  const handleSubmitCollections = async () => {


    setCompletionStatus(true);
    const tokens = Fetch('token');
    const values = {
      NFTCollections: addNftTier,
      NFTName: addCollectionName,
      OwnedBy: addNftOwnedBy,
      NFTPrice: addNftPrice,
      NFTDescription: addNftDescription,
      NFTCount: addCollectionCount,
      NFTEndDate: addNftEnddate,
      NFTReleaseDate: addNft_release_date,
      NFTBenefits: addNftBenefits,
      NFTImage: NftImage,
      CollectionName: addCollectionName,
      NFTRoyalty: addNftRoyalty
    }
  
    let arr = [];

    for (let i = 0; i < values.NFTCount; i++) {
      const nftname = values.NFTName + " - " + (i + 1);
      const price_ = Number(values.NFTPrice).toFixed(2);
      const value = {
        NFTName: nftname,
        NFTDescription: values.NFTDescription,
        NFTPrice: price_,
        NFTCount: "1",
        NFTCollections: values.NFTCollections,
        OwnedBy: values.OwnedBy,
        NFTEndDate: values.NFTEndDate,
        NFTReleaseDate: values.NFTReleaseDate,
        benefits: values.NFTBenefits,
        NFTImage: values.NFTImage,
        CollectionName: values.CollectionName,
        NFTRoyalty: values.NFTRoyalty
      }

      arr.push(value);
    }

    try {
      const res = await AddCollections({ array: arr, Collections: values }, tokens)
     
      setCompletionStatus(false);
      getrestaurant();
      getlistofcollections();
      getnftgroup()
      setOpenLoader(false);
      toast("Collections added successfully")
      setNftImage("");
      onChangeModalMode('INIT')
    } catch (err) {
      console.log("something went wrong:", err)
      toast("something went wrong")
      setOpenLoader(false);
      setNftImage("");
      onChangeModalMode('INIT')
    }

  }

  

  const renderMenu = useMemo(
    () => {
      return routes.map(item => {
        const {
          active, bg, title
        } = getSelectedMenu(item);
       
        return (
          <li key={item.label} className={`mb-0 ${bg} cursor-pointer`}>
            <MenuItem onClick={e => onClickMenu(e, item)} className='flex items-center py-[20px] ml-3' active={active}>
              <item.icon className='item-icon mr-[12px]' />
              <p className='m-0'>{title}</p>
            </MenuItem>
            {
              item.child &&
              <ul>
                {
                  item.child.map(child => {
                    return (
                      <li key={child.label} className={'ml-5 mb-0 cursor-pointer'}>
                        <MenuItem className='flex items-center py-[20px] ml-3'>
                          <p className='m-0'>{child.label}</p>
                        </MenuItem>
                      </li>
                    );
                  })
                }
              </ul>
            }
          </li>
        );
      });
    },
    [getSelectedMenu],
  );

  const renderToggleTableMode = useMemo(() => {
    if (menuFactory.toggleMode) {
      return (
        <IconWrapper className='flex items-center mr-[40px]' active={tableMode === "LIST"}>
          <Icons.ListIcon className='cursor-pointer list-icon' onClick={() => onChangeTableMode('LIST')} />
          <div className='vertical-line' />
          <Icons.CollectionsIcon className='cursor-pointer collection-icon' onClick={() => onChangeTableMode('CARD')} />
        </IconWrapper>
      );
    }
  }, [menuFactory.toggleMode, onChangeTableMode, tableMode]);

  const [modal,setmodal]=useState(false)

  const renderAddButton = useMemo(() => {
   
    if (menuFactory.showAddButton) {
      return (
        <>
          {CurrentUser?.userType != "admin" ?
            <>
           
              {!window.location.href.includes('admin') ?
              
                <Button theme='primary' width='200px' height='44px' className='bg-prussianBlue' onClick={() => {
                  if (wallet && wallet.address) {
                    // onChangeModalMode('ADD')
                  
                    {menuFactory.label === "Event" ? window.location.replace( "addrestaurant")                  
                    :onChangeModalMode('ADD') }
            
                  } else {
                    toast("Connect your wallet")
                  }
                }}>

                  <div className='flex items-center space-x-2 justify-center'>
                    <PlusIcon className='h-4 w-4 m-0 inline' aria-hidden='true' />
                    <span>Add {menuFactory.label}</span>
                  </div>
                </Button>

                :
                <>
                  {/* {wallet.address == ownerwallet || "0xa8175C521a0E1F8a2F7E23529f0eb2140b023689" ? */}
                    <Button theme='primary' width='200px' height='44px' className='bg-prussianBlue' onClick={() => {
                      if (wallet && wallet.address) {
                        {menuFactory.label === "Event" ? navigate("/addrestaurant") : menuFactory.label === "Admin Referra" ? setmodal(true) :  onChangeModalMode('ADD')}
                        // navigate("/AddRestaurant")
                      } else {
                        toast("Connect your wallet")
                      }
                    }}>
                      <div className='flex items-center space-x-2 justify-center'>
                        <PlusIcon className='h-4 w-4 m-0 inline' aria-hidden='true' />
                        <span>{menuFactory.label === "Admin Referra" ? "Create New Referral" : "Add " + menuFactory.label }</span>
                      </div>
                    </Button>
                     {/* :
                     null
                   } */}
                </>
              }
            </>


            : null
          }
        </>
      );
    }
  }
    , [menuFactory, wallet]);

  const renderContent = useMemo(() => {
    if (menuFactory.showFilter) {
      return (
        <div className='border rounded-md min-h-[85vh]'>
          <div id='filter' className='border-b p-[20px] items-center grid grid-cols-2'>
            <div className='flex items-center'>

              {window.location.href.includes('/events') || location.pathname == '/' && existing_countries.length > 0 ?
                <>
                  {renderToggleTableMode}
                  <div className='flex items-center'>
                    <button style={{color:"black",border:"1px solid #e5e7eb",width:"55px",height:"44px",borderRadius:"5px"}} className='mr-2' onClick={()=>allrestaurant()}>All</button>
                    {/* <Dropdown placeholder='Financial Criteria' className='mr-[15px] w-[200px] h-[44px]' options={FinancialCriteriaList} onChange={(e) => handleFilterFinalcialCriteria(e.target.value)} /> */}
                    <Dropdown placeholder='Country' className='mr-[20px] h-[44px]' options={existing_countries && existing_countries} onChange={(e) => { handleFilter(e.target.value) }} />
                  </div>
                </>
                : ""
              }

              {window.location.href.includes('/collections') && resarray.length > 0 ?
                <div className='flex items-center'>
                  <button style={{color:"black",border:"1px solid #e5e7eb",width:"55px",height:"44px",borderRadius:"5px"}} className='mr-2' onClick={()=>allrestaurantcollection()}>All</button>
                  <Dropdown placeholder='Events' className='mr-[20px] h-[44px]' value={resfiltername} options={resarray && resarray} onChange={(e) => handleFilterrestaurant(e.target.value)} />
                  <Dropdown placeholder='Tiers' className='mr-[20px] h-[44px]' value={collectiontier} options={tier} onChange={(e) => { handleCollectionsfilter(e.target.value) }} />
                </div>
                : ""
              }
              {window.location.href.includes('/users') ?
                <div className='flex border p-[20px] items-center background-white/5' onClick={() => handleUserAtoZ()}>
                  <Text text={userAtoZ} />
                </div>
                : ""
              }
              {window.location.href.includes('admins') ?
              <div className='flex items-center'>
              <button style={{color:"black",border:"1px solid #e5e7eb",width:"55px",height:"44px",borderRadius:"5px"}} className='mr-2' onClick={()=>alladmins()}>All</button>
              <Dropdown placeholder='SuperAdmin & Admin' className='mr-[20px] w-[200px] h-[44px]' value={resfiltername} options={Adminsfun && Adminsfun} onChange={(e) => adminsuperadmin(e.target.value)} />
            </div>
                : ""
              }

            {window.location.href.includes('adminrefferral') ?
              <div className='flex items-center'>
              <button style={{color:"black",border:"1px solid #e5e7eb",width:"55px",height:"44px",borderRadius:"5px"}} className='mr-2' onClick={()=>allreferal()}>All</button>
              <Dropdown placeholder='Active Referral Code' className='mr-[20px] w-[200px] h-[44px]' value={resfiltername} options={referalfunction && referalfunction} onChange={(e) => referaladmin(e.target.value)} />
            </div>
                : ""
              }
              {window.location.href.includes('admins') ?
                <div className='flex border p-[20px] items-center background-white/5' onClick={() => handleAdminAtoZ()}>
                  <Text text={adminAtoZ} />
                </div>
                : ""
              }

               {window.location.href.includes('adminrefferral') ?
                <div className='flex border p-[10px] items-center background-white/5 ml-3' onClick={() => handleAdminReferalAtoZ()}>
                  <Text text={adminrefAtoZ} />
                </div>
                : ""
              }
            </div>
            {window.location.href.includes('events') || location.pathname == '/' ?
              <div className={`flex items-center ${!menuFactory.showAddButton && 'justify-end'}`} >
                <TextField className='w-[185px] border mr-[20px] h-[44px] rounded-lg' placeholder={`Search ${menuFactory.label}`} onChange={(e) => { search(e.target.value) }} />
                {renderAddButton}
              </div>
              : ""
            }
            {window.location.href.includes('collections') ?
              <div className='flex items-center ml-5'>
                <TextField className='w-[360px] border mr-[20px] ml-[20px] h-[44px] rounded-lg' placeholder={`Search ${menuFactory.label}`} onChange={(e) => { handleCollectionsearch(e.target.value) }} />
                {renderAddButton}
              </div>
              : ""
            }
            {window.location.href.includes('users') ?
              <div className='flex items-center'>
                <TextField className='w-[360px] border mr-[20px] h-[44px] rounded-lg' placeholder={`Search ${menuFactory.label}`} onChange={(e) => { handleSearch(e.target.value) }} />
                {renderAddButton}
              </div>
              : ""
            }
            {window.location.href.includes('admins') ?
              <div className='flex items-center'>
                <TextField className='w-[360px] border mr-[20px] h-[44px] rounded-lg' placeholder={`Search ${menuFactory.label}`} onChange={(e) => { handlesearchAdmin(e.target.value) }} />
                {renderAddButton}
              </div>
              : ""
            }
             {window.location.href.includes('adminrefferral') ?
              <div className='flex items-center'>
                <TextField className='w-[360px] border mr-[20px] h-[44px] rounded-lg' placeholder={`Search ${menuFactory.label}l`} onChange={(e) => { handlesearchAdminreferal(e.target.value) }} />
                {renderAddButton}
              </div>
              : ""
            }
          </div>
          <div>
            {children}
          </div>
        </div>
      );
    }
    return children;
  }, [
    menuFactory,
    renderToggleTableMode,
    renderAddButton,
    children,
    resarray,
    getrestaurant,
    getlistofcollections,
    getnftgroup,
    getadmins,
    getusers,
    getcountries,
    fetchCountryData,
  ]);

  const renderDeleteModal = () => {
    return (
      <div>
        <Text text='Are you sure you want to delete this item?' className='py-5' fontSize='18px' />
        <div className='flex justify-end items-center space-x-2'>
          <Button
            label='Yes'
            width='80px'
            backgroundColor='white'
            textColor={Colors.prussianBlue}
            height='35px'
            onClick={() => {
              Deleterestaurants();
              // modalDeleteFactory.onSubmit()
              // onChangeModalMode('INIT')
            }}
          />
          <Button
            label='No'
            width='80px'
            height='35px'
            backgroundColor='white'
            textColor={Colors.prussianBlue}
            className='border-solid border-2 border-prussianBlue'
            onClick={() => {
              onChangeModalMode('INIT')
            }}
          />
        </div>
      </div>
    )
  }


  const renderModalContent = () => {
    if (menuFactory.name === 'restaurant') {
      return (
        <ModalWrapper>
          <Form className='my-5' autoComplete='off'>
            <Wrapper className='flex'>
              <div>
                <UploadImage onChange={(e) => onChangeProfile(e)} imageurl={restaurantProfile ? restaurantProfile : ""} />
              </div>

              <div className='ml-3'>
                <FormGroup className='input-wrapper'>
                  <Label htmlFor='name' className='mb-2 text-darkGrey'>Name</Label>
                  <TextField
                    id='name'
                    placeholder='Name'
                    value={restaurantName}
                    onChange={(e) => setRestaurantName(e.target.value)}
                    className='input-name border rounded-md outline-prussianBlue'
                    noInlineError />
                </FormGroup>
                {modalFactory.modalTitle == "Edit" ?
                  <p className={"mt-5"} style={{ color: "red" }}>
                    {restaurantNameList.includes(restaurantName) && fieldsEditRestaurant.restaurantName != restaurantName ? "This restaurant name already exists" : null}
                  </p>
                  : 
                  <p className={"mt-5"} style={{ color: "red" }}>
                    {restaurantNameList.includes(restaurantName)  ? "This restaurant name already exists" : null}
                  </p>
                }

                <FormGroup className='input-wrapper mt-5'>
                  <Label htmlFor='website' className='mb-2 text-darkGrey'>Website</Label>
                  <TextField
                    id='website'
                    value={website}
                    onChange={(e) => setWebsite(e.target.value)}
                    placeholder='Website'
                    className='input-name border rounded-md'
                    noInlineError />
                </FormGroup>
              </div>
            </Wrapper>
            <FormGroup className='input-wrapper mt-4'>
              <Label htmlFor='description' className='mb-2 text-darkGrey'>Description</Label>
              <TextArea
                id='description'
                placeholder='Description'
                value={Description}
                onChange={(e) => setDescription(e.target.value)}
                className='h-[80px] border rounded-md resize-none'
                noInlineError />
            </FormGroup>
            <Wrapper className='grid grid-cols-2 gap-3 mt-2'>
              <FormGroup className='input-wrapper mt-2'>
                <Label className='text-darkGrey mb-2'>Country</Label>
                <Dropdown
                  className='h-[40px]'
                  menuClassname='h-[100px]'
                  value={Country}
                  onChange={(e) => { handleSetCities(e.target.value); setCountry(e.target.value) }}
                  placeholder='Choose Country'
                  options={countries}
                />
              </FormGroup>
              <FormGroup className='input-wrapper mt-2'>
                <Label className='text-darkGrey mb-2'>City</Label>
                <Dropdown
                  className='h-[40px]'
                  menuClassname='h-[100px]'
                  // value={city}
                  placeholder={city ? city : `Choose City`}
                  options={cities}
                  onChange={(e) => setCity(e.target.value)}
                />
              </FormGroup>
            </Wrapper>
            <FormGroup className='input-wrapper mt-4'>
              <TextArea
                id='address'
                placeholder='Input Address'
                value={Address}
                onChange={(e) => setAddress(e.target.value)}
                className='h-[80px] border rounded-md resize-none'
                noInlineError />
              <TextField
                id='Name'
                name="walletAddress"
                placeholder='wallet address'
                defaultValue={walletAddress}
                className='input-name border rounded-md mt-2 w-[100%]'
                onChange={(e) => { setWalletAddress(e.target.value) }}
                noInlineError
              />
            </FormGroup>
            <div className="mt-3">
              <span style={{ color: "#ff0f0f" }} >{walletAddress.length > 0 && !Web3.utils.isAddress(walletAddress) ? "Enter a valid wallet address" : null}</span>
            </div>
            <FormGroup className='input-wrapper mt-4'>
              <Label htmlFor='collections' className='mb-2 text-darkGrey'>Collections</Label>
              <FormList
                name='collections'
              >
                {(fields, {
                  onAdd, onRemove, onChange
                }) => (
                  <Wrapper className='w-full'>
                    {Collections && Collections.map((item, index) => {
                      return (
                        <div key={item.id} style={{ flexDirection: 'column' }} className='flex justify-between  items-start mb-3 mt-2'>

                          <TextField
                            id='Name'
                            name="NFTName"
                            placeholder='Collection Name'
                            className='input-name border rounded-md mt-2 w-[360px] mr-5 mb-5'
                            noInlineError
                            defaultValue={Collections[index].NFTName}
                            onChange={e => handleInputChange(e.target, index)}
                          />
                          <Dropdown
                            className='h-[40px] w-[360px] mr-5'
                            menuClassname='h-[100px] w-[360px] z-20'
                            options={collectionType}
                            name="NFTCollections"
                            id={item.id}
                            value={Collections[index].NFTCollections}
                            // disabled={modalFactory.modalTitle == "Edit" ? true : false}
                            onChange={e => handleInputChange(e.target, index)}
                            placeholder=''
                            {...item}
                          />

                          <TextField
                            id='Count'
                            name="NFTCount"
                            placeholder='Number of Nfts'
                            className='input-name border rounded-md mt-2 w-[360px] mr-5'
                            noInlineError
                            value={Collections[index].NFTCount}
                            onChange={e => {
                              const re = /^[0-9\b]+$/;
                              if (e.target.value === '' || re.test(e.target.value)) {
                                handleInputChange(e.target, index)
                              } else {
                                Collections[index].NFTCount = ""
                              }
                            }
                            }
                          />
                          <TextField
                            id='Price'
                            name="NFTPrice"
                            value={Collections[index].NFTPrice}
                            placeholder="Price of NFT (in Dollar / NFT)"
                            onChange={e => {
                              const re = /^[0-9\b]+$/;
                              if (e.target.value === '' || re.test(e.target.value)) {
                                handleInputChange(e.target, index)
                              } else {
                                Collections[index].NFTPrice = ""
                              }
                            }
                            }
                            className='input-name border rounded-md mt-2 w-[360px] mr-5'
                            noInlineError
                          />
                          <div className="flex w-full  items-center">
                            <TextField
                              id='NFTRoyalty'
                              name="NFTRoyalty"

                              value={Collections[index].NFTRoyalty}
                              placeholder="Royalty in %"
                              onChange={e => {
                                const re = (/^\d*\.?\d*$/);
                                if (e.target.value === '' || re.test(e.target.value)) {
                                  handleInputChange(e.target, index)
                                } else {
                                  Collections[index].NFTRoyalty = ""
                                }
                              }
                              }
                              className='input-name border rounded-md mt-2 w-[260px] mr-2'
                              noInlineError
                            />
                            {Number(Collections[index].NFTRoyalty) > 0 ?
                              <span>{"$ " + (Number(Collections[index].NFTRoyalty) * Collections[index].NFTPrice) / 100}</span>
                              : null
                            }
                          </div>
                          <div className="mt-3">
                            <Label htmlFor='date1' className='input-name1  mt-4  text-darkGrey w-[160px] mr-2 mt-2 '>Release date</Label>
                            <br />
                            <input
                              id='date'
                              name="NFTReleaseDate"
                              type="date"
                              defaultValue={Collections[index].NFTReleaseDate}
                              placeholder="Release date"
                              min={Collections[index].NFTReleaseDate ? "" : disablePastDate()}
                              onChange={e => handleInputChange(e.target, index)}
                              className='input-name1 border rounded-md   w-[360px] mr-1 mt-3'
                              noInlineError
                            />
                          </div>
                          <div className="mt-3">
                            <Label htmlFor='date1' className='input-name1  mt-4  text-darkGrey w-[160px] mr-2 mt-2 '>Sale End date</Label>
                            <br />
                            <input
                              id='date'
                              name="NFTEndDate"
                              type="datetime-local"
                              defaultValue={Collections[index].NFTEndDate}
                              placeholder="Sale End date"
                              min={Collections[index].NFTEndDate ? Collections[index].NFTReleaseDate+"T00:00" : Collections[index].NFTReleaseDate+"T00:00"}
                              onChange={e => { handleInputChange(e.target, index) }}
                              className='input-name1 border rounded-md  w-[360px] mr-1 mt-3'
                              noInlineError
                            />
                          </div>
                         
                          <TextField
                            id='description'
                            name="NFTDescription"
                            defaultValue={Collections[index].NFTDescription}
                            placeholder='Description'
                            onChange={e => handleInputChange(e.target, index)}
                            className='input-name border rounded-md mt-2 w-[360px] mr-5'
                            noInlineError
                          />

                          <TextField
                            id='benefits'
                            name="benefits"
                            defaultValue={Collections[index].benefits}
                            placeholder='benefits'
                            onChange={e => handleInputChange(e.target, index)}
                            className='input-name border rounded-md mt-2 w-[360px] mr-5'
                            noInlineError
                          />

                          {/* { Collections[index].benefits.length < 3 &&
                                  <div className='flex items-center mt-5'>
                                    <PlusCircleIcon
                                      className='w-4 h-4 cursor-pointer text-prussianBlue mr-3'
                                      onClick={ () => {
                                        handleAddbenefitsClick(index)
                                      } } 
                                    />
                                    <span className='font-extrabold text-prussianBlue'>Add benefits</span>
                                  </div>
                                } */}

                          {/* <TextField
                              id='image'
                              type="file"
                              name="NFTImage"
                              onChange={ e => handleInputChange(e, index) }
                              placeholder='image'
                              
                              className='input-name border rounded-md mt-2 w-[360px] mr-5'
                              noInlineError
                            /> */}
                          {Collections[index].NFTImage ?
                            <div className="mt-3" style={{ display: "flex", alignItems: "flex-start" }}>
                              <img style={{ width: "30%", height: "30%" }} src={`${process.env.REACT_APP_S3_URL}${Collections[index].NFTImage}`}></img>
                              <img src={Images.Close} alt="close" style={{ cursor: "pointer" }} onClick={() => handleCloseImage(index)} />
                            </div>
                            :
                            <>
                              <label htmlFor={`img${index}`}>
                                <Icons.UploadIcon />
                                <input id={`img${index}`} placeholder="nft image" accept={"image/png, image/jpeg, image/jpg"} style={{ display: "none" }} name="NFTImage" type="file" onChange={(e) => handleNftImage(e.target.files, index)} />
                              </label>
                            </>
                          }
                          {Collections && Collections.length > 1 &&
                            <MinusCircleIcon
                              className='w-4 h-4 mx-auto cursor-pointer'
                              onClick={() => {
                                handleRemove(index);
                              }}
                            />
                          }
                        </div>
                      );
                    })}
                    {/* {Collections && Collections.length < 3 && */}
                    <div className='flex items-center mt-5'>
                      {/* <PlusCircleIcon
                        className='w-4 h-4 cursor-pointer text-prussianBlue mr-3'
                        onClick={() => {
                          handleAddClick()
                        }}
                      />
                      <span className='font-extrabold text-prussianBlue'>Add Collections</span> */}
                    </div>
                    {/* } */}
                  </Wrapper>
                )}
              </FormList>
            </FormGroup>
            <FormGroup className='input-wrapper mt-4'>
              <Label className='text-darkGrey'>Social Media</Label>
              <Wrapper className='grid grid-cols-2 gap-3 mt-2'>
                <TextField
                  placeholder='Username'
                  value={Twitter}
                  onChange={e => setTwitter(e.target.value)}
                  className='input-socmed border rounded-md icon-twitter'
                  noInlineError
                />
                <TextField
                  placeholder='Username'
                  value={LinkedIn}
                  onChange={e => setLinkedIn(e.target.value)}
                  className='input-socmed border rounded-md icon-linkedin'
                  noInlineError
                />
                <TextField
                  placeholder='Username'
                  value={Instagram}
                  className='input-socmed border rounded-md icon-ig'
                  onChange={e => SetInstagram(e.target.value)}
                  noInlineError
                />
                <TextField
                  placeholder='Username'
                  value={Discord}
                  className='input-socmed border rounded-md icon-discord'
                  onChange={e => setDiscord(e.target.value)}
                  noInlineError
                />
              </Wrapper>
            </FormGroup>
            <FormGroup className='mt-4'>
              <Label className='text-darkGrey'>Financial Criteria</Label>
              <Wrapper className='mt-2'>
                <Dropdown
                  className='h-[40px]'
                  menuClassname='h-[100px]'
                  options={options}
                  value={FinancialCriteria}
                  onChange={e => setFinancialCriteria(e.target.value)}

                />
              </Wrapper>
            </FormGroup>
          </Form>

        </ModalWrapper>
      )
    } else if (menuFactory.name === 'admins') {
      return (
        <ModalWrapper>
          <>
            {/* {ownerwallet == wallet.address ? 
              <> */}
            <Form className='my-5' autoComplete='off'>
              <Form.FormGroup className='input-wrapper mb-5'>
                <Form.Label htmlFor='name' className='mb-2 text-darkGrey'>Admin Name</Form.Label>
                <Form.TextField
                  id='name'
                  placeholder='admin name'
                  className='input-name border rounded-md outline-prussianBlue'
                  noInlineError
                  onChange={(e) => setAdminname(e.target.value)}
                // { ...registeredValue('name') }
                />
              </Form.FormGroup>
              <Form.FormGroup className='input-wrapper mb-5'>
                <Form.Label htmlFor='email' className='mb-2 text-darkGrey'>Email Address</Form.Label>
                <Form.TextField
                  id='email'
                  placeholder='email'
                  className='input-name border rounded-md outline-prussianBlue'
                  onChange={(e) => setAdminEmail(e.target.value)}
                  noInlineError
                // { ...registeredValue('email') }
                />
              </Form.FormGroup>
              <span style={{ color: "red", fontWeight: "300", marginBottom: "20px" }}>{ListUsers && ListUsers.includes(adminEmail) ? "This email is already registered" : ""}</span>
              <Form.FormGroup className='input-wrapper mb-5 mt-3'>
                <Form.Label htmlFor='phone' className='mb-2 text-darkGrey'>Phone Number</Form.Label>
                <Form.TextField
                  id='phone'
                  placeholder='ex. 0812xxxxxxx'
                  onChange={(e) => setAdminPhone(e.target.value)}
                  className='input-name border rounded-md outline-prussianBlue'
                  noInlineError
                // { ...registeredValue('phone') }
                />
              </Form.FormGroup>

              <Form.FormGroup className='input-wrapper  mb-5'>
                <Form.Label htmlFor='access' className='mb-2 text-darkGrey'>Access Level</Form.Label>
                <Form.Dropdown
                  id='access'
                  value='Super Admin'
                  className='input-name border rounded-md outline-prussianBlue'
                  options={accessOption}
                  onChange={(e) => setUserType(e.target.value)}
                  noInlineError
                // { ...registeredValue('access') }
                />
              </Form.FormGroup>

              {usertype == "superadmin" ?
                <>
                  <Form.FormGroup className='input-wrapper mb-5'>
                    <Form.Label htmlFor='walletaddress' className='mb-2 text-darkGrey'>Wallet Address</Form.Label>
                    <Form.TextField
                      id='walletaddress'
                      placeholder='wallet address'
                      className='input-name border rounded-md outline-prussianBlue'
                      onChange={(e) => setAdminWallet(e.target.value)}
                      // style={{border:"13px", borderColor:"red"}}
                      noInlineError
                    // { ...registeredValue('email') }
                    />
                  </Form.FormGroup>
                  <span className='mb-5' style={{ color: "red" }}>{adminwallet.length > 0 && ListWallets.includes(adminwallet) ? "This wallet address is already registered" : null}</span>
                  <span className='mb-5' style={{ color: "red" }}>{adminwallet && !Web3.utils.isAddress(adminwallet) ? "Enter a valid wallet address" : ""}</span>
                </>
                : null
              }
              <Form.FormGroup className='input-wrapper mb-5'>
                <Form.Label htmlFor='phone' className='mb-2 text-darkGrey'>Password</Form.Label>
                <Form.TextField
                  id='password'
                  placeholder='Password'
                  type={passwordtype}
                  onChange={(e) => setPassword(e.target.value)}
                  className='input-name border rounded-md outline-prussianBlue'
                  noInlineError
                // { ...registeredValue('phone') }
                />
              </Form.FormGroup>
              <Form.FormGroup className='input-wrapper mb-5'>
                <Form.Label htmlFor='phone' className='mb-2 text-darkGrey'>Confirm Password</Form.Label>
                <Form.TextField
                  id='Confirmpassword'
                  placeholder='Confirm Password'
                  type={passwordtype}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  className='input-name border rounded-md outline-prussianBlue'
                  noInlineError
                // { ...registeredValue('phone') }
                />
              </Form.FormGroup>
              {/* <Form.FormGroup className='flex input-wrapper mb-5 '> */}
              {/* <Form.Label htmlFor='phone' className='mb-2 text-darkGrey'>Confirm Password</Form.Label> */}
              <div className='flex'>
                <input
                  id='Confirmpassword'
                  placeholder='Confirm Password'
                  type="checkbox"
                  // style={{}}
                  onChange={(e) => handlePasswordType()}
                  className='input-name-check'
                  noInlineError
                // { ...registeredValue('phone') }
                />
                <Text text="Show Password" className="ml-2" />
              </div>

              {/* </Form.FormGroup> */}
              {/* <Button>Submit</Button> */}
            </Form>
            <ModalSuccess
              visible={showModalSuccess}
              onClose={() => setShowModalSuccess(fModalWrapperalse)}
            />
            {/* </> */}
            {/* :
              <p>restricted</p>
              } */}
          </>
        </ModalWrapper>
      );
    } else if (menuFactory.name === 'collections') {
      return (
        <ModalWrapper>
          <Form className='my-5' autoComplete='off'>
            <Wrapper className='flex justify-center' >
              <UploadImage
                width='120px'
                height='120px'
                title='Icons'
                rounded='rounded-full'
                onChange={(e) => onChangeAddNFTImage(e)}
                imageurl={NftImage ? NftImage : ""}
              // { ...registeredValue('photo') }
              />
            </Wrapper>
            <div>
              <Form.FormGroup className='input-wrapper mt-4'>
                <Form.Label htmlFor='restaurants' className='mb-2 text-darkGrey'>Events</Form.Label>
                <Dropdown
                  className='h-[40px] w-[360px] mr-5'
                  menuClassname='h-[100px] w-[360px] z-20'
                  options={resarray}
                  name="NFTCollections"
                  // id={ item.id }
                  // value={Collections[index].NFTCollections}
                  onChange={e => { setAddNftOwnedBy(e.target.value) }}
                  placeholder='Choose Events'
                // { ...item }
                />
              </Form.FormGroup>
              <Form.FormGroup className='input-wrapper mt-4'>
                <Form.Label htmlFor='tier' className='mb-2 text-darkGrey'>Tier(s)</Form.Label>
                <Dropdown
                  className='h-[40px] w-[360px] mr-5'
                  menuClassname='h-[100px] w-[360px] z-20'
                  options={tiers_ && tiers_}
                  name="NFTCollections"
                  // id={ item.id }
                  // value={Collections[index].NFTCollections}
                  onChange={e => setAddNftTier(e.target.value)}
                  placeholder='Choose Tier'
                // { ...item }
                />
              </Form.FormGroup>
              <Form.FormGroup className='input-wrapper'>
                <Form.Label htmlFor='name' className='mb-2 text-darkGrey'>Name</Form.Label>
                <Form.TextField
                  id='name'
                  placeholder='Name'
                  className='input-name border rounded-md outline-prussianBlue'
                  onChange={(e) => setAddCollectionName(e.target.value)}
                  noInlineError
                // { ...registeredValue('name') } 
                />
              </Form.FormGroup>
            </div>
            <div>
              <Form.FormGroup className='input-wrapper mt-2'>
                {/* <Form.Label htmlFor='benefits' className='mb-2 text-darkGrey'>N</Form.Label> */}
                <Form.TextField
                  id='Number of nfts'
                  placeholder='Number of Nfts'
                  type="number"
                  className='input-name border rounded-md outline-prussianBlue'
                  onChange={(e) => setAddCollectionCount(e.target.value)}
                  noInlineError
                // { ...registeredValue('name') } 
                />
              </Form.FormGroup>
            </div>
            <div>
              <Form.FormGroup className='input-wrapper mt-2'>
                <Form.Label htmlFor='price' className='mb-2 text-darkGrey'>Price</Form.Label>
                <Form.TextField
                  id='price'
                  placeholder='price (in dollar)'
                  className='input-name border rounded-md outline-prussianBlue'
                  onChange={(e) => {
                    if (e.target.value > 0) {
                      setAddNftPrice(e.target.value)
                    } else {
                      setAddNftPrice('')
                    }
                  }}
                  noInlineError
                // { ...registeredValue('name') } 
                />
              </Form.FormGroup>
            </div>
            <div>
              <Form.FormGroup className='input-wrapper mt-2'>
                <Form.Label htmlFor='royalty' className='mb-2 text-darkGrey'>Royalty</Form.Label>
                <Form.TextField
                  id='royalty'
                  placeholder='Royalty (in %)'
                  className='input-name border rounded-md outline-prussianBlue'
                  onChange={(e) => {
                    if (e.target.value > 0) {
                      setAddNftRoyalty(e.target.value)
                    } else {
                      setAddNftRoyalty('')
                    }
                  }}
                  noInlineError
                // { ...registeredValue('name') } 
                />
              </Form.FormGroup>
            </div>

            <Form.FormGroup className='input-wrapper mt-4'>
              <Form.Label htmlFor='description' className='mb-2 text-darkGrey'>Description</Form.Label>
              <Form.TextArea
                id='description'
                placeholder='Description'
                className='h-[80px] border rounded-md resize-none'
                onChange={(e) => setAddNftDescription(e.target.value)}
                noInlineError
              // { ...registeredValue('description') } 
              />
            </Form.FormGroup>



            <div>
              <Form.FormGroup className='input-wrapper'>
                <Form.Label htmlFor='benefits' className='mb-2 text-darkGrey'>Benefits</Form.Label>
                <Form.TextField
                  id='benefits'
                  placeholder='Benefits'
                  className='input-name border rounded-md outline-prussianBlue'
                  onChange={(e) => setAddNftBenefits(e.target.value)}
                  noInlineError
                // { ...registeredValue('name') } 
                />
              </Form.FormGroup>
            </div>

            <Wrapper className='grid grid-cols-2 gap-3'>
              <Form.FormGroup className='input-wrapper mt-4'>
                <Form.Label className='text-darkGrey'>Release Date</Form.Label>
                {/* <Form.DatePicker
						className='input-datepicker border rounded-md mt-2 icon-date'
            onChange={(e)=>setAddNftReleaseDate(e.target.value)}
						// { ...registeredValue('releaseDate') }
					/> */}
                <input
                  id='date'
                  name="NFTReleaseDate"
                  type="date"
                  // defaultValue={Collections[index].NFTReleaseDate}
                  placeholder="Release date"
                  onChange={e => setAddNftReleaseDate(e.target.value)}
                  className='input-name1 border rounded-md  w-[160px] mr-1 mt-3'
                  noInlineError
                />
              </Form.FormGroup>
              <Form.FormGroup className='input-wrapper mt-4'>
                <Form.Label className='text-darkGrey'>Finish Date</Form.Label>
                <input
                  id='date'
                  name="NFTEndDate"
                  type="datetime-local"
                  // defaultValue={Collections[index].NFTReleaseDate}
                  placeholder="End date"
                  min={addNft_release_date+"T00:00"}
                  onChange={e => setAddNftEnddate(e.target.value)}
                  className='input-name1 border rounded-md  w-[160px] mr-1 mt-3'
                  noInlineError
                />
              </Form.FormGroup>
            </Wrapper>
          </Form>
        </ModalWrapper>
      );
    }
  }

  return (
    <Layout className='flex'>
      <div>
        <Aside
          className={'h-screen w-[240px] sticky top-0 bg-white border-r border-gray-200 border-solid'}
        >
          <MenuItem onClick={e => onClickMenu(e)}>
            <img src={Images.LogoCms} className='mx-auto pt-[10px] cursor-pointer' alt='oku-group' />
          </MenuItem>
          <div className='mt-[40px]'>
            <ul>
              {renderMenu}
            </ul>
          </div>
        </Aside>
      </div>
      <div className='w-[100%]'>
        <header
          className={'sticky text-right w-[100%] border-b border-gray-200 border-solid h-[80px]'}
        >
          <div>
            <ProfilePopup />
          </div>
        </header>
        <Content className='p-[20px]'>
          {/* {resarray.length > 0 ? renderContent : ""} */}
          {renderContent}
        </Content>

      </div>


      {modalFactory.modalTitle == "Add" ? (
        <Modal
          visible={modalFactory.visible}
          position='top'
          headerTitle={`${modalFactory.modalTitle} ${menuFactory.label}`}
          onClickClose={() => {
            onChangeModalMode('INIT')
          }}
          customFooter={
            <Button
              theme='primary'
              width='100%'
              height='44px'
              className='bg-prussianBlue'
              disabled={window.location.href.includes('admins') ? !isadminSubmitEnabled : false}
              onClick={() => {
                if (window.location.href.includes('admins')) {
                  if (usertype == "admin") {
                    handleSubmitadmins();
                  } else {
                    admincontract();
                  }
                } else if (window.location.href.includes('restaurant')) {
                  createRestaurant();
                  // submitcheck();
                } else if (window.location.href.includes('collections')) {
                  handleaddCollectionContract();
                  // handleSubmitCollections()          
                } else {
                  createRestaurant();
                }
              }}>
              <span>{modalFactory.btntitle}</span>
            </Button>
          }


        >
          {renderModalContent()}
        </Modal>

      ) : modalFactory.modalTitle == "Edit" ? (
        <Modal
          visible={modalFactory.visible}
          position='top'
          headerTitle={`${modalFactory.modalTitle} ${menuFactory.label}`}
          onClickClose={() => {
            handleCloseEditModal();
            onChangeModalMode('INIT')
          }}
          customFooter={
            <Button
              theme='primary'
              width='100%'
              height='44px'
              className='bg-prussianBlue'
              // disabled={ !isFormValid() }
              onClick={() => {
                editContract();
              }}>
              <span>{modalFactory.btntitle}</span>
            </Button>

          }
        >
          {renderModalContent()}
        </Modal>
      ) : ("")}
      <Modal
        visible={modalDeleteFactory.visible}
        position='top'
        headerTitle={`${modalDeleteFactory.modalTitle} ${menuFactory.label}`}
        onClickClose={() => {
          onChangeModalMode('INIT')
        }}
        customFooter={null}
      >
        {renderDeleteModal()}
      </Modal>
      {openLoader &&
        <Modal visible={true} onClickClose={() => setOpenLoader(false)}>
          <WalletStyles>
            <Text
              text={'Wallet Action'}
              fontType={'h5'}
              fontWeight={'700'}
              fontSize={'28px'}
              color={'#FAFAFA'}
              fontFamily={'Lora'}
              className='title'
            />

            {/* { list.map((item, i) => ( */}
            <div
              className='card'
            >
              <div className='logo'>
                <img src={group29} width={50} height={50} />
              </div>
              <div className='menu'>
                <Text
                  text={"Request sent to wallet"}
                  fontWeight={'700'}
                  color={'#FAFAFA'}
                  fontType={'p'}
                  fontSize={'20px'}
                  fontFamily={'Lora'}
                />
              </div>
              <div className='arrow'>
                {metamaskStatus &&
                  <img src={loadiing} width={40} height={40} />
                }
              </div>


            </div>
            <div
              className='card'
            >
              <div className='logo'>
                <img src={group28} width={50} height={50} />
              </div>
              <div className='menu'>
                <Text
                  text={"Transaction Underway"}
                  fontWeight={'700'}
                  color={'#FAFAFA'}
                  fontType={'p'}
                  fontSize={'20px'}
                  fontFamily={'Lora'}
                />
              </div>
              <div className='arrow'>
                {contractStatus &&
                  <img src={loadiing} width={40} height={40} />
                }
              </div>


            </div>
            <div
              className='card'
            >
              <div className='logo'>
                <img src={group27} width={50} height={50} />
              </div>
              <div className='menu'>
                <Text
                  text={"Waiting for Confirmation"}
                  fontWeight={'700'}
                  color={'#FAFAFA'}
                  fontType={'p'}
                  fontSize={'20px'}
                  fontFamily={'Lora'}
                />
              </div>
              <div className='arrow'>
                {completionStatus &&
                  <img src={loadiing} width={40} height={40} />
                }
              </div>


            </div>
            {/* )) } */}
            <Button
              label='CHECK IN SCAN'
              className='connect-wallet'
            // onClick={() => {window.open(``, "_blank")}}

            // onClick={ () => handleShowModalContactUs && handleShowModalContactUs() }
            />
            {/* <ToastContainer /> */}
          </WalletStyles>
        </Modal>
      }
    {modal && 

<Modal
          visible={ modal }
          onClickClose={()=>setmodal(false) }
        >
          <div className='modal-input-container' style={{width:"500px"}}>
            <Text
              fontType='h2'
              text='Create New Referral '
              color='var(--blue)'
              className='mr-30 mb-3 join-modal-title'
            />
           
            <div className='row mt-4'>
            <div className='col col-lg-12 col-md-12'>
            <div className='' style={{fontWeight:"800",fontSize:"20px"}}> Refferal Name </div>
            <input
              id="website"
              name="refferalname"
              value={refferalname}
              onChange={(e) =>setrefferalname(e.target.value)}
              placeholder="Enter Referral Name"
              className="input-name border rounded-md mt-3 ml-2"
              style={{ minWidth: "450px", height: "50px",border:"none",outline:"unset" }}
              noInlineError
            />
            </div>

            <div className='col col-lg-12 col-md-12 mt-3'>
            <div className='' style={{fontWeight:"800",fontSize:"20px"}}> No of Referrals</div>
            <input
              id="website"
              name="nftsupply"
              value={refferalcount}
              onChange={(e) =>setrefferalcount(e.target.value)}
              placeholder="Enter No of Referrals"
              className="input-name border rounded-md mt-3 ml-2"
              style={{ minWidth: "450px", height: "50px",border:"none",outline:"unset" }}
              noInlineError
            />
            </div>


            {/* <div className='col col-lg-4 col-md-4 d-flex justify-content-center align-items-center'>
             <img width={30} height={30} src={token == 1 ?Images.MATIC : token == 2 ? Images.USDT : token == 3 ? Images.USDC : ""}/>
             <div className='ml-2' style={{fontWeight:"800",fontSize:"20px"}}>{token == 1 ?"MATIC" : token == 2 ? "USDT" : token == 3 ? "USDC" : ""}</div>
            </div> */}
           
            </div>
            <div className='row mt-5 d-flex justify-content-center'>
            <div className='col col-lg-6 col-md-6 mt-3'>
              <button onClick={()=>setmodal(false)} className='btn text-light bg-prussianBlue w-40 h-30 ml-10' >
               Cancel
              </button>
              </div>
              <div className='col col-lg-6 col-md-6 mt-3'>
              <button onClick={()=>generateadmincodes()} className='btn text-light bg-prussianBlue w-40 h-30' >
                Generate Code
              </button>
              </div>
            </div>
          </div>
        </Modal>
    }
      
      {/* <ToastContainer /> */}
    </Layout>
  );
};

export default Sidebar;