import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Icons, Images } from 'constant';

import { Dropdown,TextField, Form, Table, Action, SelectMenu, Text ,Modal, Button } from 'components';
import { changeModalMode } from 'store/utlis/actions';
import { selectAdmins } from 'store/admins/actions';
import { refferalactionSelector } from 'store/refferal/selector';
import { Menu } from '@headlessui/react';
import { Container } from './style';
import {getAllusers, deactivateUser, EditAdmins, activateUser,deleteUser,getadminrefferalcode,deletereferalcode} from '../../services/auth';
import {email, passwordMatch} from '../../helpers/validation';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Fetch, persist} from '../../services/local-storage'; 
import Web3 from 'web3';
import loadiing from  '../../assets/images/loading.gif';
import { MdContentCopy } from "react-icons/md";
import {MdDelete} from "react-icons/md";


const  AdminRefferal= () => {
	const refferalactions = useSelector(refferalactionSelector);
	
	
	const dispatch = useDispatch();
	const [openloader, setOpenloader] = useState(false);
	const [deletemodal, setDeletemodal] = useState(false);
	const [deletemodal1, setDeletemodal1] = useState(false);
	const [editmodal, setEditmodal] = useState(false);
	const wallet = useSelector((state)=> state.WalletConnect);
	const [ownerwallet, setOwnerwallet] = useState('');
	const accessOption = [
		{
		  label: 'Super Admin',
		  value: 'superadmin'
		},
		{
		  label: 'Admin',
		  value: 'admin'
		},
	];
	  
	const [User, setUser] = useState('');
	const [newUser, setNewUser] = useState('');
	const [admins, setAdmins] = useState([]);
	const CurrentUser = JSON.parse(Fetch("user"));

	const getusers = async() => {
		const res = await getadminrefferalcode();
		console.log(res);
		dispatch({
			type:"GET_REFERAL_LIST", 
			payload: res.data.result
		  })
		setAdmins(refferalactions.refferalaction.refferalaction ? refferalactions.refferalaction.refferalaction : res.data.result);
	}
	const [adminName, setAdminname] = useState(User.userName);
	const [adminPhone, setAdminPhone] = useState(User.Phone);
	const [adminwallet, setAdminWallet] = useState('');
	const [adminEmail, setAdminEmail] = useState(User.email);
	const [password, setPassword] = useState('');
	const [confirmpasswod, setConfirmPassword] = useState('');
	const [usertype, setUserType] = useState(User.userType);
	const [passwordtype, setPasswordtype] = useState("password");
	const [ListUsers, setListUsers]=  useState([]);
	const getadmins = async() => {
		
	const res = await getadminrefferalcode();
    dispatch({
      type:"GET_REFERAL_LIST", 
      payload: res.data.result
    })
	setEditmodal(false);
    
	}

	const adminEditcontract= async() => {
		setOpenloader(true);
		const {web3, marketplace, address} = wallet;
		try{
		const addcollection_= await marketplace.methods.editOwner(User.walletAddress, adminwallet ? adminwallet : User.walletAddress).send({from:address});
		if (addcollection_.status){
			handleSubmitEditadmins()
		}else{	
		  toast("Editing admin failed")
		}
		}catch(err){
			console.log("error:", err)
			setOpenloader(false);
			setEditmodal(false);
			toast("Editing admin failed")
		}
	}
	const handleSubmitEditadmins = async() =>{
		setOpenloader(true)
		const wallet_ = adminwallet ? adminwallet : User.walletAddress;
	  const values={
		userName:adminName ? adminName : User.userName,
		phone:adminPhone ? adminPhone : User.Phone,
		email: adminEmail ? adminEmail : User.email,
		userType: usertype ? usertype : User.userType,
		walletAddress: User.userType == "superadmin" ? wallet_ : "",
	  }
	
	  const tokens = Fetch('token')
	
	  try{
		const id = User._id;
		
		const res = await EditAdmins(id,values,tokens) 
		if(res.data.status){
		  getadmins();
		  setOpenloader(false);
		  setEditmodal(false)
		  toast("Admin edited successfully")
		  setUser("");
		  setAdminname("");
		  setAdminPhone("");
		  setAdminWallet("");
		  setAdminEmail("");
		  setUserType("");
		}else{
			setEditmodal(false)
			toast("Something went wrong")

		}
	  }catch(err){
		console.log("err.data.response.message:",err.data.response.message)
	  }
	}
	const handlePasswordType = () =>{
	  if(passwordtype == "password"){
		setPasswordtype("text")
	  }else{
		setPasswordtype("password")
	  }
	}
	useEffect(() => {
		setAdmins(refferalactions.refferalaction.refferalaction);
		getusers();
		// listUsers();
	},[])


	useEffect(()=>{
		checkowner()
	},[wallet])

	const checkowner = async() =>{
		const {web3, marketplace, address} = wallet;
		try{
		const re = await marketplace.methods.owner().call()
		setOwnerwallet(re)
		}catch(err){
			console.log("err",err)
		}
	}
	const listUsers = async() =>{
		try{
		const res = await ListallUsers();
		if(res.data.status){
		  setListUsers(res.data.result)
		}
		}catch(err){
			console.log("err",err)
		}
	  }

	const onClickAction = useCallback(
		(mode, item) => {
			dispatch(selectAdmins(item));
			dispatch(changeModalMode(mode));
		},
		[dispatch],
	);
	const deactivateContract = async() =>{
		const {web3, marketplace, address} = wallet;
		const addcollection_= await marketplace.methods.SetOwner(User.walletAddress, false).send({from:address});
		if (addcollection_.status){
			deactivate();
		}else{
			toast("Can't connect with contract")
		}
	}
	const deactivate = async() =>{
		const action_ = await deactivateUser(User._id);
		
		getadmins();
		setDeletemodal(false);
		// window.location.reload(false);
	}
	const activateAdmin = async() => {
		const activate_ = await activateUser(User._id);
		if(activate_ .data.status){
			toast("Admin activated")
			getadmins();
			setDeletemodal(false);
		}else{
			toast("something went wrong")
		}
		
	}
	const isadminSubmitEnabled = email(adminEmail || User.email) && (adminName || User.userName)  && (adminPhone || User.Phone)  && (User.userType !="admin" ? Web3.utils.isAddress(adminwallet ? adminwallet : User.walletAddress) : true) && ListUsers && !ListUsers.includes(User.email != adminEmail)

	const isadminEditSubmitEnabled = email(adminEmail) && adminName || User.userName &&  adminEmail || User.email && adminPhone || User.Phone && usertype || User.userType;

	const deletereferal=async(value)=>{

		const deleted = await deletereferalcode(value)
		console.log("deleted",deleted);
		if(deleted.data.status){
			toast(deleted.data.message)
			getusers()
		}else{
			toast(deleted.data.message)
		}

	}


	const deleteadmins =async()=>{
		
		const res = await deleteUser(User && User._id)
		
		toast("Admin Deleted Successfully")
		getadmins();
		setDeletemodal1(false);
	}

	const timeconvertdays = (unix_timestamp) => {
		var a = new Date(unix_timestamp);
		var months = [
		  "Jan",
		  "Feb",
		  "Mar",
		  "Apr",
		  "May",
		  "Jun",
		  "Jul",
		  "Aug",
		  "Sep",
		  "Oct",
		  "Nov",
		  "Dec",
		];
		var year = a.getFullYear();
		var month = months[a.getMonth()];
		var date = a.getDate();
		var time = a.toLocaleTimeString()
		var time = date + "/" + month + "/" + year // + ", " + time; //+ ' ' + year + ' ' + hour + ':' + min + ':' + sec;
		return time;
	  };
	

	const columns = onClickAction => {
		return [
			{
				label: 'Referral Name',
				key: 'referralname',
				dataIndex: 'referralname',
				render: item => {
					return (
						<div>
							<span className='font-bold'>{item.RefferalName}</span>
						</div>
					);
				},
			},
			{
				label: 'Created by',
				key: 'createdby',
				dataIndex: 'createdby',
				render: item => {
					return (
						<div>
							<span className='font-bold'>{item.RefferedbyName ? item.RefferedbyName : "---"}</span>
						</div>
					);
				},
			},
			{
				label: 'Created on',
				key: 'createdon',
				dataIndex: 'createdon',
				render: item => {
					return (
						<div>
							<span className='font-bold'>{ timeconvertdays(Number(item.Refferalcreatetime)*1000)}</span>
						</div>
					);
				},
	
			},
			{
				label: 'Referral Code',
				key: 'refferalCode',
				dataIndex: 'refferalCode',
				render: item => {
					return (
						<div className='d-flex align-items-center'>
							<span className='font-bold mr-3'>{ item.RefferalCode}</span>
							<MdContentCopy size={15} style={{ fill: 'black',cursor:'pointer'}} onClick={()=>{navigator.clipboard.writeText(`${item.RefferalCode}`);toast("Referral Code Copied!")}}/>
						</div>
					);
				},
			},
			{
				label: 'Status',
				key: 'Status',
				dataIndex: 'Status',
				render: item => {
					return (
						<div>
							<span className='font-bold'  style={ item.RefferalStatus =="notused" ? {color:"red"} : null}>{ item.RefferalStatus =="notused" ? "Not Used" : "Used" }</span>
						</div>
					);
				},
			},
			{
				label: 'Used By',
				key: 'Usedby',
				dataIndex: 'Usedby',
				render: item => {
					return (
						<div>
							<span className='font-bold'>{ item.RefferedEmail ? item.RefferedEmail : "---"}</span>
						</div>
					);
				},
	
			},
			{
				label: '',
				key: 'delete',
				dataIndex: 'delete',
				render: item => {
					return (
						<div>
							<span className='font-bold'><MdDelete size={25} style={{ fill: 'red',cursor:'pointer'}} onClick={()=>{deletereferal(item._id)}}/></span>
						</div>
					);
				},
	
			},
			// {
			// 	label: '',
			// 	key: 'operation',
			// 	dataIndex: 'action',
			// 	render: item => {
			// 		return (
			// 			<>
			// 			{CurrentUser.userType == "superadmin" && wallet.address == ownerwallet ?
			// 			<ul className='flex items-center justify-between relative left-[-7px]'>
			// 				<li
			// 					className='m-0 p-3 rounded cursor-pointer scale-[0.75] mr-2'
			// 				 >
			// 					{renderPopMoreIcon(item, setDeletemodal,setDeletemodal1)}
			// 					</li>
			// 			</ul>
			// 			:null
			// 			}
				// 		</>
				// 	);
				// },
			// }
		];
	};

	const renderPopMoreIcon = (item,setDeletemodal,setDeletemodal1) => {
	
		return (
		  <>
		 
		  <SelectMenu
			MenuButton={<Icons.MoreIcon />}
			wrapperClassName='mr-0'
			menuItemsClassName='z-[9] w-56'
			menuBtnClassName='bg-blueLighter p-3 rounded cursor-pointer scale-[0.75]'
		  >
			{item.status !='inactive' ? 
			<div className='z-10'>
			  <Menu.Item>
				{( { active }) => (
				  <Text text="Edit" className='py-3 pl-3 hover:bg-gray-400 cursor-pointer' onClick={() => {
					if(wallet.address){
						setUser(item);
						setNewUser(item);
						setEditmodal(true);
					}else{
						toast("Connect wallet")
					}
					}}/>
				)}
			  </Menu.Item>
			</div>
			:<div className='z-10'>
			<Menu.Item>
			{( { active }) => (
			  <Text text="Delete" className='py-3 pl-3 hover:bg-gray-400 cursor-pointer' onClick={() => {
				if(wallet.address){
					setUser(item);
			        setDeletemodal1(true);
				}else{
					toast("Connect wallet")
				}
				}}/>
			)}
		  </Menu.Item>
		</div>
			}
			<div className='z-10'>
			  <Menu.Item>
				{({ active }) => (
					<>
					{item.status != "inactive" ? 
				  <Text text='Inactive' className='py-3 pl-3 hover:bg-gray-400 cursor-pointer' onClick={() => {
					if(wallet.address){
						setUser(item);
						setDeletemodal(true)
					}else{
						toast("Connect wallet")
					}
				}} />
				  :				 
				   <Text text='Activate' className='py-3 pl-3 hover:bg-gray-400 cursor-pointer' onClick={() => {
					if(wallet.address){
						setUser(item);
						setDeletemodal(true)
					}else{
						toast("Connect wallet");
					}
				}} />
					}
					</>
				)}
			  </Menu.Item>
			</div>
	  
		  </SelectMenu>
		  </>
		)
	}
	return (
		<Container>
			<Table
				columns={ columns(onClickAction) }
				dataSource={ refferalactions.refferalaction.refferalaction.length ==0 ? admins : refferalactions.refferalaction.refferalaction}
			/>
			<Modal
				visible={ deletemodal }
				onClickClose={() => setDeletemodal(false)}
			>
				<div className='modal-input-container'>
				<img src={ Images.Deactivate } className='mx-auto mb-10' />
				<Text
					fontType='h4'
					fontFamily='Lora'
					fontWeight='700'
					fontSize='24px'
					text={User.status != "inactive" ? `Do you want to Inactive  ${User?.userName}? `: `Do you want to Activate  ${User?.userName}? `}
					textAlign='center'
					color='var(--blue)'
					className='mb-3 join-modal-title'
				/>
		{/* 
				<Text
					text='WARNING : This action cannot be Undone!'
					color='var(--black200)'
					textAlign='center'
					className='mb-8'
				/> */}
				</div>
				<div className='btn-submit mx-auto flex justify-between'>
				<div>
					<Button
					className=''
					label='Yes'
					height={ '40px' }
					width={ '185px' }
					textColor={ '#003051' }
					backgroundColor={ '#E3E0E0' }
					onClick={()=>{
						if(User.status != "inactive"){
							if(User.userType == 'superadmin'){
								deactivateContract()
							}else{
								deactivate()
							}
						}else{
							activateAdmin()
						}
						}
					}
					/>
				</div>
				<div>
					<Button
					className=''
					label='No'
					height={ '40px' }
					width={ '185px' }
					textColor={ '#003051' }
					backgroundColor={ '#E3E0E0' }
					onClick={() =>setDeletemodal(false)}
					/>
				</div>
				</div>
			</Modal>

			<Modal
				visible={ deletemodal1 }
				onClickClose={() => setDeletemodal1(false)}
			>
				<div className='modal-input-container'>
				<img src={ Images.Deactivate } className='mx-auto mb-10' />
				<Text
					fontType='h4'
					fontFamily='Lora'
					fontWeight='700'
					fontSize='24px'
					text={`Do you want to delete  ${User?.userName} Permanently?`}
					textAlign='center'
					color='var(--blue)'
					className='mb-3 join-modal-title'
				/>
		{/* 
				<Text
					text='WARNING : This action cannot be Undone!'
					color='var(--black200)'
					textAlign='center'
					className='mb-8'
				/> */}
				</div>
				<div className='btn-submit mx-auto flex justify-between'>
				<div>
					<Button
					className=''
					label='Yes'
					height={ '40px' }
					width={ '185px' }
					textColor={ '#003051' }
					backgroundColor={ '#E3E0E0' }
					onClick={()=>{ deleteadmins()}
					}
					/>
				</div>
				<div>
					<Button
					className=''
					label='No'
					height={ '40px' }
					width={ '185px' }
					textColor={ '#003051' }
					backgroundColor={ '#E3E0E0' }
					onClick={() =>setDeletemodal1(false)}
					/>
				</div>
				</div>
			</Modal>

			{/* <Modal
				visible={ editmodal }
				onClickClose={() => setEditmodal(false)}
			></Modal> */}
			<Modal visible={ editmodal }
				onClickClose={() => setEditmodal(false)}
			>
            <>
              <Form className='my-5' autoComplete='off'>
			  <Text className='mb-2 text-black' text="Edit Admin" fontWeight={700} fontSize={42}/>

                <Form.FormGroup className='input-wrapper mb-5'>
                  <Form.Label htmlFor='name' className='mb-2 text-darkGrey'>Admin Name</Form.Label>
                  <Form.TextField
                    id='name'
                    placeholder='admin name'
					defaultValue={User.userName}
                    className='input-name border rounded-md outline-prussianBlue  h-10'
                    noInlineError
                    onChange={(e) =>{User.userName = e.target.value;setAdminname(e.target.value)}}
                    // { ...registeredValue('name') }
                  />
                </Form.FormGroup>
                <Form.FormGroup className='input-wrapper mb-5'>
                  <Form.Label htmlFor='email' className='mb-2 text-darkGrey'>Email Address</Form.Label>
                  <Form.TextField
                    id='email'
					defaultValue={User.email}
                    placeholder='email'
                    className='input-name border rounded-md outline-prussianBlue h-10'
                    onChange={(e)=>{setAdminEmail(e.target.value); User.email = e.target.value}}
                    noInlineError
                    // { ...registeredValue('email') }
                  />
                </Form.FormGroup>
				
				<span style={{color:"red", fontSize:"18px"}}>{ListUsers && ListUsers.includes(adminEmail)  ? "This Email is registered already" : null}</span>
                <Form.FormGroup className='input-wrapper mb-5'>
                  <Form.Label htmlFor='phone' className='mb-2 text-darkGrey'>Phone Number</Form.Label>
                  <Form.TextField
                    id='phone'
					defaultValue={User.Phone}
                    placeholder='ex. 0812xxxxxxx'
                    onChange={(e)=>{setAdminPhone(e.target.value); User.Phone = e.target.value}}
                    className='input-name border rounded-md outline-prussianBlue h-10'
                    noInlineError
                    // { ...registeredValue('phone') }
                  />
                </Form.FormGroup>
				{User.userType == "superadmin" ? 
                <>
                <Form.FormGroup className='input-wrapper mb-5'>
                  <Form.Label htmlFor='walletaddress' className='mb-2 text-darkGrey'>Wallet Address</Form.Label>
                  <Form.TextField
                    id='walletaddress'
                    placeholder='wallet address'
                    className='input-name border rounded-md outline-prussianBlue h-10'
					defaultValue={User.walletAddress}
                    onChange={(e)=>{setAdminWallet(e.target.value); User.walletAddress = e.target.value}}
                    // style={{border:"13px", borderColor:"red"}}
                    noInlineError
                    // { ...registeredValue('email') }
                  />
                </Form.FormGroup>
                <span className='mb-5' style={{color:"red"}}>{adminwallet && !Web3.utils.isAddress(adminwallet) ? "Enter a valid wallet address" : ""}</span>
                </>
                : null
                }
                <Form.FormGroup className='input-wrapper  mb-5'>
                  <Form.Label htmlFor='access' className='mb-2 text-darkGrey'>Access Level</Form.Label>
                  <Form.Dropdown
                    id='access'
					placeholder={User.userType}
                    className='input-name border rounded-md outline-prussianBlue h-10'
                    options={ accessOption }
                    onChange={(e)=>{setUserType(e.target.value); User.userType = e.target.value}}
                    noInlineError
                    // { ...registeredValue('access') }
                  />
                </Form.FormGroup>
                {/* <Form.FormGroup className='input-wrapper mb-5'>
                  <Form.Label htmlFor='phone' className='mb-2 text-darkGrey'>Password</Form.Label>
                  <Form.TextField
                    id='password'
                    placeholder='Password'
                    type={passwordtype}
                    onChange={(e)=>setPassword(e.target.value)}
                    className='input-name border rounded-md outline-prussianBlue h-10'
                    noInlineError
                    // { ...registeredValue('phone') }
                  />
                </Form.FormGroup>
                <Form.FormGroup className='input-wrapper mb-5'>
                  <Form.Label htmlFor='phone' className='mb-2 text-darkGrey'>Confirm Password</Form.Label>
                  <Form.TextField
                    id='Confirmpassword'
                    placeholder='Confirm Password'
                    type={passwordtype}
                    onChange={(e)=>setConfirmPassword(e.target.value)}
                    className='input-name border rounded-md outline-prussianBlue h-10'
                    noInlineError
                    // { ...registeredValue('phone') }
                  />
                </Form.FormGroup>
               
                  <div className='flex'>
                  <input
                    id='Confirmpassword'
                    placeholder='Confirm Password'
                    type="checkbox"
                    // style={{}}
                    onChange={(e)=>handlePasswordType()}
                    className='input-name-check h-10 w-6'
                    noInlineError
                  />
                  <Text  text="Show Password" className="ml-2 mt-2"/>
                  </div>
                  */}
                
              </Form>
			  {openloader? 
				<img src={loadiing} alt='loader' style={{width:"40px", height:"40px",alignSelf:"center", marginLeft:"45%"}}/>  
				:
				<Button theme='primary' 
					width='200px'
					height='44px' 
					className='bg-prussianBlue justify-center ml-20' 
					disabled={!isadminSubmitEnabled}
					onClick={()=>{
						if(User.userType == "superadmin"){
							adminEditcontract();
						}else{
							handleSubmitEditadmins();
						}
					} }
					>
						Submit
				</Button>
				}
              {/* <ModalSuccess
                visible={ showModalSuccess }
                onClose={()=> setShowModalSuccess(false) }
              /> */}
		        </>
          </Modal>
		  
			{/* <ToastContainer /> */}
		</Container>
	);
};

export default React.memo(AdminRefferal);
