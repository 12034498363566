export const persist = async (key, value) => {
  await localStorage.setItem(key, value);
};

export const Fetch = (key) => {
  const item = localStorage.getItem(key);
  return item;
};

export const remove = async (key) => {
  await localStorage.removeItem(key);
};

export const clear = async (key) => {
  localStorage.removeItem(key);
};
