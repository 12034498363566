import { createSelector } from 'reselect';

const userSelector = state => {
	return {
		...state.user,
		search: state.utils.search
	};
};

const userListSelector = createSelector(userSelector, user => {
	return {
		...user,
		user: user
	};
});

export { userListSelector };