
import * as UtilsActionTypes from './actionTypes';

export const changeModalMode = payload => ({
	type: UtilsActionTypes.CHANGE_MODAL_MODE,
	payload,
});

export const changeSearch = payload => ({
	type: UtilsActionTypes.CHANGE_SEARCH,
	payload,
});
export const resetUtils = () => ({
	type: UtilsActionTypes.RESET_UTILS,
});