import { createSelector } from 'reselect';

const nftgroupSelector = state => {
	return {
		...state.refferalaction,
		search: state.utils.search
	};
};

const refferalactionSelector = createSelector(nftgroupSelector, refferalaction => {
    {console.log("refferalaction==>",refferalaction)}
	return {
		...refferalaction,
		refferalaction: refferalaction
	};
});



export { refferalactionSelector };


