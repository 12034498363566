import * as UserActionTypes from './actionTypes';
import { Images } from 'constant';

const nftList =  [
	{
		id: 0,
		name: 'NFT 1',
		restoName: 'Resto 1',
		price: '1,234'
	},
	{
		id: 1,
		name: 'NFT 2',
		restoName: 'Resto 2',
		price: '1,234'
	},
	{
		id: 2,
		name: 'NFT 3',
		restoName: 'Resto 3',
		price: '1,234'
	},
	{
		id: 3,
		name: 'NFT 4',
		restoName: 'Resto 4',
		price: '1,234'
	},
	{
		id: 4,
		name: 'NFT 5',
		restoName: 'Resto 5',
		price: '1,234'
	},
	{
		id: 5,
		name: 'NFT 6',
		restoName: 'Resto 6',
		price: '1,234'
	},
];

const initialState = {
	user: [],
	loading: false,
	tableModeUser: 'LIST',
	selectedUser: {},
	//   fieldsEditAdmins: {}
};

const reqUser = (state, payload) => ({
	...state,
	user: payload,
	loading: true
});

const setUser = (state, payload) => ({
	...state,
	user: payload,
	loading: false
});

const setTableModeUser = (state, payload) => ({
	...state,
	tableModeUser: payload
});

// const addAdmins = (state, payload) => {
//   let data = { ...payload }
//   data.id = makeID(10)
//   data = {
//     ...data,
//     ...restructureData(data)
//   }
//   return {
//     ...state,
//     admins: [data, ...state.admins]
//   }
// }

// const editAdmins = (state, payload) => {
//   let data = { ...payload }
//   const admins = [...state.admins]
//   const findID = admins.findIndex(item => item.id === state.selectedAdmins.id)
//   data = restructureData(data)
//   admins[findID] = { ...data }
//   return {
//     ...state,
//     admins: [...admins]
//   }
// }

// const selectUser = (state, payload) => {
//   let selectedUser = { ...payload }
//   let fieldsEditAdmins = {}
//   if (payload) {
//     const locations = selectedAdmins['locations'].split(', ')
//     fieldsEditAdmins['photo'] = selectedAdmins['photo']
//     fieldsEditAdmins['name'] = selectedAdmins['name']
//     fieldsEditAdmins['website'] = selectedAdmins['website']
//     fieldsEditAdmins['description'] = selectedAdmins['description']
//     fieldsEditAdmins['country'] = locations[2]
//     fieldsEditAdmins['city'] = locations[1]
//     fieldsEditAdmins['address'] = locations[0]
//     fieldsEditAdmins['collections'] = selectedAdmins.collections.map(item => {
//       const id = makeID(10);
//       return {
//         name: `$collections-${id}`,
//         id: id,
//         errorMessage: '',
//         valid: true,
//         value: item
//       }
//     })
//     selectedAdmins.socialMedia.forEach(item => {
//       const name = item.name
//       fieldsEditAdmins[name] = item.username
//     })
//     fieldsEditAdmins['financialCriteria'] = selectedAdmins['financialCriteria']
//   }
//   return {
//     ...state,
//     selectedAdmins,
//     fieldsEditAdmins
//   }
// }

const deleteUser = state => {
	const user = [...state.user];
	const filteredUser = user.filter(item => item.id !== state.selectedUser.id);
	return {
		...state,
		user: [...filteredUser]
	};
};

const userReducer = (state = initialState, action) => {
	switch (action.type) {
		case UserActionTypes.GET_USER_LIST:
			console.log("response from userdispatch",action.payload)
			return setUser(state, action.payload);
		case UserActionTypes.GET_USER_LIST_SUCCESS:
			return setUser(state, action.payload);
		case UserActionTypes.DELETE_USER:
			return deleteUser(state);
		case UserActionTypes.CHANGE_TABLE_MODE:
			return setTableModeUser(state, action.payload);
			// case UserActionTypes.SELECT_USER:
			//   return selecUser(state, action.payload);
		default:
			return state;
	}
};

export default userReducer;
