import React, { useMemo} from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import { Icons } from 'constant';
import { Container } from './style';

const Modal = ({
  visible, onClose, customHeader, children, customFooter, headerTitle, position = 'center', onClickClose
}) => {

  const positionModal = useMemo(() => {
    if (position === 'top') {
      return 'items-start';
    }
    return 'items-center';
  }, [position]);

  const renderHeaderModal = useMemo(() => {
    if (!customHeader) {
      return (
        <div className='flex items-center justify-between'>
          <div>
            <h5 className='font-bold text-2xl font-lora'>{ headerTitle ? headerTitle : '' }</h5>
          </div>
          <div onClick={onClickClose}>
            <Icons.CloseIcon className='icon-close cursor-pointer'/>
          </div>
        </div>
      );
    }

    return customHeader;

  }, [customHeader, headerTitle]);

  return (
    <Container>

      <Transition appear show={ visible } as={ Fragment }>
        <Dialog as='div' className='relative z-10' onClose={() => onClose ? onClose() : null }>
          <Transition.Child
            as={ Fragment }
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className={ `flex min-h-full ${positionModal} justify-center p-4 text-center` }>
              <Transition.Child
                as={ Fragment }
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel className='w-250 transform rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all'>
                  <Dialog.Title
                    as='h3'
                    className='text-lg font-medium leading-6 text-gray-900'
                  >
                    { renderHeaderModal }
                  </Dialog.Title>
                  <div>
                    { children }
                  </div>
                  { customFooter }
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </Container>
  );
};

export default React.memo(Modal);
