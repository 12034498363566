import React from "react";
import {Modal, Button , Text} from 'components';


// const useStyles = makeStyles(() => ({
//   dialog: {
//     borderRadius: ".5em",
//     padding:"1em"
//   },
// }));

// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });

const SessionTimeoutDialog = ({  open, countdown, onLogout,onContinue }) => {
  // const classes = useStyles();

  return (
    <Modal
        visible={ open }
        onClickClose={() => onLogout()}
      >
        <div className='modal-input-container mb-15'>
          <Text
            fontType='h4'
            fontFamily='Lora'
            fontWeight='700'
            fontSize='24px'
            text={`Your session ends in ${countdown} seconds do you want to continue.`}
            textAlign='center'
            color='var(--blue)'
            className='mb-3 join-modal-title'
          />
        </div>
        <div className='btn-submit mx-auto flex justify-between mt-5'>
          <div>
            <Button
              className=''
              label='Yes'
              height={ '40px' }
              width={ '185px' }
              textColor={ '#003051' }
              backgroundColor={ '#E3E0E0' }
              onClick={onContinue}
            />
          </div>
          <div>
            <Button
              className=''
              label='No'
              height={ '40px' }
              width={ '185px' }
              textColor={ '#003051' }
              backgroundColor={ '#E3E0E0' }
              onClick={onLogout}
            />
          </div>
        </div>
      </Modal>
  );
}


export default SessionTimeoutDialog;