import moment from 'moment';
import React, { useState, useEffect, useCallback } from 'react';
import Picker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DatePicker = ({ className, onChange, placeholder = 'Choose', name, id, ...props }) => {
	const [startDate, setStartDate] = useState();
	const onChangeValue = useCallback(
		date => {
			if (onChange) {
				const event = {
					target: {
						name: name,
						value: date,
						id,
					}
				};
				onChange(event);
			}
			setStartDate(date);
		},
		[onChange, name, id],
	);

	useEffect(() => {
		const selectedValue = props.value;
		if (selectedValue) {
			setStartDate(moment(selectedValue).toDate());
			return () => {
				setStartDate();
			};
		}
	}, []);
	return (
		<Picker
			dateFormat='dd/MM/yyyy'
			selected={ startDate }
			onChange={ date => onChangeValue(date) }
			className={ className }
			onChangeRaw={ e => e.preventDefault() }
			placeholderText='Choose Date'
		/>
	);
};

export default React.memo(DatePicker);