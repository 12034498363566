import styled from "styled-components";

const WalletStyles = styled.div`
	
    .buttonhover:hover {
        background-color: #fff;
        color: #003051 !important;
        font-color: #003051 !important;
        font-weight: bold;
        border: 1px solid #003051;
        border-radius:10px
    }
`;

export default WalletStyles;
