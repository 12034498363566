import { createSelector } from 'reselect';

const nftgroupSelector = state => {
	return {
		...state.groupcollection,
		search: state.utils.search
	};
};

const groupcollectionSelector = createSelector(nftgroupSelector, groupcollection => {
    {console.log("groupcollection==>",groupcollection)}
	return {
		...groupcollection,
		groupcollection: groupcollection
	};
});



export { groupcollectionSelector };


