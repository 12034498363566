import styled from 'styled-components';

const SubHeaderStyle = styled.div`
   .profil {
      margin-right: 30px;
   }
   @media (max-width: 715px) { 
      .profil {
         width: 90px;
         height: 90px;
         margin-right: 15px;
      }
    }

    @media (max-width: 480px) { 
      .settingmenu {
         position: relative;
         margin-top: -30px;
      }
    }
`;

export default SubHeaderStyle;