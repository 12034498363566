import styled from 'styled-components';
import colors from 'constant/colors';

const ForgotPasswordStepsStyle = styled.div`
  margin-top: 30px;

  .logo-cms {
    display: block;
      margin: 0 auto;
  }

  .forgotpassword-form {
    width: 450px;
    background: white;
    padding: 30px;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    margin: auto;
  }

  .input-name {
    min-width: 230px !important;
    height: 40px ;
    padding-left: 9px ;
    &::placeholder {
      padding-left: 0 !important ;
    }
  }

  .input-wrapper:focus-within {
    label {
      font-weight: bold ;
      color: ${colors.prussianBlue} ;
    }
  .input-name {
      &:focus {
      outline: 1px solid ${colors.blue.prussianBlue} ;
      border-color: transparent ;
    }
  }
`;

export default ForgotPasswordStepsStyle;
