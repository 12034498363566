import * as AdminsActionTypes from './actionTypes';
import { makeID } from 'helpers';

const restructureData = data => {
	return data;
};

const initialState = {
	admins: [],
	loading: false,
	tableModeAdmins: 'LIST',
	selectedAdmins: {},
	fieldsEditAdmins: {}
};

const reqAdmins = (state, payload) => ({
	...state,
	admins: payload,
	loading: true
});

const setAdmins = (state, payload) => ({
	...state,
	admins: payload,
	loading: false
});

const setTableModeAdmins = (state, payload) => ({
	...state,
	tableModeAdmins: payload
});

const addAdmins = (state, payload) => {
	let data = { ...payload };
	data.id = makeID(10);
	data = {
		...data,
		...restructureData(data)
	};
	return {
		...state,
		admins: [data, ...state.admins]
	};
};

const editAdmins = (state, payload) => {
	let data = { ...payload };
	const admins = [...state.admins];
	const findID = admins.findIndex(item => item.id === state.selectedAdmins.id);
	data = restructureData(data);
	admins[findID] = { ...data };
	return {
		...state,
		admins: [...admins]
	};
};

const selectAdmins = (state, payload) => {
	let selectedAdmins = { ...payload };
	let fieldsEditAdmins = {};
	if (payload) {
		const locations = selectedAdmins['locations'].split(', ');
		fieldsEditAdmins['photo'] = selectedAdmins['photo'];
		fieldsEditAdmins['name'] = selectedAdmins['name'];
		fieldsEditAdmins['website'] = selectedAdmins['website'];
		fieldsEditAdmins['description'] = selectedAdmins['description'];
		fieldsEditAdmins['country'] = locations[2];
		fieldsEditAdmins['city'] = locations[1];
		fieldsEditAdmins['address'] = locations[0];
		fieldsEditAdmins['collections'] = selectedAdmins.collections.map(item => {
			const id = makeID(10);
			return {
				name: `$collections-${id}`,
				id: id,
				errorMessage: '',
				valid: true,
				value: item
			};
		});
		selectedAdmins.socialMedia.forEach(item => {
			const name = item.name;
			fieldsEditAdmins[name] = item.username;
		});
		fieldsEditAdmins['financialCriteria'] = selectedAdmins['financialCriteria'];
	}
	return {
		...state,
		selectedAdmins,
		fieldsEditAdmins
	};
};

const deleteAdmins = state => {
	const admins = [...state.admins];
	const filteredAdmins = admins.filter(item => item.id !== state.selectedAdmins.id);
	return {
		...state,
		admins: [...filteredAdmins]
	};
};

const adminsReducer = (state = initialState, action) => {
	switch (action.type) {
		case AdminsActionTypes.GET_ADMINS_LIST:
			console.log("admin paylaod:", action.payload)
			return reqAdmins(state, action.payload);
		case AdminsActionTypes.GET_ADMINS_LIST_SUCCESS:
			return setAdmins(state, action.payload);
		case AdminsActionTypes.ADD_ADMINS:
			return addAdmins(state, action.payload);
		case AdminsActionTypes.EDIT_ADMINS:
			return editAdmins(state, action.payload);
		case AdminsActionTypes.DELETE_ADMINS:
			return deleteAdmins(state);
		case AdminsActionTypes.CHANGE_TABLE_MODE:
			return setTableModeAdmins(state, action.payload);
		case AdminsActionTypes.SELECT_ADMINS:
			return selectAdmins(state, action.payload);
		default:
			return state;
	}
};

export default adminsReducer;
