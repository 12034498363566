import React, { useCallback, useEffect } from 'react';
import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import { CheckIcon, ChevronDownIcon } from '@heroicons/react/solid';
import { toast } from 'react-toastify';

const Dropdown = ({
  className = '', menuClassname = '', onChange, options, placeholder = 'Choose', name, id,disabled ,...props
}) => {

  const [selected, setSelected] = useState();

  const onChangeValue = useCallback(
    (item) => {
      if (onChange) {
        const event = {
          target: {
            name: name,
            value: item.value,
            id,
          }
        };
        onChange(event);
      }
      setSelected(item);
    },
    [setSelected, onChange],
  );

  useEffect(() => {
    const prop = props
    const selectedValue = options.find(item => item.value === prop.value);
    setSelected(selectedValue);
    return () => {
      setSelected(undefined);
    };
  }, []);

  return (
    <Listbox name={ name } value={ selected }  onChange={ onChangeValue }>
      <div id={ id } style={{width:"230px"}} className='relative mr-2 ml-2'>
        <Listbox.Button className={ `${className} cursor-pointer relative w-full rounded-lg bg-white py-2 pl-3 ml-3 pr-10 text-left border focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 sm:text-sm` }>
          <span className='block truncate'>{ selected?.label || placeholder }</span>
          <span className='pointer-events-none absolute z-10 inset-y-0 right-0 flex items-center pr-2'>
            <ChevronDownIcon className='-mr-1 ml-2 h-5 w-5' aria-hidden='true' />
          </span>
        </Listbox.Button>
        <Transition
          as={ Fragment }
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Listbox.Options className={ `absolute z-10 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm ${menuClassname}` }>
           
            {options.map((item, itemIdx) => (
              <Listbox.Option
                key={ itemIdx }
                className={ ({ active }) =>
                  `relative cursor-default select-none py-2 pl-10 pr-4 ${
                    active ? 'bg-blueLighter text-prussianBlue' : 'text-gray-900'
                  }`
                }
                value={ item }
              >
                { ({ selected }) => (
                  <>
                    <span
                      className={ `block truncate ${
                        selected ? 'font-medium' : 'font-normal'
                      }` }
                    >
                      { item.label }
                    </span>
                    { selected ? (
                      <span className='absolute z-10 inset-y-0 left-0 flex items-center pl-3 text-prussianBlue'>
                        <CheckIcon className='h-5 w-5' aria-hidden='true' />
                      </span>
                    ) : null }
                  </>
                ) }
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </div>
    </Listbox>
  );
};

export default React.memo(Dropdown);