import colors from 'constant/colors';
import styled from 'styled-components';

const Container = styled.div`

    td {
        font-size: 14px ;
    }

    .link-icon {
        path {
            fill: ${colors.blue.prussianBlue} ;
        }
    }

    .limitation-btn {
        position: relative;
        margin-left: -25px;
    }
    .description {
    display: -webkit-box;
    width: 210px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
`;

export { Container };