const colors = {
	darkBlue: '#141E30',
	lemonChiffon: '#FFFBD1',
	white2: '#FAFAFA',
	cardBg: '#1D1D1D',
	redSalsa: '#FB3640',
	prussianBlue: '#003051',
	whiteCoffee: '#E6E2D6',
	lightSilver: '#D9D9D9',
	okuGreen: '#71FE7F',
	generalBlue: '#31A2EF',
	green: {
		dark: '#2ABC39'
	},
	grey: { netralGrey: '#D4D4D4', lighter: '#0030510d', dark: '#9D9D9D' },
	blue: {
		prussianBlue: '#003051',
		lighter: 'rgba(0, 48, 81, 0.05)',
		default: '',
		dark: '',
		aqua: 'rgb(53, 162, 235)',
		blueGray: '#9caebd'
	},
	white: {
		lighter: '#FAFAFA',
		default: '#ffffff'
	},
	black: {
		default: '#000000',
		backdrop: 'rgba(0, 0, 0, 0.4)'
	}
};

export default colors;
