import styled from 'styled-components';

const ForgotPasswordStepsStyle = styled.div`
  height: 100vh;
  background: #FBFDFF;

  .create-new-password {
    width: 450px;
    background: white;
    padding: 30px;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    margin: auto;
    position: relative;
    top: 25%;
  }
`;

export default ForgotPasswordStepsStyle;
