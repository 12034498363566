import styled from 'styled-components';
import { Images } from 'constant';
import colors from 'constant/colors';

const LandingPageStyle = styled.div`
  display: flex;
  height: 100vh;
  background: #FBFDFF;

  .left-side {
    width: 800px;
    height: 100%;
    background-image: url(${Images.LoginBg})
  }

  .title {
    color: #FFFBD1;
    padding: 0 80px 0 80px;
    position: relative;
    top: 60%;
  }

  .right-side {
    margin: auto;
  }

  .slogan {
    padding: 0 80px 0 80px;
    color: white;
    position: relative;
    top: 60%;
  }

  .logo-cms {
    display: block;
      margin: 0 auto;
  }

  .login-form {
    width: 400px;
    background: white;
    padding: 30px;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    margin: auto;
  }

  .input-name {
    min-width: 230px !important;
    height: 40px ;
    padding-left: 9px ;
    &::placeholder {
      padding-left: 0 !important ;
    }
  }

  .input-wrapper:focus-within {
    label {
      font-weight: bold ;
      color: ${colors.prussianBlue} ;
    }
  .input-name {
      &:focus {
      outline: 1px solid ${colors.blue.prussianBlue} ;
      border-color: transparent ;
    }
  }
}
`;

export default LandingPageStyle;
