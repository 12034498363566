import React from 'react';
import useForm from './useForm';
import Label from '../Label';
import TextField from '../TextField';
import TextArea from '../TextArea';
import FormGroup from '../FormGroup';
import UploadImage from '../UploadImage';
import Dropdown from '../Dropdown';
import FormList from '../FormList';
import DatePicker from '../DatePicker';
import { FormStyled } from './style';

const Form = ({ children, ...props }) => {
	return (
		<FormStyled { ...props }>{ children }</FormStyled>
	);
};

export default Object.assign(React.memo(Form), {
	TextField,
	TextArea,
	Label,
	FormGroup,
	FormList,
	UploadImage,
	Dropdown,
	DatePicker,
	useForm
});