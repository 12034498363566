import colors from 'constant/colors';
import styled from 'styled-components';

const Container = styled.div`
.input-wrapper:focus-within {
    label {
      font-weight: bold ;
      color: ${colors.prussianBlue} ;
    }
    .input-name {
        &:focus {
        outline: 1px solid ${colors.blue.prussianBlue} ;
        border-color: transparent ;
      }
    }
    .input-socmed {
        &:focus {
        outline: 1px solid ${colors.blue.prussianBlue} ;
        border-color: transparent ;
      }
    }
    textarea:focus {
      outline: 1px solid ${colors.blue.prussianBlue} ;
      border-color: transparent ;
    }
  }

    td {
        font-size: 14px ;
    }

    .link-icon {
        path {
            fill: ${colors.blue.prussianBlue} ;
        }
    }
    .description {
    display: -webkit-box;
    width: 210px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
}
`;

export { Container };