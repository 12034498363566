import React, {
  useState, useCallback, useMemo, useEffect
} from 'react';
import { PlusIcon, PencilIcon } from '@heroicons/react/solid';
import { Icons } from 'constant';
import { Container } from './style';
import S3 from "react-aws-s3";
window.Buffer = window.Buffer || require("buffer").Buffer;

const UploadImage = ({
  imageurl, width = '160px', height = '150px', rounded = 'rounded-md', source, id, onChange, name, ...props
}) => {
  const [image, setImage] = useState(imageurl ? `${process.env.REACT_APP_S3_URL}${imageurl}` : "");

  const formatFiles = useCallback(
    (files) => {
      let file = files[0];
      const config = {
        bucketName: process.env.REACT_APP_BUCKET_NAME,
        dirName:process.env.REACT_APP_DIRECTORYNAME,
        region: process.env.REACT_APP_REGION,
        accessKeyId: process.env.REACT_APP_AWS_ACCESSKEY_ID,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
      };
      try{
      const ReactS3Client = new S3(config);
      const newFileName = Date.now()+ "_" +file.name  ;
      ReactS3Client.uploadFile(file, newFileName).then((data) =>
        console.log(data)
      );
      const newImage = URL.createObjectURL(files[0]);
      setImage(newImage);
      if (onChange) {
        const event = {
          target: {
            name: name,
            value: newFileName,
            id,
          }
        };
        onChange(event);
      }
    }catch (e) {
      console.error("s3_error",e);
    }
    },
    [setImage, onChange],
  );
  const onChangeImage = useCallback(
    (e) => {
      const files = e.currentTarget.files;
      if (files) {
        formatFiles(files);
      }
    },
    [formatFiles],
  );

  useEffect(() => {
    if (source) {
      setImage(source);
    }
  }, [setImage, source]);

  useEffect(() => {
    if(props.value) {
      setImage(props.value)
    }
  },[])

  const renderButtonUpload = useMemo(() => {
    const fontSize = rounded === 'rounded-full' ? 'text-sm' : 'text-base';
    if (image) {
      return (
        <label htmlFor='files' className='cursor-pointer items-center edit bg-blackBackdrop py-[5px] px-[15px] rounded-md'>
          <span className='flex items-center'>
            <PencilIcon className={ `w-4 h-4 inline text-white mr-2 ${fontSize}` } />
            <span className={ `font-bold text-white ${fontSize}` }>Edit</span>
          </span>
        </label>
      );
    }
    return (
      <label htmlFor='files' className='cursor-pointer flex flex-col items-center'>
        <Icons.UploadIcon/>
        <span>
          <PlusIcon className='w-3 h-3 inline mr-1' />
          <span className={ `font-extrabold text-prussianBlue ${fontSize}` }>Upload Photo</span>
        </span>
      </label>
    );
  }, [image, rounded]);

  return (
    <Container
      id={ id }
      isEmpty={ image === '' }
      className={ `relative border-prussianBlue border-[1px] ${rounded} flex justify-center align-middle items-center` }
      width={ width }
      height={ height }
    >
      { image && <img src={ image } className={ `img-preview absolute top-0 left-0 right-0 bottom-0 z-[-1] drop-shadow-mdm ${rounded}` } /> }
      <div>
        <input name={ name } type='file' id='files' className='hidden' accept={"image/png, image/jpeg, image/jpg"} onChange={ onChangeImage } />
        { renderButtonUpload }
      </div>
    </Container>
  );
};

export default React.memo(UploadImage);