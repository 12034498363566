import styled from 'styled-components';

const InputStyle = styled.div`
  .input-container {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  input {
    height: 60px;
    width: 100%;
    padding: 0px 20px;
    border-radius: 5px;
    outline: none;
    border: 1px solid var(--grey100);
    background-color: transparent;
    color: var(--blue);
    font-size: 16px;

    :focus {
      border: 1px solid var(--color-input-focus);
    }

    ::placeholder {
      color: var(--grey200);
    }
  }

  .input-search {
    input {
      height: 44px;
      padding-left: 50px;
      border-radius: 5px;
      border: none;
      background-color: rgba(255, 255, 255, 0.05);

      ::placeholder {
        color: var(--grey200);
        font-weight: 700;
      }
    }
 
    .search-icon {
      position: absolute;
      left: 20px;
    }
  }

  
  @media screen and (max-width: 720px) {
    input {
      height: 45px;
    }

    .label p {
      font-size: 12px;
    }
  }

  .eye-icon {
    position: absolute;
    right: 20px;
    width: 20px;
    height: 20px;
  }

  textarea {
    border: none;
    background: none;
    outline: none;
    color: var(--blue);
    font-family: Nunito;
    font-size: 12px;
    border: 1px solid var(--grey100);
    width: 100%;
    border-radius: 5px;
    height: 150px;
    padding: 15px 20px;
    font-size: 16px;

    :focus {
      border: 1px solid var(--color-input-focus);
    }

    ::placeholder {
      color: #797979;
    }
  }
`;

export default InputStyle;