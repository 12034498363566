import {
	createFieldConfig, requiredRule, minLengthRule, maxLengthRule, emailRule
} from 'helpers';

export const loginField = {
	email: {
		...createFieldConfig({
			name: 'email',
			type: 'email'
		}),
		validationRules: [
			requiredRule('email'),
			minLengthRule('email', 10),
			maxLengthRule('email', 25),
			emailRule()
		]
	},
	password: {
		...createFieldConfig({
			name: 'password',
			type: 'password'
		}),
		validationRules: [
			requiredRule('password'),
			minLengthRule('password', 8),
			maxLengthRule('password', 20)
		]
	},
};

export const emailForgotPasswordField = {
	email: {
		...createFieldConfig({
			name: 'email',
			type: 'email'
		}),
		validationRules: [
			requiredRule('email'),
			minLengthRule('email', 10),
			maxLengthRule('email', 25),
			emailRule()
		]
	},
};

export const phoneForgotPasswordField = {
	phone: {
		...createFieldConfig({
			name: 'phone',
			type: 'number'
		}),
		validationRules: [requiredRule('phone'),]
	},
};

export const createNewPasswordField = {
	password: {
		...createFieldConfig({
			name: 'password',
			type: 'password'
		}),
		validationRules: [
			requiredRule('password'),
			minLengthRule('password', 8),
			maxLengthRule('password', 20)
		]
	},
	confirmpassword: {
		...createFieldConfig({
			name: 'confirmpassword',
			type: 'password'
		}),
		validationRules: [
			requiredRule('password'),
			minLengthRule('password', 8),
			maxLengthRule('password', 20)
		]
	},
};