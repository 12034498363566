import React, { useCallback, useMemo } from 'react';
import { usePagination, DOTS } from './usePagination';

const Pagination = props => {
	const {
		onPageChange,
		totalCount,
		siblingCount = 1,
		currentPage,
		pageSize,
	} = props;

	const paginationRange = usePagination({
		currentPage,
		totalCount,
		siblingCount,
		pageSize
	});

	const onNext = useCallback(
		() => {
			onPageChange(currentPage + 1);
		},
		[currentPage],
	);

	const onPrevious = useCallback(
		() => {
			onPageChange(currentPage - 1);
		},
		[currentPage],
	);

	const lastPage = useMemo(() => paginationRange[paginationRange.length - 1], [paginationRange]);
	return (
		<ul
			className='pagination-container'
		>
			<li
				className={ `pagination-item ${currentPage === 1 ? 'disabled' : ''}` }
				onClick={ onPrevious }
			>
				<div className='arrow left cursor-pointer' />
			</li>
			{ paginationRange.map((pageNumber, index) => {
				if (pageNumber === DOTS) {
					return <li className='pagination-item dots' key={ index }>&#8230;</li>;
				}

				return (
					<li
						className={ `cursor-pointer pagination-item font-extrabold ${pageNumber === currentPage ? 'selected border-solid border-[1px] border-prussianBlue' : ''}` }
						onClick={ () => onPageChange(pageNumber) }
						key={ index }
					>
						{ pageNumber }
					</li>
				);
			}) }
			<li
				className={ `pagination-item ${currentPage === lastPage ? 'disabled' : ''}` }
				onClick={ onNext }
			>
				<div className='arrow right cursor-pointer' />
			</li>
		</ul>
	);
};

export default React.memo(Pagination);
