import React from 'react';
import TextStyle from './style';

const Text = ({
	fontType = 'h1' | 'h2' | 'h3' | 'h4' | 'p' | 'pre',
	fontFamily = 'Nunito',
	children,
	className,
	text,
	fontSize,
	textAlign = 'left' | 'center' | 'right',
	fontStyle = 'italic' | 'normal',
	fontWeight =  'bold' | 'bolder' | '300' | '400' | '500' | '600' | '700' | '800' | '900',
	color,
	lineHeight,
	onClick,
}) => {
	let content = <p>{ text || children }</p>;
	if (fontType === 'h1') content = <h1>{ text || children }</h1>;
	else if (fontType === 'h2') content = <h2>{ text || children }</h2>;
	else if (fontType === 'h3') content = <h3>{ text || children }</h3>;
	else if (fontType === 'h4') content = <h4>{ text || children }</h4>;
	else if (fontType === 'pre') content = <pre>{ text || children }</pre>;

	return (
		<TextStyle
			className={ className }
			fontFamily={ fontFamily }
			fontSize={ fontSize }
			textAlign={ textAlign }
			fontStyle={ fontStyle }
			fontWeight={ fontWeight }
			color={ color }
			lineHeight={ lineHeight }
			onClick={ onClick }
		>
			{ content }
		</TextStyle>
	);
};

export default Text;