import { Icons } from 'constant';
import React from 'react';
import styled from 'styled-components';

const Input = styled.input`
    min-width:330px;
    &::placeholder {  
        padding-left: 10px; 
    } 
    background-image: url(${Icons.AtoZ}) ;
`;

const ErrorText = styled.span`
    color: red ;
`;
export { Input, ErrorText };