import styled from 'styled-components';

const FavouriteStyle = styled.div`
    .wrapper1 {
        display: flex;
        flex-wrap: wrap;
    }
    .item {
        margin: 20px 20px 0 0;
        width: 280px;
    }

    @media (max-width: 705px) { 
        .item {
            width: 250px;
            margin: auto;
            margin-bottom: 10px;
        }
      }

    @media (max-width: 600px) { 
        .item {
            width: 230px;
        }
      }

    @media (max-width: 545px) { 
        .item {
           margin: auto;
           margin-bottom: 10px;
           width: 320px;
        }
      }
`;

export default FavouriteStyle;