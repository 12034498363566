import { ReactComponent as CloseIcon } from '../assets/icons/close.svg';
import { ReactComponent as CollectionsIcon } from '../assets/icons/collections.svg';
import { ReactComponent as RestaurantIcon } from '../assets/icons/restaurant.svg';
import { ReactComponent as AdminsIcon } from '../assets/icons/admins.svg';
import { ReactComponent as StatisticsIcon } from '../assets/icons/statistics.svg';
import { ReactComponent as SupportIcon } from '../assets/icons/support.svg';
import { ReactComponent as UsersIcon } from '../assets/icons/users.svg';
import { ReactComponent as ListIcon } from '../assets/icons/list.svg';
import { ReactComponent as LinkedinIcon } from '../assets/icons/linkedin.svg';
import { ReactComponent as DiscordIcon } from '../assets/icons/discord.svg';
import { ReactComponent as InstagramIcon } from '../assets/icons/instagram.svg';
import { ReactComponent as TwitterIcon } from '../assets/icons/twitter.svg';
import { ReactComponent as LinkIcon } from '../assets/icons/link.svg';
import { ReactComponent as MoreIcon } from '../assets/icons/more.svg';
import { ReactComponent as MarkupIcon } from '../assets/icons/markup.svg';
import { ReactComponent as UploadIcon } from '../assets/icons/upload.svg';
import { ReactComponent as RightArrow } from '../assets/icons/rightArrow.svg';
import { ReactComponent as CirclePlus } from '../assets/icons/circlePlus.svg';
import { ReactComponent as CircleMinus } from '../assets/icons/circleMinus.svg';
import { ReactComponent as ArrowDropdown } from '../assets/icons/arrowdown-cms.svg';
import { ReactComponent as PencilIcon } from '../assets/icons/pencil.svg';
import { ReactComponent as SearchIcon } from '../assets/icons/search.svg';
import { ReactComponent as CopyIcon } from '../assets/icons/copy.svg';
import { ReactComponent as RisingIcon } from '../assets/icons/rising.svg';
import { ReactComponent as DecreaseIcon } from '../assets/icons/decrease.svg';
import { ReactComponent as TrashIcon } from '../assets/icons/trash.svg';
import { ReactComponent as EditIcon } from '../assets/icons/edit.svg';
// import Facebook from '../assets/facebook.svg';
import LogoutIcon  from '../assets/icons/logout.svg';
import { ReactComponent as addCircleIcon } from '../assets/icons/circlePlus.svg';
import { ReactComponent as minusCircleIcon } from '../assets/icons/circleMinus.svg';
import { ReactComponent as DateIcon } from '../assets/icons/date.svg';
import polygon from '../assets/images/matic.svg';
import Summer from '../assets/icons/summer.svg';
import Spring from '../assets/icons/spring.svg';
import dollar from '../assets/icons/Dollar_sign.png'

const icons = {
	polygon,
	CloseIcon,
	CollectionsIcon,
	RestaurantIcon,
	AdminsIcon,
	StatisticsIcon,
	SupportIcon,
	UsersIcon,
	ListIcon,
	LinkedinIcon,
	DiscordIcon,
	InstagramIcon,
	TwitterIcon,
	LinkIcon,
	MoreIcon,
	MarkupIcon,
	UploadIcon,
	RightArrow,
	Summer,
	Spring,
	CirclePlus,
	CircleMinus,
	ArrowDropdown,
	PencilIcon,
	SearchIcon,
	RisingIcon,
	DecreaseIcon,
	TrashIcon,
	CopyIcon,
	EditIcon,
	LogoutIcon,
	addCircleIcon,
	minusCircleIcon,
	DateIcon,
	dollar,
	// Facebook
};

export default icons;