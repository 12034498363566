import React from 'react';
import { ChangeEvent, useState } from 'react';

import { validation } from 'helpers';
import { Images } from 'constant';
import { Text } from 'components';

import InputStyle from './style';

const Input = ({
  type = 'text',
  name,
  value,
  label = '',
  placeholder = '',
  className = '',
  autofocus = false,
  onChange,
}) => {
  const [invalidMessage, setInvalidMessage] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [isFocus, setIsFocus] = useState(false);

  const handleOnBlur = () => {
    if (name === 'email') {
      if (!validation.email(value)) {
        setInvalidMessage('Email format');
      } else {
        setInvalidMessage('');
      }
    }

    setIsFocus(false);
  };

  const handleOnFocus = () => {
    setIsFocus(true);
  };

  const handleOnChange = (e) => onChange && onChange({
    value: e.target.value,
    name: e.target.name
  });

  const renderLabel = () => {
    if (!label) return null;

    return (
      <Text
        text={ label }
        fontWeight='700'
        color={ isFocus ? 'var(--color-text-blue)' : 'var(--grey200)' }
        className='mb-5 label'
      />
    );
  };

  const renderInputType = () => {
    if (type === 'area') {
      return renderTextAreaInput();
    } else if (type === 'search') {
      return renderSearchInput();
    }

    return renderTextInput();
  };

  const renderSearchInput = () => {
    return (
      <form className='input-container input-search'>
        <img alt='search' src={ Images.IconSearch } className='search-icon' />
        <input
          name={ name }
          value={ value }
          placeholder='Search'
          type='text'
          onChange={ handleOnChange }
          autoFocus={ autofocus }
          onFocus={ handleOnFocus }
          onBlur={ handleOnBlur }
        />
      </form>
    );
  };

  const renderTextInput = () => (
    <div className='input-container'>
      <input
        name={ name }
        value={ value }
        placeholder={ placeholder }
        type={ type === 'password' && showPassword ? 'text' : type }
        onChange={ handleOnChange }
        autoFocus={ autofocus }
        onFocus={ handleOnFocus }
        onBlur={ handleOnBlur }
      />
    </div>
  );

  const renderTextAreaInput = () => (
    <textarea
      value={ value }
      name={ name }
      onChange={ handleOnChange }
      placeholder={ placeholder }
      onFocus={ handleOnFocus }
      onBlur={ handleOnBlur }
    />
  );

  return (
    <InputStyle className={ className }>
      { renderLabel() }
      { renderInputType() }
    </InputStyle>
  );
};

export default Input;