import { createSelector } from 'reselect';

const collectionSelector = state => {
	return {
		...state.collections,
		search: state.utils.search
	};
};

const collectionListSelector = createSelector(collectionSelector, collections => {
	return {
		...collections,
		collections: collections
	};
});


export { collectionListSelector };