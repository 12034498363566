import * as FaqActionTypes from './actionTypes';
import { makeID } from 'helpers';

const restructureData = (data) => {
    return data;
};

const initialState = {
    faq: [
        {
            id: 0,
            categories: 'General',
            question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac facilisi sit etiam vestibulum enim lacus venenatis eu. In in elementum ultricies elementum.'
        },
        {
            id: 1,
            categories: 'Payment',
            question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac facilisi sit etiam vestibulum enim lacus venenatis eu. In in elementum ultricies elementum.'
        },
        {
            id: 2,
            categories: 'Wallet',
            question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac facilisi sit etiam vestibulum enim lacus venenatis eu. In in elementum ultricies elementum.'
        },
        {
            id: 3,
            categories: 'General',
            question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac facilisi sit etiam vestibulum enim lacus venenatis eu. In in elementum ultricies elementum.'
        },
        {
            id: 4,
            categories: 'General',
            question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac facilisi sit etiam vestibulum enim lacus venenatis eu. In in elementum ultricies elementum.'
        },
        {
            id: 5,
            categories: 'Payment',
            question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac facilisi sit etiam vestibulum enim lacus venenatis eu. In in elementum ultricies elementum.'
        },
        {
            id: 6,
            categories: 'General',
            question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac facilisi sit etiam vestibulum enim lacus venenatis eu. In in elementum ultricies elementum.'
        },
        {
            id: 7,
            categories: 'Wallet',
            question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac facilisi sit etiam vestibulum enim lacus venenatis eu. In in elementum ultricies elementum.'
        },
        {
            id: 8,
            categories: 'General',
            question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac facilisi sit etiam vestibulum enim lacus venenatis eu. In in elementum ultricies elementum.'
        },
        {
            id: 9,
            categories: 'Wallet',
            question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac facilisi sit etiam vestibulum enim lacus venenatis eu. In in elementum ultricies elementum.'
        },
        {
            id: 10,
            categories: 'General',
            question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac facilisi sit etiam vestibulum enim lacus venenatis eu. In in elementum ultricies elementum.'
        },
        {
            id: 11,
            categories: 'Wallet',
            question: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit?',
            answer: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ac facilisi sit etiam vestibulum enim lacus venenatis eu. In in elementum ultricies elementum.'
        },
    ],
    loading: false,
    //   tableModeAdmins: 'LIST',
    //   selectedAdmins: {},
    //   fieldsEditAdmins: {}
};

const reqFaq = (state) => ({
    ...state,
    loading: true
});

const setFaq = (state, payload) => ({
    ...state,
    faq: payload.data,
    loading: false
});

const addFaq = (state, payload) => {
    let data = { ...payload };
    data.id = makeID(10);
    data = {
        ...data,
        ...restructureData(data)
    };
    return {
        ...state,
        faq: [data, ...state.faq]
    };
};

const faqReducer = (state = initialState, action) => {
    switch (action.type) {
        case FaqActionTypes.GET_FAQ_LIST:
            return reqFaq(state);
        case FaqActionTypes.GET_FAQ_LIST_SUCCESS:
            return setFaq(state, action.payload);
        case FaqActionTypes.ADD_FAQ:
            return addFaq(state, action.payload);
        default:
            return state;
    }
};

export default faqReducer;
