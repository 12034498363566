import { Menu, Transition } from '@headlessui/react';
import {useNavigate} from 'react-router-dom';
import React, {
  Fragment,
  useState,
  useEffect
} from 'react';
import { Icons, Images } from 'constant';
import colors from 'constant/colors';
import { Text, Button, Modal } from 'components';

import ProfilePopupStyle from './style';
import { Fetch } from 'services/local-storage';
import {useSelector, useDispatch} from 'react-redux'
import metamask from '../../assets/images/logo/MetaMask_Fox.svg.png'
import walletconnect from '../../assets/images/logo/WalletConnect-Logo.png';
import formatic from '../../assets/images/logo/formatic.jpg';
import coinbase from '../../assets/images/logo/coinbase.png';
import WalletStyles from './styles';
import Web3 from 'web3';
import { connectWallet, connectFailed } from "../../store/walletconnection/WalletAction";
const arr = [
  {
    icons: Icons.logout,
    title: 'Logout'
  }
];
const list = [
  {
      id: 1,
      image: metamask,
      title: "metamask"
  },
  {
      id: 2,
      image: coinbase,
      title: "coinbasewallet"
  },
  {
      id: 3,
      image: walletconnect,
      title: "walletconnect"
  },
  {
      id: 4,
      image: formatic,
      title: "fortmatic"
  }
]

const ProfilePopup = ({ array , onClose}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [connectionType, setConnectionType] =useState('');
  const [openDisconnectModal,setOpenDisconnectModal] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const wallet = useSelector((state)=> state.WalletConnect)
  const admin = JSON.parse(Fetch('user'))
 
  const [alert_, setAlert] = useState('');
  const walletconnectaction=()=>{
        dispatch(connectWallet(connectionType && connectionType));
        
        // window.location.reload(false);
        // navigate("/restaurant")
  }
  const disconnect = () =>{
    dispatch(connectFailed());
    setOpenDisconnectModal(false)
  }
  useEffect(()=>{
    if(wallet.address){
      if(wallet.address != admin.walletAddress){
        dispatch(connectFailed());
        setAlert("Connect with the registered wallet address")
      }else{
        setOpenModal(false)
        setAlert("")  
      }
    }
  },[wallet])
  const renderMenu = () => {
    return arr.map((el, idx) => {
      return (
        <div className='px-px' key={idx}>
          <Menu.Item>
            {({ active }) => (
              <button
                className={'h-[62px] pl-[20px] group flex w-full items-center rounded-md px-1 border-solid border-[1px] border-white'}
                onClick={() => {
                  localStorage.removeItem('token')
                  localStorage.removeItem('user')
                  const { web3Modal } = wallet;
                  web3Modal.clearCachedProvider();
                  dispatch(connectFailed());
                  navigate('/login')
                }}
                style={{ backgroundColor: '#fff' }}
              >
                {active ? (
                  <img src={Icons.LogoutIcon} className={`mr-3 ${el.className && el.className}`} alt='bell' />
                ) : (
                  <img src={Icons.LogoutIcon} className={`mr-3 ${el.className && el.className}`} alt='bell' />
                )}
                <div style={{color:"#000",fontSize:"22px",fontWeight:"800px"}}>Logout</div>
              </button>
            )}
          </Menu.Item>
        </div>
      );
    });
  };

  return (
    <ProfilePopupStyle>
    <div className='flex justify-end items-center m-[20px] mr-[60px]'>
   
      {/* <div className=''> */}
      {wallet.connected ? 
      <Button theme='primary' width='200px' height='44px' className='bg-prussianBlue mr-5'  onClick={() => setOpenDisconnectModal(true)}>
      <div className='flex items-center space-x-2 justify-center'>
        <span>{`disconnect ${wallet.address.slice(0,5)}...${wallet.address.slice(-3)}`}</span>
      </div>
      </Button>
      // <Button className='font-extrabold text-prussianBlue'>{`${wallet.address.slice(0,3)}...${wallet.address.slice(-3)}`}</Button>
      :
      <Button theme='primary' width='200px' height='44px' className='bg-prussianBlue mr-5'  onClick={() => setOpenModal(true)}>
      <div className='flex items-center space-x-2 justify-center'>
        <span>Connect Wallet</span>
      </div>
    </Button>
      }
      {/* </div> */}
      <Menu as='div' className='relative inline-block text-left'>
        <div className='flex justify-center'>
          <Menu.Button className='inline-flex mr-2 justify-center rounded-md  bg-opacity-20 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
            <img src={Images.Profile} className='' alt='profile' />
          </Menu.Button>
          <div className='' style={{width:"auto"}}>
            <Text
            
              text={admin?.userName}
              fontWeight={'300'}
            />
            <Text
              style={{color:"red"}}
              text={admin?.userType}
              fontWeight={'400'}
            />
          </div>
          <Menu.Button>
            <Icons.ArrowDropdown className='ml-2' />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='menu-item absolute right-0 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none pt-px pb-px'>
            {renderMenu()}
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
    {openModal &&
      <Modal visible={openModal} onClickClose={() => setOpenModal(false)}>
        <WalletStyles>
            <Text
                text={ 'Connect Wallet' }
                fontType={ 'h5' }
                fontWeight={ '700' }
                fontSize={ '28px' }
                color={ '#FAFAFA' }
                fontFamily={ 'Lora' }
                className='title'
            />
            
            { list.map((item, i) => (
                <div
                    className='card mt-4'
                    style={{display:"flex",flexDirection:"row"}}
                    key={ item.id }
                >
                    <div className='logo'>
                        <img src={ item.image } width={ 60 } height={ 60 } />
                    </div>
                    <div className='menu'>
                        <Text
                            text={ item.title }
                            fontWeight={ '700' }
                            color={ '#FAFAFA' }
                            fontType={ 'p' }
                            fontSize={ '20px' }
                            fontFamily={ 'Lora' }
                        />
                    </div>

                    <div className='arrow'>
                        {/* <img src={ Images.ArrowRight } width={ 8 } height={ 15 } /> */}
                        <input type="radio" name="wallet" onChange={() => {console.log(item.title); setConnectionType(item.title)}}></input> 
                    </div>

                </div>
            )) }
            <span style={{color:"red", fontWeight:"bold", display:'flex', justifyContent:"center", marginTop:"3%"}}>{alert_}</span>
            <Button
                label='Connect Wallet'
                className='connect-wallet'
                onClick={() => {walletconnectaction()}}
                
                // onClick={ () => handleShowModalContactUs && handleShowModalContactUs() }
            />

            {/* <ToastContainer /> */}
        </WalletStyles>
      </Modal>
    }
    {openDisconnectModal &&
      <Modal visible={openDisconnectModal} onClickClose={() => setOpenDisconnectModal(false)}>
        {/* <WalletStyles> */}
          <div className="mt-3">
            <Text
                text={ 'Are you sure you want to Disconnect Your wallet ?' }
                fontType={ 'h5' }
                fontWeight={ '700' }
                fontSize={ '22px' }
                color={ '#FAFAFA' }
                fontFamily={ 'Lora' }
                className='title'
            />
          </div>

          <div className='d-flex justify-content-between mb-5'>
            <Button
              className='mt-5 ml-4'
              label='Yes'
              height={ '30px' }
              width={ '185px' }
              textColor={ '#003051' }
              backgroundColor={ '#E3E0E0' }
              onClick={() => {disconnect(); }}
            />
            <Button
              className='mt-5 mr-4'
              label='No'
              height={ '30px' }
              width={ '185px' }
              textColor={ '#003051' }
              backgroundColor={ '#E3E0E0' }
              onClick={() => {setOpenDisconnectModal(false)}}
            />
            
          </div>
        {/* </WalletStyles> */}
      </Modal>
    }

    </ProfilePopupStyle>
  );
};

export default ProfilePopup;