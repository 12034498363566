import React, {
  useEffect, useRef, useState
} from 'react';

const Tabs  = ({
  activeTabIndex,
  setActiveTabIndex,
  tabUnderlineWidth,
  setTabUnderlineWidth,
  tabUnderlineLeft,
  setTabUnderlineLeft,
  tabsData
}) => {
  const tabsRef = useRef([]);

  useEffect(() => {
    function setTabPosition() {
      const currentTab  = tabsRef.current[activeTabIndex];

      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    }

    setTabPosition();
    window.addEventListener('resize', setTabPosition);

    return () => window.removeEventListener('resize', setTabPosition);
  }, [activeTabIndex]);

  return (
    <div>
      <div className='relative border-solid border-b-[1px] border-b-white/20 mb-[30px]'>
        <div className='flex space-x-3 border-b'>
          { tabsData.map((tab, idx) => {
            return (
              <button
                key={ idx }
                ref={ el => (tabsRef.current[idx] = el) }
                className={ `pt-2 pb-3 mr-10 ${activeTabIndex === idx ? 'font-extrabold' : ''} transition-all duration-300 text-xl` }
                onClick={ () => setActiveTabIndex(idx) }>
                { tab }
              </button>
            );
          }) }
        </div>
        <span
          className='absolute bottom-0 block h-1 bg-prussianBlue transition-all duration-300'
          style={ {
            left: tabUnderlineLeft,
            width: tabUnderlineWidth
          } }
        />
      </div>
    </div>
  );
};

export default Tabs;