// import { RootState } from 'stores';

const selectors = {
  // todo
  // userProfile: (state: RootState) => state.user.profile,
  // userToken: (state: RootState) => state.user.profile.token
  userProfile: (state) => state.user.profile,
  userToken: (state) => state.user.profile.token,
  restaurants: (state) => state.restaurant,
  restaurant: (state) => state.restaurantlist
};

export default selectors;