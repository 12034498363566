import styled from 'styled-components';

const ModalStyle = styled.div`
  position: fixed;
  
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  z-index: 9999;
  
  .modal-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
  }

  .modal-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0,0,0, 0.8);
    z-index: 0;
  }

  .modal-content-wrapper {
    position: relative;
    z-index: 10;
  }

  .modal-content-container {
    width: ${props => props.width ? props.width : 'unset'};
    max-width: 540px;
    border-radius: 5px;
    background-color: var(--white);
    z-index: 1;
  }
  
  .modal-content {
    /* max-height: calc(100vh - 105px); */
    
    padding: ${props => props.noPadding ? '0px' : ' 40px'};
    overflow-y: scroll;
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
    background-color: ${props => props.bgColor};
    
    ::-webkit-scrollbar {
      display: none;
    }

    @media screen and (max-width: 500px) {
      padding: ${props => props.noPadding ? '0px' : ' 20px'};
    }
  }

  .modal-title {
    padding: 15px 20px 10px 20px;
    border-bottom: 1px solid #444444;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1)
  }

  .close-icon {
    position: absolute;
    right: 40px;
    top: 45px;
    width: fit-content;
    margin-left: auto;
    cursor: pointer;

    @media screen and (max-width: 500px) {
      right: 20px;
      top: 25px;
    }
  }
  
  @keyframes slideUp {
    0% {
      transform: translate(0px, -100vh);
      opacity: 0;
    }

    100% {
      transform: translate(0px, 0px);
      opacity: 1;
    }
  }
  
  @keyframes slideDown {
    0% {
      transform: translate(0px, 0px);
      opacity: 1;
    }
    
    100% {
      transform: translate(0px, -100vh);
      opacity: 0;
    }
  }

  .backdrop-open-animation {
    animation: fadeIn 300ms ease forwards;
  }
  
  .backdrop-close-animation {
    animation: fadeOut 300ms ease forwards;
  }

  .open-animation {
    animation: slideUp 500ms ease forwards;
  }

  .close-animation {
    animation: slideDown 500ms ease forwards;
  }
`;

export const Container = styled.div``;

export default ModalStyle;