import styled from 'styled-components';

const TransactionHistoryStyle = styled.div`
    .section {
        width: 100%;
        display: grid;
        gap: 20px;
        grid-template-columns: auto auto auto auto auto auto;
    }
    .selected {
        grid-column: 1 / 6;
    }
`;

export default TransactionHistoryStyle;