import styled from 'styled-components';

const NFTCollectionStyle = styled.div`
    .wrapper1 {
        width: 100%;
        display: grid;
        gap: 20px;
        grid-template-columns: auto auto auto auto auto auto;
    }
`;

export default NFTCollectionStyle;