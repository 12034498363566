import React, { useEffect, useState } from "react";
import {
  Dropdown,
  Button,
  TextField,
  Modal,
  UploadImage,
  NftImage,
  Form,
  Text,
  ProfilePopup,
  Label,
} from "components";
import { Div, Wrapper } from "./style";
import WalletStyles from './styles';
import FormGroup from "components/FormGroup";
import "bootstrap/dist/css/bootstrap.min.css";
import * as XLSX from "xlsx/xlsx.mjs"

// import * as XLSX from "https://unpkg.com/xlsx/xlsx.mjs";
import { GlobalOutlined } from "@ant-design/icons";
import { useNavigate } from 'react-router-dom';
import { RiFacebookCircleLine } from "react-icons/ri";
import { BsInstagram } from "react-icons/bs";
import { RxDiscordLogo } from "react-icons/rx";
import {CiLinkedin} from "react-icons/ci";
import { AiFillCloseCircle } from "react-icons/ai";
import {BsFileEarmarkZipFill} from "react-icons/bs";
import axios from 'axios';
import S3 from "react-aws-s3";
import { addnewevents, editevents,addnewcollections,editcollection,addnftsgroup,editnftsgroup,activeevents } from '../../../services/restaurant';
import { rawListeners } from "process";
import { useSelector, useDispatch } from 'react-redux'
import { solidityKeccak256 } from "ethers/lib/utils";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { data } from "components/Chart/Line";
import loadiing from  '../../../assets/images/loading.gif';


const countryOption = [
  {
    label: "Singapore",
    value: "singapore",
  },
  {
    label: "Indonesia",
    value: "indonesia",
  },
  {
    label: "India",
    value: "india",
  },
  {
    label: "China",
    value: "china",
  },
  {
    label: "United States",
    value: "us",
  },
  {
    label: "Ukraine",
    value: "ukranine",
  },
];
const AddRestaurant = () => {

  const baseUrl = process.env.REACT_APP_BASE_URL;
  const navigate = useNavigate();
  const [Country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  

  const wallet = useSelector((state) => state.WalletConnect)

  const [eventloader,seteventloader]=useState(false)
  const [collectionloader,setcollectionloader]=useState(false)
  const [Ziploader,setziploader]=useState(false)
  const [Csvloader,setcsvloader]=useState(false)

  const { web3, marketplace, address } = wallet;



  const fetchCountryData = async () => {
    // get the data from the api
    const res = await axios.get(
      "https://countriesnow.space/api/v0.1/countries"
    );
    // convert the data to json
    let countries_ = res.data.data;

    const a = countries_.map((item) => {
      item["value"] = item.country;
      item["label"] = item.country;
      return item;
    });
    setCountries(countries_);
  };

  const tiers_ = [
    { label: "Silver", value: "Silver",isdisable:true },
    { label: "Platinum", value: "Platinum" },
    { label: "Gold", value: "Gold" },
  ];

  const [tireoption,settireoption]=useState([
    { label: "Silver", value: "Silver",isdisable:true },
    { label: "Platinum", value: "Platinum" },
    { label: "Gold", value: "Gold" },
  ])



  const [NftImage, setNftImage] = useState("");
  const [Collection, setCollection] = useState([{
    NFTCollectionname:"",
    NFTCollectionid:"",
    NFTcolletionbutstate:false,
    NFTtradedate:"",
    NFTcollectionimg:"",
    Collectiontier:"",
    NFTRoyality:"",
    CollectionDescription:"",
    Collectiontrade:"",
    Collectionnftgroup:[{nftgroupname:"",
                         nftsupply:"",
                         nftzip:"", 
                         nftzipname:"",
                         nftcvs:"",
                         nftcsvname:"",
                         nfttradedate:"",
                         pinataid:"",
                         nftgroupstatus:false,
                         firstgroupsave:false
                        }]
                      }]);



  const [events,setevents]=useState({
    eventname:"",
    eventcountry:"",
    eventcity:"",
    eventaddress:"",
    website:"",
    facebook:"",
    instagram:"",
    linkedin:"",
    discord:"",
    eventimg:"",
    eventimgname:"",
    eventid:"",
  })

  const [jsonFilename,setjsonFilename]=useState("")

  const onChangeAddNFTImage = async (e) => {
     
      setevents({...events,eventimgname:e.target.value})
  };
 
  const [colimgloader,setcolimgloader] = useState(false)
  const changecollection = async (e,index) => {
    setcolimgloader(true)
    var file = e.target.files[0];
    const config = {
      bucketName: process.env.REACT_APP_BUCKET_NAME,
      dirName: process.env.REACT_APP_DIRECTORYNAME,
      region: process.env.REACT_APP_REGION,
      accessKeyId: process.env.REACT_APP_AWS_ACCESSKEY_ID,
      secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
    };
    const ReactS3Client = new S3(config);

    const newFileName = Date.now()+ "_" +file.name  ;
    // const newFileName = file.name 
    ReactS3Client.uploadFile(file, newFileName).then((data) => {
    const list = [...Collection];
    list[index]["NFTcollectionimg"] = newFileName;
    setCollection(list);
    setcolimgloader(false);
    })

  
  };
  
  // const [image, setImage] = useState(
  //   Collection ? `${process.env.REACT_APP_S3_URL}${Collection}` : ""
  // );





  const addCollection =(value)=>{
    if(value === "col"){
      if(Collection.length > 2){
        toast("Collection Reach The Max Limit")
      }else{
      setCollection([...Collection,{NFTCollectionname:"",
      NFTCollectionid:"",
      NFTcolletionbutstate:false,
      NFTtradedate:"",
      NFTcollectionimg:"",
      Collectiontier:"",
      NFTRoyality:"",
      CollectionDescription:"",
      Collectiontrade:"",
      Collectionnftgroup:[{nftgroupname:"",
                           nftsupply:"",
                           nftzip:"", 
                           nftzipname:"",
                           nftcvs:"",
                           nftcsvname:"",
                           nfttradedate:"",
                           pinataid:"",
                           nftgroupstatus:false,
                           firstgroupsave:false
                          }]}])
    }
    }
  }



  const addnftgroup = async(value,index)=>{
    if(value === "col"){
    await Collection.map((row,eve)=>{
        if(eve == index){
          row.Collectionnftgroup.push({nftgroupname:"",
          nftsupply:"",
          nftzip:"", 
          nftzipname:"",
          nftcvs:"",
          nftcsvname:"",
          nfttradedate:"",
          pinataid:"",
          nftgroupstatus:false,
          firstgroupsave:false
         })
        }
      })
      setCollection([...Collection])      
    }
  }

  const removenftgroup =(value,i,index)=>{
    if(value === "col"){
      const list= [...Collection];
      const values =list[index].Collectionnftgroup
    
      if(values.length>1){
        values.splice(i,1);
       
        list[index].Collectionnftgroup = values
      
        setCollection(list)
      }
    }
  }

const removeCollection =(value ,i)=>{
  if(value === "col"){
    const values = [...Collection];
    if(values.length>1){
      values.splice(i,1);
      // const result = tiers_.filter((object1) => !values.some((object2) => object1.value === object2.Collectiontier))
      // settireoption(result)
      setCollection(values)
      setsaveeditcollection(true)
    }
  }
}

const setnftGroupvalues = (index,i,e)=>{
  const { name, value } = e ;
  const list = [...Collection];
  list[index].Collectionnftgroup[i][name] = value;
  setCollection(list); 
}

const submitevents =async()=>{
 
  if(address){
    const checkadmins = await marketplace.methods.isOwner(address).call();
    console.log("checkadmin",checkadmins)
    if(checkadmins){
  try{
  var collection_1 = 0;
  var collection_2 = 0;
  var collection_3 = 0;
  var goldtradedaate = []
  var slivetradedaate = []
  var platinamtradedaate = []
  var goldtradestatus =""
  var silvertradestatus =""
  var platinumtradestatus = ""
  Promise.all(Collection.map(async(event)=>{
    Promise.all( event.Collectionnftgroup.map((item)=>{
      if(event.Collectiontier == "Gold"){
        collection_1 +=Number(item.nftsupply)
        goldtradedaate.push({tradedate:item.nfttradedate})
        goldtradestatus = event.Collectiontrade
      }else if(event.Collectiontier == "Silver"){
        collection_2 +=Number(item.nftsupply)
        slivetradedaate.push({tradedate:item.nfttradedate})
        silvertradestatus = event.Collectiontrade
      }else if(event.Collectiontier == "Platinum"){
        collection_3 +=Number(item.nftsupply)
        platinamtradedaate.push({tradedate:item.nfttradedate})
        platinumtradestatus = event.Collectiontrade
      }
    }))
  }))
  if(goldtradedaate.length > 0){
    var maxgold = goldtradedaate?.reduce((accumulator, current) => {
    return accumulator.tradedate > current.tradedate ? accumulator : current;
  });
}else{
  var maxgold=0
}
if(slivetradedaate.length > 0){
  var maxsilver = slivetradedaate?.reduce((accumulator, current) => {
    return accumulator.tradedate > current.tradedate ? accumulator : current;
  });
}else{
  var maxsilver =0
}
if(platinamtradedaate.length > 0){
  var maxplati = platinamtradedaate?.reduce((accumulator, current) => {
    return accumulator.tradedate > current.tradedate ? accumulator : current;
  });
}else{
  var maxplati =0
}
  let totalcollection = Number(collection_1)+Number(collection_2)+Number(collection_3)  
  const createevents = await marketplace.methods.buildEvents(events.eventname,totalcollection,process.env.REACT_APP_MARKETPLACE,collection_2 ? collection_2:0,collection_1 ? collection_1 : 0,collection_3 ? collection_3:0,maxsilver ? maxsilver.tradedate : 0,maxgold ? maxgold.tradedate : 0,maxplati ? maxplati.tradedate : 0,[silvertradestatus == "trade"?true :false,goldtradestatus == "trade"?true :false,platinumtradestatus == "trade"?true :false]).send({ from: address })                                                                   
  try {
    const data={
      Eventid:events.eventid,
    }
    const res = await activeevents(data);
  
    toast(res.data.message)
    if(res.data.result){
      navigate("/events")
    }
  } catch (ex) {
    toast("something went wrong")
    console.log(ex);
  }
}catch (ex) {
  console.log(ex);
}
    }else{
      toast("SuperAdmin wallet Only Add Events")
    }
  }else{
    toast("connect your wallet")
  }

}

const timeconvert =(date)=>{
  const dates = new Date(date).getTime()
  return dates;
}

const handelcollection= async (e, index) => {
  const { name, value } = e;
 
  if(name == "Collectiontier"){
  const tirecheck = Collection.filter(item => item.Collectiontier == value)

  if(tirecheck.length > 0){
    toast("Tier Already Selected ")
  }else{
    const list = [...Collection];
    list[index]["Collectiontier"] = value;
    setCollection(list);
  }
  }else{
    const list = [...Collection];
    list[index][name] = value;
    setCollection(list);
  }
};

const handelcollection1=async (e, index) => {
  const { value } = e;
   
    const list = [...Collection];
    list[index]["Collectiontier"] = value;
    setCollection(list);
};


const uploadFiles=async(index,i,e,nftzip,nftzipname)=>{
  setziploader(true)
  const file = e.target.files[0]
  if(file){
  let extension = "." + file.name.split(".").pop();
  if(extension == ".zip"){
  const fileName = e.target.files[0].name
 
  const formData = new FormData();
  formData.append("file", file);
  formData.append("fileName", fileName);
  formData.append("colName", Collection[index].NFTCollectionname);
  formData.append("groupName", Collection[index].Collectionnftgroup[i].nftgroupname);
  var requestOptions = {
    method: 'POST',
    body: formData,
    redirect: 'follow'
  };
  
 try{ 
fetch("https://okunftbackend.click/auth/uploadnftzip", requestOptions).then(response => response.json()).then(res => {
if(res.CID && res.Supply){
  if(Number(res.Supply) == Number(Collection[index].Collectionnftgroup[i].nftsupply)){
    const list = [...Collection];
    list[index].Collectionnftgroup[i][nftzip] =file;
    list[index].Collectionnftgroup[i][nftzipname] =fileName;
    list[index].Collectionnftgroup[i]["pinataid"]=res.CID;
    setCollection(list)
    setziploader(false)
    toast(res.message)
  }else{
     setziploader(false)
     toast("Total Supply and Images are missmatch ")
  }
}else{
  setziploader(false)
  toast(res.message)
}
}
).catch((err)=>{
  setziploader(false)
  toast("Something Went Worng")
  console.log('error', err)
})
}catch(error){
  setziploader(false)
  console.log('error', error)
};

}else{
  toast("Upload Zip File")
  setziploader(false)
}
}else{
  toast("Select Zip File")
  setziploader(false)
}
}

const bannerImageChange = (index,i,e,name) => {
 
  setcsvloader(true)
  var file = e.target.files[0];
  if(file){
    let extension = "." + file.name.split(".").pop();
  if(extension == ".xlsx" || extension == ".csv" || extension == ".xls" ){
 
  const reader = new FileReader();
  reader.fileName = file.name // file came from a input file element. file = el.files[0];
  let newData;
  reader.onload = async (e) => {

      const data1 = e.target.result;
      const workbook = XLSX.read(data1, { type: "array" });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet,{raw:false});
      if (json) {
        if(Number(json.length) == Number(Collection[index].Collectionnftgroup[i].nftsupply)){
        const list = [...Collection];
        list[index].Collectionnftgroup[i][name] = json;
        const maxs = Math.max(...Collection[index].Collectionnftgroup[i].nftcvs.map(o => timeconvert(o.SaleEndDate.replaceAll("/","-"))))
        const finaletradeday = Math.round(Number(maxs)/1000)+86400
        list[index].Collectionnftgroup[i]["nfttradedate"] = finaletradeday;
        list[index].Collectionnftgroup[i]["nftcsvname"] = e.target.fileName;
        setCollection(list)
        setcsvloader(false)
        }else{
          setcsvloader(false)
          toast("Total Supply and CSV are Missmatch")
        }
      }else{
        setcsvloader(false)
      }
      // setAirdropDetails({ ...airdropDetails, xdcairdrop_json: json });

  };
  reader.readAsArrayBuffer(e.target.files[0]);
}else{
  setcsvloader(false)
  toast("Upload XLSX or CSV File")
}
}else{
  setcsvloader(false)
  toast("Select XLSX or CSV File")
}

};


const timeconvertdays = (unix_timestamp) => {
  var a = new Date(unix_timestamp);
  var months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var time = a.toLocaleTimeString()
  var time = date + "/" + month + "/" + year // + ", " + time; //+ ' ' + year + ' ' + hour + ':' + min + ':' + sec;
  return time;
};


function onChangeValue(event) {
  const { id,value } = event.target;
  const list = [...Collection];
  list[id]["Collectiontrade"] = value;
  setCollection(list);
}

const [saveeditevent,setsaveeditevent]=useState(false)


function isValidHttpUrl(string) {
  try {
    const newUrl = new URL(string);
    return true;
  } catch (err) {
    return false;
  }
}

const evetschange=async()=>{
  seteventloader(true)
  if(saveeditevent){
    
    setsaveeditevent(false)
    seteventloader(false)
  }else{
       if(events.eventid && events.eventid !=""){
      if(events.eventname && events.eventcountry && events.eventcity && events.website && events.instagram && events.facebook && events.discord && events.eventimgname && events.eventid){

        if(!isValidHttpUrl(events.website)){
          seteventloader(false)
         toast("Please Enter Valid Website URL")
         return
        }else if(!isValidHttpUrl(events.instagram)){
          seteventloader(false)
          toast("Please Enter Valid Instagram URL")
          return
        }else if(!isValidHttpUrl(events.discord)){
          seteventloader(false)
          toast("Please Enter Valid Discord URL")
          return
        }else if(!isValidHttpUrl(events.facebook)){
          seteventloader(false)
          toast("Please Enter Valid Facebook URL")
          return
        }
        const data = {
          eventid:events.eventid,
          eventname: events.eventname,
          website: events.website,
          eventcountry: events.eventcountry,
          eventcity: events.eventcity,
          instagram:events.instagram,
          discord:events.discord,
          facebook:events.facebook,
          eventimgname:events.eventimgname,
        }
        const editevent = await editevents(data)
     
        if(editevent.data.result){
          setsaveeditevent(true)
          seteventloader(false)
          toast(editevent.data.message)
        }else{
          seteventloader(false)
          toast(editevent.data.message)
        }
      }else{
        seteventloader(false)
        toast("Fill All Inputs")
      }
    }else{
      if(events.eventname && events.eventcountry && events.eventcity && events.website && events.instagram && events.facebook && events.discord && events.eventimgname){
        if(!isValidHttpUrl(events.website)){
          seteventloader(false)
          toast("Please Enter Valid Website URL")
          return
         }else if(!isValidHttpUrl(events.instagram)){
           seteventloader(false)
           toast("Please Enter Valid Instagram URL")
           return
         }else if(!isValidHttpUrl(events.discord)){
           seteventloader(false)
           toast("Please Enter Valid Discord URL")
           return
         }else if(!isValidHttpUrl(events.facebook)){
           seteventloader(false)
           toast("Please Enter Valid Facebook URL")
           return
         }
        const data = {
          eventname: events.eventname,
          website: events.website,
          eventcountry: events.eventcountry,
          eventcity: events.eventcity,
          instagram:events.instagram,
          discord:events.discord,
          facebook:events.facebook,
          eventimgname:events.eventimgname,
        }
        const addevent = await addnewevents(data)
     
        if(addevent.data.result){
          setevents({...events,eventid:addevent.data.result._id})
          setsaveeditevent(true)
          seteventloader(false)
          toast(addevent.data.message)
        }else{
          seteventloader(false)
          toast(addevent.data.message)
        }
      }else{
        seteventloader(false)
        toast("Fill All Inputs")
      }
    }
   
  }
}

const [saveeditcollection,setsaveeditcollection]=useState(false)

useEffect(()=>{

},[saveeditcollection])

const onchangecollection=async(index)=>{
  const { web3,address,NFT,marketplace } = wallet;
  if(address){
    const checkadmins = await marketplace.methods.isOwner(address).call();
    console.log("checkadmin",checkadmins)
  if(checkadmins){

  setcollectionloader(true)
  if(Collection[index].NFTcolletionbutstate){
    const value = false
    const list = [...Collection];
    list[index]["NFTcolletionbutstate"] = value;
    setcollectionloader(false)
    setsaveeditcollection(false)
  }else{
    if(Collection[index].NFTCollectionid && Collection[index].NFTCollectionid !="")
    {
      if(Collection[index].NFTCollectionname && Collection[index].Collectiontier  && Collection[index].Collectiontrade && Collection[index].CollectionDescription && Collection[index].NFTcollectionimg  && Collection[index].NFTRoyality && events.eventid ){

      const data ={
        Eventid:events.eventid,
        Eventname:events.eventname,
        NFTCollectionid:Collection[index].NFTCollectionid,
        NFTCollectionname:Collection[index].NFTCollectionname,
        Collectiontier:Collection[index].Collectiontier,
        Collectiontrade:Collection[index].Collectiontrade,
        CollectionDescription:Collection[index].CollectionDescription,
        NFTcollectionimg:Collection[index].NFTcollectionimg,
        NFTRoyality:Collection[index].NFTRoyality
      }
      const editevent = await editcollection(data)
    
      if(editevent.data.result){
        const value = true
        const list = [...Collection];
        list[index]["NFTcolletionbutstate"] = value;
        setsaveeditcollection(true)
        setcollectionloader(false)
        toast(editevent.data.message)
      }else{
        setcollectionloader(false)
        toast(editevent.data.message)
      }
    }else{
      setcollectionloader(false)
      toast("Fill All Input")
    }
    }else{
      if(Collection[index].NFTCollectionname && Collection[index].Collectiontier  && Collection[index].Collectiontrade && Collection[index].CollectionDescription && Collection[index].NFTcollectionimg  && Collection[index].NFTRoyality && events.eventid ){
        const data ={
          Eventid:events.eventid,
          Eventname:events.eventname,
          NFTCollectionname:Collection[index].NFTCollectionname,
          Collectiontier:Collection[index].Collectiontier,
          Collectiontrade:Collection[index].Collectiontrade,
          CollectionDescription:Collection[index].CollectionDescription,
          NFTcollectionimg:Collection[index].NFTcollectionimg,
          NFTRoyality:Collection[index].NFTRoyality
        }
        const addnewcol = await addnewcollections(data)
   
        if(addnewcol.data.result){
          const value = true
          const list = [...Collection];
          list[index]["NFTcolletionbutstate"] = value;
          list[index]["NFTCollectionid"] = addnewcol.data.result._id;
          setsaveeditcollection(true)
          setcollectionloader(false)
          toast(addnewcol.data.message)
        }else{
          setcollectionloader(false)
          toast(addnewcol.data.message)
        }
      }else{
        setcollectionloader(false)
        toast("Fill All Collection Data")
      }
    }

  }

}else{
  toast("Only Superadmin add NFT's")
}
}else{
  toast("Connect Your Wallet")
}
 
}

const editsavenftgroup=async(index,i)=>{

  const { web3,address,NFT,marketplace } = wallet;
  if(address){
    const checkadmins = await marketplace.methods.isOwner(address).call();
    console.log("checkadmin",checkadmins)
  if(checkadmins){

  if(Collection[index].Collectionnftgroup[i].nftgroupstatus){
    const value = false
    const list = [...Collection];
    list[index].Collectionnftgroup[i]["nftgroupstatus"] = value;
    setsaveeditevent(false)
  }else{
    if(!Collection[index].Collectionnftgroup[i].firstgroupsave){
   
      if(!Collection[index].Collectionnftgroup[i].nftcsvname){
        toast("Upload a NFT Group XLSX")
        return
      }else if(!Collection[index].Collectionnftgroup[i].nftzipname){
        toast("Upload a NFT Group Image ZIP")
        return
      }else if(!Collection[index].Collectionnftgroup[i].nftgroupname){
        toast("Enter a NFT Group Name")
        return
      }else if(!Collection[index].Collectionnftgroup[i].nftsupply){
        toast("Enter a NFT Group Supply Number")
        return
      }
    const data={
       EventName:events.eventname,
       Eventid:events.eventid,
       Collecctions:Collection[index],
       Nftgroup:Collection[index].Collectionnftgroup[i]
    }
    const addnftgroupnew = await addnftsgroup(data)

    if(addnftgroupnew.data.result){
      toast(addnftgroupnew.data.message)
      const value = true
      const list = [...Collection];
      list[index].Collectionnftgroup[i]["nftgroupstatus"] = value;
      list[index].Collectionnftgroup[i]["firstgroupsave"] = value;
      setCollection(list)
      setsaveeditevent(true)
    }else{
      toast(addnftgroupnew.data.message)
    }

  }else{

      if(!Collection[index].Collectionnftgroup[i].nftcsvname){
        toast("Upload a NFT Group XLSX")
        return
      }else if(!Collection[index].Collectionnftgroup[i].nftzipname){
        toast("Upload a NFT Group Image ZIP")
        return
      }else if(!Collection[index].Collectionnftgroup[i].nftgroupname){
        toast("Enter a NFT Group Name")
        return
      }else if(!Collection[index].Collectionnftgroup[i].nftsupply){
        toast("Enter a NFT Group Supply Number")
        return
      }
      const data={
        EventName:events.eventname,
        Eventid:events.eventid,
        Collecctions:Collection[index],
        Nftgroup:Collection[index].Collectionnftgroup[i]
     }
     const editnftgroupnew = await editnftsgroup(data)
    
     if(editnftgroupnew.data.result){
      toast(editnftgroupnew.data.message)
      const value = true
      const list = [...Collection];
      list[index].Collectionnftgroup[i]["nftgroupstatus"] = value;
      setCollection(list)
      setsaveeditevent(true)
    }else{
      toast(editnftgroupnew.data.message)
    }
  }
}

}else{
  toast("Only Superadmin add NFT's")
}
}else{
  toast("Connect Your Wallet")
}



}
  return (
    <div>
      <WalletStyles>
      <div class="container-fluid">
        <div className="row">
          <div
            className="col"
            style={{
              backgroundColor: "#c6c6c6d6",
              padding: "10px",
              borderRadius: "10px",
            }}
          >
            <div>
              <h1 style={{fontWeight:"700",color:"#003051",fontSize:"20px"}}>Location or Event</h1>
            </div>
          </div>
          <div>
            <Div>

              {saveeditevent ?  
              <div className="row  mt-5 d-flex align-items-center">
                <div className="col-xl-3 col-lg-3 col-md-3 col-xs-6 d-flex justify-content-center">
                <img src={`${process.env.REACT_APP_S3_URL}${events.eventimgname}`}  width="200px"
                height="200px"
                />
                </div>
                <div className="col-lg-9 col-9">
                  <div className="row">
                    <div className="col-xl-4 col-md-4 col-xs-6 d-flex ">
                      <FormGroup className="w-100">
                        <Label htmlFor="Name" className="mb-2 text-dark">
                          Name
                        </Label>
                        <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{events.eventname}</div>
                      </FormGroup>
                    </div>
                    <div className="col-xl-4 col-md-4 col-xs-6 d-flex justify-content-center ">
                      <FormGroup className="w-100">
                        <Label htmlFor="Country" className="mb-2 text-dark">
                          Country
                        </Label>
                        <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{events.eventcountry}</div>
                      </FormGroup>
                    </div>
                    <div className="col-xl-4 col-md-4 col-xs-6 d-flex  justify-content-end ">
                      <FormGroup className="w-100">
                        <Label htmlFor="City" className="mb-2 text-dark">
                          City
                        </Label>
                        <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{events.eventcity}</div>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex ">
                      <div className="w-100">
                        <Label htmlFor="Name" className="mb-2 text-dark">
                          Website
                        </Label>
                        <div className="d-flex ">
                        <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{events.website}</div>
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex  justify-content-end">
                      <div className="w-100">
                        <Label htmlFor="Name" className="mb-2 text-dark">
                          Instagram
                        </Label>
                        <div className="d-flex ">
                        <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{events.instagram}</div>
                         
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex justify-content-center">
                      <div className="w-100">
                        <Label htmlFor="Name" className="mb-2 text-dark">
                          {" "}
                          Facebook
                        </Label>
                        <div className="d-flex ">
                        <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{events.facebook}</div>
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex  justify-content-end">
                      <div className="w-100">
                        <Label htmlFor="Name" className="mb-2 text-dark">
                          Discord
                        </Label>
                        <div className="d-flex ">
                          <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{events.discord}</div>
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-4">
                {eventloader? 
                 <button className="btn buttonhover text-light bg-prussianBlue">
               <img src={loadiing} alt='loader' style={{width:"40px", height:"40px",alignSelf:"center"}}/>
               </button>
				 : <button onClick={()=>evetschange()} className="btn buttonhover  text-light bg-prussianBlue">
         Edit
       </button>}
                  
                </div>

              
              </div> :  
              <div className="row  mt-5 d-flex align-items-center">
                <div className="col-xl-3 col-lg-3 col-md-3 col-xs-6 d-flex justify-content-center">
               
                   <UploadImage
                    width="200px"
                    height="200px"
                    title="Icons"
                    style={{ backgroundColor: "gainsboro" }}
                    onChange={(e) => onChangeAddNFTImage(e)}
                    imageurl={events.eventimgname ? events.eventimgname : ""}
                    // { ...registeredValue('photo') }
                  />
        
                </div>
                <div className="col-lg-9 col-9">
                  <div className="row">
                    <div className="col-xl-4 col-md-4 col-xs-6 d-flex ">
                      <FormGroup className="w-100">
                        <Label htmlFor="Name" className="mb-2 text-dark">
                          Name
                        </Label>
                        <TextField
                          id="name"
                          value={events.eventname}
                          onChange={(e) => setevents({...events,eventname:e.target.value})}
                          placeholder="Name"
                          className="input-name border rounded-md"
                          style={{ minWidth: "250px", height: "40px" }}
                          noInlineError
                        />
                      </FormGroup>
                    </div>
                    <div className="col-xl-4 col-md-4 col-xs-6 d-flex justify-content-center ">
                      <FormGroup className="w-100">
                        <Label htmlFor="Country" className="mb-2 text-dark">
                          Country
                        </Label>
                        <Dropdown
                          className="h-[40px]"
                          menuClassname="h-[100px]"
                          value={events.eventcountry}
                          options={countryOption}
                          onChange={(e) => {
                            // handleSetCities(e.target.value);
                            setevents({...events,eventcountry:e.target.value})
                            // setCountry(e.target.value);
                          }}
                          placeholder="Choose Country"
                          // options={countries}
                          style={{ width: "350px" }}
                        />
                      </FormGroup>
                    </div>
                    <div className="col-xl-4 col-md-4 col-xs-6 d-flex  justify-content-end ">
                      <FormGroup className="w-100">
                        <Label htmlFor="City" className="mb-2 text-dark">
                          City
                        </Label>
                        <TextField
                          id="city"
                          value={events.eventcity}
                          onChange={(e) => setevents({...events,eventcity:e.target.value})}
                          placeholder="City"
                          className="input-name border rounded-md"
                          style={{ minWidth: "250px", height: "40px" }}
                          noInlineError
                        />
                      </FormGroup>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex ">
                      <div className="w-100">
                        <Label htmlFor="Name" className="mb-2 text-dark">
                          Website
                        </Label>
                        <div className="d-flex ">
                          <GlobalOutlined className="icon_pos" />
                          <input
                            id="website"
                            value={events.website}
                            onChange={(e) => setevents({...events,website:e.target.value})}
                            placeholder="Website link"
                            className="input-name  border w-100 rounded-md"
                            style={{
                              padding: " 0px 0px 0px 40px",
                              height: "40px",
                            }}
                            noInlineError
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex  justify-content-end">
                      <div className="w-100">
                        <Label htmlFor="Name" className="mb-2 text-dark">
                          Instagram
                        </Label>
                        <div className="d-flex ">
                          <BsInstagram className="icon_pos" />
                          <input
                            id="instagram"
                            value={events.instagram}
                            onChange={(e) => setevents({...events,instagram:e.target.value})}
                            placeholder="Instagram link"
                            className="input-name w-100 border rounded-md"
                            style={{
                              padding: " 0px 0px 0px 40px",
                              height: "40px",
                            }}
                            noInlineError
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex justify-content-center">
                      <div className="w-100">
                        <Label htmlFor="Name" className="mb-2 text-dark">
                          {" "}
                          Facebook
                        </Label>
                        <div className="d-flex ">
                          <RiFacebookCircleLine className="icon_pos" />
                          <input
                            id="facebook"
                            value={events.facebook}
                            onChange={(e) => setevents({...events,facebook:e.target.value})}
                            placeholder="FaccBook link"
                            className="input-name  border w-100 rounded-md"
                            style={{
                              padding: " 0px 0px 0px 40px",
                              height: "40px",
                            }}
                            noInlineError
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex  justify-content-end">
                      <div className="w-100">
                        <Label htmlFor="Name" className="mb-2 text-dark">
                          Discord
                        </Label>
                        <div className="d-flex ">
                          <RxDiscordLogo className="icon_pos" />
                          <input
                            id="discord"
                            value={events.discord}
                            onChange={(e) => setevents({...events,discord:e.target.value})}
                            placeholder="Discord link"
                            className="input-name w-100 border rounded-md"
                            style={{
                              padding: " 0px 0px 0px 40px",
                              height: "40px",
                            }}
                            noInlineError
                          />
                        </div>
                      </div>
                    </div>
                  
                  </div>
                </div>
                <div className="d-flex justify-content-end mt-4">
                {eventloader? 
                 <button className="btn buttonhover text-light bg-prussianBlue">
               <img src={loadiing} alt='loader' style={{width:"40px", height:"40px",alignSelf:"center"}}/>
               </button>
				 : <button onClick={()=>evetschange()} className="btn buttonhover text-light bg-prussianBlue">
             Save
           </button>}
                  
                </div>              
              </div> }
           
            {Collection && Collection.map((row,index)=>{
              return(
                <>
                <div
                className="col mt-5 d-flex justify-content-between align-items-center"
                style={{
                  backgroundColor: "#c6c6c6d6",
                  padding: "10px",
                  borderRadius: "10px",
                }}
              >
                <div>
                  <h1 style={{fontWeight:"700",color:"#003051",fontSize:"20px"}} className="text-blackLighter">Collections-{index+1}</h1>
                </div>
                <div>
                {/* disabled={ events.eventid =="" } */}
                  <button className="border p-3 rounded border-dark " disabled={ events.eventid =="" } onClick={()=>addCollection("col")}>                    
                    + Add Collection
                  </button>
                </div>
              </div>
           
                <div className="row mt-5">
                { row.NFTcolletionbutstate ?
                <>
                 <div className="col-xl-3 col-lg-3 col-md-3 col-xs-6 d-flex justify-center">
                <div className="row d-flex justify-center">
                { Collection[index].NFTcollectionimg ?
                <img src={`${process.env.REACT_APP_S3_URL}${Collection[index].NFTcollectionimg}`}  width="200px"
                height="200px"
                />: null
                }
                  </div>
                </div>
                <div className="col-lg-9 col-9">
                  <div className="row">
                    <div className="col-xl-4 col-md-4 col-xs-6 d-flex ">
                      <FormGroup className="w-100">
                        <Label htmlFor="Collection" className="mb-2 text-dark">
                          Collection
                        </Label>
                        <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{Collection[index].NFTCollectionname}</div>
                      </FormGroup>
                    </div>
                    <div className="col-xl-4 col-md-4 col-xs-6 d-flex justify-content-center ">
                      <FormGroup className="w-100">
                        <Form.Label htmlFor="tier" className="mb-2 text-dark">
                          Tier
                        </Form.Label>
                        <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{Collection[index].Collectiontier}</div>
                      </FormGroup>
                    </div>
                    <div className="col-xl-4 col-md-4 col-xs-6 d-flex  justify-content-end ">
                      <FormGroup className="w-100">
                        <Label htmlFor="City" className="mb-2 text-dark">
                          Royality
                        </Label>
                        <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{Collection[index].NFTRoyality}</div>
                        </FormGroup>
                    </div>
                  </div>
                 
                  <div className="row mt-4">
                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex justify-content-center">
                      <div className="w-100">
                        <Form.FormGroup className="input-wrapper mt-2">
                          <Form.Label
                            htmlFor="description"
                            className="mb-2 text-dark"
                          >
                            Description
                          </Form.Label>
                          
                          <Form.TextArea
                            id="description"
                            placeholder="Description"
                            name="CollectionDescription"
                            value={Collection[index].CollectionDescription}
                            className="h-[80px] rounded-md resize-none p-2"
                            noInlineError
                          />
                        </Form.FormGroup>
                      </div>
                    </div>

                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex  justify-content-end">
                      <div className="w-100">
                      <Form.FormGroup disabled={ events.eventid =="" } className="input-wrapper mt-2">
                      <Form.Label
                            htmlFor="description"
                            className="mb-2 text-dark"
                          >
                            Trade
                          </Form.Label>
                          {Collection[index].Collectiontrade === "trade"?
                        <label class="container mt-1">
                          NFTs shall be traded outside of OKU
                          <input type="radio" value="trade" id={index} name={"Collectiontrade"+index} checked={Collection[index].Collectiontrade === "trade"} />
                          <span class="checkmark"></span>
                        </label>
                        :
                        <label class="container mt-2">
                          NFTs shall not be traded outside of OKU
                          <input type="radio" value="nontrade" id={index} name={"Collectiontrade"+index} checked={Collection[index].Collectiontrade === "nontrade"} />
                          <span class="checkmark"></span>
                        </label>
                        }
                        </Form.FormGroup>
                      </div>
                    </div>
                    <div className="row mt-4">
                    <div className="col-xl-12 col-md-12 col-xs-12 d-flex justify-content-end"> 
                    <div className="w-100"> 
                    <div className="d-flex justify-content-end">
                    {collectionloader ? 
                    <button className="btn buttonhover text-light bg-prussianBlue">
                  <img src={loadiing} alt='loader' style={{width:"40px", height:"40px",alignSelf:"center"}}/>
                  </button>
                    : 
                    <button className="btn buttonhover mx-5 text-light bg-prussianBlue" onClick={()=>onchangecollection(index)}> Edit</button> 
                  }

                  <button className="btn btn-outline-danger " onClick={()=>removeCollection("col",index)}> Remove </button>
                    

                     </div>
                    </div>
                        </div>
                        </div>
                  </div>
                </div></> :
                 <>
                 <div className="col-xl-3 col-lg-3 col-md-3 col-xs-6 d-flex justify-center">
                <div className="row d-flex justify-center">
                { Collection[index].NFTcollectionimg ?
                <img src={`${process.env.REACT_APP_S3_URL}${Collection[index].NFTcollectionimg}`}  width="200px"
                height="200px"
                />: null
                }
               {colimgloader ? 
                <label className="btn buttonhover text-light bg-prussianBlue mt-2" style={{display:"flex",alignItems:"center" ,justifyContent:"center" ,width:"80px",height:"45px"}} htmlFor={"contained-button-file"+index}>
                 <img src={loadiing} alt='loader' style={{width:"20px", height:"20px",alignSelf:"center"}}/>
                </label>
               :
               <label className="btn buttonhover text-light bg-prussianBlue mt-2" style={{display:"flex",alignItems:"center",width:"80px",height:"45px"}} htmlFor={"contained-button-file"+index}>
                Upload
                </label>
               }
                
                <input
                    type="file"
                    id={"contained-button-file"+index}
                    accept={"image/png, image/jpg, image/jpeg"}
                    style={{ display: "none" }}
                    disabled={ events.eventid =="" }
                    onChange={(e) => changecollection(e,index)}
                  />
                  </div>

                </div>
                <div className="col-lg-9 col-9">
                  <div className="row">
                    <div className="col-xl-4 col-md-4 col-xs-6 d-flex ">
                      <FormGroup className="w-100">
                        <Label htmlFor="Collection" className="mb-2 text-dark">
                          Collection
                        </Label>
                        <TextField
                          name="NFTCollectionname"
                          value={Collection[index].NFTCollectionname}
                          disabled={ events.eventid =="" }
                          onChange={(e) => handelcollection(e.target,index)}
                          placeholder="Collection Name"
                          className="input-name border rounded-md"
                          style={{ minWidth: "250px", height: "40px" }}
                          noInlineError
                        />
                      </FormGroup>
                    </div>
                    <div className="col-xl-4 col-md-4 col-xs-6 d-flex justify-content-center ">
                      <FormGroup className="w-100">
                        <Form.Label htmlFor="tier" className="mb-2 text-dark">
                          Tier
                        </Form.Label>
                        { console.log("tierss",tiers_.filter((object1) => !Collection.some((object2) => object1.value === object2.NFTTier)))}
                        {tiers_.filter((object1) => !Collection.some((object2) => object1.value === object2.NFTTier)).length > 0 ?
                        <Dropdown
                          className="h-[40px]  mr-5"
                          menuClassname="h-[100px]  z-20"
                          options={tiers_ && tiers_.filter((object1) => !Collection.some((object2) => object1.value === object2.Collectiontier)) }
                          disabled={ events.eventid =="" || tiers_.length === 0}
                          name={"Collectiontier"+index}
                          id={ index }
                          value={Collection[index].Collectiontier}
                          onChange={(e) => handelcollection1(e.target,index)}
                          placeholder="Choose Tier"
                        />:
                        <TextField
                        name="Tier"
                        type="text"
                        value={Collection[index].Collectiontier}
                        className="input-name border rounded-md"
                        style={{ minWidth: "250px", height: "40px" }}
                        noInlineError
                      />
                        }
                      </FormGroup>
                    </div>
                    <div className="col-xl-4 col-md-4 col-xs-6 d-flex  justify-content-end ">
                      <FormGroup className="w-100">
                        <Label htmlFor="City" className="mb-2 text-dark">
                          Royality
                        </Label>
                        <TextField
                          name="NFTRoyality"
                          type="number"
                          value={Collection[index].NFTRoyality}
                          onChange={(e) => handelcollection(e.target,index)}
                          placeholder="Royality in %"
                          disabled={ events.eventid =="" }
                          className="input-name border rounded-md"
                          style={{ minWidth: "250px", height: "40px" }}
                          noInlineError
                        />
                      </FormGroup>
                    </div>
                  </div>
                 
                  <div className="row mt-4">
                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex justify-content-center">
                      <div className="w-100">
                        <Form.FormGroup className="input-wrapper mt-2">
                          <Form.Label
                            htmlFor="description"
                            className="mb-2 text-dark"
                          >
                            Description
                          </Form.Label>
                          <Form.TextArea
                            id="description"
                            placeholder="Description"
                            name="CollectionDescription"
                            disabled={  events.eventid =="" }
                            value={Collection[index].CollectionDescription}
                            className="h-[80px] border rounded-md resize-none p-2"
                            onChange={(e) => handelcollection(e.target,index)}
                            noInlineError
                            // { ...registeredValue('description') }
                          />
                        </Form.FormGroup>
                      </div>
                    </div>

                    <div className="col-xl-6 col-md-6 col-xs-6 d-flex  justify-content-end">
                      <div className="w-100">
                      <Form.FormGroup onChange={onChangeValue} disabled={ events.eventid =="" } className="input-wrapper mt-2">
                      <Form.Label
                            htmlFor="description"
                            className="mb-2 text-dark"
                          >
                            Trade
                          </Form.Label>
                        <label class="container mt-1">
                          NFTs shall be traded outside of OKU
                          <input type="radio" value="trade" id={index} name="Collectiontrade" checked={Collection[index].Collectiontrade === "trade"} />
                          <span class="checkmark"></span>
                        </label>
                        <label class="container mt-2">
                          NFTs shall not be traded outside of OKU
                          <input type="radio" value="nontrade" id={index} name="Collectiontrade" checked={Collection[index].Collectiontrade === "nontrade"} />
                          <span class="checkmark"></span>
                        </label>
                        </Form.FormGroup>
                      </div>
                    </div>
                    <div className="row mt-4">
                    <div className="col-xl-12 col-md-12 col-xs-12 d-flex justify-content-end"> 
                    <div className="w-100"> 
                    <div className="d-flex justify-content-end">
                    {collectionloader ? 
                 <button className="btn buttonhover text-light bg-prussianBlue">
               <img src={loadiing} alt='loader' style={{width:"40px", height:"40px",alignSelf:"center"}}/>
               </button>
				 : 
         <button className="btn buttonhover mx-5 text-light bg-prussianBlue" onClick={()=>onchangecollection(index)}> Save</button> 
           }
          { Collection && Collection.length > 1 ? <button className="btn btn-outline-danger " onClick={()=>removeCollection("col",index)}> Remove </button> : null} 
                     </div>
                    </div>
                        </div>
                        </div>
                  </div>
                </div></> }
              </div>
              {row.Collectionnftgroup.map((data,i)=>{
              return(
                <>
                 <div className="col mt-5 d-flex justify-content-between align-items-center py-3 border-bottom">
             <div>
               <h1 style={{fontWeight:"700",color:"#003051",fontSize:"20px"}} className="text-blackLighter">NFT Group-{i+1}</h1>
              
             </div>
             { row.Collectionnftgroup && row.Collectionnftgroup.length > 0 ?
             <div className="d-flex">
               <AiFillCloseCircle className="text-danger" />{" "}
               <button className="text-danger mx-2 " onClick={()=>{removenftgroup("col",i,index)}}>Remove</button>
             </div> : null
              }
             </div>
          
          {data.nftgroupstatus ? <><div className="row mt-5">

<div className="col-lg-12 col-12">
  <div className="row d-flex justify-center">
    <div className="col-xl-4 col-md-4 col-xs-6 d-flex">
      <FormGroup className="w-100">
        <Label htmlFor="Collection" className="mb-2 text-dark">
          Name
        </Label>
        <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{Collection[index].Collectionnftgroup[i].nftgroupname}</div>
      </FormGroup>
    </div>
    <div className="col-xl-4 col-md-4 col-xs-6 d-flex">
      <FormGroup className="w-100">
        <Label htmlFor="City" className="mb-2 text-dark">
          Supply
        </Label>
        <div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{Collection[index].Collectionnftgroup[i].nftsupply}</div>
      </FormGroup>
    </div>
  </div>


</div>
</div>

<div className="row mt-4">
<div className="col-lg-12 col-12 d-flex justify-center">
<span className="" style={{fontWeight:"800",fontSize:"20px"}}>Upload Image Folder</span>
</div>
</div>

<div className="row mt-3">
<div className="col-lg-12 col-12 d-flex justify-center">
  <div className="row d-flex justify-center">
      <div  className="col mt-3"
style={{
  backgroundColor: "#c6c6c6d6",
  width:"850px",
  borderRadius:"1em",
  height:"100px"
}}> 
<div className="row mt-3">
 <div className="col col-lg-2 col-2 mt-1 d-flex justify-center">
 <BsFileEarmarkZipFill size={50} className=""/>
</div> 
<div className="col col-lg-7 col-7 mt-1">
  <span className="" style={{fontWeight:"800"}}>Upload Image Folder</span><br/>
  <span> Upload Folder with all the NFT images. Make sure images matches the total supply number</span>
</div> 
<div className="col col-lg-3 col-3 mt-1 d-flex justify-center ">
<div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{Collection[index].Collectionnftgroup[i].nftzipname}</div>
</div>
     
</div>
      </div>
</div>
</div>
</div>

<div className="row mt-4">
<div className="col-lg-12 col-12 d-flex justify-center">
<span className="" style={{fontWeight:"800",fontSize:"20px"}}>Upload CSV File</span>
</div>
</div>

<div className="row mt-3">
<div className="col-lg-12 col-12 d-flex justify-center">
  <div className="row d-flex justify-center">
      <div  className="col mt-3"
style={{
  backgroundColor: "#c6c6c6d6",
  width:"850px",
  borderRadius:"1em",
  height:"170px"
}}> 
<div className="row mt-3">
 <div className="col col-lg-2 col-2 mt-2 d-flex justify-center">
 <BsFileEarmarkZipFill size={50} className=""/>
</div> 
<div className="col col-lg-7 col-7 mt-1">
  <span className="" style={{fontWeight:"800"}}>Select a CSV File to Upload</span><br/>
  <span> Download the CSV file template to bring in NFT metadata,fill in all fields in CSV file which should match with total supply and NFT name should match with file name of images that was uploaded in previous step.</span>
</div> 
<div className="col col-lg-3 col-3 mt-2 d-flex justify-center">
<div className="mt-2" style={{fontWeight:"400",color:"#003051",fontSize:"20px"}}>{Collection[index].Collectionnftgroup[i].nftcsvname}</div>
</div>     
</div>
      </div>
</div>
</div>
</div>
<div className="d-flex justify-content-end mt-4">
<button className="btn mx-5 text-dark border border-dark " onClick={()=>addnftgroup("col",index)}>
  +Add Nft
</button>

<button className="btn buttonhover text-light bg-prussianBlue" onClick={()=>editsavenftgroup(index,i)}>
  Edit
</button>

</div></> : <><div className="row mt-5">

<div className="col-lg-12 col-12">
  <div className="row d-flex justify-center">
    <div className="col-xl-4 col-md-4 col-xs-6 d-flex">
      <FormGroup className="w-100">
        <Label htmlFor="Collection" className="mb-2 text-dark">
          Name
        </Label>
        <TextField
          id="website"
          name="nftgroupname"
          value={Collection[index].Collectionnftgroup[i].nftgroupname}
         //  onChange={(e) => setCollection({...Collection,[Collection[0].Collectionnftgroup:[] ]}) }
          disabled={row.NFTCollectionid ==""}
          onChange={(e) => {Collection[index].Collectionnftgroup[i].firstgroupsave ? toast("Unable to edit") : setnftGroupvalues(index,i,e.target)}}
         //  .Collectionnftgroup[i].nftgroupname : e.target,value
          placeholder=" Name"
          className="input-name border rounded-md"
          style={{ minWidth: "250px", height: "40px" }}
          noInlineError
        />
      </FormGroup>
    </div>
    <div className="col-xl-4 col-md-4 col-xs-6 d-flex">
      <FormGroup className="w-100">
        <Label htmlFor="City" className="mb-2 text-dark">
          Supply
        </Label>
        <input
          id="website"
          name="nftsupply"
          value={Collection[index].Collectionnftgroup[i].nftsupply}
          disabled={row.NFTCollectionid ==""}
          onChange={(e) =>setnftGroupvalues(index,i,e.target)}
          placeholder="Supply"
          className="input-name border rounded-md"
          style={{ minWidth: "250px", height: "40px" }}
          noInlineError
        />
      </FormGroup>
    </div>
  </div>


</div>
</div>

<div className="row mt-4">
<div className="col-lg-12 col-12 d-flex justify-center">
<span className="" style={{fontWeight:"800",fontSize:"20px"}}>Upload Image Folder</span>
</div>
</div>

<div className="row mt-3">
<div className="col-lg-12 col-12 d-flex justify-center">
  <div className="row d-flex justify-center">
      <div  className="col mt-3"
style={{
  backgroundColor: "#c6c6c6d6",
  width:"850px",
  borderRadius:"1em",
  height:"100px"
}}> 
<div className="row mt-3">
 <div className="col col-lg-2 col-2 mt-1 d-flex justify-center">
 <BsFileEarmarkZipFill size={50} className=""/>
</div> 
<div className="col col-lg-7 col-7 mt-1">
  <span className="" style={{fontWeight:"800"}}>Upload Image Folder</span><br/>
  <span> Upload Folder with all the NFT images. Make sure images matches the total supply number</span>
</div> 
<div className="col col-lg-3 col-3 mt-1 d-flex justify-center ">

{Collection[index].Collectionnftgroup[i].nftzipname ? <div className="row d-flex justify-center align-items-center"> 
  <div className="row d-flex justify-center mb-2">{Collection[index].Collectionnftgroup[i].nftzipname ? Collection[index].Collectionnftgroup[i].nftzipname :""}</div>
  {/* {ziploader ?<>
 <label className="btn buttonhover text-light bg-prussianBlue" style={{display:"flex",alignItems:"center",width:"95px",height:"45px"}} htmlFor={"contained-button_"+i}>
 <img src={loadiing} alt='loader' style={{width:"40px", height:"40px",alignSelf:"center"}}/>
  </label>
 <input
         type="file"
         id={"contained-button_"+i}
         style={{display:"none"}}
         accept=".zip"
         disabled={row.NFTCollectionid ==""}
        //  onChange={(e)=>uploadFiles(index,i,e,"nftzip","nftzipname")}
     />   </> : <> */}

    {Ziploader ? <label className="btn buttonhover text-light bg-prussianBlue" style={{display:"flex",alignItems:"center",width:"95px",height:"45px"}} htmlFor={"contained-button_"+i}>
    <img src={loadiing} alt='loader' style={{width:"20px", height:"20px",alignSelf:"center"}}/>
    </label> :   
    <label className="btn buttonhover text-light bg-prussianBlue" style={{display:"flex",alignItems:"center",width:"100px",justifyContent:"center",height:"45px"}} htmlFor={"contained-button_"+i}>
     Uploaded
    </label>} 
  

   <input
           type="file"
           id={"contained-button_"+i}
           style={{display:"none"}}
           accept=".zip"
          disabled={row.NFTCollectionid ==""}
           onChange={(e)=>uploadFiles(index,i,e,"nftzip","nftzipname")}
       />
        {/* </>}     */}
     </div> 
     
              
     :
     
<>
{/* {ziploader ? <><label className="btn buttonhover text-light bg-prussianBlue" style={{display:"flex",alignItems:"center",width:"80px",height:"45px"}} htmlFor={"contained-button_"+i}>
<img src={loadiing} alt='loader' style={{width:"40px", height:"40px",alignSelf:"center"}}/>
</label>
 <input
         type="file"
         id={"contained-button_"+i}
         style={{display:"none"}}
         disabled={row.NFTCollectionid ==""}
         accept=".zip"
      
     /></>  :  <> */}
     {Ziploader ?  
     <label className="btn buttonhover text-light bg-prussianBlue" style={{display:"flex",alignItems:"center",justifyContent:"center",width:"80px",height:"45px"}} htmlFor={"contained-button_"+i}>
        <img src={loadiing} alt='loader' style={{width:"20px", height:"20px",alignSelf:"center"}}/>
      </label>
      :
      <label className="btn buttonhover text-light bg-prussianBlue" style={{display:"flex",alignItems:"center",width:"80px",height:"45px"}} htmlFor={"contained-button_"+i}>
      Upload
      </label>
     }
    
 <input
         type="file"
         id={"contained-button_"+i}
         accept=".zip"
         style={{display:"none"}}
         disabled={row.NFTCollectionid ==""}
         onChange={(e)=>{uploadFiles(index,i,e,"nftzip","nftzipname")}}
     />
     
      {/* </> } */}
     </>
 }
</div>
     
</div>
      </div>
</div>
</div>
</div>

<div className="row mt-4">
<div className="col-lg-12 col-12 d-flex justify-center">
<span className="" style={{fontWeight:"800",fontSize:"20px"}}>Upload CSV File</span>
</div>
</div>

<div className="row mt-3">
<div className="col-lg-12 col-12 d-flex justify-center">
  <div className="row d-flex justify-center">
      <div  className="col mt-3"
style={{
  backgroundColor: "#c6c6c6d6",
  width:"850px",
  borderRadius:"1em",
  height:"170px"
}}> 
<div className="row mt-3">
 <div className="col col-lg-2 col-2 mt-2 d-flex justify-center">
 <BsFileEarmarkZipFill size={50} className=""/>
</div> 
<div className="col col-lg-7 col-7 mt-1">
  <span className="" style={{fontWeight:"800"}}>Select a CSV File to Upload</span><br/>
  <span> Download the CSV file template to bring in NFT metadata,fill in all fields in CSV file which should match with total supply and NFT name should match with file name of images that was uploaded in previous step.</span>
</div> 
<div className="col col-lg-3 col-3 mt-2 d-flex justify-center">
{Collection[index].Collectionnftgroup[i].nftcsvname || jsonFilename ?  <div className="row d-flex justify-center align-items-center"> 
  <div className="row d-flex justify-center mb-2">{Collection[index].Collectionnftgroup[i].nftcsvname ? Collection[index].Collectionnftgroup[i].nftcsvname : jsonFilename}</div>
{Csvloader ? 
<label className="btn buttonhover text-light bg-prussianBlue" style={{display:"flex",alignItems:"center",justifyContent:"center",width:"80px",height:"45px"}} htmlFor={"contained_file"+i}>
<img src={loadiing} alt='loader' style={{width:"20px", height:"20px",alignSelf:"center"}}/>
</label>
:
<label className="btn buttonhover text-light bg-prussianBlue" style={{display:"flex",alignItems:"center",justifyContent:"center",width:"100px",height:"45px"}} htmlFor={"contained_file"+i}>
Uploaded
</label>
}


<input
type="file"
accept=".xlsx, .xls, .csv"
id={"contained_file"+i}
style={{display:"none"}}
placeholder="Upload"
disabled={row.NFTCollectionid ==""}
onChange={(e)=>bannerImageChange(index,i,e,"nftcvs")}
/>
</div>  
: <>
{Csvloader ?
<label className="btn buttonhover text-light bg-prussianBlue" style={{display:"flex",alignItems:"center",justifyContent:"center",width:"80px",height:"45px"}} htmlFor={"contained_file"+i}>
<img src={loadiing} alt='loader' style={{width:"20px", height:"20px",alignSelf:"center"}}/>
</label>
:
<label className="btn buttonhover text-light bg-prussianBlue" style={{display:"flex",alignItems:"center",width:"80px",height:"45px"}} htmlFor={"contained_file"+i}>
Upload
</label>
}

<input
type="file"
accept=".xlsx, .xls, .csv"
id={"contained_file"+i}
style={{display:"none"}}
placeholder="Upload"
disabled={row.NFTCollectionid ==""}
// onChange={(e)=>bannerImageChange(index,i,e,"nftcvs") }
onChange={(e)=> Collection[index].Collectionnftgroup[i].nftzipname ? bannerImageChange(index,i,e,"nftcvs") : toast("upload Image folder")}
/>
</>   

}
</div>

<div className="row d-flex justify-center align-items-center mt-3">
<a  className="d-flex justify-center" href="https://sunrisetechs.s3-ap-southeast-2.amazonaws.com/oku/MetadataTemplate.xlsx" download>
<label className="btn buttonhover text-light bg-prussianBlue" style={{display:"flex",alignItems:"center",width:"174px",height:"45px"}} >
Download Template
</label>
</a>
</div>

</div>
      </div>
</div>

</div>
</div>

<div className="d-flex justify-content-end mt-4">
{/* <button className="btn mx-5 text-dark border border-dark " onClick={()=>addnftgroup("col",index)}>
  +Add Nft
</button> */}
<button className="btn buttonhover text-light bg-prussianBlue mr-10" onClick={()=>editsavenftgroup(index,i)}>
  Save
</button>
</div></>
}         
           <div className="row justify-content-center ">
             <div className="col-4 d-flex justify-content-center mt-4">
               <label class="container d-flex justify-content-center align-items-center">
                 <div class="mt-1">NFTs shall be traded only after {Collection[index].Collectionnftgroup[i].nfttradedate ? timeconvertdays(Number(Collection[index].Collectionnftgroup[i].nfttradedate) * 1000) : "Sale End Date"}</div>
                 <input type="radio" checked={true} name={"radio"+index+i} />
                 <span class="checkmark"></span>
               </label>
             </div>
           </div>
           </> )
               })}


              </> )
            })}

          <div className="row justify-content-center align-item-center">
             <div className="col-4 d-flex justify-content-center mt-4 ">
                 <button className="w-75 btn buttonhover text-light bg-prussianBlue p-2" onClick={()=>submitevents()} >Submit</button>
             </div>
           </div>  
            </Div>
          </div>
        </div>
      </div>
      </WalletStyles>
    </div>
  );
};
export default React.memo(AddRestaurant);
