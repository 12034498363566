import * as UserActionTypes from './actionTypes';

export const getUserLoading = () => ({
	type: UserActionTypes.GET_USER_LIST,
});

export const getUserSuccess = payload => ({
	type: UserActionTypes.GET_USER_LIST_SUCCESS,
	payload,
});

export const changeTableModeUser = payload => ({
	type: UserActionTypes.CHANGE_TABLE_MODE,
	payload,
});

// export const addAdmins = (payload) => ({
// 	type: AdminsActionTypes.ADD_ADMINS,
// 	payload,
// });

// export const editAdmins = (payload) => ({
// 	type: AdminsActionTypes.EDIT_ADMINS,
// 	payload,
// });

export const deleteUser = () => ({
	type: UserActionTypes.DELETE_USER,
});

export const selectUser = payload => ({
	type: UserActionTypes.SELECT_USER,
	payload,
});

export const getUser = dispatch => params => {
	// return new Promise(async(resolve, reject) => {
	// 	dispatch(getRestaurantLoading());

	// 	const response = await productServices.getRestaurant(params);

	// 	const { stat_code, data, stat_msg, pagination } = response;

	// 	if (stat_code === 200) {
	// 		resolve(dispatch(getRestaurantSuccess({ data,
	// 			pagination })));
	// 	} else {
	// 		reject(stat_msg);
	// 	}
	// });
};
