import * as FaqActionTypes from './actionTypes';

export const getFaqLoading = () => ({
	type: FaqActionTypes.GET_FAQ_LIST,
});

export const getFaqSuccess = (payload) => ({
	type: FaqActionTypes.GET_FAQ_LIST_SUCCESS,
	payload,
});

export const addFaq = (payload) => ({
	type: FaqActionTypes.ADD_FAQ,
	payload,
});