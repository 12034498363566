import React, {useState} from 'react';
import { Button, Input, Text } from 'components';
import { useNavigate } from "react-router-dom";

import ForgotPasswordStyle from './style';
import {resetPassword} from '../../services/auth';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
const CreateNewPassword = () => {
  const navigate = useNavigate();
  // const { onClickLogin, onChangeText } = useLoginPage();
  const [newpassword, setNewpasswod] = useState('');
  const [confirmpassword, setConfirmpasswod] = useState('');
  const email = useParams();
  console.log("useParams",email)
  const check = confirmpassword.length > 7 && newpassword.length > 7;
  const checkequal = newpassword == confirmpassword 
  const handleSubmit = async () =>{
    console.log("checkcondition",newpassword,confirmpassword)
    if(!newpassword){
      toast("Enter the New Password")
      return
    }else if(!confirmpassword){
      toast("Enter the Confirm Password")
      return
    }
    if(check){
      if(checkequal){
          const values = { password: confirmpassword}
          const res = await resetPassword(email.id,values);
          if(res.data.success){
            navigate('/login')
          }else{
            toast("something went wrong")
          }
      }else{
      toast("Password Should Match")
      }
    }else{
      toast("Password Must Be At Least 8 Characters")
    }
   
  }
  return (
    <ForgotPasswordStyle>
      <div className='create-new-password'>
        <Text
          text={'Create New Password'}
          fontWeight={'700'}
          fontSize={'20px'}
        //   className={'mb-8'}
        />
        <Text
          text={'Input your new password on the form below.'}
          fontWeight={'300'}
          fontSize={'16px'}
          className={'mb-8'}
        />
        <Input
          label='New Password'
          name='newpassword'
          type='password'
          placeholder='new password'
          className='mb-5'
          onChange={(e) => setNewpasswod(e.value)}
        />
        <Input
          label='Confirm New Password'
          name='confirmpassword'
          type='password'
          placeholder='confirm new password'
          className='mb-5'
          onChange={(e) => setConfirmpasswod(e.value)}        />
        <button
          style={!check? { background:"#E3E0E0",color:"#003051",width:"70%",height:"30px",marginLeft:"15%",borderRadius:"20px",fontSize:"16px",fontWeight:"700px"} : { background:"#003051",color:"#E3E0E0",width:"70%",height:"30px",marginLeft:"15%",borderRadius:"20px",fontSize:"16px",fontWeight:"700px"}}
          onClick={ () => handleSubmit() }
          // disabled={!check}
        > Submit</button>
      </div>
    </ForgotPasswordStyle>
  );
};

export default React.memo(CreateNewPassword);
