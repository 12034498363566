import colors from 'constant/colors';
import styled, { css } from 'styled-components';

const Container = styled.div`
    width: ${props => props.width} ;
    height: ${props => props.height} ;
    border-style: dashed ;
    .edit {
        display: none ;

    }
    &:hover {
        .edit {
            display: flex ;
        }
    }

    ${props => !props.isEmpty &&
        css`
        border-style: none ;
            &:hover {
                background: ${colors.black.backdrop} ;
            }
        `
}

    .img-preview {
        width: ${props => props.width} ;
        height: ${props => props.height} ;
    }

`;

export { Container };