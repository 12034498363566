import { post , get , put } from "../http";

export const addRestaurant = async (data) => {
  try {
    const result = await post(`restaurant/addRestaurnt`, data);
    return result;

  } catch (error) {
    throw error;
  }
};

export const getRestaurants = async () => {
  try {
    const result = await get(`restaurant/getRestaurants`);
    return result;

  } catch (error) {
    throw error;
  }
};

export const editRestaurants = async (data,queryParams) => {
  try {
    const result = await put(`restaurant/editRestaurants`,data,queryParams);
    
    return result;

  } catch (error) {
    throw error;
  }
};

export const addnewevents= async (data,queryParams) => {
  try {
    const result = await post(`restaurant/addnewevents`,data,queryParams);
    return result;
  } catch (error) {
    throw error;
  }
};

export const editevents = async (data,queryParams) => {
  try {
    const result = await post(`restaurant/editevents`,data,queryParams);
    return result;
  } catch (error) {
    throw error;
  }
};



export const activeeventscollection = async (data,queryParams) => {
  try {
    const result = await post(`restaurant/activeeventscollection`,data,queryParams);
    return result;
  } catch (error) {
    throw error;
  }
};

export const addnewcollections= async (data,queryParams) => {
  try {
    const result = await post(`restaurant/addnewcollections`,data,queryParams);
    return result;
  } catch (error) {
    throw error;
  }
};

export const editnftdetails= async (data,queryParams) => {
  try {
    const result = await post(`restaurant/editnftdetails`,data,queryParams);
    return result;
  } catch (error) {
    throw error;
  }
};

export const editcollection = async (data,queryParams) => {
  try {
    const result = await post(`restaurant/editcollection`,data,queryParams);
    return result;
  } catch (error) {
    throw error;
  }
};


export const addnftsgroup = async (data,queryParams) => {
  try {
    const result = await post(`restaurant/addnftsgroup`,data,queryParams);
    return result;
  } catch (error) {
    throw error;
  }
}; 



export const addnftsgroupedit = async (data,queryParams) => {
  try {
    const result = await post(`restaurant/addnftsgroupedit`,data,queryParams);
    return result;
  } catch (error) {
    throw error;
  }
};


export const editnftsgroupedit= async (data,queryParams) => {
  try {
    const result = await post(`restaurant/editnftsgroupedit`,data,queryParams);
    return result;
  } catch (error) {
    throw error;
  }
};


export const editnftsgroup= async (data,queryParams) => {
  try {
    const result = await post(`restaurant/editnftsgroup`,data,queryParams);
    return result;
  } catch (error) {
    throw error;
  }
}; 


export const activeevents= async (data,queryParams) => {
  try {
    const result = await post(`restaurant/activeevents`,data,queryParams);
    return result;
  } catch (error) {
    throw error;
  }
}; 

export const deleteRestaurants = async (data) => {
  try {
    const result = await put(`restaurant/deleteRestaurants`,data);
    console.log("results",result)
    return result;
  } catch (error) {
    throw error;
  }
};



export const getCountries = async () => {
  try {
    const result = await get(`restaurant/getCountries`);
    return result;

  } catch (error) {
    throw error;
  }
};


export const listCollections = async () => {
  try {
    const result = await get(`restaurant/listCollections`);
    return result;
  } catch (error) {
    throw error;
  }
};

export const getNewCollections = async () => {
  try {
    const result = await get(`restaurant/getNewCollections`);
    return result;
  } catch (error) {
    throw error;
  }
};
export const NFTgroupcollection = async () => {
  try {
    const result = await get(`restaurant/NFTgroupcollection`);
    return result;
  } catch (error) {
    throw error;
  }
};

export const getallNfts = async() => {
  try{
      const result = await get('nft/getallNFTs')
      return result;
  }catch(error){
      throw error;
  }
}


export const deleteNftCollections = async(body) => {
  try{
      const result = await put('nft/deleteNftCollections', body)
      return result;
  }catch(error){
      throw error;
  }
}


export const AddCollections = async(body, tokens) => {
  try{
      const result = await post('nft/AddCollections', body, tokens)
      return result;
  }catch(error){
      throw error;
  }
}


export const addNfts = async(body, tokens) => {
  try{
      const result = await post('nft/addNfts', body, tokens)
      return result;
  }catch(error){
      throw error;
  }
}


export const EditCollections = async(body, tokens) => {
  try{
      const result = await put('nft/EditCollections', body, tokens)
      return result;
  }catch(error){
      throw error;
  }
}

export const getRestaurantsByName = async(body, tokens) => {
  try{
      const result = await post('restaurant/getRestaurantsByName', body, tokens)
      return result;
  }catch(error){
      throw error;
  }
}

export const getEventsdetails=async(id)=>{
  try{
    const result = await get(`restaurant/getEventsdetails/${id}`)
    return result;
  }catch(error){
    throw error;
}
}

export const deletenft=async(id)=>{
  try{
    const result = await post(`restaurant/deletenft/${id}`)
    return result;
  }catch(error){
    throw error;
}
}

export const deleteCollections=async(id)=>{
  try{
    const result = await post(`restaurant/deleteCollections/${id}`)
    return result;
  }catch(error){
    throw error;
}

}


