import React, {  useState } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/solid';
import { Button, Form, Text } from 'components';
import { phoneForgotPasswordField } from 'components/Form/formData';

import ModalInputCode from 'pages/ForgotPassword/Components/ModalInputCode';
import ForgotPasswordStyle from '../style';

const InputPhoneNumber = ({ prev }) => {
	// const { onClickLogin, onChangeText } = useLoginPage();
	const [showModalInputCode, setShowModalInputCode] = useState(false);

	const handleShowModalInputCode = () => setShowModalInputCode(!showModalInputCode);

	const {
		registeredValue, isFormValid
	} = Form.useForm({ fields: phoneForgotPasswordField });

	return (
		<ForgotPasswordStyle>
			<div className='forgotpassword-form'>
				<div className='flex items-center mb-8' onClick={ prev }>
					<div className='w-[32px] h-[32px] border rounded-full mr-2 cursor-pointer' onClick={ prev }>
						<ChevronLeftIcon />
					</div>
					<span>Back</span>
				</div>
				<Text
					text={ 'Enter Your Phone Number' }
					fontWeight={ '700' }
					fontSize={ '20px' }
					fontFamily={ 'Lora' }
					//   className={'mb-8'}
				/>
				<Text
					text={ 'Please enter registered phone number and you will get verification code.' }
					fontWeight={ '300' }
					fontSize={ '16px' }
					className={ 'mb-6 mt-3' }
					lineHeight={ '26px' }
				/>
				<Form.FormGroup className='input-wrapper mb-6'>
                    <Form.Label htmlFor='email' className='mb-2 text-darkGrey'>Phone Number</Form.Label>
                    <Form.TextField
                        id='email'
                        placeholder='ex. 081234*****'
                        className='input-name border rounded-md outline-prussianBlue'
                        noInlineError
                        { ...registeredValue('phone') }
                    />
                </Form.FormGroup>
				<Button
					label='Next'
					width='100%'
					height='45px'
                    className='bg-prussianBlue  mt-2' 
					disabled={ !isFormValid() }
					onClick={ handleShowModalInputCode }

				/>
			</div>
			<ModalInputCode
				visible={ showModalInputCode }
				onClose={ () => setShowModalInputCode(false) }
			/>
		</ForgotPasswordStyle>
	);
};

export default React.memo(InputPhoneNumber);
