import React,{useState, useEffect} from 'react';
import { Button, Text, Form } from 'components';
import { emailForgotPasswordField } from 'components/Form/formData';
import {sendVerificationCode} from  '../../../../services/auth';
import ForgotPasswordStyle from '../style';
import ModalInputCode from 'pages/ForgotPassword/Components/ModalInputCode';

const InputEmail = ({ next }) => {
    const [showModalInputCode, setShowModalInputCode] = useState(false);

	const handleShowModalInputCode = () => setShowModalInputCode(!showModalInputCode);
    const {
		registeredValue, isFormValid
	} = Form.useForm({ fields: emailForgotPasswordField });

    const [email, setEmail] = useState('');

    const handlesubmit = async()=>{
        const values = {email:email}
        const res = await sendVerificationCode(values);
        if(res.data.status){
            handleShowModalInputCode();
        }else{
            alert("User not registered");
        }
    }

    return (
        <ForgotPasswordStyle>
            <div className='forgotpassword-form'>
                <Text
                    text={ 'Enter Your Email Address' }
                    fontWeight={ '700' }
                    fontSize={ '20px' }
                    fontFamily={ 'Lora' }
                //   className={'mb-8'}
                />
                <Text
                    text={ 'Please enter your registered email.' }
                    fontWeight={ '300' }
                    fontSize={ '16px' }
                    className={ 'mb-6 mt-3' }
                    lineHeight={ '26px' }
                />
                <Form.FormGroup className='input-wrapper mb-6'>
                    <Form.Label htmlFor='email' className='mb-2 text-darkGrey'>Email</Form.Label>
                    <Form.TextField
                        id='email'
                        placeholder='email'
                        className='input-name border rounded-md outline-prussianBlue'
                        onChange={(e) => setEmail(e.target.value)}
                        noInlineError
                        // { ...registeredValue('email') }
                    />
                </Form.FormGroup>
                <Button
                    label='Next'
                    width='100%'
                    height='45px'
                    className='bg-prussianBlue  mt-2' 
                    disabled={ !email }
                    onClick={ ()=> handlesubmit() }

                />
            </div>
            <ModalInputCode
				visible={ showModalInputCode }
				onClose={ () => setShowModalInputCode(false) }
                email={email}
			/>
        </ForgotPasswordStyle>
    );
};

export default React.memo(InputEmail);
