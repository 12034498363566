import { useMemo } from 'react';

export const DOTS = '...';

const range = (start, end) => {
	const length = end - start + 1;
	const newRange =  Array.from({ length }, (_, idx) => idx + start);
	return newRange;
};

export const usePagination = ({
	totalCount = 0,
	pageSize = 0,
	siblingCount = 1,
	currentPage = 0
}) => {

	const paginationRange = useMemo(() => {
		// total of data
		const totalPageCount = Math.ceil(totalCount / pageSize);

		// page number
		const totalPageNumbers = siblingCount + 5;
		// when totalPageNumbers more than total
		if (totalPageNumbers >= totalPageCount) {
			return range(1, totalPageCount);
			// [1,2,3,4,5,6, till, 100]
		}

		const leftSiblingIndex = Math.max(currentPage - siblingCount, 1);
		const rightSiblingIndex = Math.min(
			currentPage + siblingCount,
			totalPageCount
		);
		const shouldShowLeftDots = leftSiblingIndex > 2;
		const shouldShowRightDots = rightSiblingIndex < totalPageCount - 2;

		const firstPageIndex = 1;
		const lastPageIndex = totalPageCount;

		if (!shouldShowLeftDots && shouldShowRightDots) {
			const leftItemCount = 3 + 2 * siblingCount;
			const leftRange = range(1, leftItemCount);

			return [
				...leftRange,
				DOTS,
				totalPageCount
			];
			// [ 1, 2 , 3, 4, 5 ... , 100]
		}

		if (shouldShowLeftDots && !shouldShowRightDots) {
			const rightItemCount = 3 + 2 * siblingCount;
			const rightRange = range(
				totalPageCount - rightItemCount + 1,
				totalPageCount
			);
			// [ 1, ... , 96, 97, 98, 99, 100]
			return [
				firstPageIndex,
				DOTS,
				...rightRange
			];
		}

		if (shouldShowLeftDots && shouldShowRightDots) {
			const middleRange = range(leftSiblingIndex, rightSiblingIndex);
			return [
				firstPageIndex,
				DOTS,
				...middleRange,
				DOTS,
				lastPageIndex
			];
			// [1 ... 6,7,8, ... 100]
		}
	}, [
		totalCount,
		pageSize,
		siblingCount,
		currentPage
	]);

	return paginationRange;
};
