import { useState, useCallback, useMemo, useEffect } from 'react';
import _ from 'lodash';
import { Icons } from 'constant';
import { navigation } from 'helpers';
import { useSelector, useDispatch } from 'react-redux';
import { restaurantListSelector } from 'store/restaurant/selector';
import { collectionListSelector } from 'store/collection/selector';
import { adminsListSelector } from 'store/admins/selector';
import { utilsListSelector, } from 'store/utlis/selector';
import { faqListSelector } from 'store/faq/selector';
import { useLocation, useParams } from 'react-router-dom';
import {
	changeTableMode, addRestaurant, selectRestaurant, editRestaurant, deleteRestaurant
} from 'store/restaurant/actions';
import { addCollection, selectCollection, editCollection, deleteCollection } from 'store/collection/actions';
import { addAdmins, selectAdmins, deleteAdmins } from 'store/admins/actions';
import { addFaq } from 'store/faq/actions';
import { changeModalMode, changeSearch, resetUtils } from 'store/utlis/actions';

const routes = [
	{
		label: 'Events',
		name: 'events',
		icon: Icons.RestaurantIcon,
	},
	{
		label: 'Collections',
		name: 'collections',
		icon: Icons.CollectionsIcon,
	},
	{
		label: 'NFT Group',
		name: 'NFT-group',
		icon: Icons.CollectionsIcon,
	},

	{
		label: 'Users',
		name: 'users',
		icon: Icons.UsersIcon,
	},
	// {
	// 	label: 'Membership',
	// 	name: 'membership',
	// 	icon: Icons.UsersIcon,
	// },
	{
		label: 'Admin Referral',
		name: 'adminrefferral',
		icon: Icons.UsersIcon,
	},
	{
		label: 'Admins',
		name: 'admins',
		icon: Icons.AdminsIcon,

	},
	// {
	// 	label: 'Preview',
	// 	name: 'preview',
	// 	icon: Icons.AdminsIcon,

	// },
	{
		label: 'Statistics',
		name: 'statistics',
		icon: Icons.StatisticsIcon,
	},
	{
		label: 'Support Service',
		name: 'support-service',
		icon: Icons.SupportIcon,
		child: [
			{
				label: 'FAQ',
				name: 'faq',
				icon: Icons.SupportIcon
			}, {
				label: 'Tickets',
				name: 'tickets',
				icon: Icons.SupportIcon
			},
		]
	},

];

const useSidebar = () => {
	const navigate = navigation();
	const dispatch = useDispatch();
	const location =useLocation();
	
	const splitPathname = navigate.pathname.split('/');
	const pathname = splitPathname[1];
	const subPathname = splitPathname[2] || '';
	const [selectedPage, setSelectedPage] = useState(pathname || 'events');

	const { tableMode, fieldsEditRestaurant } = useSelector(restaurantListSelector);
	const { fieldsEditCollection } = useSelector(collectionListSelector);
	const { fieldsEditAdmins } = useSelector(adminsListSelector);
	const { modalMode, search } = useSelector(utilsListSelector);

	const getSelectedMenu = useCallback(
		item => {
			const splitPath = selectedPage.split('/');
			const path = splitPath[0];
			const subpath = splitPath[1];
			const active = item.name === path || item.name === subpath;
			return {
				bg: active && item.name !== 'support-service' ? 'bg-blueLighter' : '',
				active,
				title: `${item.label}`,
				// title: `${item.label} ${item.name === 'collections' ? 'NFTs' : ''}`,
				subpath
			};
		},
		[selectedPage],
	);

	const onClickMenu = useCallback((event, item = routes[0]) => {
		event.preventDefault();
		const resultPath = item.name === 'faq' || item.name === 'tickets' ? `support-service/${item.name}` :
			item.name === 'support-service' ? 'support-service/faq' : item.name;
		navigate.navigate(resultPath);
		setSelectedPage(resultPath);
		dispatch(resetUtils());
	}, [navigate, setSelectedPage]);
	
	

	const menuFactory = useMemo(() => {
		const splitPath = selectedPage.split('/');
		const path = splitPath[0];
		const subpath = splitPath[1] || subPathname;
		const route = routes.find(item => item.name === path);
        console.log("location.pathname*",location.pathname)
		if(location.pathname === "/addrestaurant"){
		return {  
				...route,	
			}	
			}else if(location.pathname ?location.pathname.slice(0,8)  === "/preview" : null){
				return {  
					...route,	
				}
			}else{
				return {
			        
					...route,
					label: path !== 'support-service' ? route.label.substring(0, route.label.length - 1) : subpath === 'faq' ? subpath.toUpperCase() : 'User',
					toggleMode: route.name === 'events',
					showAddButton: !(route.name === 'users' || subpath === 'tickets' ||route.name === 'collections'),
					showFilter: !(route.name === 'statistics' || (route.name === 'collections' && Boolean(subPathname)) || (route.name === 'users' && Boolean(subPathname))),
					showDetail: ((route.name === 'collections' && Boolean(subPathname)) || (route.name === 'users' && Boolean(subPathname)))
					
				}	
				
			}

	}, [selectedPage, subPathname]);

	const onChangeTableMode = useCallback(
		type => {
			dispatch(changeTableMode(type));
		},
		[dispatch],
	);

	const onChangeModalMode = useCallback(
		type => {
			dispatch(changeModalMode(type));
			if (!_.isEmpty(fieldsEditRestaurant)) {
				dispatch(selectRestaurant());
			} else if (!_.isEmpty(fieldsEditAdmins)) {
				dispatch(selectAdmins());
			} else if (!_.isEmpty(fieldsEditCollection)) {
				dispatch(selectCollection());
			}
		},
		[dispatch, fieldsEditRestaurant, fieldsEditAdmins, fieldsEditCollection],
	);

	const onAddData = useCallback(
		payload => {
			if (selectedPage === 'events') {
				dispatch(addRestaurant(payload));
			} else if (selectedPage === 'admins') {
				dispatch(addAdmins(payload));
			} else if (selectedPage === 'collections') {
				dispatch(addCollection(payload));
			} else if (selectedPage === 'support-service') {
				dispatch(addFaq(payload));
			}
		},
		[dispatch, selectedPage],
	);
	const oneEditData = useCallback(
		payload => {
			if (selectedPage === 'events') {
				dispatch(editRestaurant(payload));
			} else if (selectedPage === 'admins') {
				// dispatch(editRestaurant(payload))
			} else if (selectedPage === 'collections') {
				dispatch(editCollection(payload));
			}
		},
		[dispatch, selectedPage],
	);

	const onDeleteData = useCallback(
		() => {
			if (selectedPage === 'events') {
				dispatch(deleteRestaurant());
			} else if (selectedPage === 'admins') {
				dispatch(deleteAdmins());
			} else if (selectedPage === 'collections') {
				dispatch(deleteCollection());
			}
		},
		[dispatch, selectedPage],
	);

	const modalFactory = useMemo(() => {
		const modal = {
			visible: modalMode === 'ADD' || modalMode === 'EDIT',
			mode: modalMode,
			modalTitle: 'Add',
			btntitle: 'SUBMIT',
			onSubmit: payload => onAddData(payload)
		};
		if (modalMode === 'EDIT') {
			modal.btntitle = 'SUBMIT';
			modal.modalTitle = 'Edit';
			modal.onSubmit = payload => oneEditData(payload);
		}
		return modal;
	}, [modalMode, onAddData, oneEditData]);

	const modalDeleteFactory = useMemo(() => {
		const modal = {
			visible: modalMode === 'DELETE',
			mode: modalMode,
			modalTitle: 'Delete',
			btntitle: 'DELETE',
			onSubmit: () => onDeleteData()
		};
		return modal;
	}, [modalMode, onDeleteData]);

	const onChangeSearch = event => {
		dispatch(changeSearch(event.target.value));
	};

	return {
		routes,
		selectedPage,
		getSelectedMenu,
		onClickMenu,
		menuFactory,
		tableMode,
		onChangeTableMode,
		modalFactory,
		onChangeModalMode,
		fieldsEditRestaurant,
		modalDeleteFactory,
		fieldsEditCollection,
		onChangeSearch,
		search
	};
};

export default useSidebar;