import { RegExp } from 'constant';
export const createFieldConfig = ({
	name, type, defaultValue = ''
}) => {
	return {
		value: type === 'list' ? [] : type === 'list-multi' ? [[]] : defaultValue,
		valid: false,
		errorMessage: '',
		name,
		type
	};
};
const createValidationRule = (ruleName, errorMessage, validateFunc) => {
	return {
		name: ruleName,
		message: errorMessage,
		validate: validateFunc
	};
};

export const requiredRule = inputName => {
	return createValidationRule(
		'required',
		`${inputName} required`,
		inputValue => inputValue.length !== 0
	);
};

export const minLengthRule = (inputName, minCharacters) => {
	return createValidationRule(
		'minLength',
		`${inputName} should contain atleast ${minCharacters} characters`,
		inputValue => inputValue.length >= minCharacters
	);
};

export const maxLengthRule = (inputName, maxCharacters) => {
	return createValidationRule(
		'minLength',
		`${inputName} cannot contain more than ${maxCharacters} characters`,
		inputValue => inputValue.length <= maxCharacters
	);
};

export const passwordMatchRule = () => {
	return createValidationRule(
		'passwordMatch',
		'passwords do not match',
		(inputValue, formObj) => inputValue === formObj.password.value
	);
};

export const emailRule = () => {
	return createValidationRule(
		'emailPattern',
		'Should contain email pattern ',
		inputValue => RegExp.email.test(inputValue)
	);
};
