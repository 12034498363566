import React, {  useState, useEffect } from 'react';

import { Images, Colors, Icons } from 'constant';
import { Container } from './style';
import { Text, Button, Action, Modal, SelectMenu } from 'components';
import NFTCard from 'components/Card/NFTCard';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import {getAllusers, deleteUser} from '../../services/auth';
import ModalDeactivate from '../User/ModalDeactivate';
import { Menu } from '@headlessui/react';
import {useNavigate} from 'react-router-dom';
import {Fetch, persist} from '../../services/local-storage';
import {activateUser} from '../../services/auth/index';
import { toast } from 'react-toastify';

const DetailUser = () => {

	const CurrentUser = JSON.parse(Fetch("user"));

	const [openTab, setOpenTab] = useState(1);
	const navigate = useNavigate();
	const {id} = useParams();
	const [User, setUser] = useState('');
	const [deletemodal, setDeletemodal] = useState(false);
	const [showModalDeactivate, setShowModalDeactivate] = useState(false);
	const [showModalactivate, setShowModalActivate] = useState(false);
	const [limit, setLimit] = useState('');
	const[UserNfts, setUSerNfts] = useState([]);
	const getusers = async() => {
		const res = await getAllusers();
		
		const nfts = res.data.nfts;
		const newdata= res.data.result;
		
		const filtered= newdata.filter((item) => item.userType == "user" && item._id == id)

		for(let i=0; i < filtered.length; i++ ){
		const filtered_nfts = nfts.filter((item) => filtered[i].walletAddress && filtered[i].walletAddress == item.walletAddress)
		setUSerNfts(filtered_nfts);
		let data = filtered[i]
		data['nfts']=filtered_nfts.length
		}    
		setUser(filtered[0]);
	
	}
	const deleteUsers = async() =>{
		const res = await deleteUser(User && User._id)
		
		toast("User Deleted Successfully")
		// getusers();
		// setDeletemodal(false);
		navigate('/users')
	}
	useEffect(() =>{
		getusers();
	},[])

	const activate_User = async () =>{
		const activate_ = await activateUser(User._id);
		
		window.location.reload(false);
	}

	const renderPopMoreIcon = (setDeletemodal) => {
	
		return (
		  <>
		
		  <SelectMenu
			MenuButton={<Icons.MoreIcon />}
			wrapperClassName='mr-0'
			menuItemsClassName='z-[9] w-56'
			menuBtnClassName='bg-blueLighter p-3 rounded cursor-pointer scale-[0.75]'
		  >
			
			<div className='z-10'>
			  <Menu.Item>
				{({ active }) => (
				  <Text text='Delete' className='py-3 pl-3 hover:bg-gray-400 cursor-pointer' onClick={() => {setDeletemodal(true)}} />
				)}
			  </Menu.Item>
			</div>
	  
		  </SelectMenu>
		  </>
		)
	}
	const data = {
		id: 1,
		img: Images.Profile,
		name: 'Jane Smith',
		wallet_address: 'awxq3kal01bwgw7',
		nft: [
			{
				id: 0,
				name: 'NFT 1',
				restoName: 'Resto 1',
				price: '1,234'
			},
			{
				id: 1,
				name: 'NFT 2',
				restoName: 'Resto 2',
				price: '1,234'
			},
			{
				id: 2,
				name: 'NFT 3',
				restoName: 'Resto 3',
				price: '1,234'
			},
			{
				id: 3,
				name: 'NFT 4',
				restoName: 'Resto 4',
				price: '1,234'
			},
			{
				id: 4,
				name: 'NFT 5',
				restoName: 'Resto 5',
				price: '1,234'
			},
			{
				id: 5,
				name: 'NFT 6',
				restoName: 'Resto 6',
				price: '1,234'
			},
		],
		registration_date: '01/01/2022',
		kyc_status: 'Verified',
		limitation: '2 Weeks'
	};
	const  copyAddress = () =>{
		var copyText = User.walletAddress ? User.walletAddress : "";
		navigator.clipboard.writeText(copyText);
	}

	return (
		<Container>
			<div className='h-[100px] rounded-md border px-5 pt-3 flex items-center'>
				<div className='flex items-center w-[73%]'>
					<ul className='flex items-center w-[100%] justify-between' >
						<li className='flex items-center'>
							<div className='rounded-full'>
								<img src={ data?.img } alt='collection' className='w-[60px] h-[60px]' />
							</div>
							<div className='ml-5'>
								<p className='text-darkGrey text-sm'>Name</p>
								<p className='mt-2.5'>{ User?.userName }</p>
							</div>
						</li>
						<li>
							<p className='text-darkGrey text-sm'>Wallet Address</p>
							<div className='flex justify-center mt-2.5 cursor-pointer'>
								<p className=''>{ User.walletAddress  ?User.walletAddress.slice(0,15)  : "--" }...</p>
								<Icons.CopyIcon onClick={()=>copyAddress()}/>
							</div>
						</li>
						<li>
							<p className='text-darkGrey text-sm'>Registration Date</p>
							<p className='mt-2.5'>{ moment(User.createdAt).format('DD/MM/YYYY') }</p>
						</li>
						<li>
							<p className='text-darkGrey text-sm'>KYC Status</p>
							<p className={ User.kyc_status === 'Verified' ? 'text-lime-500 font-bold mt-2.5' : 'text-red-500 font-bold mt-2.5' }>
								{ User.kyc_status }
							</p>
						</li>
					</ul>
				</div>
				<div className='flex items-center justify-end w-[27%]'>
					<ul className='flex items-center relative'>
						<li className='m-0 rounded cursor-pointer scale-[0.75] mr-2'
							onClick={ e => {
								e.stopPropagation();
							} }
						>
							{/* <Button
								backgroundColor={ 'rgba(0, 48, 81, 0.1)' }
								textColor={ Colors.darkBlue }
							>
                             Deactivate
							</Button> */}

{User?.status  == 'active' ? 
			<SelectMenu
				MenuButton={
					<button
						backgroundColor={ User?.status == 'active' ? Colors.grey.lighter : Colors.white }
						textColor={ Colors.darkBlue }
						height={ '60px' }
						onClick={ () => {
							// setLimit(item?.value);
							setShowModalDeactivate(!showModalDeactivate);	
						} }
					>
						{ User?.status  == 'active' ?
						<div className='flex '><span className='ml-10 mt-1 font-bold' style={{fontSize:"25px"}}>Deactivate</span></div>
						
							:
							<div className='flex'>
								{/* { User?.limitation } */}
								<span className='ml-10 mt-1'>Deactivated</span>
							</div> }
					</button>
				}
				menuItemsClassName='z-[9] w-56'
				menuBtnClassName='rounded cursor-pointer scale-[0.75]'
			>
			</SelectMenu>
			:<div className='px-17 text-red-500 font-bold'  onClick={() =>setShowModalActivate(true)}>Deactivated</div>
			}

          <ModalDeactivate
				visible={ showModalDeactivate }
				onClose={ () => setShowModalDeactivate(false) }
				limit={ limit }
				data={ User }
			/>

        <Modal
				visible={ showModalactivate }
				onClickClose={() => setShowModalActivate(false)}
			>
				<>
				{ CurrentUser.userType =="superadmin" ? 
				<>
					<div className='modal-input-container'>
			<img src={ Images.Deactivate } className='mx-auto mb-10' />
			<Text
				fontType='h4'
				fontFamily='Lora'
				fontWeight='700'
				fontSize='24px'
				text={` Are you sure you want to Activate ${User?.userName}?`}
				textAlign='center'
				color='var(--blue)'
				className='mb-3 join-modal-title'
			/>

			<Text
				text='lorem ipsum dolor sit amet, consectetur adipiscing elit.'
				color='var(--black200)'
				textAlign='center'
				className='mb-8'
			/>
					</div>
					<div className='btn-submit mx-auto flex justify-between'>
				<div>
					<Button
					className=''
					label='Yes'
					height={ '40px' }
					width={ '185px' }
					textColor={ '#003051' }
					backgroundColor={ '#E3E0E0' }
					onClick={() =>activate_User()}
					/>
				</div>
				<div>
					<Button
					className=''
					label='No'
					height={ '40px' }
					width={ '185px' }
					textColor={ '#003051' }
					backgroundColor={ '#E3E0E0' }
					onClick={() =>setShowModalActivate(false)}
					/>
				</div>
					</div>
				</>
				:
				<>
					<div className='modal-input-container'>
			<img src={ Images.Deactivate } className='mx-auto mb-10' />
			<Text
				fontType='h4'
				fontFamily='Lora'
				fontWeight='700'
				fontSize='24px'
				text={` Only super admin can perform this action`}
				textAlign='center'
				color='var(--blue)'
				className='mb-3 join-modal-title'
			/>
					</div>
					<div className='btn-submit  flex justify-center'>
				<div>
					<Button
					className=''
					label='Ok'
					height={ '40px' }
					width={ '185px' }
					textColor={ '#003051' }
					backgroundColor={ '#E3E0E0' }
					onClick={() =>setShowModalActivate(false)}
					/>
				</div>
				</div>
				</>
				}
				</>
        
      </Modal>


						</li>
						{renderPopMoreIcon( setDeletemodal)}
					</ul>
				</div>
			</div>
			<div className='tabmenu list-none' role='tablist'>
				<div
					key={ 1 }
					onClick={ e => {
						e.preventDefault();
						setOpenTab(1);
					} }
					className={ openTab === 1 ? 'tabmenu-list rounded mt-6 mb-8 flex w-full' : 'tabmenu-list mt-5 mb-5 mr-2 last:mr-0 flex rounded w-full' }
				>
					<a
						data-toggle='tab'
						href={ `link${1}` }
						role='tablist'
					>
						<Text
							text={ `NFTs Owned (${UserNfts?.length})` }
							fontWeight={ '800' }
							color={ openTab === 1 ? '#FFFBD1' : '#B2BABB' }
							fontSize={ '20px' }
							fontFamily={ 'Nunito' }
						/>
					</a>
				</div>
			</div>
			<div className={ openTab === 1 ? 'block' : 'hidden' } id='link1'>
				<div className='px-5 sm:px-0'>
					<div className='grid grid-cols-1 sm:grid-cols-3 lg:grid-cols-4 gap-x-[20px] gap-y-[20px] mt-5'>
						{ UserNfts?.map((item,i) => (
							<NFTCard
								key={ i }
								type={item?.NFTCollections}
								title={ item?.NFTName }
								restoName={ item?.OwnedBy }
								price={ item?.NFTPrice }
								image={item?.NFTImage}
								wrapperClass='w-full'
								isPerminted={item?.status == "sold" ? false : true}
							/>
						)) }
					</div>
				</div>
			</div>
			
			<Modal
				visible={ deletemodal }
				onClickClose={() => setDeletemodal(false)}
			>
        <div className='modal-input-container'>
          <img src={ Images.Deactivate } className='mx-auto mb-10' />
          <Text
            fontType='h4'
            fontFamily='Lora'
            fontWeight='700'
            fontSize='24px'
            text={`Do you want to Permanently delete  ${User?.userName}?`}
            textAlign='center'
            color='var(--blue)'
            className='mb-3 join-modal-title'
          />

          <Text
            text='WARNING : This action cannot be Undone!'
            color='var(--black200)'
            textAlign='center'
            className='mb-8'
          />
        </div>
        <div className='btn-submit mx-auto flex justify-between'>
          <div>
            <Button
              className=''
              label='Yes'
              height={ '40px' }
              width={ '185px' }
              textColor={ '#003051' }
              backgroundColor={ '#E3E0E0' }
              onClick={()=>deleteUsers()}
            />
          </div>
          <div>
            <Button
              className=''
              label='No'
              height={ '40px' }
              width={ '185px' }
              textColor={ '#003051' }
              backgroundColor={ '#E3E0E0' }
              onClick={() =>setDeletemodal(false)}
            />
          </div>
        </div>
      </Modal>
		</Container>
	);
};

export default React.memo(DetailUser);