import React, { useState } from 'react';
import { Button, Input, Text } from 'components';
import { Images } from 'constant';

import InputEmail from './Sections/InputEmail';
import InputPhoneNumber from './Sections/InputPhoneNumber';
import ForgotPasswordStyle from './style';

const ForgotPassword = () => {
	// const { onClickLogin, onChangeText } = useLoginPage();
	const [step, setStep] = useState(0);

	const next = () => {
		setStep(step + 1);
	};

	const prev = () => {
		setStep(step - 1);
	};

	return (
		<ForgotPasswordStyle>
			<div className='left-side'>
				<div className='left-side'>
					<Text
						text={ 'Some Encouraging Words Goes Here' }
						fontWeight={ '700' }
						className={ 'title' }
						fontSize={ '56px' }
						color={ '#FFFBD1' }
					/>
					<Text
						text={ 'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Gravida ac tempus consequat enim, sed tellus sed.' }
						fontWeight={ '300' }
						className={ 'slogan mt-6' }
						fontSize={ '20px' }
						lineHeight={ '31px' }
					/>
				</div>
			</div>
			<div className='right-side'>
				<img src={ Images.LogoCms } className='logo-cms' height={ 100 } width={ 100 } />
				<Text
					text={ 'Forgot Password' }
					fontWeight={ '700' }
					fontType={ 'h4' }
					className={ '' }
					textAlign={ 'center' }
				/>
				{ step === 0 ?
					<InputEmail
						next={ next }
					/>
					:
					<InputPhoneNumber
						prev={ prev }
					/>
				}
			</div>
		</ForgotPasswordStyle>
	);
};

export default React.memo(ForgotPassword);
