import colors from 'constant/colors';
import React, { useMemo } from 'react';
import { Line } from 'react-chartjs-2';

export const options = {
	responsive: true,
	maintainAspectRatio: true,
	plugins: { legend: { display: false, },
		tooltip: {
			enabled: false
		} },
	interaction: {
		intersect: false,
		mode: 'index',
	},
	scales: {
		y: {
			grid: {
				drawBorder: false,
				display: false,
				drawOnChartArea: true,
				drawTicks: false,
				borderDash: [5, 5]
			},
			ticks: {
				display: true,
				padding: 10,
				color: '#fbfbfb',
				font: {
					size: 11,
					family: 'Poppins-Regular',
					style: 'normal',
					lineHeight: 2
				},
			}
		},
		x: {
			grid: {
				drawBorder: false,
				display: false,
				drawOnChartArea: false,
				drawTicks: false,
				borderDash: [5, 5]
			},
			ticks: {
				display: false,
				color: '#ccc',
				padding: 20,
				font: {
					size: 11,
					family: 'Poppins-Regular',
					style: 'normal',
					lineHeight: 2
				},
			}
		},
	},

};

const LineGradient = ({ isRising = true, backgroundBottom = 'white' }) => {
	const datasetAttribute = useMemo(() => {
		return {
			tension: 0.4,
			pointRadius: 0,
			borderColor: isRising ? colors.green.dark : colors.redSalsa,
			backgroundColor: context => {
				const ctx = context.chart.ctx;
				const gradient = ctx.createLinearGradient(0, 0, 0, 65);
				if (isRising) {
					gradient.addColorStop(0, 'rgba(42, 188, 57, 1)');
					gradient.addColorStop(0.2, 'rgba(42, 188, 57, 0.8)');
					gradient.addColorStop(1, backgroundBottom);
				} else {
					gradient.addColorStop(0, 'rgba(251, 54, 64, 1');
					gradient.addColorStop(0.2, 'rgba(251, 54, 64, 0.8)');
					gradient.addColorStop(1, backgroundBottom);
				}
				return gradient;
			},
			borderWidth: 1,
			fill: true,
			maxBarThickness: 6
		};
	}, [isRising, backgroundBottom]);
	const data = useMemo(() => {
		return  {
			labels: [
				'Apr',
				'May',
				'Jun',
				'Jul',
				'Aug',
				'Sep',
				'Oct',
				'Nov',
				'Dec'
			],
			datasets: [
				{
					label: 'Transaction',
					...datasetAttribute,
					data: [
						50,
						40,
						300,
						220,
						500,
						250,
						400,
						230,
						500
					],
          
				}
			],
		};
	}, [datasetAttribute]);
	return <Line options={ options } data={ data } height={ 300 } />;
};

export default React.memo(LineGradient);
