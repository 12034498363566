import React from 'react';
import {
  StyledTextArea, ErrorText
} from './style';

const TextArea = ({
  errorMessage, children, valid, noInlineError = false, ...props
}) => {
  return (
    <>
      <StyledTextArea { ...props } />
      { !noInlineError && errorMessage  && !valid && <ErrorText>{ errorMessage }</ErrorText>  }
    </>

  );
};

export default React.memo(TextArea);