// import { Colors } from 'constant';
import styled from 'styled-components';

// interface HeaderStyleProps {

// }

const ProfilePopupStyle = styled.div`
  .menu-item {
    background: white;
  }
`;

export default ProfilePopupStyle;
