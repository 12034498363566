import styled from 'styled-components';


const ButtonStyle = styled.button`
  position: relative;
  background-color: ${props => props.disabled ? 'var(--color-button-disabled)' : props.backgroundColor ? props.backgroundColor : 'var(--blue200)'};
  border-radius: 5px;
  ${props => !props.className && 'border: none;'}
  padding: 0px 20px;
  width: 200px;
  height: 60px;
  width: ${props => props.width};
  height: ${props => props.height};
  font-family: Nunito;
  font-size: 16px;
  font-weight: 800;
  color: ${props => props.textColor ? props.textColor : 'var(--color-text-white)'}; 
  transition: .6s;
  overflow: hidden;
  cursor: pointer;

  @media screen and (max-width: 720px) {
    height: 45px;
    font-size: 14px;
  }

  /* @media screen and (min-width: 900px) {
    ${props => props.theme === 'primary' && !props.disabled && `
      ::before{
        content: '';
        display: block;
        position: absolute;
        background: rgba(255,255,255,0.3);
        width: 60px;
        height: 100%;
        left: 0;
        top: 0;
        opacity: .5;
        filter: blur(30px);
        transform: translateX(-100px)  skewX(-15deg);
      }

      ::after{
        content: '';
        display: block;
        position: absolute;
        background: rgba(255,255,255,0.1);
        width: 30px;
        height: 100%;
        left: 30px;
        top: 0;
        opacity: 0;
        filter: blur(5px);
        transform: translateX(-100px) skewX(-15deg);
      }

      :hover{
        background: ${props.bgHover ? props.bgHover : '#006cb4'};
        color: ${props.textColorHover ? props.textColorHover : props.textColor};
        cursor: pointer;

        ::before{
          transform: translateX(600px)  skewX(-15deg);  
          opacity: 0.6;
          transition: 1.2s ease;
        }
        
        ::after{
          transform: translateX(600px) skewX(-15deg);  
          opacity: 1;
          transition: 1.2s ease;
        }
      }
    `}
  } */

`;

export default ButtonStyle;