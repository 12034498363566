import * as NFTGroupAction from './actionTypes';


const initialState = {
	groupcollection:[],
	loading: false,
};

const reqgroupcollection = (state, payload) => ({
    ...state,
	groupcollection: payload,
	loading: true
   
});


const groupcollectionReducer = (state = initialState,action)=>{
    {console.log(state,action)}
    switch(action.type){
        case NFTGroupAction.GET_GROUPCOLLECTION_LIST:
            return reqgroupcollection(state,action.payload);
        default:
			return state;
    }
}

export default groupcollectionReducer;