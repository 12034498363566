import { compose, applyMiddleware, legacy_createStore as createStore, combineReducers } from 'redux';
import { routerMiddleware, connectRouter } from 'connected-react-router';
import thunk from 'redux-thunk';

import { History as history } from '../helpers';
import restaurantReducer from './restaurant/reducer';
import adminsReducer from './admins/reducer';
import utilReducer from './utlis/reducer';
import collectionReducer from './collection/reducer';
import groupcollectionReducer from './nftgroup/reducer'
import refferalcollectionReducer from './refferal/reducer';
import userReducer from './user/reducer';
import faqReducer from './faq/reducer';
import ticketsReducer from './tickets/reducer';
import walletConnectReducer from 'redux/Reducer';

const routeMiddleware = routerMiddleware(history);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const rootReducer = combineReducers({
	restaurant: restaurantReducer,
	admins: adminsReducer,
	utils: utilReducer,
	collections: collectionReducer,
	groupcollection:groupcollectionReducer,
	refferalaction:refferalcollectionReducer,
	user: userReducer,
	faq: faqReducer,
	tickets: ticketsReducer,
	router: connectRouter(history),
	WalletConnect:walletConnectReducer,
});

const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk, routeMiddleware)));

export { history, store };
