import * as TicketsActionTypes from './actionTypes';
import { Images } from 'constant';

const initialState = {
  tickets: [
    {
      id: 0,
      img: Images.Profile,
      name: 'Jane Smith',
      email: 'janesmith@gmail.com',
      categories: 'Wallet Problem',
      question: 'Please help, I can’t connect my phantom wallet',
      duration: '2 hours',
      status: 'open'
    },
    {
      id: 1,
      img: Images.Profile,
      name: 'Jane Smith',
      email: 'janesmith@gmail.com',
      categories: 'Wallet Problem',
      question: 'Please help, I can’t connect my phantom wallet',
      duration: '2 hours',
      status: 'open'
    },
    {
      id: 2,
      img: Images.Profile,
      name: 'Jane Smith',
      email: 'janesmith@gmail.com',
      categories: 'Wallet Problem',
      question: 'Please help, I can’t connect my phantom wallet',
      duration: '2 hours',
      status: 'open'
    },
    {
      id: 3,
      img: Images.Profile,
      name: 'Jane Smith',
      email: 'janesmith@gmail.com',
      categories: 'Wallet Problem',
      question: 'Please help, I can’t connect my phantom wallet',
      duration: '2 hours',
      status: 'open'
    },
    {
      id: 4,
      img: Images.Profile,
      name: 'Jane Smith',
      email: 'janesmith@gmail.com',
      categories: 'Wallet Problem',
      question: 'Please help, I can’t connect my phantom wallet',
      duration: '2 hours',
      status: 'open'
    },
    {
      id: 5,
      img: Images.Profile,
      name: 'Jane Smith',
      email: 'janesmith@gmail.com',
      categories: 'Wallet Problem',
      question: 'Please help, I can’t connect my phantom wallet',
      duration: '2 hours',
      status: 'open'
    },
    {
      id: 6,
      img: Images.Profile,
      name: 'Jane Smith',
      email: 'janesmith@gmail.com',
      categories: 'Wallet Problem',
      question: 'Please help, I can’t connect my phantom wallet',
      duration: '2 hours',
      status: 'resolved'
    },
    {
      id: 7,
      img: Images.Profile,
      name: 'Jane Smith',
      email: 'janesmith@gmail.com',
      categories: 'Wallet Problem',
      question: 'Please help, I can’t connect my phantom wallet',
      duration: '2 hours',
      status: 'resolved'
    },
    {
      id: 8,
      img: Images.Profile,
      name: 'Jane Smith',
      email: 'janesmith@gmail.com',
      categories: 'Wallet Problem',
      question: 'Please help, I can’t connect my phantom wallet',
      duration: '2 hours',
      status: 'open'
    },
    {
      id: 9,
      img: Images.Profile,
      name: 'Jane Smith',
      email: 'janesmith@gmail.com',
      categories: 'Wallet Problem',
      question: 'Please help, I can’t connect my phantom wallet',
      duration: '2 hours',
      status: 'open'
    },
    {
      id: 10,
      img: Images.Profile,
      name: 'Jane Smith',
      email: 'janesmith@gmail.com',
      categories: 'Wallet Problem',
      question: 'Please help, I can’t connect my phantom wallet',
      duration: '2 hours',
      status: 'open'
    },
    {
      id: 11,
      img: Images.Profile,
      name: 'Jane Smith',
      email: 'janesmith@gmail.com',
      categories: 'Wallet Problem',
      question: 'Please help, I can’t connect my phantom wallet',
      duration: '2 hours',
      status: 'open'
    },
  ],
  loading: false,
//   tableModeAdmins: 'LIST',
//   selectedAdmins: {},
//   fieldsEditAdmins: {}
};

const reqTickets = (state) => ({
  ...state,
  loading: true
});

const setTickets = (state, payload) => ({
  ...state,
  tickets: payload.data,
  loading: false
});


const ticketsReducer = (state = initialState, action) => {
  switch (action.type) {
    case TicketsActionTypes.GET_TICKETS_LIST:
      return reqTickets(state);
    case TicketsActionTypes.GET_TICKETS_LIST_SUCCESS:
      return setTickets(state, action.payload);
    default:
      return state;
  }
};

export default ticketsReducer;
