import styled from 'styled-components';
import { Icons } from 'constant';
import dateIcon from 'assets/icons/date.svg';
import colors from 'constant/colors';

const Container = styled.div`

`;
const ModalWrapper = styled.div`
    .input-name {
        min-width: 230px !important;
        height: 40px ;
        padding-left: 9px ;
        &::placeholder {
        padding-left: 0 !important ;
        }

        &:disabled {
            background: ${colors.grey.lighter} !important ;
            cursor: not-allowed ;
        }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    }

    /* Firefox */
    input[type=number] {
    -moz-appearance: textfield;
    }
    .input-amount {
        min-width: 230px !important;
        height: 40px ;
        padding-left: 5px ;
        &::placeholder {
        padding-left: 0 !important ;
        }
    }
    // .icon-sol {
    //   background: url(${Icons.polygon}) no-repeat scroll 10px 10px;
    // }
    .container-label {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .container-label input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
    }

    .checkmark {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #fff;
        border-radius: 50%;
        z-index: 9999 ;
        border: 1px solid ${colors.blue.prussianBlue} ;
    }

    .container-label:hover input ~ .checkmark {
        background-color: ${colors.blue.prussianBlue};
    }

    .container-label input:checked ~ .checkmark {
        background-color: ${colors.blue.prussianBlue};
        border: none ;
    }

    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    .container-label input:checked ~ .checkmark:after {
        display: block;
    }

    .container-label .checkmark:after {
        top: 9px;
        left: 9px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: white;
    }
    .input-datepicker {
        width: 190px !important;
        height: 40px ;
        padding-left: 20px ;
        &::placeholder {
            padding-left: 0 !important ;
        }
    }
    .input-wrapper:focus-within {
    label {
      font-weight: bold ;
      color: ${colors.prussianBlue} ;
    }
    .input-name {
        &:focus {
        outline: 1px solid ${colors.blue.prussianBlue} ;
        border-color: transparent ;
      }
    }
    .input-datepicker {
        &:focus {
        outline: 1px solid ${colors.blue.prussianBlue} ;
        border-color: transparent ;
      }
    }
    textarea:focus {
      outline: 1px solid ${colors.blue.prussianBlue} ;
      border-color: transparent ;
    }
  }
  .icon-date {
      background: url(${dateIcon}) no-repeat scroll 155px 11px;
      background-size: 16px 16px;
    }
`;

const StepWrapper = styled.div`
     .FinancialCriteria {
    left: -45px !important ;
  }
`;

export { Container, ModalWrapper, StepWrapper };