import React, { useState } from 'react';
import { useNavigate } from "react-router-dom";
import { Button, Input, Text, Modal } from 'components';
import { Images } from 'constant';
import { deactivateUser } from 'services/auth';
import {Fetch, persist} from '../../../services/local-storage';

const ModalDeactivate = ({ visible, onClose, limit, data }) => {
  const CurrentUser = JSON.parse(Fetch("user"));

	const navigate = useNavigate();
  const deactivate = async() =>{
    const action_ = await deactivateUser(data._id);
    console.log("action_:",action_);
    onClose();
    window.location.reload(false);
  }


  return (
    <>
      <Modal
        visible={ visible }
        onClickClose={() => onClose()}
      >
        <>
        {CurrentUser.userType =="superadmin" ? 
        <>
        <div className='modal-input-container'>
          <img src={ Images.Deactivate } className='mx-auto mb-10' />
          <Text
            fontType='h4'
            fontFamily='Lora'
            fontWeight='700'
            fontSize='24px'
            text={` Are you sure you want to Deactivate ${data?.userName}?`}
            textAlign='center'
            color='var(--blue)'
            className='mb-3 join-modal-title'
          />

          <Text
            text='lorem ipsum dolor sit amet, consectetur adipiscing elit.'
            color='var(--black200)'
            textAlign='center'
            className='mb-8'
          />
        </div>
        <div className='btn-submit mx-auto flex justify-between'>
          <div>
            <Button
              className=''
              label='Yes'
              height={ '40px' }
              width={ '185px' }
              textColor={ '#003051' }
              backgroundColor={ '#E3E0E0' }
              onClick={() =>deactivate()}
            />
          </div>
          <div>
            <Button
              className=''
              label='No'
              height={ '40px' }
              width={ '185px' }
              textColor={ '#003051' }
              backgroundColor={ '#E3E0E0' }
              onClick={onClose}
            />
          </div>
        </div>
        </>
        :
        <>
        <div className='modal-input-container'>
          <img src={ Images.Deactivate } className='mx-auto mb-10' />
          <Text
            fontType='h4'
            fontFamily='Lora'
            fontWeight='700'
            fontSize='24px'
            text={`Only super admin can perform this action`}
            textAlign='center'
            color='var(--blue)'
            className='mb-3 join-modal-title'
          />

          <Text
            text='lorem ipsum dolor sit amet, consectetur adipiscing elit.'
            color='var(--black200)'
            textAlign='center'
            className='mb-8'
          />
        </div>
        <div className='btn-submit  flex justify-center'>
          <div>
            <Button
              className=''
              label='Ok'
              height={ '40px' }
              width={ '185px' }
              textColor={ '#003051' }
              backgroundColor={ '#E3E0E0' }
              onClick={onClose}
            />
          </div>
        </div>
        </>
        }
        </>
      </Modal>
    </>
  );
};

export default React.memo(ModalDeactivate);
