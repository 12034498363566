import React from 'react';
import {
	Routes,
	Route,
	BrowserRouter,
	Outlet,
} from 'react-router-dom';

import {
	Restaurant,
	Collection,
	User,
	Admin,
	Membership,
	Statistic,
	Faq,
	Ticket,
	LoginPage,
	ForgotPassword,
	CreateNewPassword,
	DetailCollection,
	DetailUser,
	AddRestaurant,
	NFTgroup,
	NFTgroupDetail,
	AdminRefferral,
	EventDetail
} from 'pages';
import { Text, Sidebar } from 'components';
import { Globalstyle } from 'constant';

import PrivateRoute from './PrivateRoute';

const AppRouter = () => {
	return (
		<BrowserRouter window={ window }>
			<Globalstyle />
			<Routes>
				{ /* <Route element={ <PrivateRoute /> }>
          <Route path='/login' element={ <LoginPage /> } />
        </Route> */ }
				<Route path='/login' element={ <LoginPage /> } />
				<Route path='/forgot-password' element={ <ForgotPassword /> } />
				<Route path='/create-new-password/:id' element={ <CreateNewPassword /> } />
				<Route element={ <PrivateRoute /> }>
					<Route element={ <Sidebar><Outlet /></Sidebar> }>

						<Route path='/' element={ <Restaurant /> } />
						<Route path='/events'  element={ <Restaurant /> } />
						<Route path='/preview/:id' element={ <EventDetail /> } />
						<Route path='/collections'>
							<Route path='' element={ <Collection /> } />
							<Route path=':id' element={ <DetailCollection /> } />
						</Route>

						<Route path='/addrestaurant' element={<AddRestaurant /> }/>

						<Route path='/NFT-group'>
							<Route path='' element={ <NFTgroup /> } />
							<Route path=':id' element={ <NFTgroupDetail /> } />
						</Route>

						<Route path='/adminrefferral' element={<AdminRefferral />} /> 

						<Route path='/admins' element={ <Admin /> } />
						<Route path='/users'>
							<Route path='' element={ <User /> } />
							<Route path=':id' element={ <DetailUser /> } />
						</Route>
						{/* <Route path='/membership' element={ <Membership /> } /> */}
						<Route path='/statistics' element={ <Statistic /> } />
						<Route path='/support-service' >
							<Route path='faq' element={ <Faq /> } />
							<Route path='tickets' element={ <Ticket /> } />
						</Route>
					</Route>
					
				</Route>
				<Route path='*' element={ <Text>404 NOT FOUND</Text> } />
			</Routes>
		</BrowserRouter >
	);
};

export default AppRouter;
