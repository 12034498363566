import React from 'react';
import {
  Input, ErrorText, InputType
} from './style';

const TextField = ({
  errorMessage, children, valid, noInlineError = false, ...props
}) => {
  return (
    <>
      <Input { ...props } />
      { !noInlineError && errorMessage && !valid && <ErrorText>{ errorMessage }</ErrorText> }
    </>
  );
};

export default React.memo(TextField);