import { createSelector } from 'reselect';

const adminsSelector = state => {
	return {
		...state.admins,
		search: state.utils.search
	};
};

const adminsListSelector = createSelector(adminsSelector, admins => {
	return {
		...admins,
		admins: admins
	};
});

export { adminsListSelector };