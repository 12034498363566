import styled from 'styled-components';
import { Images } from 'constant';

const LandingPageStyle = styled.div`
  display: flex;
  height: 100vh;
  background: #FBFDFF;

  .left-side {
    width: 800px;
    height: 100%;
    background-image: url(${Images.LoginBg})
  }

  .title {
    color: #FFFBD1;
    padding: 0 80px 0 80px;
    position: relative;
    top: 60%;
  }

  .right-side {
    margin: auto;
  }

  .slogan {
    padding: 0 80px 0 80px;
    color: white;
    position: relative;
    top: 60%;
  }

  .logo-cms {
    display: block;
      margin: 0 auto;
  }

  .login-form {
    width: 400px;
    background: white;
    padding: 30px;
    box-shadow: 2px 4px 10px rgba(0, 0, 0, 0.08);
    border-radius: 5px;
    margin: auto;
  }
`;

export default LandingPageStyle;
