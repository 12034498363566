import colors from 'constant/colors';
import styled from 'styled-components';

const Container = styled.div`
    h1,
p {
  font-family: Lato;
}
table {
  width: 100%;
  border-collapse: collapse;
  position: relative;
}

thead tr th {
  font-weight: 800;
  text-align: left;
  background-color: ${colors.white.lighter};
}

th {
    padding: 20px ;
}
td {
  padding: 20px;
  overflow-wrap: break-word;
}

.pagination-bar {
  width: 100%;
  display: flex;
  justify-content: center;
}

.pagination-container {
  display: flex;
  list-style-type: none;
  visibility: ${props => props.isEmpty ? 'hidden' : 'visible'} ;
.pagination-item {
  padding: 0 12px;
  height: 32px;
  text-align: center;
  margin: auto 4px;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  box-sizing: border-box;
  align-items: center;
  letter-spacing: 0.01071em;
  border-radius: 5px;
  line-height: 1.43;
  font-size: 13px;
  min-width: 32px;
}
.arrow::before {
  position: relative;
  content: '';
  display: block;
  width: 0.4em;
  height: 0.4em;
  border-right: 0.12em solid rgba(0, 0, 0, 0.87);
  border-top: 0.12em solid rgba(0, 0, 0, 0.87);
}

.arrow.left {
  transform: rotate(-135deg) translate(-50%);
}
.arrow.right {
  transform: rotate(45deg);
}

.disabled {
  pointer-events: none;
}
.selected {
  background-color: rgba(0, 0, 0, 0.08);
}
}`;
export { Container };