export default {
	nunitoBold: 'Nunito-Bold',
	nunitoSemiBold: 'Nunito-SemiBold',
	nunitoMedium: 'Nunito-Medium',
	nunitoExtraBold: 'Nunito-ExtraBold',
	nunitoRegular: 'Nunito-Regular',
	nunito: 'Nunito',
	sizes: {
		xl: '56px',
		l: '48px',
		l2: '40px',
		m2: '32px',
		m1: '24px',
		m: '20px',
		s2: '18px',
		s: '16px',
		xs: '14px',
		xs2: '12px',
		xxs: '11px',
	},
};
