import * as AdminsActionTypes from './actionTypes';

export const getAdminsLoading = (payload) => ({
	type: AdminsActionTypes.GET_ADMINS_LIST,
	payload,
});

export const getAdminsSuccess = payload => ({
	type: AdminsActionTypes.GET_ADMINS_LIST_SUCCESS,
	payload,
});

export const changeTableModeAdmins = payload => ({
	type: AdminsActionTypes.CHANGE_TABLE_MODE,
	payload,
});

export const addAdmins = payload => ({
	type: AdminsActionTypes.ADD_ADMINS,
	payload,
});

export const editAdmins = payload => ({
	type: AdminsActionTypes.EDIT_ADMINS,
	payload,
});

export const deleteAdmins = () => ({
	type: AdminsActionTypes.DELETE_ADMINS,
});

export const selectAdmins = payload => ({
	type: AdminsActionTypes.SELECT_ADMINS,
	payload,
});

export const getAdmins = dispatch => params => {
	// return new Promise(async(resolve, reject) => {
	// 	dispatch(getRestaurantLoading());

	// 	const response = await productServices.getRestaurant(params);

	// 	const { stat_code, data, stat_msg, pagination } = response;

	// 	if (stat_code === 200) {
	// 		resolve(dispatch(getRestaurantSuccess({ data,
	// 			pagination })));
	// 	} else {
	// 		reject(stat_msg);
	// 	}
	// });
};
