
import * as CollectionActionTypes from './actionTypes';

export const getCollectionLoading = () => ({
	type: CollectionActionTypes.GET_COLLECTION_LIST,
});


export const addCollection = payload => ({
	type: CollectionActionTypes.ADD_COLLECTION,
	payload,
});

export const selectCollection = payload => ({
	type: CollectionActionTypes.SELECT_COLLECTION,
	payload,
});

export const editCollection = payload => ({
	type: CollectionActionTypes.EDIT_COLLECTION,
	payload,
});

export const deleteCollection = () => ({
	type: CollectionActionTypes.DELETE_COLLECTION,
});
