import { createSelector } from 'reselect';

const faqSelector = state => {
	return {
		...state.faq,
		search: state.utils.search
	};
};

const faqListSelector = createSelector(faqSelector, faq => {
	return {
		...faq,
		faq: faq.faq.filter(item => item.question.includes(faq.search))
	};
});

export { faqListSelector };