import {
	Chart as ChartJS,
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
	BarElement,
} from 'chart.js';
import LineGradient from './LineGradient';
import MultiaxisLine from './MultiaxisLine';
import BarChart from './BarChart';
import Line from './Line';

ChartJS.register(
	CategoryScale,
	LinearScale,
	PointElement,
	LineElement,
	Title,
	Tooltip,
	Legend,
	Filler,
	BarElement
);

export default Object.assign({}, { MultiaxisLine, LineGradient, Line, BarChart });