import {
	createFieldConfig, requiredRule, minLengthRule, maxLengthRule, emailRule
} from 'helpers';

export const loginField = {
	email: {
		...createFieldConfig({
			name: 'email',
			type: 'email'
		}),
		validationRules: [
			requiredRule('email'),
			minLengthRule('email', 10),
			maxLengthRule('email', 25),
			emailRule()
		]
	},
	password: {
		...createFieldConfig({
			name: 'password',
			type: 'password'
		}),
		validationRules: [
			requiredRule('password'),
			minLengthRule('password', 8),
			maxLengthRule('password', 20)
		]
	},
};

export const restaurantField = {
	photo: {
		...createFieldConfig({
			name: 'photo',
			type: 'text'
		}),
		validationRules: []
	},
	name: {
		...createFieldConfig({
			name: 'name',
			type: 'text'
		}),
		validationRules: [requiredRule('Name'),]
	},
	website: {
		...createFieldConfig({
			name: 'website',
			type: 'text'
		}),
		validationRules: [requiredRule('Website'),]
	},
	description: {
		...createFieldConfig({
			name: 'description',
			type: 'text'
		}),
		validationRules: [requiredRule('Description'),]
	},
	country: {
		...createFieldConfig({
			name: 'country',
			type: 'dropdown'
		}),
		validationRules: [requiredRule('Country'),]
	},
	city: {
		...createFieldConfig({
			name: 'city',
			type: 'dropdown'
		}),
		validationRules: [requiredRule('City'),]
	},
	address: {
		...createFieldConfig({
			name: 'address',
			type: 'text'
		}),
		validationRules: [requiredRule('Address'),]
	},
	collections: {
		...createFieldConfig({
			name: 'collections',
			type: 'list'
		}),
		shouldUpdate: false,
		validationRules: [requiredRule('Collections'),]
	},
	twitter: {
		...createFieldConfig({
			name: 'twitter',
			type: 'text'
		}),
		validationRules: [requiredRule('Twitter'),]
	},
	linkedin: {
		...createFieldConfig({
			name: 'linkedin',
			type: 'text'
		}),
		validationRules: [requiredRule('Linkedin'),]
	},
	instagram: {
		...createFieldConfig({
			name: 'instagram',
			type: 'text'
		}),
		validationRules: [requiredRule('Instagram'),]
	},
	discord: {
		...createFieldConfig({
			name: 'discord',
			type: 'text'
		}),
		validationRules: [requiredRule('Discord'),]
	},
	financialCriteria: {
		...createFieldConfig({
			name: 'financialCriteria',
			type: 'dropdown'
		}),
		validationRules: [requiredRule('Financial Criteria'),]
	},
};

export const adminsField = {
	email: {
		...createFieldConfig({
			name: 'email',
			type: 'email'
		}),
		validationRules: [
			requiredRule('email'),
			minLengthRule('email', 10),
			maxLengthRule('email', 25),
			emailRule()
		]
	},
	name: {
		...createFieldConfig({
			name: 'name',
			type: 'text'
		}),
		validationRules: [requiredRule('name'),]
	},
	phone: {
		...createFieldConfig({
			name: 'website',
			type: 'text'
		}),
		validationRules: [requiredRule('phone'),]
	},
	access: {
		...createFieldConfig({
			name: 'access level',
			type: 'dropdown'
		}),
		validationRules: [requiredRule('access'),]
	},
};

export const collectionField = {
	photo: {
		...createFieldConfig({
			name: 'photo',
			type: 'text'
		}),
		validationRules: []
	},
	name: {
		...createFieldConfig({
			name: 'name',
			type: 'text'
		}),
		validationRules: [requiredRule('Name'),]
	},
	description: {
		...createFieldConfig({
			name: 'description',
			type: 'text'
		}),
		validationRules: [requiredRule('Description'),]
	},
	restaurants: {
		...createFieldConfig({
			name: 'restaurants',
			type: 'list'
		}),
		shouldUpdate: false,
		validationRules: [requiredRule('Restaurants'),]
	},
	tier: {
		...createFieldConfig({
			name: 'tier',
			type: 'list-multi'
		}),
		shouldUpdate: false,
		validationRules: [requiredRule('Tier'),]
	},
	releaseDate: {
		...createFieldConfig({
			name: 'releaseDate',
			type: 'text'
		}),
		validationRules: [requiredRule('Release Date'),]
	},
	finishDate: {
		...createFieldConfig({
			name: 'finishDate',
			type: 'text'
		}),
		validationRules: [requiredRule('Finish Date'),]
	},
};

export const faqField = {
	categories: {
	  ...createFieldConfig({
		name: 'categories',
		type: 'dropdown'
	  }),
	  validationRules: [requiredRule('categories'),]
	},
	question: {
	  ...createFieldConfig({
		name: 'question',
		type: 'text'
	  }),
	  validationRules: [requiredRule('question'),]
	},
	answer: {
	  ...createFieldConfig({
		name: 'answer',
		type: 'text'
	  }),
	  validationRules: [requiredRule('answer'),]
	},
  };