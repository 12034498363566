import { Menu, Transition } from '@headlessui/react';
import React, {
  Fragment
} from 'react';

const SelectMenu = ({
  children,
  MenuButton,
  wrapperClassName = 'mr-[80px]',
  menuClassName,
  menuItemsClassName = 'w-56',
  menuBtnClassName = 'inline-flex w-full justify-center rounded-md  bg-opacity-20 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
  onEnter,
  onLeave
}) => {

  return (
    <div className={ wrapperClassName }>
      <Menu as='div' className={ `relative inline-block text-left ${menuClassName}` }>
        <div>
          <Menu.Button className={ menuBtnClassName }>
            { MenuButton }
          </Menu.Button>
        </div>
        <Transition
          as={ Fragment }
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
          afterEnter={onEnter }
          afterLeave={onLeave }
        >
          <Menu.Items className={ `absolute right-0 mt-2 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none pt-px pb-p ${menuItemsClassName}` }>
            { children }
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
};

export default SelectMenu;