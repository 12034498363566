import * as RefferalAction from './actionTypes';


const initialState = {
	refferalaction:[],
	loading: false,
};

const reqrefferalcollection = (state, payload) => ({
    ...state,
	refferalaction: payload,
	loading: true
   
});


const refferalcollectionReducer = (state = initialState,action)=>{
    {console.log(state,action)}
    switch(action.type){
        case RefferalAction.GET_REFERAL_LIST:
            console.log("reqrefferalcollection paylaod:", action.payload)
            return reqrefferalcollection(state,action.payload);
        default:
			return state;
    }
}

export default refferalcollectionReducer;