import React, { useCallback , useState, useEffect} from 'react';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Menu } from '@headlessui/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Table, Text, Tooltip, Action, SelectMenu, Button, Modal } from 'components';
import { Container } from './style';
import {  Colors, Icons, Images  } from 'constant';
import { navigation } from 'helpers';

import {useNavigate} from 'react-router-dom';

import { collectionListSelector } from 'store/collection/selector';
import { selectCollection } from 'store/collection/actions';
import { changeModalMode } from 'store/utlis/actions';
import {listCollections,getNewCollections} from '../../services/restaurant';
import {Fetch, persist} from '../../services/local-storage'; 

import {deleteNftCollections} from '../../services/restaurant';
const columns = (onClickAction, setDeletemodal, setNewItem, getlistofcollections, CurrentUser) => {
	return [
		
		{
			label: 'Collection Info',
			key: 'name',
			dataIndex: 'name',
			render: item => {
				return (
					
					<div className='flex items-center h-10 '>
						<div className='flex w-10 h-10 mr-5 bg-blueLighter rounded-full relative overflow-hidden justify-center items-center'>
							<img src={`${process.env.REACT_APP_S3_URL}${ item.NFTCollectionsimage }`} className='block m-auto absolute' alt='oku-group' />
						</div>
						<span>{ item.NFTCollections}</span>
					</div>
				);
			}
		},
		{
			label: 'Events',
			key: 'Events',
			dataIndex: 'Events',
			render: item => {
				return (
					<Tooltip title={ `${item.EventName}`}>
						{/* {
							item.restaurantList.map(data => {
								return (
									<div className='py-1' key={ data.id }>
										<Menu.Item>
											{ () => (
												<div className='flex flex-1 justify-between items-center px-5 py-2 hover:bg-gray-300'>
													<div className='flex items-center space-x-3'>
														<Text text={ data.name } className='py-3 pl-3' />
													</div>
												</div>
											) }
										</Menu.Item>
									</div>
								);
							})
						} */}
					</Tooltip>
				);
			}
		},
		{
			label: 'TIER',
			key: 'Tier',
			dataIndex: 'Tier',
			render: item => {
				return (
					<Tooltip title={ `${item.NFTTier}` }>
						{/* {
							item.nftList.map(data => {
								return (
									<div className='py-1' key={ data.id }>
										<Menu.Item>
											{ () => (
												<div className='flex flex-1 justify-between pointer items-center px-5 py-2 hover:bg-gray-300'>
													<div className='flex items-center space-x-3'>
														<Text text={ data.name } className='py-3 pl-3 ' />
													</div>
												</div>
											) }
										</Menu.Item>
									</div>
								);
							})
						} */}
					</Tooltip>
				);
			}
		},
		{
			label: 'Release Date',
			key: 'releaseDate',
			dataIndex: 'releaseDate',
			render: item => {
				return (
					<span className='leading-5'>{ moment(item.createdAt).format('DD/MM/YYYY') }</span>
				);
			}
		},
		// {
		// 	label: 'Finish Date',
		// 	key: 'finishDate',
		// 	dataIndex: 'finishDate',
		// 	render: item => {
		// 		return (
		// 			<span className='leading-5'>{ moment(item.NFTEndDate).format('DD/MM/YYYY') }</span>
		// 		);
		// 	}
		// },
		{
			label: 'Action',
			key: 'operation',
			dataIndex: 'action',
			render: item => {
				return (
					<>
					{CurrentUser?.userType == "superadmin" ?
					<ul className='flex items-center relative left-[-7px]'>
						{/* <li className='m-0 bg-blueLighter p-3.5 rounded cursor-pointer scale-[0.75] mr-2'
							onClick={ e => {
								e.stopPropagation();
							} }
						>
							<Icons.PencilIcon className='w-5 h-5' />
						</li> */}
						{/* <li className='m-0 bg-blueLighter p-3.5 rounded cursor-pointer scale-[0.75] mr-2'
							onClick={ e => {
								e.stopPropagation();
							} }
						>
							<Icons.SearchIcon className='w-5 h-5' />
						</li> */}
						<li className='m-0'
							onClick={ e => {
								e.stopPropagation();
							} }>
							{renderPopMoreIcon(item, onClickAction, setDeletemodal, setNewItem)}
						</li>
					</ul>
					:null}
					</>
				);
			},
		}
	];
};
const deleteCollections = async(nftitem) => {
	
	const values = {
		OwnedBy: nftitem.OwnedBy,
		tier:nftitem.NFTCollections
	}
	
	try{
		const _delete= await deleteNftCollections(values)
		if(_delete.data.status){
			toast("Collection deleted");
		}else{
			toast("This Collection cannot be deleted");
		}
		
		// setDeletemodal(false);
		// getlistofcollections();
	}catch(err){
		console.log("catcherror:", err)
	}
	
}
const renderPopMoreIcon = (item, onClickAction, setDeletemodal, setNewItem) => {
	
	return (
	  <>
	  
	  <SelectMenu
		MenuButton={<Icons.MoreIcon />}
		wrapperClassName='mr-0'
		menuItemsClassName='z-[9] w-56'
		menuBtnClassName='bg-blueLighter p-3 rounded cursor-pointer scale-[0.75]'
	  >
		{/* <div className='z-10'>
		  <Menu.Item>
			{( { active }) => (
			  <Text text='Edit'  className='py-3 pl-3 hover:bg-gray-400 cursor-pointer' onClick={() => onClickAction('EDIT', item)} />
			)}
		  </Menu.Item>
		</div> */}
		<div className='z-10'>
		  <Menu.Item>
			{({ active }) => (
			  <Text text='Delete' className='py-3 pl-3 hover:bg-gray-400 cursor-pointer' onClick={() => {setNewItem(item);setDeletemodal(true)}} />
			)}
		  </Menu.Item>
		</div>
  
	  </SelectMenu>
	  </>
	)
  }
 

const Collection = () => {
	const CurrentUser = JSON.parse(Fetch("user"));
	const [deletemodal, setDeletemodal] = useState(false);
	const [newitem,setNewItem] = useState('')
	const navigate = useNavigate();
	const redcollections  = useSelector(collectionListSelector);
	
	const [collections, setCollections] = useState([]);
	const dispatch = useDispatch();
	const getlistofcollections = async() => {
	
		const res = await getNewCollections()
		const value=res.data.result;
		
		setCollections(res.data.result)
		dispatch({
			type:"GET_COLLECTION_LISTCollection",
			payload:res.data.result,
		})
	}
	useEffect(() => {
		getlistofcollections();
	},[])
	const onClickAction = useCallback(
		(mode, item) => {
		  dispatch(selectRestaurant(item))
		  dispatch(changeModalMode(mode))
		},
		[dispatch],
	  )

	
	  
	

	const navigateToDetail = (item) =>{
		console.log("navigateToDetail=>",item)
		navigate(`/preview/${item.EventId}`);
	}
  
	return (
		<>
		<Container>
			<Table
				columns={ columns(onClickAction,setDeletemodal, setNewItem, getlistofcollections, CurrentUser) }
				dataSource={  redcollections.collections.collections }
				tableRowClassname='cursor-pointer'
				onClickRow={ item => navigateToDetail(item) }
			/>
			{/* <ToastContainer /> */}
		</Container>

		<Modal visible={deletemodal} onClickClose={() =>setDeletemodal(false)}>
			<div>
		
        <Text text='Are you sure you want to delete this Collection?' className='py-5' fontSize='18px' />
        <div className='flex justify-end items-center space-x-2'>
          <Button 
            label='Yes' 
            width='80px' 
            height='35px'
			backgroundColor='white'
            textColor={ Colors.prussianBlue }
            className='border-solid border-2 border-prussianBlue'
            onClick={() => {
				deleteCollections(newitem).then(() =>{
					setDeletemodal(false);
					getlistofcollections();
				})
				
              // modalDeleteFactory.onSubmit()
            //   onChangeModalMode('INIT')
            }}
            />
          <Button 
            label='No' 
            width='80px' 
            height='35px'
            backgroundColor='white'
            textColor={ Colors.prussianBlue }
            className='border-solid border-2 border-prussianBlue'
            onClick={() => {
              setDeletemodal(false)
            }}
          />
        </div>
		{/* </Modal> */}
      </div>
		</Modal>
		</>
	);
};

export default React.memo(Collection);