import styled from "styled-components";

const WalletStyles = styled.div`
	
	padding-bottom: 80px;
	// padding-top: 80px;
	margin: auto;
	display: flex;
	flex-direction: column;
	justify-content: center;
	overflow: hidden;
	background: white;

    .card {
        background: white;
        border-radius: 10px;
        backdrop-filter: blur(100px);
        display: flex;
        box-sizing: border-box;
        max-width: 100%;
        margin-top: 5px;
        // width: 540px;
        height: 1px
        overflow: hidden;
        cursor: pointer;
        animation: slideToBottom 1s;
    }

    .card:hover {
        border: 2px solid #FFFBD1;
        background-color: #003051;
        color:white;
        // opacity:0.5;
    }

    .logo {
        padding: 30px;
        max-width: 250px;
    }

    .menu {
        padding: 30px;
        position: relative;
        width: 100%;
        margin: auto;
    }

    .arrow {
        position: relative;
        padding-right: 30px;
        margin: auto;
    }
    .connect-wallet {
        background-color: #003051;
        margin-right: auto;
        margin-left: auto;
        top: 2em;
        display: block;
        position: relative;
        border: 1px solid #FFFBD1;
        color:white;
    }
    .connect-wallet:hover{
        background-color: #007aff;
    }
    input[type='radio'] {
        -webkit-appearance: none;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        outline: none;
        border: 3px solid gray;
    }

    input[type='radio']:before {
        content: '';
        display: block;
        width: 60%;
        height: 60%;
        margin: 20% auto;
        border-radius: 50%;
    }

    input[type="radio"]:checked:before {
        background: #007aff;
        
    }
    
    input[type="radio"]:checked {
      border-color:#007aff;
    }
`;

export default WalletStyles;
