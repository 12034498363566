import colors from 'constant/colors';
import fonts from 'constant/fonts';
import React from 'react';
import { Line as LineChart } from 'react-chartjs-2';

const currentMonth = new Date().getMonth();
export const options = {
	responsive: true,
	maintainAspectRatio: false,
	interaction: {
		mode: 'index',
		intersect: false,
	},
	elements: {
		point: {
			radius: 0
		}
	},
	stacked: false,
	plugins: {
		title: {
			display: false,
		},
		legend: {
			display: false
		}
	},
	scales: {
		y: {
			type: 'linear',
			display: true,
			position: 'left',
			grid: {
				tickLength: 5,
				drawBorder: false
			},
			ticks: {
				stepSize: 6,
				font: {
					family: fonts.nunito,
					size: 14
				},
				color: colors.grey.dark,
				padding: 20
			},
			min: 0,
			max: 24,
		},
		y1: {
			type: 'linear',
			display: true,
			position: 'right',
			grid: {
				tickLength: 5,
				drawBorder: false
			},
			ticks: {
				stepSize: 2,
				callback: ctx => {
					return `$${ctx}K`;
				},
				font: {
					family: fonts.nunito,
					size: 14
				},
				color: colors.grey.dark,
				padding: 20
			},
			min: 0,
			max: 8,
		},
		x: {
			grid: {
				drawBorder: false,
				display: true,
				color: ctx => {
					if (ctx.index === currentMonth) {
						return '#CCCCCC';
					}
				}
			},
			ticks: {
				display: true,
				color: ctx => {
					if (ctx.index === currentMonth) {
						return colors.blue.prussianBlue;
					}
				},
				font: (ctx => {
					const font = {
						family: fonts.nunito,
						weight: '400'
					};
					if (ctx.index === currentMonth) {
						font.weight = '700';
					}
					return { ...font };
				})

			},
		},
	},
};

const labels = ['Jan', 'Febr', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Des'];

export const data = {
	labels,
	datasets: [
		{
			label: '',
			data: [1, 2, 10, 4, 14, 6, 7, 1, 2, 10, 4, 14],
			borderColor: colors.blue.aqua,
			backgroundColor: colors.white.default,
			fill: false,
			yAxisID: 'y',
		},
		{
			label: '',
			data: [3, 2, 5, 4, 3, 6, 2, 6, 6, 5, 1, 2],
			borderColor: colors.blue.prussianBlue,
			backgroundColor: colors.white.default,
			fill: false,
			yAxisID: 'y1',
		},
	],
};

const MultiaxisLine = () => {
	return <LineChart options={ options } data={ data } />;
};

export default React.memo(MultiaxisLine);
