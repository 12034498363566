import colors from 'constant/colors';
import styled from 'styled-components';


const Container = styled.div`

    td {
        font-size: 14px ;
    }
    .nft-image{
        border-radius: 50%;
    }
    .th-locations {
        width: 265px ;
    }
    .th-logo {
        width: 40px ;
    }
    .th-name {
        width: 130px;
    }
    .th-collection {
        width: 130px;
    }
    .th-financialCriteria {
        width: 200px ;
    }
    .th-SocialMedia {
        width: 220px ;
    }
    .link-icon {
        path {
            fill: ${colors.blue.prussianBlue} ;
        }
    }
    .description {
    display: -webkit-box;
    width: 210px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    
}
`;

export { Container };