
import * as RestaurantActionTypes from './actionTypes';
import {Images} from 'constant'
import {makeID} from 'helpers'

const restructureData = (data) => {

  data.collections = data.collections.map(item => item.value)
  data.socialMedia = [
    { username: data['twitter'], name: 'twitter' },
    { username: data['linkedin'], name: 'linkedin' },
    { username: data['instagram'], name: 'instagram' },
    { username: data['discord'], name: 'discord' },
  ]
  data.locations = `${data.address}, ${data.city}, ${data.country}`
  return data
}

const initialState = {
	restaurants: [],
	loading: false,
  tableMode: 'LIST',
  selectedRestaurant: {},
  fieldsEditRestaurant: {},
  Restaurant:{}
};

const reqRestaurant = (state) => ({
	...state,
	loading: true
});

const setRestaurant = (state, payload) => ({
	...state,
	restaurants: payload,
	loading: false,
  
});


const setTableMode = (state, payload) => ({
	...state,
  tableMode: payload
});

const addRestaurant = (state, payload) => {
  let data = {...payload}
  data.id = makeID(10)
  data = {
    ...data,
    ...restructureData(data)
  }
  return {
    ...state,
    restaurants: [data,...state.restaurants]
  }
}

const editRestaurant = (state, payload) => {
  let data = {...payload}
  const restaurants = [...state.restaurants]
  const findID = restaurants.findIndex(item => item.id === state.selectedRestaurant.id)
  data = restructureData(data)
  restaurants[findID] = {...data}
  return {
    ...state,
    restaurants: [...restaurants]
  }
}


const selectRestaurant = (state, payload) => {
  let selectedRestaurant = {...payload}
  let fieldsEditRestaurant = {}
  if(payload) {
    const locations = selectedRestaurant
    
    // .split(', ')
    fieldsEditRestaurant['_id'] = selectedRestaurant['_id']
    fieldsEditRestaurant['City'] = selectedRestaurant['City']
    fieldsEditRestaurant['restaurantProfile'] = selectedRestaurant['restaurantProfile'] 
    fieldsEditRestaurant['restaurantName'] = selectedRestaurant['restaurantName'] 
    fieldsEditRestaurant['website'] = selectedRestaurant['website'] 
    fieldsEditRestaurant['Description'] = selectedRestaurant['Description'] 
    fieldsEditRestaurant['Country'] = selectedRestaurant['Country'] 
    // fieldsEditRestaurant['city'] = selectedRestaurant['city'] 
    fieldsEditRestaurant['Address'] = selectedRestaurant['Address'] 
    // fieldsEditRestaurant['Collections'] = selectedRestaurant.Collections.map(item => {
    //   const id = makeID(10);
    //   return {
    //     NFTCollections: item.NFTCollections,
    //     NFTCount: item.NFTCount,
    //     NFTPrice: item.NFTPrice,
    //     NFTDescription: item.NFTDescription,
    //     NFTImage: item.NFTImage,
    //     NFTName:item.NFTName,
    //     NFTEndDate:item.NFTEndDate,
    //     NFTReleaseDate: item.NFTReleaseDate,
    //     NFTEndTime:item.NFTEndTime,
    //     benefits:item.benefits
    //   }
    // })
    // selectedRestaurant.socialMedia.forEach(item => {
    //   const name = item.name
    //   fieldsEditRestaurant[name] = item.username
    // })
    fieldsEditRestaurant['FinancialCriteria'] = selectedRestaurant['FinancialCriteria']
    fieldsEditRestaurant['walletAddress'] = selectedRestaurant['walletAddress']
    fieldsEditRestaurant['Instagram'] = selectedRestaurant['Instagram']
    fieldsEditRestaurant['Twitter'] = selectedRestaurant['Twitter']
    fieldsEditRestaurant['LinkedIn'] = selectedRestaurant['LinkedIn']
    fieldsEditRestaurant['Discord'] = selectedRestaurant['Discord']
  }
  return {
    ...state,
    selectedRestaurant,
    fieldsEditRestaurant
  }
}


const deleteRestaurant = (state) => {
  const restaurants = [...state.restaurants]
  console.log("delete",restaurants)
  const filteredRestaurant = restaurants.filter(item => item.id !== state.selectedRestaurant.id)
  return {
    ...state,
    restaurants: [...filteredRestaurant]
  }
}

const restaurantReducer = (state = initialState, action) => {
	switch (action.type) {
		case RestaurantActionTypes.GET_RESTAURANT_LIST:
			return reqRestaurant(state);
		case RestaurantActionTypes.GET_RESTAURANT_LIST_SUCCESS:

    // console.log("response from dispatch",action.payload)
			return setRestaurant(state, action.payload);
    case RestaurantActionTypes.ADD_RESTAURANT:
      return addRestaurant(state, action.payload);
    case RestaurantActionTypes.EDIT_RESTAURANT:
      return editRestaurant(state, action.payload);
    case RestaurantActionTypes.DELETE_RESTAURANT:
      return deleteRestaurant(state);
    case RestaurantActionTypes.CHANGE_TABLE_MODE:
      return setTableMode(state, action.payload);
    case RestaurantActionTypes.SELECT_RESTAURANT:
      return selectRestaurant(state, action.payload);
		default:
			return state;
	}
};

export default restaurantReducer;
