import React, { useCallback } from 'react';
import { Button, Text } from 'components';
import { Icons, Images } from 'constant';
import colors from 'constant/colors';

const NFTCard = ({
	title = 'NFT Name Goes Here',
	price = '1,2345',
	wrapperClass = 'w-full',
	isPerminted = false,
	total = 100,
	typeNft = 'Gold',
	image,
	type,
	...props
}) => {

	const renderBtnBuy = useCallback(
		() => {
			if (isPerminted) {
				return (
					<Button
						width='100%'
						height='44px'
						bgHover={ colors.blue.prussianBlue }
						textColorHover={ colors.white.default }
						textColor={ colors.blue.prussianBlue }
						className='h-6 mt-2.5'
						backgroundColor={ colors.grey.lighter }
						label='On Sale'
					/>
				);
			}else{
				return (
					<Button
						width='100%'
						height='44px'
						bgHover={ colors.blue.prussianBlue }
						textColorHover={ colors.white.default }
						textColor={ colors.blue.prussianBlue }
						className='h-6 mt-2.5'
						backgroundColor={ colors.grey.lighter }
						label='Minted'
					/>
				);
			}
		},
		[isPerminted]
	);
	return (
		<div key={ props.key } className={ `h-[${isPerminted ? '381px' : '332px'}] rounded overflow-hidden shadow-lg p-px ${wrapperClass} bg-gradient-to-br from-white/40 to-transparent hover:bg-lemonChiffon ` }>
			<div className='bg-cardBg rounded-md relative'>
				<div className='absolute flex w-full justify-between z-10 p-5 items-center'>
					<div className='bg-yellow-100 p-2 rounded-md'>
						<span className='bg-yellow font-extrabold'>{ type }</span>
					</div>
					<div className='w-[32px] h-[32px] rounded-full bg-black flex cursor-pointer'>
						<Icons.MoreIcon className='more-icon-white' />
					</div>
				</div>
				<img className='w-full h-[206px]' src={`${image}`} alt='Sunset in the mountains' />
				<div className='px-6 py-4'>
					<div className='font-bold text-xl mb-3 text-white2 font-Lora'>{ title }</div>
					<div className='flex space-x-2 mb-2.5'>
						<Text text={ `Qty ${isPerminted ? 'Preminted' : 'Minted'} : 1` } fontSize='16px' color='lemonChiffon' />
					</div>
					<div>
						<div className='flex space-x-2'>
							<img src={ Icons.polygon } className='' alt='currency' />
							<Text text={ price } className='font-extrabold' />
						</div>
					</div>
					{ renderBtnBuy() }
				</div>
			</div>
		</div>
	);
};

export default React.memo(NFTCard);