import React, {useState, useEffect} from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from 'routes';
import { store } from './store/index';
import SessionTimeout from "./pages/session/SessionTimeout";
// import {persistor} from './stores/index';
import {useSelector, useDispatch} from 'react-redux'
import { connectWallet } from "./store/walletconnection/WalletAction";
import { ToastContainer, toast } from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';

// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

import './App.css';

function App () {
  const [isAuthenticated, setAuth] = useState(true);
  const UserToken = localStorage.getItem('token');
  const wallet = useSelector((state)=> state.WalletConnect)
  
  const dispatch = useDispatch();

  const handleClick = () => {
  if (UserToken != null) {
    localStorage.clear();
    window.location.replace("/");
  }
  };
  // useEffect(() => {
  //   const { web3Modal } = wallet;
  //   if(web3Modal.cachedProvider){
  //     // alert("called")
  //     dispatch(connectWallet());
  //     console.log("wallet:",wallet)
  //   }
  // },[])

  return (
    <Provider store={ store }>
      {/* <PersistGate loading={ null } persistor={ persistor }> */}
        <Routes />
        <ToastContainer/>
        {UserToken && 
        <SessionTimeout
          isAuthenticated={isAuthenticated}
          logOut={handleClick}
        />
        }
      {/* </PersistGate> */}
    </Provider>
  );
}

export default App;
