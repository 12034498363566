import axios from 'axios';
import { Fetch } from 'services/local-storage';
const getHeaders = async (tokens) => {
  let headers = {};
  const token = await Fetch('token');
  headers.Authorization = token;
  headers['Content-Type'] = 'application/json';
  headers['x-auth-token'] = tokens;
  headers['api_key'] = process.env.REACT_APP_API_KEY
  headers = { ...headers };
  return headers;
}

const baseUrl =  process.env.NODE_ENV === "production" ? process.env.REACT_APP_BASE_URL : "https://okunftbackend.click";
 // http://localhost:4000  https://okunftbackend.click
export const get = async (url, queryParams) => {
  const headers = await getHeaders()
  return axios.get(`${baseUrl}/${url}`, {
    headers,
  });
}


export const post = async (url, data, tokens, options) => {
  const headers = await getHeaders(tokens);
  return axios.post(`${baseUrl}/${url}`, data, {
    headers
  });
}

export const put = async (url, data, tokens, queryParams, options) => {
  console.log("data",data)
  const headers = await getHeaders(tokens);
  return axios.put(`${baseUrl}/${url}`, data, {
    params: queryParams,
    headers,
    
  });
  
}


export const patch = async (url, data, options) => {
  const headers = await getHeaders();
  return axios.patch(`${baseUrl}/${url}`, data, { headers });
}

// export const delete = async (url, options) => {
//   const headers = await getHeaders(options);
//   return axios.delete(url, { headers });
// }

