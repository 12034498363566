import React from 'react';
import { Modal, Text, Button } from 'components';
import { Images } from 'constant';

const ModalSuccess = ({ visible, onClose, data }) => {
	return (
		<Modal
			visible={ visible }
			onClose={ onClose }
		>
			<div className='modal-input-container'>
				<img src={ Images.Sent } className='mx-auto mb-10' />
				<Text
					fontType='h4'
					fontFamily='Lora'
					fontWeight='700'
					fontSize='24px'
					text='Successfully Added New Admin!'
					textAlign='center'
					color='var(--blue)'
					className='mb-3 join-modal-title'
				/>

				<Text
					text='Lorem ipsum dolor sit amet, consectetur adipiscing elit.'
					color='var(--black200)'
					textAlign='center'
					className='mb-3'
				/>
				<Text
					text={ data && data.email }
					color='var(--blue)'
					textAlign='center'
					fontWeight={ '700' }
					className='mb-8'
				/>
				<div className='flex justify-center'>
					<Button
						label='Done'
						height={ '50px' }
						textColor={ 'white' }
						backgroundColor={ '#003051' }
						onClick={ onClose }
					/>
				</div>
			</div>
		</Modal>
	);
};

export default ModalSuccess;