import styled from 'styled-components';

const TextStyle = styled.div`
  ${props => `
    h1, h2, h3, h4, h5, p {
      // color: ${props.color || 'var(--color-text-white)'};
    }

    h1 {
      font-family: Lora;
      font-size: ${props.fontSize || '48px'};
      text-align: ${props.textAlign || 'left'};
      font-style: ${props.fontStyle || 'unset'};
      font-weight: ${props.fontWeight || '700'};
    }

    h2 {
      font-family: Lora;
      font-size: ${props.fontSize || '32px'};
      text-align: ${props.textAlign || 'left'};
      font-style: ${props.fontStyle || 'unset'};
      font-weight: ${props.fontWeight || '700'};
      line-height: ${props.lineHeight || '41px'};
      
      @media screen and (max-width: 500px) {
        font-size: ${props.fontSize || '24px'};
        line-height: ${props.lineHeight || '31px'};
      }
    }

    h3 {
      font-family: Lora;
      font-size: ${props.fontSize || '24px'};
      text-align: ${props.textAlign || 'left'};
      font-style: ${props.fontStyle || 'unset'};
      font-weight: ${props.fontWeight || 'unset'};
      font-weight: ${props.fontFamily};

      span {
        font-family: Lora;
        font-size: ${props.fontSize || '2rem'};
        text-align: ${props.textAlign || 'left'};
        font-style: ${props.fontStyle || 'unset'};
        font-weight: ${props.fontWeight || 'unset'};
      }
    }
    
    h4 {
      font-family: ${props.fontFamily};
      font-size: ${props.fontSize || '1.7rem'};
      text-align: ${props.textAlign || 'left'};
      font-style: ${props.fontStyle || 'unset'};
      font-weight: ${props.fontWeight || 'unset'};
    }
    
    p,
    pre {
      font-family: ${props.fontFamily};
      font-size: ${props.fontSize || '16px'};
      text-align: ${props.textAlign || 'left'};
      font-style: ${props.fontStyle || 'unset'};
      font-weight: ${props.fontWeight || 'unset'};
			line-height: ${props.lineHeight || 'normal'} ;
    }

    @media screen and (max-width: 720px) {
      h1 {
        font-size: 34px;
      }

      h4 {
        font-size: 16px;
        line-height: 26px;
      }

      p,
      pre {
        font-size: 14px;
      }
    }

    pre {
      white-space: normal;
    }
  `}
`;

export default TextStyle;