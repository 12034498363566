import { createGlobalStyle } from 'styled-components';
import colors from './colors';
import fonts from './fonts';

import Sizes from './sizes';

export default createGlobalStyle`

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }

  body, p, span, div, label, textarea, input, h1, h2, h3, h4,h5,h6, label, th, td, li {
    line-height: 1;
    font-family: ${fonts.nunito};
  }

  ol, ul {
    list-style: none;
  }

  blockquote, q {
    quotes: none;
  }

  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }

  table {
    border-collapse: collapse;
    border-spacing: 0;
  }

  .p-0 {
    padding: 0 !important;
  }

  @media ${Sizes.md}{
    .md-p-0 {
      padding: 0 !important;
    }
  }
  .icon-close {
    path {
      fill: ${colors.grey.netralGrey};
    }
  }
  .pointer {
    cursor: pointer !important;
  }
  .more-icon-white {
    margin: auto ;
    display: block ;
    path {
      fill: ${colors.white.default} ;
    }
  }
  .react-datepicker-popper {
      z-index: 99999 ;
      border-radius: 5px ;
      font-family: ${fonts.nunito} ;
      .react-datepicker__header {
        background-color: ${colors.white.default} ;
      }
      .react-datepicker__day--selected {
        background-color: ${colors.blue.prussianBlue} !important ;
      }
  }
  .dropdown {
    &:hover {
      .icon-dropdown {
      path {
        fill: ${colors.blue.prussianBlue} ;
      }
  }
    }
  }
  .icon-dropdown {
    path {
      fill: ${colors.grey.netralGrey} ;
    }
  }

  .search-input {
    padding-left: 10px !important ;
    &::placeholder {
      padding-left: 0 !important ;
    }
  }

`;