import React from 'react';
import { FormGroupWrapper } from './style';

const FormGroup = ({ children, ...props }) => {
  return (

    <FormGroupWrapper { ...props }>
      { children }
    </FormGroupWrapper>
  );
};

export default React.memo(FormGroup);