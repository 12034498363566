import colors from 'constant/colors';
import styled from 'styled-components';
import twitterIcon from 'assets/icons/twitter.svg';
import igIcon from 'assets/icons/instagram.svg';
import discordIcon from 'assets/icons/discord.svg';
import linkedinIcon from 'assets/icons/linkedin.svg';

const Aside = styled.aside`
`;

const Layout = styled.div`
`;

const Content = styled.div`
  .vertical-line {
    width: 32px;
    height: 0px;
    left: 314px;
    top: 126px;
    border: 1px solid #F4F4F4;
    transform: rotate(90deg);
  }

`;

const MenuItem = styled.a`
  ${props => props.active && `font-weight: 800; color: ${colors.blue.prussianBlue} ;`}
  .item-icon {
    path {
      fill: ${props => props.active ? colors.blue.prussianBlue : colors.grey.netralGrey} ;
    }
  }
`;

const Wrapper = styled.div`
  TextField::-webkit-outer-spin-button,
  TextField::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  TextField[type=number] {
    -moz-appearance: textfield;
  }
`;

const ModalWrapper = styled.div`
  .input-name {
      min-width: 230px !important;
      height: 40px ;
      padding-left: 9px ;
      &::placeholder {
        padding-left: 0 !important ;
      }
  }
  .input-name-check {
    width: 20px;
    height: 20px;
    alignSelf: flex-start;
    display: flex;
  }
  .input-name1 {
    min-width: 150px !important;
    height: 40px ;
    padding-left: 9px ;
    &::placeholder {
      padding-left: 0 !important ;
    }
  }
  .input-name2 {
    min-width: 150px !important;
    height: 40px ;
    padding-left: 9px ;
    &::placeholder {
      padding-left: 0 !important ;
    }
  }
  .input-name3 {
    min-width: 150px !important;
    height: 40px ;
    padding-left: 9px ;
    &::placeholder {
      padding-left: 0 !important ;
    }
  }
  
    .input-socmed {
      min-width: 190px !important;
      height: 40px ;
      padding-left: 30px ;
      &::placeholder {
        padding-left: 0 !important ;
      }
    }
    .icon-twitter {
      background: url(${twitterIcon}) no-repeat scroll 10px 13px;
    }
    .icon-ig {
      background: url(${igIcon}) no-repeat scroll 10px 13px;
    }
    .icon-linkedin {
      background: url(${linkedinIcon}) no-repeat scroll 10px 13px;
    }
    .icon-discord {
      background: url(${discordIcon}) no-repeat scroll 10px 13px;
    }
    textarea {
      padding-top: 10px ;
      padding-left: 10px ;
    }
  .input-wrapper:focus-within {
    label {
      font-weight: bold ;
      color: ${colors.prussianBlue} ;
    }
    .input-name {
        &:focus {
        outline: 1px solid ${colors.blue.prussianBlue} ;
        border-color: transparent ;
      }
    }
    .input-socmed {
        &:focus {
        outline: 1px solid ${colors.blue.prussianBlue} ;
        border-color: transparent ;
      }
    }
    textarea:focus {
      outline: 1px solid ${colors.blue.prussianBlue} ;
      border-color: transparent ;
    }
  }
`;

const Div = styled.div`
 .image_upload_box{
    width:100%,
    background-color:#c6c6c6d6,
    padding:10px
 }
 .icon_pos{
    position: absolute;
    margin-left: 10px;
    margin-top: 7px;
    font-size: 25px;
    color:#151559
 }
 .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 16px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  /* Hide the browser's default radio button */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  
  /* Create a custom radio button */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
    border-radius: 50%;
  }
  
  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the radio button is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #151E30;
  }
  
  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the indicator (dot/circle) when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the indicator (dot/circle) */
  .container .checkmark:after {
    top: 2px;
    left: 2px;
    width: 21px;
    height: 21px;
    border-radius: 50%;
    background: #72FE7F;
  }
`;

const IconWrapper = styled.div`

  svg {
    path {
        opacity: 1;
      }
  }

  ${props => props.active && `
    .collection-icon {
      path {
        opacity: 0.2 !important;
      }
    }
  `}

${props => !props.active && `
  .list-icon {
      path {
        opacity: 0.2 !important;
      }
    }
  `}

` 

export {
  Aside, Layout, Content, MenuItem, Wrapper, ModalWrapper, IconWrapper,Div
};